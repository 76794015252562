import React from "react";
import FooterMaster from '../Dashboard/FooterMaster'
import { Outlet } from "react-router-dom";


import './LayoutComponent.css'

function LayoutComponent() {
  return (
    <div className="layout_Total_body">
      {/* <Outlet /> */}
      <div className="layout_footer footer">
        <FooterMaster />
      </div>
    </div>
  );
}
export default LayoutComponent;
