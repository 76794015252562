import Sidemenu from "../Revenue_Sidemenu/RevenueSidemenu";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./SettleMent.css";
import Col from "react-bootstrap/Col";
import Header from "../Revenue_Sidemenu/Revenueheader";
import { useParams, useNavigate } from "react-router-dom";
import Tablecomponent from "../../../helpers/TableComponent/TableComp";
import jsonData from "../local.json";
import React from "react";
import { useEffect , useRef} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useStateRef from "react-usestateref";
import TomLogo from "../../../assets/Web_Doctor_Icon_new_theme/TOM_logo.svg";
import Viewmore from "../../../assets/Web_Doctor_Icon_new_theme/Eye.svg";
import Fasttrack from "../../../assets/Web_Doctor_Icon_new_theme/Fasttrack.svg";
import Walkin from "../../../assets/Web_Doctor_Icon_new_theme/walkin.svg";
import Member from "../../../assets/Web_Doctor_Icon_new_theme/Member.svg";
import { useHistory } from "react-router-dom";
import WeekChart from "../Revenue_Charts/DashboardChart";
import Axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
// import FooterMaster from "../../Dashboard/FooterMaster"
import dateFormat from "dateformat";

const SettleMentPage = () => {
  const { table } = jsonData.Tabels;
  const [array, setArray, arrayRef] = useStateRef([]);
  const [headings, setheading, headingRef] = useStateRef([]);
  const [tempdata, settempdata, tempdata_Ref] = useStateRef("");
  const [isVendor, setisVendor, isVendor_Ref] = useStateRef(false);
  const [VendorType, setVendorType, VendorType_Ref] = useStateRef("");
  const [paycard, setpaycard, paycardRef] = useStateRef("none");
  const [paytable, setpaytable, paytableRef] = useStateRef("block");
  const perams = useParams();
  const [var_startDate, setvar_startDate, var_startDate_ref] = useStateRef("");
  const [var_endDate, setvar_endDate, var_endDate_ref] = useStateRef("");
  const [Title, setTitle] = React.useState();
  const navigate = useHistory();
  const SettleMent = jsonData.SettleMent;
  const root = useRef(null);
  const [tb_data, setTbData] = useStateRef([]);
  const [responseValue, setresponseValue, responseValue_Ref] = useStateRef({});
  const crdactive = (data) => {
    if (data) {
      setVendorType(String(data));
      tempdata_Ref.current === data ? settempdata("") : settempdata(data);
      if (tempdata_Ref.current === "") {
        setisVendor(false);
      } else {
        setisVendor(true);
      }
    }
  };
  useEffect(() => {
    apicall();
  }, [perams, var_startDate_ref.current,]);

  const apicall = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var data = {
      // doctorId: localStorage.getItem("Doctor_id"),
      doctorId: localStorage.getItem("Doctor_id"),
      fromDate: var_startDate_ref.current == "" ? dateFormat(firstDay, "yyyy-mm-dd") : var_startDate_ref.current,
      toDate: var_endDate_ref.current== "" ? dateFormat(lastDay, "yyyy-mm-dd") : var_endDate_ref.current,
      searchContent: "",
      appointmentFilter: "",
      paymentModeFilter: "",
      
    };
    Axios({
      method: "POST",
      url: "/patient/getDoctorSettleMentChart",
      data: data,
    })
      .then((response) => {
        debugger;
        if (response.data.data.length > 0) {
          setresponseValue(response.data.data[0]);
          if(root.current) {
            root.current.dispose();
            root.current = null;
          }
          chart_am();
        }
        console.log(response.data.data[0]);  
      })
      .catch((err) => {});
  };

  const chart_am = () => {
    debugger
    if(true){
      if(!root.current){
       root.current = am5.Root.new("1chartdiv");
       root.current?._logo?.dispose();
       
        var chart = root.current.container.children.push(am5xy.XYChart.new(root.current, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.current.verticalLayout
        }));
       
        
        var data = [
          {  
            date: "0 Days - R",
            // Thirty_day:65,
            Thirty_day:responseValue_Ref.current.receivableThirty,
            // Sixty_day:23,
            Sixty_day:responseValue_Ref.current.thirtyReceived,

          },
          {  
            date: "0  Days - R",
            // Thirty_day:23,
            Thirty_day:responseValue_Ref.current.receivableSixty,
            // Sixty_day:92,
            Sixty_day:responseValue_Ref.current.sixtyReceived,
           
          },{  
            date: "0 Days - P",
            // Thirty_day:65,
            Thirty_day:responseValue_Ref.current.thirtyPayable,
            // Sixty_day:45,
            Sixty_day:responseValue_Ref.current.thirtyPaid,
           
          },{  
            date: "0  Days - P",
            // Thirty_day:29,
            Thirty_day:responseValue_Ref.current.sixtyPayable,
            // Sixty_day:38,
            Sixty_day:responseValue_Ref.current.sixtyPaid,
            
          }
        ]
 
       
        setTbData(data);
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root.current, {}));
        cursor.lineY.set("visible", false);
     
        var xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(root.current, {
            renderer: am5xy.AxisRendererX.new(root.current, {
              cellStartLocation: 0.2,
              cellEndLocation: 0.8,
              minGridDistance: 60,
            }),
            tooltip: am5.Tooltip.new(root.current, {
              themeTags: ["axis"],
              animationDuration: 100
            }),
            categoryField: "date"
          })
        );
        xAxis.get("renderer").labels.template.setAll({
          oversizedBehavior: "truncate",
          textAlign: "center",
          maxWidth: 90
        });
        xAxis.data.setAll(data);

        var xRenderer = xAxis.get("renderer");
        xRenderer.grid.template.set("visible", false);
       
        let yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root.current, {
            renderer: am5xy.AxisRendererY.new(root.current, {
              strokeOpacity: 0.1
            })
          })
        );
      var paretoAxisRenderer = am5xy.AxisRendererY.new(root.current, { opposite: true });
      paretoAxisRenderer.grid.template.set("forceHidden", true);
      var yAxis1 = chart.yAxes.push(am5xy.ValueAxis.new(root.current, {
        renderer: paretoAxisRenderer,
      }));  

      var xRenderer = yAxis.get("renderer");
      xRenderer.grid.template.set("visible", false);
       
        let start_value = Math.max(...data.map((item) => Math.max(item.actual_, item.target_)));
        yAxis.set("min", 0);
        yAxis.set("max", start_value + 10);
       

        var mycolor = [
          "rgba(81, 15, 48, 1)",
          "rgba(0, 164, 154, 1)",
          "rgba(244, 127, 81, 1)",
          "rgba(0, 164, 154, 1)",
          
        ];


        var series1 = chart.series.push(
          am5xy.ColumnSeries.new(root.current, {
            name: "",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "Thirty_day",
            categoryXField: "date",
          })
        );
        series1.data.setAll(data);
       
        series1.columns.template.setAll({
           tooltipText: "{name}{valueY.formatNumber('[bold]#,###.000KWD')}",
          tooltipY: am5.percent(10),
          cornerRadiusTL: 5,
          cornerRadiusTR: 5,
          strokeOpacity: 0,
          // fill: am5.color("#510f30"),
        });
        series1.columns.template.adapters.add("fill", function (fill, target) {
          var columnsindex = series1.columns.indexOf(target);
          return mycolor[columnsindex];
          // return chart.get("colors").getIndex(series1.columns.indexOf(target));
        });


        
        var series2 = chart.series.push(
          am5xy.ColumnSeries.new(root.current, {
            name: "",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "Sixty_day",
            categoryXField: "date",
          })
        );
       
          series2.data.setAll(data);
       
          series2.columns.template.setAll({
          tooltipText: "{name}{valueY.formatNumber('[bold]#,###.000KWD')}",
          tooltipY: am5.percent(10),
          cornerRadiusTL: 5,
          cornerRadiusTR: 5,
          strokeOpacity: 0,
          // fill: am5.color("#4f81bc"),
        });
        series2.columns.template.adapters.add("fill", function (fill, target) {
          var columnsindex = series2.columns.indexOf(target);
          return mycolor[columnsindex];
          // return chart.get("colors").getIndex(series1.columns.indexOf(target));
        });
      
        // series1.columns.template.events.on("click", (ev) => {
          // let clicked_value = ev.target._dataItem.dataContext.date.toLowerCase();
            // navigate(`/revenue/result/${props.year}/${perams.month}/${clicked_value}`);
      // });
      // series2.columns.template.events.on("click", (ev) => {
        // let clicked_value = ev.target._dataItem.dataContext.date.toLowerCase();
            // navigate(`/revenue/result/${props.year}/${perams.month}/${clicked_value}`);
      // });
       
      series1.columns.template.events.on("click", (ev) => {
        let clicked_value =
          ev.target._dataItem.dataContext.date.toLowerCase();
        data.map((res) => {
          console.log(
            res.date.toLowerCase(),
            clicked_value.toLowerCase(),
            "week route"
          );
          if (res.date.toLowerCase() === clicked_value.toLowerCase()) {
            debugger
             if (clicked_value.toLowerCase() === "0 days - r") {
              navigate.push(`/dashboard/revenue/Receivable 30 Days/5PL`);
            } else if (clicked_value.toLowerCase() === "0  days - r") {
              navigate.push(`/dashboard/revenue/Receivable 60 Days/5PL`);
            } else if (clicked_value.toLowerCase() === "0 days - p") {
              navigate.push(`/dashboard/revenue/Payable 30 Days/6PL`);
            } else if (clicked_value.toLowerCase() === "0  days - p") {
              navigate.push(`/dashboard/revenue/Payable 60 Days/6PL`);
            } 
          }
        });
       
      });
     
      series2.columns.template.events.on("click", (ev) => {
        let clicked_value =
          ev.target._dataItem.dataContext.date.toLowerCase();
        data.map((res) => {
          console.log(
            res.date.toLowerCase(),
            clicked_value.toLowerCase(),
            "week route"
          );
          if (res.date.toLowerCase() === clicked_value.toLowerCase()) {
            debugger
             if (clicked_value.toLowerCase() === "0 days - r") {
              navigate.push(`/dashboard/revenue/Received 30 Days/5PL`);
            } else if (clicked_value.toLowerCase() === "0  days - r") {
              navigate.push(`/dashboard/revenue/Received 60 Days/5PL`);
            } else if (clicked_value.toLowerCase() === "0 days - p") {
              navigate.push(`/dashboard/revenue/Paid 30 Days/6PPL`);
            } else if (clicked_value.toLowerCase() === "0  days - p") {
              navigate.push(`/dashboard/revenue/Paid 60 Days/6PPL`);
            }
          }
        });
       
      });
    

    }else{
        root.current.dispose();
    }
  }
}




  return (
    <div className="Scrool_bar_dash">
      <Row>
        <Col xs={12} className="d-flex">
          <Col sm={2.5} md={2.5} xs={2.5} className="menucol">
            <Sidemenu></Sidemenu>
          </Col>
          <Col
            sm={9.5}
            md={9.5}
            xs={9.5}
            style={{ height: "110%", width: "100%", margin: "auto" }}
          >
            <Row style={{  textTransform: "uppercase" }}>
              <Header
                title={perams.tableType}
                startDate={
                  var_startDate_ref.current == ""
                    ? dateFormat(
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          1
                        ),
                        "yyyy-mm-dd"
                      )
                    : var_startDate_ref.current
                }
                endDate={
                  var_endDate_ref.current == ""
                    ? dateFormat(
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth() + 1,
                          0
                        ),
                        "yyyy-mm-dd"
                      )
                    : var_endDate_ref.current
                }
              ></Header>
            </Row>

            <Row className="container">
              <div
                className="d-flex flex-wrap p-5 container justify-content-between"
                style={{ gap: "30px" }}
              >
                <Card
                  className={`crdcls1 Card1 ${
                    isVendor_Ref.current
                      ? tempdata_Ref.current === "Rec30"
                        ? "shadow_Class"
                        : "overlay_Class"
                      : ""
                  }`}
                  // onClick={() => crdactive("Rec30")}
                >
                  <CardContent className="contentcrd">
                    <div className="d-flex justify-content-center m-2">
                      0 Days
                    </div>
                    <div className="border_crd  m-2">
                      <div className="pb-2 d-flex justify-content-between">
                        Receivable{" "}
                        <div>
                          {responseValue_Ref.current.receivableThirty !== null &&
                          responseValue_Ref.current.receivableThirty !== 0 ? (
                            <>
                              {responseValue_Ref.current.receivableThirty}
                              <span className="KWD_font">KWD</span>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between m-2">
                      <span>Received</span>
                      <div>{responseValue_Ref.current.thirtyReceived !== null &&
                          responseValue_Ref.current.thirtyReceived !== 0 ? (
                            <>
                              {responseValue_Ref.current.thirtyReceived}
                              <span className="KWD_font">KWD</span>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                    </div>
                  </CardContent>
                </Card>

                <Card
                  className={`crdcls1 Card2 ${
                    isVendor_Ref.current
                      ? tempdata_Ref.current === "Rec60"
                        ? "shadow_Class"
                        : "overlay_Class"
                      : ""
                  }`}
                  // onClick={() => crdactive("Rec60")}
                >
                  <CardContent className="contentcrd">
                    <div className="d-flex justify-content-center m-2">
                      0 Days
                    </div>
                    <div className="border_crd  m-2">
                      <div className="pb-2 d-flex justify-content-between">
                        Receivable{" "}
                        <div>{responseValue_Ref.current.receivableSixty !== null &&
                          responseValue_Ref.current.receivableSixty !== 0 ? (
                            <>
                              {responseValue_Ref.current.receivableSixty}
                              <span className="KWD_font">KWD</span>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between m-2">
                      <span>Received</span>
                      <div>{responseValue_Ref.current.sixtyReceived !== null &&
                          responseValue_Ref.current.sixtyReceived !== 0 ? (
                            <>
                              {responseValue_Ref.current.sixtyReceived}
                              <span className="KWD_font">KWD</span>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                    </div>
                  </CardContent>
                </Card>

                <Card
                  className={`crdcls1 Card3 ${
                    isVendor_Ref.current
                      ? tempdata_Ref.current === "pay30"
                        ? "shadow_Class"
                        : "overlay_Class"
                      : ""
                  }`}
                  // onClick={() => crdactive("pay30")}
                >
                  <CardContent className="contentcrd">
                    <div className="d-flex justify-content-center m-2">
                      0 Days
                    </div>
                    <div className="border_crd  m-2">
                      <div className="pb-2 d-flex justify-content-between">
                        Payable{" "}
                        <div>{responseValue_Ref.current.thirtyPayable !== null &&
                          responseValue_Ref.current.thirtyPayable !== 0 ? (
                            <>
                              {responseValue_Ref.current.thirtyPayable}
                              <span className="KWD_font">KWD</span>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between m-2">
                      <span>Paid</span>
                      <div>{responseValue_Ref.current.thirtyPaid !== null &&
                          responseValue_Ref.current.thirtyPaid !== 0 ? (
                            <>
                              {responseValue_Ref.current.thirtyPaid}
                              <span className="KWD_font">KWD</span>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                    </div>
                  </CardContent>
                </Card>

                <Card
                  className={`crdcls1 Card4 ${
                    isVendor_Ref.current
                      ? tempdata_Ref.current === "pay60"
                        ? "shadow_Class"
                        : "overlay_Class"
                      : ""
                  }`}
                  // onClick={() => crdactive("pay60")}
                >
                  <CardContent className="contentcrd">
                    <div className="d-flex justify-content-center m-2">
                      0 Days
                    </div>
                    <div className="border_crd  m-2">
                      <div className="pb-2 d-flex justify-content-between">
                        Payable{" "}
                        <div>{responseValue_Ref.current.sixtyPayable !== null &&responseValue_Ref.current.sixtyPayable !== 0 ? (
                            <>
                              {responseValue_Ref.current.sixtyPayable}
                              <span className="KWD_font">KWD</span>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between m-2">
                      <span>Paid</span>
                     <div>{responseValue_Ref.current.sixtyPaid !== null &&responseValue_Ref.current.sixtyPaid !== 0 ? (<>{responseValue_Ref.current.sixtyPaid}<span className="KWD_font">KWD</span></>) : ("-")}
                        </div>
                    </div>
                  </CardContent>
                </Card>

              </div>

              { <Row className="chartbox">
              <>
      <div className="chart_container">
        <div className="text-end">
          <p
            style={{
              fontSize: "15px",
              color: "black",
              fontWeight: "500",
              margin: "0px",
              padding: "15px",
              color:'#ff0060',
              cursor:'default'
            }}
          >
            All amount in KWD
          </p>
        </div>
        <div id="1chartdiv" style={{ width: "100%", height: "40vh",cursor:'pointer' }}></div>
      </div>
    </>
               </Row> }

            </Row>
          </Col>
        </Col>
      </Row>
      {/* <div className="layout_footer footer">
                    <FooterMaster />
                </div> */}
    </div>
  );
};

export default SettleMentPage;
