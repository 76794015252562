import { useHistory, useParams } from "react-router-dom";
import useStateRef from "react-usestateref";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import dateformat from "dateformat";
import { notification } from "antd";


// mui material imports
import TextField from "@mui/material/TextField";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Grid from '@mui/material/Grid';
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FooterMaster from '../Dashboard/FooterMaster';
import DialogTitle from '@mui/material/DialogTitle';
import './AdvertisementBookingMaster.css'
import Calendar from './Calendar'
import LinearProgress from '@mui/material/LinearProgress';
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-calendar/dist/Calendar.css';
import Datedata from '../Revenue_settle/mothpicker/Datedata';
import full from '../../../src/images/Full.png';
import half from '../../../src/images/Half.png';
import uploadalert from '../../assets/Doctor_web_Icon/ex.svg';
import advWhiteIcon from '../../assets/icon/AdvertiseIconWhite.svg';
import successModelTickImg from '../../assets/Doctor_web_Icon/success.svg'
import uploadimage from "../../assets/icon/uploading-archive-1.svg";
import faildIcon from "../../assets/Images/failedIcon.svg";
import closeicon from "../../assets/icon/Icon ionic-ios-close.png";


let startDate = moment();
let startdate = moment(startDate).add(2, "days").format("YYYY-MM-DD");

let enddate = moment(startdate);

function AdvertisementBookingEditComponent() {
    let { advertiseId } = useParams();
    let Var_History = useHistory();

    const [, setVar_StartDate, Var_StartDate_Ref] = useStateRef(startdate);
    const [, setVar_EndDate, Var_EndDate_Ref] = useStateRef(enddate);
    const [, setVar_ExcludedDays, Var_ExcludedDays_Ref] = useStateRef([]);
    const [, setVar_ADImgUpload, Var_ADImgUpload_Ref] = useStateRef(null);
    const [, setVar_ADImgName, Var_ADImgName_Ref] = useStateRef(null);
    const [, setVar_ImageSize, Var_ImageSize_Ref] = useStateRef("");
    const [, setVar_FeeKWD, Var_FeeKWD_Ref] = useStateRef(null);
    const [, setVar_ToTalCost, Var_ToTalCost_Ref] = useStateRef(0);
    const [, setVar_Advetisedays, Var_AdvetisedaysRef] = useStateRef("");
    const [, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
    const [, setVar_AdvertisementId] = useStateRef("");
    const [, setVar_AdvertisementTitle] = useStateRef("");
    const [isOpenAddedAdvertisementSuccessModel, setisOpenAddedAdvertisementSuccessModel] = useState(false);
    const [isOpenUploadInstructions, setisOpenUploadInstructions] = useState(false);
    const [, setVar_AdvertisementMsg, Var_AdvertisementMsg_Ref] = useStateRef("");
    const [, setVar_AdvertisementMsgHead, Var_AdvertisementMsgHead_Ref] = useStateRef("");
    const [, setVar_PopupModelIcon, Var_PopupModelIcon_Ref] = useStateRef("");
    const [, setVar_ClassName, Var_ClassName_Ref] = useStateRef("");
    const [loading, setLoading] = useState(true);
    const [Var_fileprocess, setVar_fileprocess, ] = React.useState(false);
    const [Var_progress, setVar_Progress] = useState(0);
    const [, setVar_File_Detials, Var_File_Detials_Ref] = useStateRef("");
    const [Var_IsShowFailed, setVar_IsShowFailed] = React.useState(false);
    const [isDisable, setisisDisable] = useState(false);
    // initial function call start
    useEffect(() => {
        getClinicWiseDoctor();
        getFileSize();
          //  ========== No Edit Option for size and location
        // getPlaceMentLocation();
        // getAdvertisementSize();
        getAdvertisementList();
        setVar_fileprocess(false);
        setisisDisable(false);
    }, []);
    // initial function call end 

    // naviagte back start
    const navigateToBack = (path) => { Var_History.push("/dashboard" + path) };
    // naviagte back end

    const openInstruction = () => {
        setisOpenUploadInstructions(true);
    }
    const closeInstructionModel = () => {
        setisOpenUploadInstructions(false);
    }
    // ==================Get Advertisememt list Start =================================
    const getAdvertisementList = () => {
        setLoading(true);
        const Var_RequestData = {
            doctorid: localStorage.getItem("doctor_id"),
            limit: 50,
            pageno: 1
        };
        Axios({
            method: "POST",
            url: "doctor/getAdBooking",
            data: Var_RequestData,
        }).then((response) => {
            let editAdvertiseValue = response.data.data[0].details.filter(x => x.id == advertiseId);
            setVar_AdvertisementId(editAdvertiseValue[0].id);
            setVar_AdvertisementTitle(editAdvertiseValue[0].ad_title);
            setVar_StartDate(editAdvertiseValue[0].ad_start_date);
            setVar_EndDate(editAdvertiseValue[0].ad_end_date);
            setVar_Advetisedays(editAdvertiseValue[0].ad_total_days);
            setVar_ImageSize(editAdvertiseValue[0].ad_size == 1 ? "Half" : "Full")
            setVar_FeeKWD(editAdvertiseValue[0].ad_fee_per_day);
            setVar_ToTalCost(editAdvertiseValue[0].ad_total_cost);
            setVar_ADImgName(getFileNameFromUrl(editAdvertiseValue[0].ad_path_name));
            setVar_ExcludedDays(editAdvertiseValue[0].excluded_dates.split(","));
            setVar_ADImgUpload(editAdvertiseValue[0].ad_path_name.split("?")[0]);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        });
    }

    const getFileNameFromUrl = (url) => {
        const urlObject = new URL(url);
        const pathname = urlObject.pathname;
        return pathname.substring(pathname.lastIndexOf('/') + 1);
      };

    // ==================Get Advertisememt list End  =================================

    const isCloseAdvertisementSuccessModel = () => {
        setisOpenAddedAdvertisementSuccessModel(false);
        navigateToBack("/advertisementbooking/advertisementbookinglist");
    }
    const isClosePostfailedModel = () => {
        setVar_IsShowFailed(!Var_IsShowFailed);
      }

    // ======================== Advertisement s3 file Upload start ========================

    const addvertisementFileUpload = (event) => {
        let fileSize = event.target.files[0].size;
        if (fileSize >= Number(Var_File_Detials_Ref.current.size_in_byts)) {
          setVar_IsShowFailed(!Var_IsShowFailed);
        }
else {
    setisisDisable(true);
        setVar_fileprocess(true)
        setVar_Progress(0)
        setVar_ADImgName(event.target.files[0].name)
        const formData = new FormData();
        formData.append('module_id', "3");
        formData.append('file', event.target.files[0]);
        formData.append('pathLocation', "ADV/");

        Axios({
            method: "POST", url: "admin/awsS3FileUpload", data: formData, onUploadProgress: (progressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setVar_Progress(percentage);
            },
        }).then((response) => {
            setisisDisable(false);
            setVar_ADImgUpload(response.data.filepath.privatePathUrl);
        }).catch((error) => {
            setVar_fileprocess(false);
            console.log(error);
            setisisDisable(false);
        });
    }
    }
    // ======================== Advertisement s3 file Upload end ========================
    // ==================================Update Advertisement Start ================================
    const updateAddvertisement = () => {
        if (moment(Var_StartDate_Ref.current).format("YYYY-MM-DD") >= moment().add(2, 'days').format("YYYY-MM-DD") && Var_AdvetisedaysRef.current != " " && Var_FeeKWD_Ref.current != null &&
            Var_ToTalCost_Ref.current != " " && Var_ADImgName_Ref.current != null && Var_ADImgUpload_Ref.current != null) {
            setLoading(true);
            setisisDisable(true);
            const payload = {
                ad_id: advertiseId,
                imageArray: Var_ADImgUpload_Ref.current,
                startdate: moment(Var_StartDate_Ref.current).format("YYYY-MM-DD"),
                enddate: moment(Var_EndDate_Ref.current).format("YYYY-MM-DD"),
            }
            Axios({ method: "POST", url: "Diet/editAdBooking", data: payload }).then((response) => {
                setisisDisable(false);
                if (response.data.status == 1) {
                    setVar_ClassName("title");
                    setVar_PopupModelIcon(successModelTickImg);
                    setVar_AdvertisementMsgHead("success!");
                    setVar_AdvertisementMsg("Advertisement updated successfully!");
                    setisOpenAddedAdvertisementSuccessModel(true);
                    setLoading(false);
                } else if (response.data.status == 2) {
                    setVar_ClassName("failedtxt");
                    setVar_PopupModelIcon(faildIcon);
                    setVar_AdvertisementMsgHead("failed!");
                    setVar_AdvertisementMsg("Advertisement Already Booked for Selected Date!");
                    setisOpenAddedAdvertisementSuccessModel(true);
                    setLoading(false);
                } else {
                    setVar_ClassName("failedtxt");
                    setVar_PopupModelIcon(faildIcon);
                    setVar_AdvertisementMsgHead("failed!");
                    setVar_AdvertisementMsg("Advertisement updated failed!");
                    setisOpenAddedAdvertisementSuccessModel(true);
                }
            }).catch((error) => {
                setLoading(false);
                console.log(error);
                setisisDisable(false);
            });
        } else {
            notification.error({ message: " Please Choose all important fields" });
        }
    }
    // ==================================Update Advertisement End ================================

    const getClinicWiseDoctor = () => {
        setLoading(true);
        let data = {
            clinic_id: localStorage.getItem("clinic_id")
        };
        Axios({
            method: "POST",
            url: "clinic/getClinicWiseDoctor",
            data: data
        })
            .then((response) => {
                let filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
                setVar_DoctorDetailShow(filteredData[0].doctorName + ',' + " " + filteredData[0].specialityname);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
//Dynamic file size changes
const getFileSize = () =>{
    debugger
    const payloaddata = {
        "contenttype": "Advertisement",
        "module_id": 3
    }
  
    Axios({
      method:"POST",
      url : "admin/getFileSizeByModule",
      data : payloaddata
    }).then((response)=>{
  setVar_File_Detials(response.data.data[0]);
  })
    .catch((error)=>{
      console.log(error);
    })
  }

    return (
        <div>
            {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            }

            <div className="sub_header">
                <div className="row sub_header_body">
                    <div className="col-3 justify-content-start back_navigation" onClick={() => navigateToBack("/advertisementbooking/advertisementbookinglist")}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                        <span>ADVERTISEMENT</span>
                    </div>
                    <div className="col-6 flex_grow d-flex justify-content-center">
                        <div className='page_head_date_advertisement'>
                            <button className="add_btn" onClick={() => navigateToBack("/advertisementbooking/advertisementbookinglist")}>
                                <img src={advWhiteIcon} aria-hidden="true"  alt=""/>
                                <span style={{marginLeft:"20px"}}>Ad List</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-3 justify-content-end header_right_content">
                        <div className="page_head_date_container">
                            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    SelectProps={{
                                        renderValue: (p) => p
                                    }}
                                    value={Var_DoctorDetailShow_Ref.current}
                                    readOnly={true}
                                    renderValue={(p) => p}
                                >
                                </Select>
                            </FormControl>
                        </div>

                    </div>
                </div>
            </div>

            <div className="content_view">
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {/* <InputLabel id="demo-select-small-label" style={{ paddingLeft: 'calc(1rem - 3px)' }}>Placement Location</InputLabel>
                            <Select size="small" id="demo-simple-select" sx={{ m: 1, minHeight: 10, width: 300 }}
                                style={{ marginLeft: 'calc(1rem - 2px)' }}
                                value={Var_PlacementLocationId_Ref.current}
                                onChange={(e) => getPlacementLocationValue(e)}
                                readOnly={true}
                            >
                                {Var_PlacementLocation.map((item, i) => (
                                    <MenuItem
                                        key={i}
                                        value={item.id}
                                    >
                                        <Grid container  >
                                            <Grid xs={9} className="ref_lab" align="start"  >
                                                {item.ad_location}
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                ))}

                            </Select> */}
                            <div className="calendar_full">
                                <Calendar
                                    useRef={"calendar"}
                                    getDate={(data) => { }}
                                    aftertwodays={true}
                                    showSlots={true}
                                    clinicId={null}
                                ></Calendar>
                                <div style={{ margin: '0px 0px 0px 20px ', color: '#ff0068' }}>Note : Booking can be done 2 days from the current date only</div>

                            </div>

                        </Grid>

                        <Grid item xs={6}>
                            <Grid container spacing={2}>

                                <Grid item xs={6} md={6} lg={6}>
                                    <InputLabel id="demo-select-small-label">Start Date</InputLabel>
                                    <TextField
                                        type="text"
                                        size="small"
                                        value={Var_StartDate_Ref.current != "0000-00-00" ? dateformat(Var_StartDate_Ref.current, "dd-mmm-yy") : " "}
                                        sx={{ minWidth: '-webkit-fill-available' }}
                                        readOnly={true} />
                                </Grid>
                                <Grid item xs={6} md={6} lg={6}>
                                    <InputLabel id="demo-select-small-label">End Date</InputLabel>
                                    <TextField
                                        type="text"
                                        size="small"
                                        value={Var_EndDate_Ref.current != "0000-00-00" ? dateformat(Var_EndDate_Ref.current, "dd-mmm-yy") : " "}
                                        sx={{ minWidth: '-webkit-fill-available' }}
                                        readOnly={true} />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <InputLabel id="demo-select-small-label">Excluded Dates :
                                        {Var_ExcludedDays_Ref.current.length > 0 && Var_ExcludedDays_Ref.current?.map((data,i,array) => (
                                            <label>{data!= ""&&
                                                moment(data).format("DD-MMM-YY")}{i < array.length -1 ? ",":" "}&nbsp;</label>
                                        ))}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <div className="upload_title">
                                        <span  >Upload Advertisement</span>
                                        <img onClick={() => openInstruction()} src={uploadalert} alt=""></img>
                                    </div>
                                    <div className="fileUpload">
                                        <input type="file" className="upload" accept="image/png, image/gif, image/jpeg" onChange={(e) => addvertisementFileUpload(e)} />
                                        <span><img src={uploadimage} alt="Upload Icon" /></span>
                                        <p>{Var_ADImgName_Ref.current}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} className="fileshow">
                                    {Var_fileprocess &&
                                        <div>
                                            <div className="upload-container" style={{ paddingTop: '15px' }}>
                                                {/* Your other UI elements */}
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={Var_progress}
                                                    color="secondary"
                                                    className="progressBar"
                                                    sx={{ zIndex: '9999999', width: '100%', margin: '0px 3px' }}
                                                />
                                                {/* Display progress percentage */}
                                                <div className="progress-text">{`${Var_progress}%`}</div>
                                            </div>
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={3} className="checkbox_full" >
                                            <Checkbox className="post_active_btn_ad" checked={Var_ImageSize_Ref.current == "Half" ? true : false} />
                                            <label className="fess_cost" >Half</label>
                                            <img src={half} className="select_Icon" alt=""></img>
                                        </Grid>
                                        <Grid item sm={3} className="checkbox_full">
                                            <Checkbox className="post_active_btn_ad" checked={Var_ImageSize_Ref.current == "Full" ? true : false} />
                                            <label className="fess_cost">Full</label>
                                            <img src={full} className="select_Icon" alt=""></img>
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid item xs={3} md={3} lg={3}>
                                    <p >Fee / Day <span className="fontSize10">(KWD)</span></p>
                                    <p className="txtClrg">{Datedata.formatMoney(Var_FeeKWD_Ref.current)}</p>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3}>
                                    <p className="content_SubHeadings">Total Fees <span className="fontSize10">(KWD)</span></p>
                                    <p className="txtClrg">{Datedata.formatMoney(Var_ToTalCost_Ref.current)}</p>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <div className="segment_center">
                                        <button className="submit_Button" disabled={isDisable} onClick={() => updateAddvertisement()} >Update</button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="layout_footer footer">
                <FooterMaster />
            </div>
            {/* ================= Add Advertisement  Success Model Start ================= */}
            <Dialog
                // fullWidth={fullWidth}
                open={isOpenAddedAdvertisementSuccessModel}
                onClose={isCloseAdvertisementSuccessModel}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "300px",
                            borderRadius: "20px",
                        },
                    },
                }}
            >
                <DialogContent>
                    <div className="success_model_body">
                        <div className="icon">
                            <img className="image_body_sizes" src={Var_PopupModelIcon_Ref.current} alt=""/>
                        </div>
                        <div className={Var_ClassName_Ref.current}>{Var_AdvertisementMsgHead_Ref.current}</div>
                        <div className="content">{Var_AdvertisementMsg_Ref.current}</div>
                        <div className="segment_center">
                            <button className="botton_Cart_Create" onClick={() => isCloseAdvertisementSuccessModel()}>OK</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            {/* ==========================================Upload Instructions ======================= */}
            <Dialog
                open={isOpenUploadInstructions}
                onClose={closeInstructionModel}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "400px",
                            borderRadius: "20px",
                        },
                    },
                }}
            >
                <DialogTitle>
                    <div className="upload_instruction_full">
                        <h4 className="uploadins_head">UPLOAD INSTRUCTIONS</h4>
                        <img className="upload_ins_close" onClick={() => closeInstructionModel()} src={closeicon} alt = ""></img>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className="uploaddIns_div">
                            <label className="upldinsicon"></label><label className="ins_content">Please upload image in JPG or PNG format.</label>
                        </div>
                        <div className="uploaddIns_div">
                            <label className="upldinsicon"></label><label className="ins_content">Image size should be 1080 px X 566 px. </label>
                        </div>
                        <div className="uploaddIns_div">
                            <label className="upldinsicon"></label><label className="ins_content">Image size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB.</label>
                        </div>
                        {/* <div className="uploaddIns_div">
                            <label className="upldinsicon"></label><label className="ins_content">Video size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB.</label>
                        </div> */}
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog fullWidth= {true} open={Var_IsShowFailed} onClose={setVar_IsShowFailed}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={faildIcon} alt = ""/>
              </div>
              <div className="failed">alert!</div>
              <div className="content">File size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB</div>
              <div className="segment_center">
                <button className="botton_Cart_Create" onClick={() => isClosePostfailedModel()}>OK</button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        </div>
    )
}






export default AdvertisementBookingEditComponent;