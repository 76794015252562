// AUTHOR : Dinesh
// CR-DATE: 11-July-2023
// MOD-DATE: 08-August-2023
// DESCRIPTION: Availability Update work

import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import dateformat from 'dateformat';
import useStateRef from "react-usestateref";


// Mui meterial component import
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


import "./AvailabilityMaster.css";
import Calendar from "../Calendar/Calendar"
import FooterMaster from '../Dashboard/FooterMaster'
import Service from '../../helpers/Service'

function AvailabilityComponent() {

  let Var_History = useHistory();

  var [Var_DoctorId, setVar_DoctorId, Var_DoctorId_Ref] = useStateRef(null);
  const [Var_AvailabilityMaster, setVar_AvailabilityMaster] = React.useState(false);
  const [Var_FromDate, setVar_FromDate, Var_FromDate_Ref] = useStateRef("");
  const [Var_ToDate, setVar_ToDate, Var_ToDate_Ref] = useStateRef("");
  const [Var_StoreSlots, setVar_StoreSlots, Var_StoreSlots_Ref] = useStateRef([]);
  const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
  const [loading, setLoading] = useState(true);

  // initial function call start
  useEffect(() => {
    getClinicWiseDoctor();
    doctorDropdown();
    getAppointmentCalendar();
  }, []);
  // initial function call end


  // navigation to back start
  const handleOnClick = (path) => {
    Var_History.push("/dashboard" + path)
  };
  // navigation to back end

  //header field doctor name start
  const getClinicWiseDoctor = () => {
    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("clinic_id")
    };
    Axios({
      method: "POST",
      url: "clinic/getClinicWiseDoctor",
      data: data,
    }).then((response) => {
      var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
      setVar_DoctorDetailShow(filteredData[0].doctorName + ',' + " " + filteredData[0].specialityname);
      setLoading(false);
    })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  //header field doctor name end

  //header date start
  const getRangeData = (data) => {
    console.log(data, "getRangeData")
    if (data.enddate === null) {
      setVar_FromDate(data.startdate);
      setVar_AvailabilityMaster(true);
    } else {
      if (data.startdate < data.enddate) {
        setVar_FromDate(data.startdate);
        setVar_ToDate(data.enddate);
        setVar_AvailabilityMaster(true);
        Service.start_date = data.startdate;
        Service.end_date = data.enddate;

      } else {
        setVar_FromDate(data.enddate);
        setVar_ToDate(data.startdate);
        Service.start_date = data.enddate;
        Service.end_date = data.startdate;
        setVar_AvailabilityMaster(true);
      }

      if (Var_AvailabilityMaster == true) {
        Var_History.push("/dashboard/availability/slots");
      }
    }
  }
  const doctorDropdown = () => {
    setLoading(true);
    Axios({
      method: "POST",
      url: "clinic/getClinicWiseDoctor",
      data: {
        // clinic_id: localStorage.getItem("doctor_id"),
        clinic_id: 10,
      },
    })
      .then((response) => {
        setVar_DoctorId(response.data.data[0].doctor_id);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const getAppointmentCalendar = () => {
    setLoading(true);
    var dates = {
      startdate: dateformat("2023/07/25"),
      enddate: dateformat("2023/07/31")
    }
    var data = {
      "clinicId": 10,
      "doctorId": localStorage.getItem("doctor_id"),
      "fromDate": dateformat(dates.startdate, "yyyy-mm-dd"),
      "toDate": dateformat(dates.enddate, "yyyy-mm-dd")
    }
    Axios({
      method: "POST",
      url: 'doctor/getappointmentCalendar',
      data: data
    }).then((response) => {
      setVar_StoreSlots(response.data.data);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    })
  }
  //header date end




  return (
    <div>
      {loading &&
        <div className="loader_body">
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        </div>
      }
      {/* ================= Availability sub header start ================= */}
      <div className="sub_header">
        <div className="sub_header_body">
          <div className="back_navigation" onClick={() => handleOnClick("/")} >
            <i className="fa fa-angle-left" aria-hidden="true"></i>
            <span>MANAGE SCHEDULE</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            <div>
              <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  SelectProps={{
                    renderValue: (p) => p
                  }}
                  value={Var_DoctorDetailShow_Ref.current}
                  readOnly={true}
                  renderValue={(p) => p}

                >
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      {/* ================= Availability sub header end ================= */}


      {/* ================= Availability list View  start ================= */}
      <div className="content_view">
        {/* ================= Availability calender start ================= */}
        <Grid container>
          <Grid item xs={12} className="lengcalen">
            <div>
              <Calendar
                getDate={(data) => getRangeData(data)}
                showSlots={true}
                availableSlots={true}
                doctor_id={Var_DoctorId_Ref.current}
              ></Calendar>
            </div>
          </Grid>
        </Grid>
        {/* ================= Availability calender end ================= */}
      </div>
      {/* ================= Availability list View  start ================= */}


      {/* ================= Availability footer start ================= */}
      <div className="footer">
        <FooterMaster />
      </div>
      {/* ================= Availability footer end ================= */}
    </div>
  );
}
export default AvailabilityComponent;
