import { Container, Row, Col, Dropdown, Card } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";


import './ReferralMaster.css'
import referral from "../../assets/icon/referral.svg"



import axios from "axios";
// import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { notification, Spin } from "antd";
import IOSSwitch from "../../helpers/Switch/Switch";
import FormControlLabel from '@mui/material/FormControlLabel';
// Mui meterial component import
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// import Card from "@mui/material/Card";
// import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { MenuItem } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { red } from "@mui/material/colors";
import CircularProgress from '@mui/material/CircularProgress';

import BeforeArrayIcon from './../../assets/Web_Doctor_Icon_new_theme/refBefore.svg';
import FooterMaster from '../Dashboard/FooterMaster'

const ReferralMasterComponent = () => {
  let Var_History = useHistory();
  const handleOnClick = (path) => { Var_History.push("/dashboard" + path) };

  const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
  const [Var_Referral_Name, setVar_Referral_Name, Var_Referral_Name_Ref] = useStateRef("Who Referred Me");
  const [Var_ReferralActiveSwitchValue, setVar_ReferralActiveSwitchValue, Var_ReferralActiveSwitchValue_Ref] = useStateRef(true);
  const [Var_DoctorReferralData, setVar_DoctorReferralData, Var_DoctorReferralData_Ref] = useStateRef([]);
  const [Var_ReferralDoctorData, setVar_ReferralDoctorData, Var_ReferralDoctorData_Ref] = useStateRef([]);
  var [Var_DateApi, setVar_DateApi, dateRefapi] = useStateRef("");
  const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor] = React.useState([]);
  var [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect] = useState("");
  var [Var_date, setVar_Date, Var_date_Ref] = useStateRef(new Date().toLocaleDateString('fr-CA'))
  // var [dateapi, setDateapi, dateRefapi] = useStateRef("")
  const [loading, setLoading] = useState(false);




  useEffect(() => {
    // getSlotsService();
    setVar_Date(moment(new Date().toLocaleDateString('fr-CA')).format("DD-MMM-YY"));
    setVar_DateApi(new Date().toLocaleDateString('fr-CA'));
    console.log(dateRefapi.current);
    getReferralDoctorDetails();
    getClinicWiseDoctor();
    // getservice();



    // getSlotsService();
  }, []);


  const getReferralActiveSwitchValue = (event) => {
    setVar_ReferralActiveSwitchValue(event.target.checked);
    if (event.target.checked == false) {
      setVar_Referral_Name("Who I Referred");
      getDoctorReferralDetails();
    } else {
      setVar_Referral_Name("Who Referred Me");
    }
  }

  //Date secssion for back start
  const previousdate = () => {
    let date = new Date(Var_date_Ref.current);
    let date2 = date.setDate(date.getDate() - 1);
    let options = {
      day: '2-digit',
      month: 'short',
      year: '2-digit'
    };
    let formattedDate = date.toLocaleDateString('en-US', options);
    var monthsegment = moment(formattedDate).format("DD-MMM-YY")
    setVar_Date(monthsegment);
    setVar_DateApi(date.toLocaleDateString('fr-CA'));
    getDoctorReferralDetails();


  }
  //Date secssion for back End

  //Date secssion for forward start
  const nextdate = () => {
    debugger;
    let date = new Date(Var_date_Ref.current);
    let date2 = date.setDate(date.getDate() + 1);
    if (date2 <= new Date()) {
      let options = {
        day: '2-digit',
        month: 'short',
        year: '2-digit'
      };
      let formattedDate = date.toLocaleDateString('en-US', options);
      var monthsegment = moment(formattedDate).format("DD-MMM-YY")
      setVar_Date(monthsegment);
      setVar_DateApi(date.toLocaleDateString('fr-CA'));
      getDoctorReferralDetails();
    }
  }
  //Date secssion for forward End


  const getDoctorReferralDetails = () => {
    debugger;
    var date = dateRefapi.current;
    var referraldata = {
      doctor_id: localStorage.getItem("Doctor_id"),
      date_value: date.toString()
    };
    axios
      .post("doctor/getDoctorReferral", referraldata)
      .then((response) => {
        debugger;
        setVar_DoctorReferralData(response.data.data);
      }).catch((error) =>{
        console.log(error);
      });
  }

  const getReferralDoctorDetails = () => {
    debugger;
    var referraldata = {
      doctor_id: localStorage.getItem("Doctor_id"),
    };
    axios
      .post("doctor/getReferredDoctor", referraldata)
      .then((response) => {
        debugger;
        setVar_ReferralDoctorData(response.data.data);
      }).catch((error) =>{
        console.log(error);
      });
  }

  const convertTo12HrFormat = (time) => {
    var timeArr = time.split(':');
    var hours = parseInt(timeArr[0]);
    var minutes = parseInt(timeArr[1]);
    var suffix = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;
    var time12 = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + suffix;
    return time12;
  }

  const getClinicWiseDoctor = () => {
    // setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("clinic_id")
    };
    axios
      .post("clinic/getClinicWiseDoctor", data)
      .then((response) => {
        console.log('test', response.data.data)
        setVar_getClinicWiseDoctor(response.data.data);
        setVar_doctorSpecialitySelect(response.data.data[0].specialityid);
        var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
        console.log(filteredData);
        setVar_DoctorDetailShow(filteredData[0].doctorName + ',' + " " + filteredData[0].specialityname);
        localStorage.setItem("")
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }




  return (
    <div>
      {loading &&
        <div className="loader_body">
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        </div>
      }

      <div className="sub_header">
        <div className="sub_header_body walkin_header_body">
          <div className="back_navigation" onClick={() => handleOnClick("/")}>
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span>REFERRAL</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            {Var_ReferralActiveSwitchValue_Ref.current == false && <div className="page_head_date">
              <Button className='date_left_button'
                onClick={() => {
                  previousdate();
                }}
              >
                <i className="fa fa-chevron-left date_left_button_icon" aria-hidden="true" />
              </Button>
              <Button className='date_button'>{Var_date}</Button>
              <span><Button onClick={() => {
                nextdate();
              }} className='date_right_button'>
                <i className="fa fa-chevron-right date_right_button_icon" aria-hidden="true" />
              </Button>
              </span>
            </div>}
          </div>
        </div>
      </div>
      <div>
        <Grid container className="content_row_referral" >
          <Grid
            sm={2}
            md={2}
            lg={2}
            align="start"
            className="referral_data"
          >
            <h6 className="profile_head_service">
              {Var_Referral_Name_Ref.current}
            </h6>
          </Grid>
          <Grid
            sm={8}
            md={8}
            lg={8}
            align="start"
            className="referral_data"
          ></Grid>
          <Grid
            sm={2}
            md={2}
            lg={2}
            align="start"
            className="referral_data_icon checkbox-btn"
          >
            <FormControlLabel className='every_days_service' control={
              <IOSSwitch sx={{ m: 1 }} className='toggle' checked={Var_ReferralActiveSwitchValue_Ref.current == true ? true : false} onClick={(e) => getReferralActiveSwitchValue(e)} />
            } />
            <img src={BeforeArrayIcon} style={Var_ReferralActiveSwitchValue_Ref.current == true ? { position: 'absolute', right: '46px', top: '5px', zIndex: '1', transform: 'rotate(172deg)' } : { position: 'absolute', right: '61px', top: '5px', zIndex: '1', transform: 'rotate(358deg)' }} />
          </Grid>
        </Grid>
        <div className='content_view'>
          {Var_ReferralActiveSwitchValue_Ref.current == true && <Grid container className="content_row_manage">
            {Var_ReferralDoctorData_Ref.current.map((responseData, i) => (
              <Card className='referraldoctorcard1' onClick={() => handleOnClick("/referral/viewreferral/" + responseData.patient_id + "/" + responseData.ref_id + "/" + responseData.refer_date.slice(0, 10) + "/" + 2)} >
                <p className='referraldoctorname'>{responseData.vendor_name}</p>
                <div className='referraldateandpatientname'>
                  <p className='referraldoctorpatientname_patient'><b>{responseData.patient_name}</b></p>
                  <p className='referraldoctorpatientname'>{responseData.refer_date ? moment(responseData.refer_date.split(" ")[0], "YYYY-MM-DD").format("DD-MMM-YY") : ''}</p>
                </div>
                <div className='referraldateandpatientname'>
                  <p className='referraldoctorpatientname'>{responseData.age == 0 ? "" : responseData.age + 'yrs, '}<span className='referraldoctorgender'>{responseData.gender}</span ></p>
                  <p className='referraldoctorpatientname'>{responseData.refer_date.slice(11) == null ? '' : convertTo12HrFormat(responseData.refer_date.slice(11))}</p>
                </div>
              </Card>
            ))}
          </Grid>
          }
          {Var_ReferralActiveSwitchValue_Ref.current == false && <Grid container className="content_row_manage">
            {Var_DoctorReferralData_Ref.current.map((responseData, i) => (
              <Card className='referraldoctorcard2' onClick={() => handleOnClick("/referral/viewreferral/" + responseData.patient_id + "/" + responseData.ref_id + "/" + responseData.refer_date.slice(0, 10) + "/" + 1)}>
                <p className='referraldoctorname'> {responseData.vendor_name}</p>
                <div className='referraldateandpatientname'>
                  <p className='referraldoctorpatientname_patient'><b>{responseData.patient_name}</b></p>
                  <p className='referraldoctorpatientname'>{responseData.refer_date ? moment(responseData.refer_date.split(" ")[0], "YYYY-MM-DD").format("DD-MMM-YY") : ''}</p>
                </div>
                <div className='referraldateandpatientname'>
                  <p className='referraldoctorpatientname'>{responseData.age == 0 ? "" : responseData.age + 'yrs, '}<span className='referraldoctorgender'>{responseData.gender}</span ></p>
                  <p className='referraldoctorpatientname'>{responseData.refer_date.slice(11) == null ? '' : convertTo12HrFormat(responseData.refer_date.slice(11))}</p>
                </div>
                <div>
                  <p className='referraldoctorpatientname_address'>{responseData.vendor_address}</p>
                </div>
              </Card>
            ))}
          </Grid>
          }
        </div>
      </div>
      <div className="layout_footer footer">
        <FooterMaster />
      </div>
    </div>
  )
}
export default ReferralMasterComponent;