import './ViewReferral.css'
import { Container, Row, Col, Dropdown, Card } from 'react-bootstrap';
import Button from "@mui/material/Button";
import referral from "../../assets/icon/referral.svg";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import useStateRef from "react-usestateref";
import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from "moment";

import axios from "axios";
import xeray from "../../assets/icon/xeray.png"
import FooterMaster from '../Dashboard/FooterMaster'

const ViewReferralComponent = () => {
    let Var_History = useHistory();
    let { patientId } = useParams();
    let { refId } = useParams();
    let { date } = useParams();
    let { items } = useParams();
    const handleOnClick = (path) => { Var_History.push("/dashboard" + path) };
    const [Var_ReferralDoctor_Name, setVar_ReferralDoctor_Name, Var_ReferralDoctor_Name_Ref] = useStateRef("");
    const [Var_ReferralDoctorVendor_Name, setVar_ReferralDoctorVendor_Name, Var_ReferralDoctorVendor_Name_Ref] = useStateRef("");
    const [Var_ReferralDoctorPatient_Name, setVar_ReferralDoctorPatient_Name, Var_ReferralDoctorPatient_Name_Ref] = useStateRef("");
    const [Var_ReferralDoctorPatient_Age, setVar_ReferralDoctorPatient_Age, Var_ReferralDoctorPatient_Age_Ref] = useStateRef("");
    const [Var_ReferralDoctorPatient_Gender, setVar_ReferralDoctorPatient_Gender, Var_ReferralDoctorPatient_Gender_Ref] = useStateRef("");
    const [Var_ReferralDoctorPatient_RefDate, setVar_ReferralDoctorPatient_RefDate, Var_ReferralDoctorPatient_RefDate_Ref] = useStateRef("");
    const [Var_ReferralDoctorPatient_Remarks, setVar_ReferralDoctorPatient_Remarks, Var_ReferralDoctorPatient_Remarks_Ref] = useStateRef("");
    const [Var_ReferralDoctorPatient_VendorAddress, setVar_ReferralDoctorPatient_VendorAddress, Var_ReferralDoctorPatient_VendorAddress_Ref] = useStateRef("");
    const [Var_ReferralDoctorPatient_Filepath, setVar_ReferralDoctorPatient_Filepath, Var_ReferralDoctorPatient_Filepath_Ref] = useStateRef("");
    const [loading, setLoading] = useState(false);

    // initial function call start
    useEffect(() => {
        typeCheck()
    }, []);
    // initial function call End

    const typeCheck = () => {
        if (items == 2) {
            getReferralDoctorDetails();
            setVar_ReferralDoctor_Name('VIEW YOUR REFERRALS');
        } else if (items == 1) {
            getDoctorReferralDetails();
            setVar_ReferralDoctor_Name('VIEW REFERRAL');
        }

    }

    const getReferralDoctorDetails = () => {
        debugger;
        var referraldata = {
            doctor_id: localStorage.getItem("Doctor_id"),
        };
        axios
            .post("doctor/getReferredDoctor", referraldata)
            .then((response) => {
                debugger;
                var filtereddata = response.data.data.filter(x => x.patient_id == patientId && x.ref_id == refId);
                console.log("View Referral", filtereddata[0])
                setVar_ReferralDoctorVendor_Name(filtereddata[0].vendor_name);
                setVar_ReferralDoctorPatient_Name(filtereddata[0].patient_name);
                setVar_ReferralDoctorPatient_Age(filtereddata[0].age);
                setVar_ReferralDoctorPatient_Gender(filtereddata[0].gender);
                setVar_ReferralDoctorPatient_RefDate(filtereddata[0].refer_date);
                setVar_ReferralDoctorPatient_Remarks(filtereddata[0].remarks);
                setVar_ReferralDoctorPatient_VendorAddress(filtereddata[0].vendor_address);
                setVar_ReferralDoctorPatient_Filepath(filtereddata[0].file_path);
            }).catch((error) =>{
                console.log(error);
              });
    }

    const getDoctorReferralDetails = () => {
        debugger;
        var referraldata = {
            doctor_id: localStorage.getItem("Doctor_id"),
            date_value: date
        };
        axios
            .post("doctor/getDoctorReferral", referraldata)
            .then((response) => {
                var filtereddata = response.data.data.filter(x => x.patient_id == patientId && x.ref_id == refId);
                console.log("View Referral", filtereddata[0])
                setVar_ReferralDoctorVendor_Name(filtereddata[0].vendor_name);
                setVar_ReferralDoctorPatient_Name(filtereddata[0].patient_name);
                setVar_ReferralDoctorPatient_Age(filtereddata[0].age);
                setVar_ReferralDoctorPatient_Gender(filtereddata[0].gender);
                setVar_ReferralDoctorPatient_RefDate(filtereddata[0].refer_date);
                setVar_ReferralDoctorPatient_Remarks(filtereddata[0].remarks);
                setVar_ReferralDoctorPatient_VendorAddress(filtereddata[0].vendor_address);
                setVar_ReferralDoctorPatient_Filepath(filtereddata[0].file_path);
            }).catch((error) =>{
                console.log(error);
              });
    }

    const convertTo12HrFormat = (time) => {
        var timeArr = time.split(':');
        var hours = parseInt(timeArr[0]);
        var minutes = parseInt(timeArr[1]);
        var suffix = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12;
        var time12 = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + suffix;
        return time12;
    }

    return (
        <div>
            {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            }
            <div className="sub_header">
                <div className="sub_header_body walkin_header_body">
                    <div className="back_navigation" onClick={() => handleOnClick("/referral")}>
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                        <span>{Var_ReferralDoctor_Name_Ref.current}</span>
                    </div>
                    <div className="flex_grow"></div>
                </div>
            </div>
            <div className='viewReferral_Details'>
                <div className='view_referral'>
                    <p className='viewreferraldoctorname'>{Var_ReferralDoctorVendor_Name_Ref.current}</p>
                    <div className='referraldateandpatientname'>
                        <p className='referraldoctorpatientname_patient'><b>{Var_ReferralDoctorPatient_Name_Ref.current}</b></p>
                        <p className='viewreferraldoctorpatientname'>{Var_ReferralDoctorPatient_RefDate_Ref.current ? moment(Var_ReferralDoctorPatient_RefDate_Ref.current.split(" ")[0], "YYYY-MM-DD").format("DD-MMM-YY") : ''}</p>
                    </div>
                    <div className='referraldateandpatientname'>
                        <p className='viewreferraldoctorpatientname'>{Var_ReferralDoctorPatient_Age_Ref.current == 0 ? "" : Var_ReferralDoctorPatient_Age_Ref.current + 'yrs, '}<span className='referraldoctorgender'>{Var_ReferralDoctorPatient_Gender_Ref.current}</span ></p>
                        <p className='viewreferraldoctorpatientname'>{Var_ReferralDoctorPatient_RefDate_Ref.current.slice(11) == null ? '' : convertTo12HrFormat(Var_ReferralDoctorPatient_RefDate_Ref.current.slice(11))}</p>
                    </div>
                    {items == 1 && <p className='viewreferraladdress'>{Var_ReferralDoctorPatient_VendorAddress_Ref.current}</p>}
                    <p className='referraldoctorpatientname_remarks'>Remarks</p>
                    <div className='referral_Remarks_Details'>
                        <p className='referraldoctor_remarks_data'>{Var_ReferralDoctorPatient_Remarks_Ref.current}</p>
                    </div>
                </div>
                <div className='referral_doctor_Image'>
                    <div className='p-2 image-port'>
                        <p className='referraldoctorpatientname_remarks'>Uploaded Documents</p>
                        {Var_ReferralDoctorPatient_Filepath_Ref.current ? <img className='referral_Image' src={Var_ReferralDoctorPatient_Filepath_Ref.current} />
                            : <p className='viewreferraldoctorpatientname'>No Document Available!</p>}
                    </div>
                </div>
            </div>
            <div className="layout_footer footer">
                <FooterMaster />
            </div>
        </div>
    )
}
export default ViewReferralComponent;