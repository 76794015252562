import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import React, { useState ,useEffect} from "react";
import "./RevenueSidemenu.css";
import Advance from "../../../assets/Web_Doctor_Icon_new_theme/paidadv.svg";
import Fasttrack from  "../../../assets/Web_Doctor_Icon_new_theme/fast_track.svg";
import Walkin from  "../../../assets/Web_Doctor_Icon_new_theme/Walking.svg";
import NoInsurance from  "../../../assets/Web_Doctor_Icon_new_theme/paidClinic.svg";
import FullCoverage from  "../../../assets/Web_Doctor_Icon_new_theme/FullCover.svg";
import Partail from  "../../../assets/Web_Doctor_Icon_new_theme/partailin.svg";
import Refferal from  "../../../assets/Web_Doctor_Icon_new_theme/Refferal.svg";
import { useHistory } from "react-router-dom";

export default function Sidemenu(){
  const navigate = useHistory();
  const [selectedItem1, setSelectedItem1] = useState(null);
  const [selectedItem2, setSelectedItem2] = useState('Revenue & Income');
  const [selectedItem3, setSelectedItem3] = useState('REVENUE & SETTLEMENT');
  const [selectedItem4, setSelectedItem4] = useState('Transaction');
  const linkStyle = {
    color: '#707070', 
    textDecoration: 'none', 
  };
  const handleItemClick = (itemName,type,name,menuType) => {
    debugger
    if(name!=='a'){
      if(name==="Settlement"){
        navigate.push(`/dashboard/SettleMent/${name}`);

      }else{
        if(menuType==="dashboard"){
          navigate.push(`/dashboard/revenue`);
        }else{
          navigate.push(`/dashboard/revenue/${name}/${menuType}`);
        }
      }
    }
    // if(menuType==="dashboard"){
    //   navigate.push(`/dashboard/revenue`);
    // }
    if(type==='menu1'){
      debugger
      setSelectedItem3(itemName);
    }else if(type==='menu2'){
      setSelectedItem2(itemName);
    }else if(type==='menu3'){
      setSelectedItem1(itemName);
    }
  };
return(
    <div style={{ display: 'flex', height: '100%'  ,zIndex:"unset"}}>
    <Sidebar  width="100%" backgroundColor="#ffffff">
      <Menu  style={{ paddingTop:"90px",overflowY:'auto' }} >
         <SubMenu  defaultOpen  onClick={() => handleItemClick("Doctor",'menu3','Revenue & Income',"dashboard")} style={{ color: selectedItem1 === "S_Doctor" ? "#510F30" : "#707070" ,fontSize:'20px'}}  label="REVENUE & SETTLEMENT" >
         {/* <SubMenu defaultOpen active="false" onClick={() => handleItemClick("Doctor",'menu3','Revenue & Income',"dashboard")} style={{ color: selectedItem1 === "Doctor" ? "#510F30" : "#707070" }} label="Revenue & Income"> */}
          <MenuItem onClick={() => handleItemClick("S_Doctor11",'menu3','paid advance',"1R")} style={{ color: selectedItem1 === "S_Doctor11" ? "#510F30" : "#707070" }} ><span className='Icons'> - Paid Advance <img src={Advance}></img></span></MenuItem>
          <MenuItem onClick={() => handleItemClick("S_Doctor1",'menu3','Fast Track',"1R")} style={{ color: selectedItem1 === "S_Doctor1" ? "#510F30" : "#707070" }}><span  className='Icons'> - Fast Track<img src={Fasttrack}></img></span></MenuItem>
          <MenuItem onClick={() => handleItemClick("S_Doctor2",'menu3','Walk In',"1R")} style={{ color: selectedItem1 === "S_Doctor2" ? "#510F30" : "#707070" }}><span className='Icons'> - Walk - In <img src={Walkin}></img></span></MenuItem>
          <MenuItem onClick={() => handleItemClick("S_Doctor3",'menu3','Paid at Clinic (No Insurance)',"1R")} style={{ color: selectedItem1 === "S_Doctor3" ? "#510F30" : "#707070" }}><span className='Icons'>- Paid at Clinic<img src={NoInsurance}></img></span><span style={{fontSize:'12px',fontWeight:'700',display:'flex',padding:'0px 8px'}}>(No Insurance)</span></MenuItem>
          <MenuItem onClick={() => handleItemClick("S_Doctor4",'menu3','Paid through Insurance (full Coverage)',"1R")} style={{ color: selectedItem1 === "S_Doctor4" ? "#510F30" : "#707070" }}><span className='Icons' >- Paid through Insurance<img src={FullCoverage}></img></span><span style={{fontSize:'12px',fontWeight:'700',display:'flex',padding:'0px 8px'}}>(full Coverage)</span></MenuItem>
          <MenuItem onClick={() => handleItemClick("S_Doctor5",'menu3','Paid through Insurance (Partial Coverage)',"1R")} style={{ color: selectedItem1 === "S_Doctor5" ? "#510F30" : "#707070" }}><span className='Icons' >- Paid through Insurance<img src={Partail}></img></span><span style={{fontSize:'12px',fontWeight:'700',display:'flex',padding:'0px 8px'}}>(Partial Coverage)</span></MenuItem>
          <MenuItem onClick={() => handleItemClick("S_Doctor6",'menu3','Referral Incentive',"2I")} style={{ color: selectedItem1 === "S_Doctor6" ? "#510F30" : "#707070" }}><span className='Icons'>Referral Incentive<img src={Refferal}></img></span></MenuItem>
          </SubMenu>
          {/* <MenuItem onClick={() => handleItemClick("p_Doctor",'menu3','Payable & Receivable Due',"3PC")} style={{ color: selectedItem1 === "p_Doctor" ? "#510F30" : "#707070" }}>Payable & Receivable Due</MenuItem>
          <SubMenu defaultOpen  onClick={() => handleItemClick("r_Doctor",'menu3','Settlement',"4S")} style={{ color: selectedItem1 === "r_Doctor" ? "#510F30" : "#707070" }} label="Settlement" >
          <MenuItem onClick={() => handleItemClick("r_Doctor1",'menu3','Receivable 30 Days',"5PL")} style={{ color: selectedItem1 === "r_Doctor1" ? "#510F30" : "#707070" }}>Receivable 0 Days</MenuItem>
          <MenuItem onClick={() => handleItemClick("r_Doctor2",'menu3','Receivable 60 Days',"5PL")} style={{ color: selectedItem1 === "r_Doctor2" ? "#510F30" : "#707070" }}>Receivable 0 Days</MenuItem>
          <MenuItem onClick={() => handleItemClick("r_Doctor3",'menu3','Payable 30 Days',"6PL")} style={{ color: selectedItem1 === "r_Doctor3" ? "#510F30" : "#707070" }}>Payable 0 Days</MenuItem>
          <MenuItem onClick={() => handleItemClick("r_Doctor4",'menu3','Payable 60 Days',"6PL")} style={{ color: selectedItem1 === "r_Doctor4" ? "#510F30" : "#707070" }}>Payable 0 Days</MenuItem>
          <SubMenu  defaultOpen style={{ color: selectedItem1 === "S_Doctor" ? "#510F30" : "#707070" }} label="Transaction Histroy" >
          <MenuItem onClick={() => handleItemClick("r_Doctor5",'menu3','Received 30 Days',"5PL")} style={{ color: selectedItem1 === "r_Doctor5" ? "#510F30" : "#707070" }}>Received 0 Days</MenuItem>
          <MenuItem onClick={() => handleItemClick("r_Doctor6",'menu3','Received 60 Days',"5PL")} style={{ color: selectedItem1 === "r_Doctor6" ? "#510F30" : "#707070" }}>Received 0 Days</MenuItem>
          <MenuItem onClick={() => handleItemClick("r_Doctor7",'menu3','Paid 30 Days',"6PPL")} style={{ color: selectedItem1 === "r_Doctor7" ? "#510F30" : "#707070" }}>Paid 0 Days</MenuItem>
          <MenuItem onClick={() => handleItemClick("r_Doctor8",'menu3','Paid 60 Days',"6PPL")} style={{ color: selectedItem1 === "r_Doctor8" ? "#510F30" : "#707070" }}>Paid 0 Days</MenuItem>
          </SubMenu> */}
          {/* </SubMenu> */}
          {/* </SubMenu> */}
      </Menu>
    </Sidebar>
  </div>
)
}