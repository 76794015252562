import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useStateRef from "react-usestateref";
import Axios from "axios";
import { notification } from "antd";
import HeaderMasterComponent from "../Header/HeaderMaster";

// mui imports 
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import "./EditService.css";
import IOSSwitch from "../../helpers/Switch/Switch";
import FooterMaster from '../Dashboard/FooterMaster';
import LinearProgress from '@mui/material/LinearProgress';
import uploadimage from "../../assets/icon/uploading-archive-1.svg";
import successModelTickImg from '../../assets/Doctor_web_Icon/success.svg';
import Alert from "../../assets/Images/alert.svg";
import alterImage from '../../../src/images/Vector.png';
import BackIcon from "../../assets/Doctor_Images/Group 31.svg";


function EditService() {
    let { serviceId } = useParams();
    let Var_History = useHistory();

    const [, setVar_SpecialityValue] = useStateRef("");
    const [, setVar_SpecialityValueId] = useStateRef("");

    const [, setVar_id, Var_id_Ref] = useStateRef(null);
    const [, setVar_DoctorId, Var_DoctorId_Ref] = useStateRef(null);
    const [Var_Service, setVar_Service, Var_Service_Ref] = useStateRef(null);
    const [Var_Cost, setVar_Cost, Var_Cost_Ref] = useStateRef(null);
    const [, setVar_SlotDuration, Var_SlotDuration_Ref] = useStateRef(null);
    const [Var_Description, setVar_Description, Var_Description_Ref] = useStateRef(null);
    const [, setVar_FilePath, Var_FilePath_Ref] = useStateRef(null);
    const [Var_FileName, setVar_FileName,] = useStateRef(null);
    const [Var_IsActive, setVar_IsActive,] = useStateRef(null);
    const [, setVar_SpecId, Var_SpecId_Ref] = useStateRef(null);
    const [Var_ServiceImageName, setVar_ServiceImageName,] = useStateRef(null);
    const [, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
    const [, setVar_DoctorSpeciality, Var_DoctorSpeciality_Ref] = useStateRef(null);
    const [loading, setLoading] = useState(true);
    const [isOpenServiceSuccessModel, setisOpenServiceSuccessModel] = useState(false);
    const [, setVar_PopupImg, setVar_PopupImg_Ref] = useStateRef("");
    const [, setVar_Popup_content, Var_Popup_content_Ref] = useStateRef("");
    const [, setVar_Popup_Message, Var_Popup_Message_Ref] = useStateRef("");
    const [, setVar_content_head, Var_content_head_Ref] = useStateRef("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [Var_fileprocess, setVar_fileprocess,] = React.useState(false);
    const [Var_progress, setVar_Progress] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [isDisable, setisisDisable] = useState(false);
  const [Var_msgContent,setVar_msgContent,Var_msgContent_Ref] = useStateRef("");
  const [Var_IsShowSuccessnew, setVar_IsShowSuccessnew] = useState(false);



    // navigate to back 
    const navigateToBack = (path) => {
        Var_History.push("/dashboard" + path)
    };

    // initial function call start
    useEffect(() => {
        setVar_fileprocess(false);
        getClinicWiseDoctor();
        getSpeciality();
        getManageServicesById();
        setisisDisable(false);
    }, [])
    // initial function call end


    const getManageServicesById = () => {

        setLoading(true);
        let Var_RequestData = {
            doctor_id: localStorage.getItem("doctor_id") //serviceId
        };

        Axios({ method: "POST", url: "doctor/getDoctorService", data: Var_RequestData, }).then((response) => {

            let filtereddata = response.data.data.filter(x => x.id == serviceId);

            setVar_id(filtereddata[0].id);
            setVar_DoctorId(filtereddata[0].doctor_id);
            setVar_Service(filtereddata[0].service);
            setVar_Cost(filtereddata[0].cost);
            setVar_SlotDuration(filtereddata[0].slot_duration.slice(3, 5));
            setVar_Description(filtereddata[0].description);
            setVar_FilePath(filtereddata[0].file_path);
            setVar_FileName(filtereddata[0].file_name);
            setVar_ServiceImageName(filtereddata[0].file_name);
            setVar_IsActive(filtereddata[0].is_active);
            setVar_SpecId(filtereddata[0].specid);
            setVar_DoctorSpeciality(filtereddata[0].speciality);
            setLoading(false);
        })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    // onchange get form values start

    const getSpeciality = () => {
        setLoading(true);
        let data = {
            doctorId: localStorage.getItem("Doctor_id")
        };
        axios
            .post("doctor/getDoctorDetails", data).then((response) => {
                const doctorSpeciality = JSON.parse(response.data.data[0].speciality);
                setLoading(false);

            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }


    const getServiceValue = (event) => {
        setVar_Service(event.target.value);
    }

    const getFeeValue = (event) => {
        let numericonly = event.target.value.replace(/[^0-9.]/g, '');
        setVar_Cost(numericonly);
    }
    const getDescriptionValue = (event) => {
        setVar_Description(event.target.value);
    }
    const getServiceImage = (event) => {

        setVar_ServiceImageName(event.target.files[0].name)
        setVar_FileName(event.target.files[0].name)
        fileupload(event)
    }
    const getActiveSwitchValue = (event) => {
        setVar_IsActive(event.target.checked == true ? 1 : 0)
    }


    const fileupload = (e) => {
        setisisDisable(true);
        setVar_fileprocess(true)
        setVar_Progress(0)
        const formData = new FormData();
        formData.append('module_id', "3");
        formData.append('file', e.target.files[0]);
        formData.append('pathLocation', "MANAGE-SERVICE/");
        axios({
            method: "POST", url: "admin/awsS3FileUpload", data: formData, onUploadProgress: (progressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log("progress", percentage)
                setVar_Progress(percentage);
            },
        })
            .then((response) => {
                setVar_FilePath(response.data.filepath.Location);
                setisisDisable(false);
            })
            .catch((error) => {
                console.log(error);
                // setLoading(false);
                setVar_fileprocess(false);
                setisisDisable(false);
            });
    }
    // onchange get form values end

    // Update service API call start
    const UpdateService = () => {
        if (Var_Service_Ref.current != "" && Var_Cost_Ref.current != "" && Var_Description != "" && Var_SpecId_Ref.current != "") {
            if (Var_SlotDuration_Ref.current > 9 && Var_SlotDuration_Ref.current < 61) {
                if (Var_Cost_Ref.current > 1) {
                    setisisDisable(true);
                    setLoading(true);
                    let data = {
                        id: Var_id_Ref.current,
                        doctor_id: Var_DoctorId_Ref.current,
                        service: Var_Service_Ref.current,
                        specialityId: Var_SpecId_Ref.current,
                        cost: Var_Cost_Ref.current,
                        slot_duration: "00:" + Var_SlotDuration_Ref.current,
                        description: Var_Description,
                        is_active: Var_IsActive,
                        file_name: Var_ServiceImageName,
                        file_path: Var_FilePath_Ref.current.split("?")[0]

                    }
                    axios.post("doctor/addmanageservice", data).then((response) => {
                        setisisDisable(false);
                        if (response.data.data[0].ErrorCode == "9999") {
                            // setVar_PopupImg(successModelTickImg);
                            // setVar_content_head("title");
                            // setVar_Popup_content("SUCCESS!");
                            // setVar_Popup_Message("Manage service updated successfully!");
                            // setisOpenServiceSuccessModel(!isOpenServiceSuccessModel);
                            setVar_msgContent("Manage service updated successfully!");
                            setVar_IsShowSuccessnew(true);
                                  setTimeout(() => {
                                    setVar_IsShowSuccessnew(false);
                                   Var_History.push("/dashboard/manageservice");
                                  }, 1500);
                            setLoading(false);

                        }
                        else if (response.data.data[0].ErrorCode == "9998") {
                            notification.error({ message: response.data.data[0].msg })
                            setLoading(false);
                        } else {
                            // setVar_PopupImg(Alert);
                            // setVar_content_head("failedtxt");
                            // setVar_Popup_content("FAILED!");
                            // setVar_Popup_Message(response.data.data[0].msg + "!");
                            // setisOpenServiceSuccessModel(!isOpenServiceSuccessModel);
                            setVar_msgContent(response.data.data[0].msg + "!");
                            setVar_IsShowSuccessnew(true);
                                  setTimeout(() => {
                                    setVar_IsShowSuccessnew(false);
                                    Var_History.push("/dashboard/manageservice");
                                  }, 1500);
                            setLoading(false);
                        }
                        // else if (response.data.data[0].ErrorCode == "9998") {
                        //     notification.error({ message: response.data.data[0].msg })
                        //     setLoading(false);

                        // }
                        // else if (response.data.data[0].ErrorCode == "9997") {
                        //     notification.error({ message: response.data.data[0].msg })
                        //     setLoading(false);
                        // }
                        // else if (response.data.data[0].ErrorCode == "9995") {
                        //     notification.error({ message: response.data.data[0].msg })
                        //     setLoading(false);
                        // }
                    })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                            setisisDisable(false);
                        });
                }
                else {
                    notification.error({ message: "The fee should be above 1 KWD." });
                }
            } else {
                notification.error({ message: "Slot Duration 10 Minu to 60 Minu" });
            }
        } else {
            notification.error({ message: "Please select all fields" });
        }
    }
    // Update service API call end


    const isCloseUpdateServiceSuccessModel = () => {

        setisOpenServiceSuccessModel(!isOpenServiceSuccessModel);
        Var_History.push("/dashboard/manageservice");
    }


    const getClinicWiseDoctor = () => {

        setLoading(true);

        let data = {
            clinic_id: localStorage.getItem("clinic_id")
        };
        axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {

                let filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
                setVar_DoctorDetailShow(filteredData[0].doctorName + ',' + " " + filteredData[0].specialityname);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverClick = (value) => {
        console.log(value);
        setVar_SlotDuration(value);
        handleClose();
    };
    const values = Array.from({ length: 50 }, (_, index) => index + 10);

    return (
        <div>
            {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            }
                    <div>
          <HeaderMasterComponent />
      </div>
                <div className="Navication-header">
                    <div>
                    <img src={BackIcon} onClick={()=>navigateToBack("/manageservice")}/><label onClick={()=>navigateToBack("/manageservice")}>Manage Services</label> <i class="fa fa-angle-left" aria-hidden="true"></i><label>Edit Service</label>
                    </div>
                    
                </div>
            {/* <div className="sub_header">
                <div className="sub_header_body">
                    <div className="back_navigation" onClick={() => navigateToBack("/manageservice")}>
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                        <span>EDIT SERVICE</span>
                    </div>
                    <div className="flex_grow"></div>
                    <div className="header_right_content">
                        <div>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    SelectProps={{
                                        renderValue: (p) => p
                                    }}
                                    value={Var_DoctorDetailShow_Ref.current}
                                    readOnly={true}
                                    renderValue={(p) => p}

                                >
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="content_view_manage_service">





        <div className="manage_service_adding_details">

<div className="add_service_details">


<Grid item xs={6} className="speciality_ame_aded">
            <InputLabel className="demo_special_name" id="demo-select-small-label">Specialty</InputLabel>
            <TextField id="outlined-basic" placeholder={Var_DoctorSpeciality_Ref.current} size="small"
              sx={{ minWidth: '-webkit-fill-available' }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>




          <Grid item xs={6} className="speciality_ame_aded">
            <InputLabel className="demo_special_name" id="demo-select-small-label">Service Title</InputLabel>
            <TextField
              type="text"
              placeholder="Enter service description"
              size="small"
              value={Var_Service}
              sx={{ minWidth: '-webkit-fill-available' }}
              onChange={(e) => getServiceValue(e)} />
          </Grid>


          <Grid item xs={6} className="speciality_ame_aded">

<InputLabel className="demo_special_name" id="demo-select-small-label">Service Description</InputLabel>

<TextField
  type="text"
  placeholder="Description"
  size="small"
  value={Var_Description}
  sx={{ minWidth: '-webkit-fill-available' }}
  onChange={(e) => getDescriptionValue(e)} />
</Grid>


<div className="fee_slot_duration">
  <div>
  <Grid container item xs={12} lg={12} className="speciality_ame_aded_fee">
            <InputLabel className="demo_special_name" id="demo-select-small-label">Fee <span className="fontSize10"></span></InputLabel>
            <TextField
                            type="text"
                            size="small"
                            inputProps={{ maxLength: 7 }}
                            value={Var_Cost}
                            onChange={(e) => getFeeValue(e)}
                            sx={{ minWidth: '-webkit-fill-available' }} />
          </Grid>
  </div>

  <div>
  <Grid  container item xs={12} lg={12} className="speciality_ame_aded_fee">
            <InputLabel className="demo_special_name" id="demo-select-small-label">Slot Duration <span className="fontSize10">(mins)</span></InputLabel>

            <TextField
                            type="text"
                            size="small"
                            // inputProps={{ maxLength: 2 }}
                            value={Var_SlotDuration_Ref.current}
                            // onChange={(e) => getSlotDurationValue(e)}
                            sx={{ minWidth: '-webkit-fill-available' }}
                            onClick={(event) => handleClick(event)} />
            <div >
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div>
                  {values.map((value, index) => (
                    <Typography
                      key={index}
                      sx={{ p: 2, cursor: 'pointer' }}
                      onClick={() => handlePopoverClick(value)}
                    >
                      {index + 10}
                    </Typography>
                  ))}
                </div>
              </Popover>
            </div>
          </Grid>

  </div>

</div>

<Grid item xs={6} className="speciality_ame_aded_active">
            <InputLabel id="demo-select-small-label" className="demo_special_name active_edit_manage_service">Active</InputLabel>
            <FormControlLabel className='every_days' control={
                                <IOSSwitch checked={Var_IsActive == 1 ? true : false} onChange={(e) => getActiveSwitchValue(e)} sx={{ m: 1 }} className='toggle' />
                            } />
          </Grid>


          <Grid container className="save_details_new">
              <Grid ClassName="save_profile_details">
                <div className="save_button_details" onClick={() => UpdateService()}>Update</div>
              </Grid>
              </Grid>


  </div>


 

</div>






            </div>
            {/* ================= Edit Success Model Start ================= */}
            <Dialog
                // fullWidth={fullWidth}
                open={isOpenServiceSuccessModel}
                onClose={setisOpenServiceSuccessModel}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "300px",
                            borderRadius: "20px",
                        },
                    },
                }}
            >
                <DialogContent>
                    <div className="success_model_body">
                        <div className="icons">
                            <img className="image_body_sizes" src={setVar_PopupImg_Ref.current} alt="" />
                        </div>
                        <div className={Var_content_head_Ref.current}>{Var_Popup_content_Ref.current}</div>
                        <div className="content">{Var_Popup_Message_Ref.current}</div>
                        <div className="segment_center">
                            <button className="botton_Cart_Create" onClick={() => isCloseUpdateServiceSuccessModel()}>OK</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            {/* ================= Edit Success Model End ================= */}

            {Var_IsShowSuccessnew &&
        <div className="popup-main">
          <div className="pushNotification_body">
            <div className="pushNotification_content">
              <p className="notification_text_head"><b>{Var_msgContent}</b></p>
            </div>
            <div className="progress-prt">
              <div className="progress-bar"></div>
            </div>
            </div>
          </div>} 


        </div>
    )
}
export default EditService;