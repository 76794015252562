// AUTHOR : PRAVEEN.P
// CR-DATE: 20-july-2023
// MOD-DATE:21-july-2023
// DESCRIPTION: WALK IN BOOKING work

import axios from "axios";
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import React, { useState, useEffect } from "react";
import moment from "moment";

// Mui meterial component import
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import './CancelledAppoinmentsMaster.css'
// import User from '../../assets/Doctor_web_Icon/Vector.png'
import Visit from '../../assets/Doctor_web_Icon/Visit.svg'
import Online from '../../assets/Doctor_web_Icon/Online.svg'
import Fasttrack from '../../assets/Doctor_web_Icon/Fasttrack.svg'
import walkin from '../../assets/Doctor_web_Icon/Walkin.svg'
import crown from '../../images/crown_Q.png'
import User from '../../assets/Doctor_web_Icon/Group 23213.svg'
import MaleIcon from '../../assets/clinicIcon/MaleJpg.jpg'
import FemaleIcon from '../../assets/clinicIcon/FeMaleJpg.jpg'
import FooterMaster from '../Dashboard/FooterMaster'
import NotFound from "../../assets/Web_Doctor_Icon_new_theme/NotFound.svg"

function CancelledAppointmentsComponent() {

    const [value, onChange] = useState(new Date());
    let Var_History = useHistory();
    const handleOnClick = (path) => { Var_History.push("/dashboard" + path) };

    var [Var_CancelledData, setVar_CancelledData, Var_CancelledData_Ref] = useStateRef([]);
    const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
    var [Var_currentmonth, setVar_currentmonth] = useState();
    var [Var_Value, setVar_value] = useState("month");
    var [dateapi, setDateapi, dateRefapi] = useStateRef("")
    var [Var_date, setVar_Date, Var_date_Ref] = useStateRef(new Date().toLocaleDateString('fr-CA'))
    // var [dateapi, setDateapi, dateRefapi] = useStateRef("")
    var [Var_HaveData, setVar_HaveData] = useStateRef("none")
    var [Var_NoData, setVar_NoData] = useStateRef("block")
    const [loading, setLoading] = useState(true);

    // initial function call start
    useEffect(() => {
        getClinicWiseDoctor();
        setVar_Date(moment(new Date().toLocaleDateString('fr-CA')).format("DD-MMM-YY"));
        setDateapi(new Date().toLocaleDateString('fr-CA'));
        setVar_currentmonth(moment(Var_date).format("DD-MMM-YY"))
        getcancelled();


    }, []);
    // initial function call end

    //Date session for back start
    const previousdate = () => {
        let date = new Date(Var_date_Ref.current);
        let date2 = date.setDate(date.getDate() - 1);
        let options = {
            day: '2-digit',
            month: 'short',
            year: '2-digit'
        };
        let formattedDate = date.toLocaleDateString('en-US', options);
        var monthsegment = moment(formattedDate).format("DD-MMM-YY")
        setVar_Date(monthsegment);
        setDateapi(date.toLocaleDateString('fr-CA'));
        getcancelled();
    }
    //Date session for back End
    const nodata = () => {
        if (Var_CancelledData == "") {

        }
        else {

        }
    }


    const getClinicWiseDoctor = () => {

        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("clinic_id")
        };
        axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {

                var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
                setVar_DoctorDetailShow(filteredData[0].doctorName + ',' + " " + filteredData[0].specialityname);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }




    //Date secssion for forward start
    const nextdate = () => {
        let date = new Date(Var_date_Ref.current);
        let date2 = date.setDate(date.getDate() + 1);
        let options = {
            day: '2-digit',
            month: 'short',
            year: '2-digit'
        };
        let formattedDate = date.toLocaleDateString('en-US', options);
        var monthsegment = moment(formattedDate).format("DD-MMM-YY")
        setVar_Date(monthsegment);
        setDateapi(date.toLocaleDateString('fr-CA'));
        getcancelled();
    }
    //Date secssion for forward End

    // ==================divide Time On Date start====================
    const divideTimeOnDate = (time24) => {

        // const convertTo12HrFormat = () => {
        var timeArr = time24.split(':');
        var hours = parseInt(timeArr[0]);
        var minutes = parseInt(timeArr[1]);
        var suffix = hours >= 12 ? 'pm' : 'am';

        hours = hours % 12 || 12;
        var time12 = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + suffix;

        return time12;
        // }
    }
    // ==================divide Time On Date end====================

    // =====================date format changing start==============
    const bookedDate = (time24) => {
        var dateWithTime = time24;
        const date = new Date(dateWithTime);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear().toString().substr(-2);
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }
    const cancelDate = (time24) => {
        var dateWithTime = time24.split(' ')
        const date = new Date(dateWithTime[0]);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear().toString().substr(-2);
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }
    // =====================date format changing end=========================

    // ==================convert 12 hours format On Date start====================
    const convertTo12HrFormat = (time) => {
        var timeArr = time.split(':');
        var hours = parseInt(timeArr[0]);
        var minutes = parseInt(timeArr[1]);
        var suffix = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12;
        var time12 = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + suffix;
        return time12;
    }
    // ==================convert 12 hours format On Date start====================

    //   ========================get cancelled list API start=====================
    const getcancelled = () => {
        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("clinic_id"),
            doctor_id: localStorage.getItem("doctor_id"),
            patient_type: "all",
            period: "day",
            search_date: dateRefapi.current,
            search_date_to: dateRefapi.current
        };
        axios
            .post("doctor/getPatientCancelledList", data)
            .then((response) => {

                if (response.data.data.length == 0) {
                    setLoading(false);
                    setVar_NoData("block")
                    setVar_HaveData("none")
                    setVar_CancelledData(response.data.data);

                } else if (response.data.data.length > 0) {
                    setLoading(false);
                    setVar_NoData("none")
                    setVar_HaveData("block")
                    setVar_CancelledData(response.data.data);

                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }
    //   ========================get cancelled list API end=====================

    return (
        <div>
            {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            }

            {/* ================================================ Cancelled sub header start ============================================== */}
            <div className="head_of_the_page">
                <div className="sub_header">
                    <div className="sub_header_body ">
                        <div className="back_navigation" onClick={() => handleOnClick("/")}>
                            <i class="fa fa-angle-left" aria-hidden="true"></i>
                            <span>CANCELED</span>
                        </div>
                        <div className="flex_grow"></div>
                        <div className="header_right_content">
                            <div>
                                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        SelectProps={{
                                            renderValue: (p) => p
                                        }}
                                        value={Var_DoctorDetailShow_Ref.current}
                                        readOnly={true}
                                        renderValue={(p) => p}

                                    >
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="page_head_date_container ">
                                <Button className='date_left_button'
                                    onClick={() => {
                                        previousdate();
                                    }}
                                >
                                    <i className="fa fa-chevron-left date_left_button_icon" aria-hidden="true" />
                                </Button>
                                <Button className='date_button'>{Var_date}</Button>
                                <span><Button onClick={() => {
                                    nextdate();
                                }} className='date_right_button'>
                                    <i className="fa fa-chevron-right date_right_button_icon" aria-hidden="true" />
                                </Button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ================================================ Cancelled sub header end ================================================= */}

            {/* =====================================================Cancelled list start===================================================== */}
            <div className="content_view">

                {/* <div style={{ display: Var_NoData }}>
                    <p className="cancel_no_data">No Data Found</p> */}
                    <div style={{ display: Var_NoData, height:"70vh" }}>
                    <p className="cancel_no_data"> <img src={NotFound} className="cancel_no_data_image" />No Data Found</p>
                </div>
                <div style={{ display: Var_HaveData }}>
                    {Var_CancelledData.map((value) => (
                        <Card className='cancel_cart'>
                            <Grid container className="cancel_card_details">
                                <Grid item xs="4" align='start' className='profile_details' >
                                    <Grid container xs={12}>
                                        <Grid item xs={2}>
                                            {/* <img src={value.file_name == " " ? User : value.file_name == "[object Object]" ? User : value.file_name == "" ? User  : value.file_name == "undefined" ? User : value.file_name == null ? User : value.file_name} User alt='error image' className="profile_image"></img> */}
                                            <img src={value.appointment_type_id == 0 && value.gender == "male" ? MaleIcon : value.appointment_type_id == 0 && value.gender == "Male" ? MaleIcon : value.appointment_type_id == 0 && value.gender == "female" ? FemaleIcon : value.appointment_type_id == 0 && value.gender == "Female" ? FemaleIcon :
                                                value.file_name == "" && value.gender == "male" ? MaleIcon : value.file_name == "" && value.gender == "Male" ? MaleIcon : value.file_name == "" && value.gender == "female" ? FemaleIcon : value.file_name == "" && value.gender == "Female" ? FemaleIcon :
                                                    value.file_name == " " && value.gender == "male" ? MaleIcon : value.file_name == " " && value.gender == "Male" ? MaleIcon : value.file_name == " " && value.gender == "female" ? FemaleIcon : value.file_name == " " && value.gender == "Female" ? FemaleIcon :
                                                        value.file_name == null && value.gender == "male" ? MaleIcon : value.file_name == null && value.gender == "Male" ? MaleIcon : value.file_name == null && value.gender == "female" ? FemaleIcon : value.file_name == null && value.gender == "Female" ? FemaleIcon :
                                                            value.file_name == "undefined" && value.gender == "male" ? MaleIcon : value.file_name == "undefined" && value.gender == "Male" ? MaleIcon : value.file_name == "undefined" && value.gender == "female" ? FemaleIcon : value.file_name == "undefined" && value.gender == "Female" ? FemaleIcon :
                                                                value.file_name} alt='profile' className='Profile_img' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className='profile_details_cancel ps-3'>
                                                <div className="header_name_profile">{value.name}</div>
                                                <div className="date_time_details">
                                                    <div>{bookedDate(value.booked_date)}</div>
                                                    <div>{divideTimeOnDate(value.appointment_time)}</div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs="4" align="center">
                                    <img src={value.appointment_type_id == 1 ? Visit : value.appointment_type_id == 2 ? Online : value.appointment_type_id == 3 ? Fasttrack : walkin} className="fast_image_queue"></img>
                                    <div className='profile_details_cancel'>{value.appointment_type}</div>
                                </Grid>
                                <Grid item xs="4" align="end" className='timing_date_details'>
                                    <div>{cancelDate(value.cancel_date)}</div>
                                    <div>{convertTo12HrFormat(value.cancel_time)}</div>
                                </Grid>
                            </Grid>
                        </Card>
                    ))}
                </div>
            </div>
            {/* =====================================================Cancelled list end===================================================== */}
            <div className="layout_footer footer">
                <FooterMaster />
            </div>
        </div>
    )
}
export default CancelledAppointmentsComponent;