import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { notification, Spin } from "antd";
import axios from "axios";
import useStateRef from "react-usestateref";
import moment from "moment";

// Mui meterial component import
import {
  InputLabel,
  FormControlLabel,
  Select,
  MenuItem,
  Container,
  Card,
  Grid,
  TextField,
  Button,
  FormControl,
  DialogContent,
  Dialog,
  Radio,
  Autocomplete,
  InputAdornment,
  IconButton,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import HeaderMasterComponent from "../Header/HeaderMaster";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearIcon from "@mui/icons-material/Clear";

import Success from "../../assets/Doctor_web_Icon/success.svg";
import "./Prescription.css";
import Report from "../../assets/icon/Group 19092.svg";
import profile from "../../assets/Images/TOMLogo.jpg";
import FooterMaster from "../Dashboard/FooterMaster";
import Member from "../../assets/Doctor_web_Icon/Visit.svg";
import Fasttrack from "../../assets/Doctor_web_Icon/Fasttrack.svg";
import Online from "../../assets/Doctor_web_Icon/Online.svg";
import Walkin from "../../assets/Doctor_web_Icon/Walkin.svg";
import Reportimage from "../../assets/Doctor_web_Icon/IMG_20200803_125505 (1).png";
import successModelTickImg from "../../assets/Doctor_web_Icon/success.svg";
import Icon from "../../assets/Doctor_web_Icon/Group 20732.svg";
import vector from "../../assets/Doctor_web_Icon/Vector.png";
import faildIcon from "../../assets/Images/failedIcon.svg";
import MaleIcon from "../../assets/Doctor_Images/male_user.png";
import FemaleIcon from "../../assets/Doctor_Images/female_user.png";
import commenttype from "../../assets/clinicIcon/Group 169.svg";
import Plus_icon_new from "../../assets/clinicIcon/plus_icon.svg";
import Plus_icon from "../../assets/icon/Plus_icon.svg";
import BackIcon from "../../assets/Doctor_Images/Group 31.svg";
import closeicon from "../../assets/Doctor_Images/closeIcon.png";

// const medicineOptions = ["Dola 650", "Paracetamol", "Aspirin", "Ibuprofen"];
const PrescriptionComponent = () => {
  let Var_History = useHistory();
  let { patientId } = useParams();
  let { bookingId } = useParams();
  let { date } = useParams();
  let { is_send_to_pharam } = useParams();
  let { frompage } = useParams();

  const [fullWidth, setFullWidth] = React.useState(true);
  var [Var_DateApi, setVar_DateApi, dateRefapi] = useStateRef("");
  var [
    Var_AppointmentDetail,
    setVar_AppointmentDetail,
    Var_AppointmentDetail_ref,
  ] = useStateRef([]);
  var [
    Var_prescriptionDetails,
    setVar_prescriptionDetails,
    Var_prescriptionDetails_Ref,
  ] = useStateRef([]);
  var [Var_TotalCuont, setVar_TotalCuont] = useState("");
  var [Var_NoData, setVar_NoData] = useStateRef("none");
  var [Var_HaveData, setVar_HaveData] = useStateRef("block");
  var [Var_FileName, setVar_FileName, Var_FileName_Ref] = useStateRef("");
  var [Var_FilePath, setVar_FilePath, Var_FilePath_Ref] = useStateRef("");
  var [Var_FileDate, setVar_FileDate] = useStateRef("");
  var [Var_Id, SetVar_Id, Var_Id_Ref] = useStateRef("");
  var [Var_Pharamacy_Id, SetVar_Pharamacy_Id, Var_Pharamacy_Id_Ref] =
    useStateRef("");
  const [
    Var_AdvertisementMsg,
    setVar_AdvertisementMsg,
    Var_AdvertisementMsg_Ref,
  ] = useStateRef("");
  const [
    Var_AdvertisementMsgHead,
    setVar_AdvertisementMsgHead,
    Var_AdvertisementMsgHead_Ref,
  ] = useStateRef("");
  const [Var_PopupModelIcon, setVar_PopupModelIcon, Var_PopupModelIcon_Ref] =
    useStateRef("");
  const [Var_ClassName, setVar_ClassName, Var_ClassName_Ref] = useStateRef("");
  const [isOpenPrescriptionModel, setisOpenPrescriptionModel] = useState(false);
  const [medicineCounts, setMedicineCounts] = useState([0, 0, 0, 0]); // initial counts for each column
  const totalMedicineCount = medicineCounts.reduce(
    (total, count) => total + count,
    0
  );
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [prescriptions, setPrescriptions] = useState([]);
  const [addmedicine, setaddmedicine] = useState([]);

  const [isOpenSuccessModel, setisOpenSuccessModel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDisable, setisisDisable] = useState(false);
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);
  const [Var_SuccessModelText, setVar_SuccessModelText] = useState("");
  const [medicineOptions, setmedicineOptions, medicineOptions_ref] =
    useStateRef([]);
  const [ishideprescrition, setishideprescrition, ishideprescrition_Ref] =
    useStateRef(true);

  // initial function call start
  useEffect(() => {
    setVar_DateApi(new Date().toLocaleDateString("fr-CA"));
    getAllAppointment();
    prescriptionImage();
    getPharmacymedicineDetials();
    setisisDisable(false);
    setishideprescrition(true);
    if (frompage == "toaddprescription") {
      setisOpenPrescriptionModel(!isOpenPrescriptionModel);
    }
    if (frompage == "toviewprescription") {
      setishideprescrition(false);
    }
  }, []);
  // initial function call End

  // navigattion to back start
  const navigateTo = (path) => {
    Var_History.push("/dashboard" + path);
  };
  // navigattion to back end
  const navigateToReportPage = () => {
    if (frompage == "toaddprescription" || frompage == "toviewprescription") {
      navigateTo(
        "/appointments/report/" + patientId + "/" + bookingId + "/" + date
      );
    } else {
      navigateTo("/appointments");
    }
  };

  // model controls start

  // =============== Get All Appointment Start ==========================================
  const getAllAppointment = () => {
    // var date = dateRefapi.current

    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("clinic_id"),
      doctor_id: localStorage.getItem("doctor_id"),
      patient_type: "",
      period: "day",
      search_date: date, //"2023-08-14",
      search_date_to: date, //"2023-08-14",
    };
    axios
      .post("doctor/getAppointmentList", data)
      .then((response) => {
        console.log("Appoinment", response.data.data[0].result);
        var filtereddata = response.data.data[0].result.filter(
          (x) => x.patient_id == patientId && x.booking_id == bookingId
        );
        setVar_AppointmentDetail(filtereddata);
        setVar_TotalCuont(response.data.data[0].result.length);
        console.log("appointment Result", filtereddata);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  // =============== Get All Appointment End ==========================================

  const getPharmacymedicineDetials = () => {
    if (localStorage.getItem("pharmacy_id") != "") {
      const payloadData = {
        pharmacy_id: localStorage.getItem("pharmacy_id"),
        pageNumber: 0,
        pageSize: 0,
      };
      axios
        .post("pharmacy/get_mas_pharm_medicine_rate", payloadData)
        .then((response) => {
          const medicineOptionslist = response.data.data.map(
            (item) => item.medicine_name
          );
          setmedicineOptions(medicineOptionslist);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // ====================get prescription details start==============

  const handleRemovePrescription = (idxToRemove) => {
    setPrescriptions((prevPrescriptions) =>
      prevPrescriptions.filter((_, idx) => idx !== idxToRemove)
    );
  };

  const Addprescrition = () => {
    setPrescriptions([]);
    setSelectedMedicine(null);
    setPrescriptions(addmedicine);
    setisOpenPrescriptionModel(!isOpenPrescriptionModel);
  };
  const handleIncrement = (prescriptionIndex, countIndex) => {
    setPrescriptions((prevPrescriptions) =>
      prevPrescriptions.map((prescription, idx) => {
        if (idx === prescriptionIndex) {
          const updatedCounts = [...prescription.counts];
          updatedCounts[countIndex] += 1;
          return { ...prescription, counts: updatedCounts };
        }
        return prescription;
      })
    );
  };

  const handleDecrement = (prescriptionIndex, countIndex) => {
    setPrescriptions((prevPrescriptions) =>
      prevPrescriptions.map((prescription, idx) => {
        if (idx === prescriptionIndex) {
          const updatedCounts = [...prescription.counts];
          updatedCounts[countIndex] = Math.max(
            0,
            updatedCounts[countIndex] - 1
          ); // Prevent negative counts
          return { ...prescription, counts: updatedCounts };
        }
        return prescription;
      })
    );
  };

  const handleAddPrescription = () => {
    console.log("presc checking", prescriptions);
    if (!selectedMedicine) return; // Only add if a medicine is selected
    const newPrescription = {
      name: selectedMedicine,
      counts: [...medicineCounts],
    };
    setPrescriptions([...prescriptions, newPrescription]); // Add new prescription
    setSelectedMedicine(""); // Reset selected medicine
    setMedicineCounts([0, 0, 0, 0]); // Reset counts
  };

  const prescriptionImage = () => {
    setLoading(true);
    var data = {
      doctorId: localStorage.getItem("doctor_id"),
      patientId: patientId,
      booking_id: bookingId,
    };
    axios
      .post("doctor/getPrescriptionImage", data)
      .then((response) => {
        debugger;
        if (response.data.data == 0) {
          console.log("data", response.data.data);
          setVar_NoData("block");
          setVar_HaveData("none");
          setVar_FileName(response.data.data.prescript_file_name);
          setVar_FilePath(response.data.data.prescript_file_path);
          setVar_FileDate(response.data.data.prescript_date);
          setLoading(false);
        } else {
          setVar_NoData("none");
          setVar_HaveData("block");
          setVar_prescriptionDetails(response.data.data);
          SetVar_Id(response.data.data[0].id);
          SetVar_Pharamacy_Id(response.data.data[0].pharamacy_id);
          setLoading(false);
          if (
            response.data.data[0].p_medicinedetails != null &&
            response.data.data[0].p_medicinedetails != undefined
          ) {
            setaddmedicine(JSON.parse(response.data.data[0].p_medicinedetails));
            setPrescriptions(
              JSON.parse(response.data.data[0].p_medicinedetails)
            );
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // ====================get prescription details end==============

  const sendpharmacy = async () => {
    if (addmedicine.length != 0) {
      const formData = new FormData();
      formData.append("doctor_id", localStorage.getItem("doctor_id"));
      formData.append("patient_id", patientId);
      formData.append("medicines", "");
      formData.append("file_name", "");
      formData.append("file_path", "");
      formData.append("is_send_to_pharam", 1);
      formData.append("booking_id", bookingId);
      formData.append("pharm_id", localStorage.getItem("pharmacy_id"));
      // Var_Pharamacy_Id_Ref.current
      formData.append("is_walkin", "1");
      formData.append("medicinedetails", JSON.stringify(addmedicine));
      formData.append("appointmentDate", date);

      axios
        .post("doctor/insertPrescription", formData)
        .then((response) => {
          setLoading(false);
          if (response.data.status == 1) {
            setVar_ClassName("title");
            // setVar_PopupModelIcon(successModelTickImg);
            // setVar_AdvertisementMsgHead("success!");
            setVar_SuccessModelText("Prescription sent to pharmacy.");
            setVar_IsShowSuccess(true);
            setTimeout(() => {
              setVar_IsShowSuccess(false);
              // navigateTo("/appointments");
              navigateToReportPage();
            }, 1500);
          } else {
            setVar_ClassName("failedtxt");
            setVar_SuccessModelText(response.data.msg + ".");
            setVar_IsShowSuccess(true);
            setTimeout(() => {
              setVar_IsShowSuccess(false);
            }, 1500);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const sendRoport = () => {
    setLoading(true);
    setisisDisable(true);
    var data = {
      is_send_to_pharam: 1,
      is_prescrp_sts: "P",
      pharm_id: Var_Pharamacy_Id_Ref.current,
      id: Var_Id_Ref.current,
      medicinedetails: addmedicine,
    };
    axios
      .post("doctor/updatePrescription", data)
      .then((response) => {
        setisisDisable(false);
        setLoading(false);
        if (response.data.status == 1) {
          setVar_ClassName("title");
          setVar_PopupModelIcon(successModelTickImg);
          setVar_AdvertisementMsgHead("success!");
          setVar_AdvertisementMsg("Prescription sent to pharmacy!");
          setisOpenSuccessModel(!isOpenSuccessModel);
        } else {
          setVar_ClassName("failedtxt");
          setVar_PopupModelIcon(faildIcon);
          setVar_AdvertisementMsgHead("failed!");
          setVar_AdvertisementMsg(response.data.msg + "!");
          setisOpenSuccessModel(!isOpenSuccessModel);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setisisDisable(false);
      });
  };

  const okCloseprescription = () => {
    debugger;
    const zeroCountPrescriptions = prescriptions
      .filter((prescription) =>
        prescription.counts.every((count) => count === 0)
      )
      .map((prescription) => prescription.name);

    if (zeroCountPrescriptions.length > 0) {
      setVar_SuccessModelText(
        `Please add medicine count for : ${zeroCountPrescriptions.join(", ")}`
      );
      setVar_IsShowSuccess(true);
      setTimeout(() => {
        setVar_IsShowSuccess(false);
      }, 1500);
    } else {
      setaddmedicine(prescriptions);
      setisOpenPrescriptionModel(!isOpenPrescriptionModel);
    }
  };
  const okcloseemptymodal = () => {
    setisOpenPrescriptionModel(!isOpenPrescriptionModel);
  };
  const okClose = () => {
    setisOpenSuccessModel(!isOpenSuccessModel);
    navigateTo("/appointments");
  };

  // model controls end

  // Time conversion start

  const convertTo12HrFormat = (time24) => {
    var timeArr = time24.split(":");
    var hours = parseInt(timeArr[0]);
    var minutes = parseInt(timeArr[1]);
    var suffix = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    var time12 =
      hours + ":" + (minutes < 10 ? "0" + minutes : minutes) + " " + suffix;

    return time12;
  };
  const timeFormat = (time) => {
    let datetime = new Date("1970-01-01T" + time + "Z");
    return datetime.toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
  };

  // Time conversion End
  return (
    <div>
      {loading && (
        <div className="loader_body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      )}

      <div>
        <div>
          {/* <div className="sub_header">
                        <div className="sub_header_body">
                            <div className="back_navigation" onClick={() => navigateTo("/appointments")}>
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                                <span>PRESCRIPTION</span>
                            </div>
                            <div className="flex_grow"></div>
                        </div>
                    </div> */}

          <div>
            <HeaderMasterComponent />
          </div>
          <div>
            <div className="Navication-header">
              <div>
                <img src={BackIcon} onClick={() => navigateToReportPage()} />
                <label onClick={() => navigateToReportPage()}>
                  Prescriptions
                </label>
              </div>
            </div>
            <div className="workinghours_content">
              <div>
                {Var_AppointmentDetail_ref.current.map((item) => (
                  <div
                    className="profile_details_prescription_new"
                    style={{ margin: "0px 15px" }}
                  >
                    <div className="profile_detail_container">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={
                            item.file_name != "" &&
                            item.file_name != " " &&
                            item.file_name != null
                              ? item.file_name
                              : item.gender.toLowerCase() == "male"
                              ? MaleIcon
                              : FemaleIcon
                          }
                          className="profile_Details"
                          style={{ border: "1px solid var(--app-theme)" }}
                        />
                      </div>
                      <div>
                        <div className="detailsprofile">
                          <p className="name_details_new">Name</p>
                          <p className="profile_name">{item.name}</p>
                          {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                        </div>
                        <div className="detailsprofile">
                          <p className="name_details_new">Date</p>
                          <p className="profile_name">
                            {moment(item.appointment_date).format("DD-MMM-YY")}
                          </p>
                          {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                        </div>
                      </div>
                      <div>
                        <div className="detailsprofile">
                          <p className="name_details_new">Patient ID</p>
                          <p className="profile_name">{item.patient_id}</p>
                          {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                        </div>
                        <div className="detailsprofile">
                          <p className="name_details_new">Time</p>
                          <p className="profile_name">
                            {item.appointment_time == null
                              ? 0
                              : convertTo12HrFormat(item.appointment_time)}
                          </p>
                          {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <Grid container className="prescription_details_start">
                Prescriptions
                <Grid>
                  <Grid>
                    {ishideprescrition_Ref.current && (
                      <div
                        className="save_button_details add_comment_details"
                        onClick={() => Addprescrition()}
                      >
                        Add Prescription +
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {addmedicine != undefined && (
                <Grid container>
                  {addmedicine.map((prescription, idx) => (
                    <Grid
                      item
                      xs={4}
                      lg={4}
                      className="Total_prescription_details"
                      key={idx}
                    >
                      <Grid container className="total_medicine_details">
                        <Grid
                          item
                          className="medicine_name_details"
                          lg={8}
                          xs={8}
                        >
                          Medicine Name :{" "}
                          <span style={{ color: "black" }}>
                            {prescription.name}
                          </span>
                        </Grid>
                        <Grid
                          item
                          className="Total_number_details"
                          lg={4}
                          xs={4}
                        >
                          Total :{" "}
                          <span style={{ color: "black" }}>
                            {prescription.counts.reduce(
                              (sum, count) => sum + count,
                              0
                            )}
                          </span>
                        </Grid>
                      </Grid>

                      <Grid container style={{ marginLeft: "6px" }}>
                        <Grid
                          item
                          className="prescription_contain_details"
                          lg={2}
                          xs={2}
                        >
                          Morning
                        </Grid>
                        <Grid
                          item
                          className="prescription_contain_details"
                          lg={0.5}
                          xs={0.5}
                        >
                          <div className="stepper-line_comment precription_stepper_line"></div>
                        </Grid>

                        <Grid
                          item
                          className="prescription_contain_details"
                          lg={2.5}
                          xs={2.5}
                        >
                          Afternoon
                        </Grid>
                        <Grid
                          item
                          className="prescription_contain_details"
                          lg={0.5}
                          xs={0.5}
                        >
                          <div className="stepper-line_comment precription_stepper_line"></div>
                        </Grid>

                        <Grid
                          item
                          className="prescription_contain_details"
                          lg={2}
                          xs={2}
                        >
                          Evening
                        </Grid>
                        <Grid
                          item
                          className="prescription_contain_details"
                          lg={0.5}
                          xs={0.5}
                        >
                          <div className="stepper-line_comment precription_stepper_line"></div>
                        </Grid>

                        <Grid
                          item
                          className="prescription_contain_details"
                          lg={2}
                          xs={2}
                        >
                          Night
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid
                          item
                          className="prescription_contain_details"
                          lg={2.5}
                          xs={2.5}
                        >
                          {prescription.counts[0]}
                        </Grid>
                        <Grid
                          item
                          className="prescription_contain_details"
                          lg={3}
                          xs={3}
                        >
                          {prescription.counts[1]}
                        </Grid>
                        <Grid
                          item
                          className="prescription_contain_details"
                          lg={2.5}
                          xs={2.5}
                        >
                          {prescription.counts[2]}
                        </Grid>
                        <Grid
                          item
                          className="prescription_contain_details"
                          lg={2}
                          xs={2}
                        >
                          {prescription.counts[3]}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}

              <Grid container className="save_details_new_prescription">
                <Grid ClassName="save_profile_details">
                  {ishideprescrition_Ref.current && (
                    <div
                      className="save_button_details add_comment_details"
                      onClick={() => sendpharmacy()}
                    >
                      Send Pharmacy
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
          {/* <div className="layout_footer footer">
                        <FooterMaster />
                    </div> */}

          {/* ================= Prescription Success Model Start ================= */}
          {/* <Dialog
                        fullWidth={fullWidth}
                        open={isOpenSuccessModel}
                        onClose={setisOpenSuccessModel}
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "300px",
                                    borderRadius: "20px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <div className="success_model_body">
                                <div className="icon">
                                    <img src={Var_PopupModelIcon_Ref.current} />
                                </div>
                                <div className={Var_ClassName_Ref.current}>{Var_AdvertisementMsgHead_Ref.current}</div>
                                <div className="content">{Var_AdvertisementMsg_Ref.current}</div>
                                <div className="segment_center">
                                    <button className="botton_Cart_Create" onClick={() => okClose()}>OK</button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog> */}

          <Dialog
            fullWidth={fullWidth}
            open={isOpenPrescriptionModel}
            onClose={setisOpenPrescriptionModel}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "94vh",
                  borderRadius: "20px",
                  position: "relative",
                  overflow: "visible",
                },
              },
            }}
          >
            <DialogContent>
              <div style={{ height: "375px" }}>
                {/* Static, non-scrollable header */}
                <div className="label_static_alignment">
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="comment_image"
                      src={commenttype}
                      style={{
                        position: "absolute",
                        top: "-70px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        zIndex: 10,
                      }}
                    />
                  </div>
                  <div
                    className="close_mark_contain_pre prescription_close_mark_details"
                    onClick={() => okcloseemptymodal()}
                  >
                    <img className="close_icon_contain" src={closeicon} />
                  </div>
                  <Grid container style={{ marginTop: "10vh" }}>
                    <Grid xs={10} lg={10} className="plus_icon_prescription">
                      <Autocomplete
                        options={medicineOptions}
                        value={selectedMedicine}
                        onChange={(event, newValue) =>
                          setSelectedMedicine(newValue)
                        }
                        onInputChange={(event, newInputValue) =>
                          setSelectedMedicine(newInputValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            style={{ width: "73vh" }}
                            {...params}
                            id="outlined-basic"
                            placeholder="Select the medicine"
                            size="small"
                            sx={{ minWidth: "-webkit-fill-available" }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end">
                                  {selectedMedicine ? (
                                    <IconButton
                                      onClick={() => setSelectedMedicine("")}
                                      size="small"
                                      edge="end"
                                    >
                                      <ClearIcon
                                        style={{ cursor: "pointer" }}
                                      />
                                    </IconButton>
                                  ) : (
                                    <KeyboardArrowDownIcon
                                      style={{ cursor: "pointer" }}
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        freeSolo
                      />
                    </Grid>
                    <Grid xs={2} lg={2} className="plus_icon_prescription">
                      <div
                        className="prescription_add_plus"
                        // onClick={handleAddPrescription}
                        onClick={() => {
                          if (selectedMedicine.length > 0) {
                            handleAddPrescription();
                          }
                        }}
                      >
                        <img
                          src={Plus_icon_new}
                          className="circle prescription_plus"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>

                {/* Scrollable content */}
                <div className="scrollable_container">
                  {prescriptions.length > 0 && (
                    <Grid container className="morning_medicine_details">
                      <Grid xs={4} lg={4}></Grid>
                      <Grid xs={2} lg={2} className="particular_days_details">
                        Morning
                      </Grid>
                      <Grid xs={2} lg={2} className="particular_days_details">
                        Afternoon
                      </Grid>
                      <Grid xs={2} lg={2} className="particular_days_details">
                        Evening
                      </Grid>
                      <Grid
                        xs={2}
                        lg={2}
                        className="particular_days_details"
                        style={{ marginLeft: "-20px" }}
                      >
                        Night
                      </Grid>
                    </Grid>
                  )}

                  {prescriptions.map((prescription, idx) => (
                    <div className="prescription_list_details" key={idx}>
                      <div className="morning_medicine_count_detail">
                        <Grid container className="medicine_prescription_data">
                          <Grid item xs={4} lg={4}>
                            <div className="medicine_details_new">
                              <div className="prescription_namee_details">
                                {prescription.name}
                              </div>

                              <div
                                className="remove_medicine_details"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleRemovePrescription(idx)}
                              >
                                X
                              </div>
                            </div>
                          </Grid>
                          {prescription.counts.map((count, index) => (
                            <Grid
                              item
                              xs={2}
                              lg={2}
                              className="particular_days_details_medicine_count"
                              key={index}
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDecrement(idx, index)}
                              >
                                - &nbsp;
                              </span>
                              {count}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => handleIncrement(idx, index)}
                              >
                                &nbsp;+
                              </span>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                      <div className="total_medicine_count">
                        <div>
                          {prescription.counts.reduce(
                            (sum, count) => sum + count,
                            0
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="success_model_body">
                    <div className="segment_center AddButt">
                      <button
                        className="botton_Cart_Create"
                        disabled={isDisable}
                        // disabled={prescriptions.some(prescription => prescription.counts.every(count => count === 0))}
                        onClick={() => okCloseprescription()}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <br />
            </DialogContent>
          </Dialog>

          {/* ================= Prescription Success Model End ================= */}
          {Var_IsShowSuccess && (
            <div className="popup-main">
              <div className="pushNotification_body">
                <div className="pushNotification_content">
                  <p className="notification_text_head">
                    <b>{Var_SuccessModelText}</b>
                  </p>
                </div>
                <div className="progress-prt">
                  <div className="progress-bar"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default PrescriptionComponent;
