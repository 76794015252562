// AUTHOR : Dinesh
// CR-DATE: 14-July-2023
// MOD-DATE: 07-August-2023
// DESCRIPTION: Availability Slots Update work

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import dateformat from 'dateformat';
import Axios from "axios";
import { notification, Spin } from "antd";
import * as moment from 'moment';


// Mui meterial component import
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


import "./AvailabilitySlots.css";
import FooterMaster from '../Dashboard/FooterMaster';
import Service from '../../helpers/Service'


import successModelTickImg from "../../assets/Doctor_web_Icon/success.svg";
import alertModelTickImg from "../../assets/Images/alert.svg";
import isBlockedImg from "../../assets/Doctor_web_Icon/Group 20851.svg";
import isBookedImg from "../../assets/Doctor_web_Icon/Group 20850.svg";
import isCancelImg from "../../assets/Doctor_web_Icon/Group 20849.svg"

function AvailabilitySlotsComponent() {
    let Var_History = useHistory();

    var date = new Date();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [Var_StartDate, setVar_StartDate, Var_StartDate_Ref] = useStateRef("");
    const [Var_EndDate, setVar_EndDate, Var_EndDate_Ref] = useStateRef("");
    const [Var_DisplayText, setVar_DisplayText, Var_DisplayText_Ref] = useStateRef(false);
    const [Var_CancelText, setVar_CancelText, Var_CancelText_Ref] = useStateRef(false);
    const [Var_SlotNumber, setVar_SlotNumber, Var_SlotNumber_Ref] = useStateRef(0);
    const [Var_SlotNumbers, setVar_SlotNumbers, Var_SlotNumbers_Ref] = useStateRef([]);
    const [Var_SlotDetails, setVar_SlotDetails, Var_SlotDetails_Ref] = useStateRef([]);
    const [Var_DuplicateSlots, setVar_DuplicateSlots, Var_DuplicateSlots_Ref] = useStateRef([]);
    const [Var_BlockDetails, setVar_BlockDetails, Var_BlockDetails_Ref] = useStateRef([]);
    const [Var_CancelDetails, setVar_CancelDetails, Var_CancelDetails_Ref] = useStateRef([]);
    const [Var_alertPopuptext, setVar_alertPopuptext, Var_alertPopuptext_Ref] = useStateRef("Do you want to block this slots?");
    const [Var_StoreIndexes, setVar_StoreIndexes, Var_StoreIndexes_Ref] = useStateRef([{ clickedIndex: null }]);
    const [Var_AllChecked, setVar_AllChecked, Var_AllChecked_Ref] = useStateRef(false);
    const [Var_IsShowConfirmAlert, setVar_IsShowConfirmAlert] = React.useState(false);
    const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);
    const [Var_ChummaState, setVar_ChummaState, Var_ChummaState_Ref] = useStateRef(0);
    const [loading, setLoading] = useState(true);
    const [loadingcheck, setLoadingcheck] = useState("");
    const [Var_PopupImg, setVar_PopupImg, setVar_PopupImg_Ref] = useStateRef("");
    const [Var_Popup_content, setVar_Popup_content, Var_Popup_content_Ref] = useStateRef("");
    const [Var_SlotName, setVar_SlotName, Var_SlotName_Ref] = useStateRef("");
    const [Var_ShowBlock_Slot, setVar_ShowBlock_Slot, Var_ShowBlock_Slot_Ref] = useStateRef("");
    const [Var_SlotDoYou, setVar_SlotDoYou, Var_SlotDoYou_Ref] = useStateRef([]);

    // initial function call start
    useEffect(() => {
        validation();
    }, []);
    // initial function call end


    // navigation to back start
    const handleOnClick = (path) => {
        Var_History.push("/dashboard" + path)
    };
    // navigation to back end

    // navigateToAvailablityCalender start
    const navigateToAvailablityCalender = () => {
        Var_History.push("/dashboard/availability");
    }
    // navigateToAvailablityCalender end

    //availability slots page redirect method start
    const validation = () => {
        if (Service.start_date && Service.end_date) {
            setVar_StartDate(Service.start_date);
            setVar_EndDate(Service.end_date);
            getSlots();
        } else {
            Var_History.push("/dashboard/availability")
        }
    }
    //availability slots page redirect method end


    // availability slots model controls start
    const isCloseAvailabilitySuccessModel = () => {
        setVar_IsShowSuccess(!Var_IsShowSuccess);
        Var_History.push("/dashboard/availability");
    }
    const yesConfirmSlots = () => {
        setVar_alertPopuptext((Var_SlotName_Ref.current == "block booking") && (SlotDoYou.length != 1) ? "Do you want to block these slots?" :
        (Var_SlotName_Ref.current == "block booking") && (SlotDoYou.length == 1) ? "Do you want to block this slot?" :
          (Var_SlotName_Ref.current == "CancelSlot") && (SlotDoYou.length == 1) ? "Do you want to cancel this slot?" :
          (Var_SlotName_Ref.current == "CancelSlot") && (SlotDoYou.length != 1) ? "Do you want to cancel these slots?" :
            (Var_SlotName_Ref.current == "cancel booking") && (SlotDoYou.length != 1) ? "Do you want to unblock this slots?" :
              (Var_SlotName_Ref.current == "cancel booking") && (SlotDoYou.length == 1) ? "Do you want to unblock this slot?" : "Please cancel or block slots.")
        setVar_IsShowConfirmAlert(!Var_IsShowConfirmAlert)
    }
    const noConfirmSlots = () => {
        setVar_IsShowConfirmAlert(!Var_IsShowConfirmAlert)
    }
    // availability slots model controls end

    // availability slots checked controls start
    const onSelectBox = (datas, index) => {
        debugger;
        datas.is_blocked = "1";
        datas.is_booked = "0";
        datas.Bookslotactivate = "0";
        setVar_AllChecked(false);
        setVar_SlotDetails((preRows) => [...preRows]);
        setVar_SlotNumbers((preRows) => [...preRows]);
        if (datas.is_blocked == "1") {
            let arrSelectBlock = Var_BlockDetails_Ref.current;
            arrSelectBlock.push({
                'from_time': datas.from_time,
                'to_time': datas.to_time,
                'block_date': datas.date,
                'bookstatus': datas.Bookslotactivate
            })
            setVar_BlockDetails(arrSelectBlock)
            if (arrSelectBlock.length == 1) {
                setVar_alertPopuptext("Do you want to block this slots?");
            } else if (arrSelectBlock.length > 1) {
                setVar_alertPopuptext("Do you want to block these slots?");
            }
        } else {
            let arrSelectCancel = Var_CancelDetails_Ref.current;
            arrSelectCancel.push({
                'from_time': datas.from_time,
                'to_time': datas.to_time,
                'block_date': datas.date,
                'bookstatus': datas.Bookslotactivate
            });
            setVar_CancelDetails(arrSelectCancel)
            if (arrSelectCancel.length == 1) {
                setVar_alertPopuptext("Do you want to unblock this slots?");
            } else if (arrSelectCancel.length > 1) {
                setVar_alertPopuptext("Do you want to unblock these slots?");
            }
        }
        IsAllcheked(index);
        console.log(Var_BlockDetails, "Var_BlockDetails_Ref AllSelect");
        console.log(Var_CancelDetails, "Var_CancelDetails_Ref AllSelect");
    }
    const onBookedChange = (datas, index) => {
        debugger;

        // setVar_alertPopuptext("Do you want to cancel this appointment?");
        datas.is_blocked = "1";
        //  datas.is_booked = "1";
        datas.Bookslotactivate = "1";
        setVar_AllChecked(false);
        setVar_SlotDetails((preRows) => [...preRows]);
        setVar_SlotNumbers((preRows) => [...preRows]);

        if (datas.is_blocked == "1") {
            let arrBookedBlock = Var_BlockDetails_Ref.current;
            arrBookedBlock.push({
                'from_time': datas.from_time,
                'to_time': datas.to_time,
                'block_date': datas.date,
                'bookstatus': datas.Bookslotactivate
            });
            setVar_BlockDetails(arrBookedBlock)
            if (arrBookedBlock.length == 1) {
                setVar_alertPopuptext("Do you want to cancel this slots?");
            } else if (arrBookedBlock.length > 1) {
                setVar_alertPopuptext("Do you want to cancel these slots?");
            }
        } else {
            let arrBookedCancel = Var_CancelDetails_Ref.current;
            arrBookedCancel.push({
                'from_time': datas.from_time,
                'to_time': datas.to_time,
                'block_date': datas.date,
                'bookstatus': datas.Bookslotactivate
            });
            setVar_CancelDetails(arrBookedCancel)
            if (arrBookedCancel.length == 1) {
                setVar_alertPopuptext("Do you want to cancel this slots?");
            } else if (arrBookedCancel.length > 1) {
                setVar_alertPopuptext("Do you want to cancel these slots?");
            }
        }
        IsAllcheked(index);

        console.log(Var_BlockDetails, "Var_BlockDetails_Ref onBooked");
        console.log(Var_CancelDetails, "Var_CancelDetails_Ref onBooked");

    }
    const onBlockedChange = (datas, index) => {
        debugger;
        setVar_alertPopuptext("Do you want to unblock these slots?");
        datas.is_blocked = "0";
        //  datas.is_booked = "0";
        datas.Bookslotactivate = "0";
        setVar_AllChecked(false);
        setVar_SlotDetails((preRows) => [...preRows]);
        setVar_SlotNumbers((preRows) => [...preRows]);
        if (datas.is_blocked == "1") {
            let arrBlocekedBlock = Var_BlockDetails_Ref.current;
            arrBlocekedBlock.push({
                'from_time': datas.from_time,
                'to_time': datas.to_time,
                'block_date': datas.date,
                'bookstatus': datas.Bookslotactivate
            });
            setVar_BlockDetails(arrBlocekedBlock)
            if (arrBlocekedBlock.length == 1) {
                setVar_alertPopuptext("Do you want to unblock this slots?");
            } else if (arrBlocekedBlock.length > 1) {
                setVar_alertPopuptext("Do you want to unblock these slots?");
            }
        } else {
            let arrBlocekedCancel = Var_CancelDetails_Ref.current;
            arrBlocekedCancel.push({
                'from_time': datas.from_time,
                'to_time': datas.to_time,
                'block_date': datas.date,
                'bookstatus': datas.Bookslotactivate
            });
            setVar_CancelDetails(arrBlocekedCancel)
            if (arrBlocekedCancel.length == 1) {
                setVar_alertPopuptext("Do you want to unblock this slots?");
            } else if (arrBlocekedCancel.length > 1) {
                setVar_alertPopuptext("Do you want to unblock these slots?");
            }
        }
        IsAllcheked(index);
        console.log(Var_BlockDetails_Ref.current, "Var_BlockDetails_Ref Blocekd");
        console.log(Var_CancelDetails_Ref.current, "Var_CancelDetails_Ref Blocekd");

    }
    const onCanceledChange = (datas, index) => {
        debugger;
        datas.is_blocked = "0";
        //  datas.is_booked = "0";
        datas.Bookslotactivate = "0";
        setVar_AllChecked(false);
        setVar_SlotDetails((preRows) => [...preRows]);
        setVar_SlotNumbers((preRows) => [...preRows]);
        if (datas.is_blocked == "1") {
            let arrCanceledBlock = Var_BlockDetails_Ref.current;
            arrCanceledBlock.push({
                'from_time': datas.from_time,
                'to_time': datas.to_time,
                'block_date': datas.date,
                'bookstatus': datas.Bookslotactivate
            });
            setVar_BlockDetails(arrCanceledBlock)
            if (arrCanceledBlock.length == 1) {
                setVar_alertPopuptext("Do you want to unblock this slots?");
            } else if (arrCanceledBlock.length > 1) {
                setVar_alertPopuptext("Do you want to unblock these slots?");
            }
        } else {
            let arrCanceledCancel = Var_CancelDetails_Ref.current;
            arrCanceledCancel.push({
                'from_time': datas.from_time,
                'to_time': datas.to_time,
                'block_date': datas.date,
                'bookstatus': datas.Bookslotactivate
            });
            setVar_CancelDetails(arrCanceledCancel)
            if (arrCanceledCancel.length == 1) {
                setVar_alertPopuptext("Do you want to unblock this slots?");
            } else if (arrCanceledCancel.length > 1) {
                setVar_alertPopuptext("Do you want to unblock these slots?");
            }
        }
        IsAllcheked(index);
        console.log(Var_BlockDetails, "Var_BlockDetails_Ref Cancel");
        console.log(Var_CancelDetails, "Var_CancelDetails_Ref Cancel");
    }
    const IsAllcheked = (index) => {
        try {
            let isAllCheked = 1;
            for (let item of Var_SlotDetails_Ref.current[index].date_slots) {
                if ((item.is_blocked == 0 || item.is_blocked == '0') && (item.is_booked == 0 || item.is_booked == '0')) {
                    isAllCheked = 0
                    break;
                };
            }

            if (isAllCheked == 1) Var_SlotDetails_Ref.current[index].isCheckedAll = true;
            else Var_SlotDetails_Ref.current[index].isCheckedAll = false;
            setVar_SlotDetails((preRows) => [...preRows]);
        } catch (e) {
            console.log(e);
        }
    }
    // availability slots checked controls end


    // availability slots details controls start    
    const checkAllSlots = (data, slotindexes) => {

        if (Var_SlotDetails_Ref.current[slotindexes].isCheckedAll) {
            Var_SlotDetails_Ref.current[slotindexes].isCheckedAll = !Var_SlotDetails_Ref.current[slotindexes].isCheckedAll;
        }
        else {
            Var_SlotDetails_Ref.current[slotindexes].isCheckedAll = true
        }

        if (Var_SlotDetails_Ref.current[slotindexes].isCheckedAll) {
            data.forEach((item) => {
                if (item.is_blocked == 0 && item.is_booked == 0 && (item.Bookslotactivate == null || item.Bookslotactivate == "0")) {
                    item.is_blocked = 1;
                    item.is_booked = 0;
                    item.Bookslotactivate = "0"
                }
                else if (item.is_blocked == 0 && item.is_booked == 1 && (item.Bookslotactivate == null || item.Bookslotactivate == "0")) {
                    item.is_blocked = 1;
                    item.Bookslotactivate = "1"
                }
                else if (item.is_blocked == 1 && item.Bookslotactivate == "1") {
                    item.is_blocked = 0;
                    item.Bookslotactivate = "0"
                }
                else if (item.is_blocked == 1 && item.is_booked == 0 && (item.Bookslotactivate == null || item.Bookslotactivate == "0")) {
                    item.is_blocked = 0;
                    item.is_booked = 0;
                    item.Bookslotactivate = "0"
                }
            });
        }
        else {
            data.forEach((item) => {
                if (item.is_blocked == 0 && item.is_booked == 0 && (item.Bookslotactivate == null || item.Bookslotactivate == "0")) {
                    item.is_blocked = 1;
                    item.is_booked = 0;
                    item.Bookslotactivate = "0"
                }
                else if (item.is_blocked == 0 && item.is_booked == 1 && (item.Bookslotactivate == null || item.Bookslotactivate == "0")) {
                    item.is_blocked = 1;
                    item.Bookslotactivate = "1"
                }
                else if (item.is_blocked == 1 && item.Bookslotactivate == "1") {
                    item.is_blocked = 0;
                    item.Bookslotactivate = "0"
                }
                else if (item.is_blocked == 1 && item.is_booked == 0 && (item.Bookslotactivate == null || item.Bookslotactivate == "0")) {
                    item.is_blocked = 0;
                    item.is_booked = 0;
                    item.Bookslotactivate = "0"
                }
            });
        }

        setVar_BlockDetails([]);
        setVar_CancelDetails([]);
        for (let i = 0; i < Var_SlotDetails_Ref.current.length; i++) {
            Var_SlotDetails_Ref.current[i].date_slots.map((datas) => {
                if (datas.is_blocked == "1") {
                    let arrBlocekedBlock = Var_BlockDetails_Ref.current;
                    arrBlocekedBlock.push({
                        'from_time': datas.from_time,
                        'to_time': datas.to_time,
                        'block_date': datas.date,
                        'bookstatus': datas.Bookslotactivate
                    });
                    setVar_BlockDetails(arrBlocekedBlock)
                    if (arrBlocekedBlock.length == 1) {
                        setVar_alertPopuptext("Do you want to block this slots?");
                    } else if (arrBlocekedBlock.length > 1) {
                        setVar_alertPopuptext("Do you want to block these slots?");
                    }
                } else {
                    let arrBlocekedCancel = Var_CancelDetails_Ref.current;
                    arrBlocekedCancel.push({
                        'from_time': datas.from_time,
                        'to_time': datas.to_time,
                        'block_date': datas.date,
                        'bookstatus': datas.Bookslotactivate
                    });
                    setVar_CancelDetails(arrBlocekedCancel)
                    if (arrBlocekedCancel.length == 1) {
                        setVar_alertPopuptext("Do you want to unblock this slots?");
                    } else if (arrBlocekedCancel.length > 1) {
                        setVar_alertPopuptext("Do you want to unblock these slots?");
                    }
                }
                console.log(Var_BlockDetails_Ref.current, "Var_BlockDetails_Ref slotindexes");
                console.log(Var_CancelDetails_Ref.current, "Var_CancelDetails_Ref slotindexes");
            })
        }
        setVar_SlotDetails((preRows) => [...preRows]);
    }
    const hideShow = (index) => {
        if (Var_SlotNumbers.find((removeid) => { return removeid === index })) {
            Var_SlotNumbers.splice(Var_SlotNumbers.findIndex((removeid) => { return removeid === index }), 1);
        } else {
            Var_SlotNumbers.push(index);
        }
        setVar_ChummaState((prevState) => ({
            ...prevState, [Var_ChummaState + index]: prevState[Var_ChummaState + index] === index ? undefined : index,
        }));
        setVar_SlotNumber(index);
        setVar_DisplayText(!Var_DisplayText);
    };
    const formatTimeShow = (h_24) => {
        var h = Number(h_24.substring(0, 2)) % 12;
        if (h === 0) h = 12;
        return (h < 10 ? '0' : '') + h + ':' + h_24.substring(3, 5);
    }
    const cancelOrBlock = () => {
        if (Var_BlockDetails_Ref.current.length == 0 && Var_CancelDetails_Ref.current.length == 0) {
            notification.error({ message: "Cancel or Block Slots" });
        } else {
            uploadSlotsModels();
        }
    }
    // availability slots details controls end    

    // availability slots get method start
    const getSlots = () => {
        var BookedCount = [];
        var arry1 = [];
        var ShowSlot = [];
        var dupslotList = [];
        let count = 0;
        setLoading(true);
        var data = {
            "from_date": dateformat(Var_StartDate_Ref.current, "yyyy-mm-dd"),
            "to_date": dateformat(Var_EndDate_Ref.current, "yyyy-mm-dd"),
            "clinic_id": localStorage.getItem("clinic_id"),
            "doctor_id": localStorage.getItem("doctor_id"),
        }
        Axios({
            method: "POST",
            url: 'doctor/getDoctorBlockCancelSlots',
            data: data
        }).then((response) => {
            debugger
            // setLoadingcheck(new Date().toLocaleTimeString)

            response.data.data.forEach((item, index) => {
                arry1 = item.date_slots;
                let slots = arry1.map(v => ({ ...v, isActive: v.is_blocked == 0 ? false : true, isBooked: v.is_booked == 1 ? true : false, bookactive: (v.Bookslotactivate == 1 && v.is_blocked == 1) ? true : false }));
                BookedCount = arry1.filter(X => X.is_booked == 1 || X.is_blocked == 1 || X.Bookslotactivate != null);
                console.log("BookedCount", BookedCount.length);
                item["Booked_count"] = BookedCount.length;
                ShowSlot.push({
                    'dateChecked': false,
                    'clinic_id': item.clinic_id,
                    'block_date': item.date,
                    'date_slots': slots,
                    'doctor_id': item.doctor_id,
                    'Booked_count' :BookedCount.length,
                  });
                  setVar_SlotDetails(ShowSlot);
                  let slots2 = slots.map(v => JSON.stringify(v));
                  dupslotList.push({
                      'dateChecked': false,
                      'clinic_id': item.clinic_id,
                      'block_date': item.date,
                      'date_slots': slots2,
                      'doctor_id': item.doctor_id,
                      'Booked_count' :BookedCount.length,
                    });
                  setVar_DuplicateSlots(dupslotList);
                  console.log(slots, "slot");
                  Var_SlotDetails_Ref.current.forEach((i, j) => {
                      count = 0
                      i.date_slots.forEach(i => {
                        if (i.is_blocked == 1) {
                          count++
                        }
                      })
          
                      if (count == i.date_slots.length) {
                        setTimeout(() => {
                          Var_SlotDetails_Ref.current[j].dateChecked = true;
                          Var_DuplicateSlots_Ref.current[j].dateChecked = true;
                        }, 100);
          
                      }
                  })
            });

             console.log(Var_SlotDetails_Ref.current,"Var_SlotDetails_Ref");
             console.log(Var_DuplicateSlots_Ref.current,"Var_DuplicateSlots_Ref");


            //     var check=(response.data.data[0].date_slots.filter(x => x.from_time > (new Date().getHours() + ":" + new Date().getMinutes()))); 
            //     setVar_SlotDetails(check)
            //    console.log("fine",Var_SlotDetails_Ref.current)




            // let array1 = [];
            //     for(let i of  response.data.data[0]?.date_slots){
            //     const currentTime = moment();
            //     let slotdate =i.date
            //     let slottime = i.from_time
            //     let givenTime = moment(String(slotdate) + 'T' + String(slottime))
            //     if (givenTime.isAfter(currentTime) == true) {
            //         array1.push(i)

            //     //  console.log("praveeni",i)
            //     }
            //     console.log("praveeni",i)
            //     setVar_SlotDetails(i);
            // }
            // setVar_SlotDetails(array1);
            setLoading(false);
            response.data.data.forEach((item, index) => {
                IsAllcheked(index);
                setLoading(false);
            });
        }).catch((err) => {
            setLoading(false);
        })
    }
    // availability slots get method end    

    var allOption = [];
    var blockedDates = [];
    const checkAll = (index,dateChecked) => {
        debugger;
        console.log(index)
        console.log("Executed", allOption[index]);
        Var_SlotDetails_Ref.current[index].dateChecked = dateChecked;
        console.log(Var_SlotDetails_Ref.current[index]);
        setVar_ShowBlock_Slot(false);
        if (allOption[index] == true || Var_SlotDetails_Ref.current[index].dateChecked == true) {
          setVar_SlotName('block booking');
          Var_SlotDetails_Ref.current[index].date_slots.forEach(v => v.isActive = true);
          Var_SlotDetails_Ref.current[index].date_slots.forEach((v, inde2) => {
            // if (v.isBooked == true) {
            //   v.isBooked = false
            //   Var_SlotDetails_Ref.current[index].date_slots[inde2].Bookslotactivate = 1;
            // }
          });
    
          if (!blockedDates.includes(Var_SlotDetails_Ref.current[index].block_date)) {
            blockedDates.push(Var_SlotDetails_Ref.current[index].block_date)
          }
          setVar_SlotDetails((preRows) => [...preRows]);
        } else {
          setVar_SlotName(dateChecked == false ? "cancel booking": 'block booking');
          Var_SlotDetails_Ref.current[index].date_slots.forEach(v => v.isActive = false);
    
          let index1 = blockedDates.map(i => i).indexOf(Var_SlotDetails_Ref.current[index].block_date)
          blockedDates.splice(index1, 1)
          setVar_SlotDetails((preRows) => [...preRows]);
    
        //   Var_SlotDetails_Ref.current[index].date_slots.forEach((v, inde2) => {
        //     if (v.isBooked == false) {
        //       v.isBooked = true
        //       Var_SlotDetails_Ref.current[index].date_slots[inde2].Bookslotactivate = 0;
        //     }
        //   });
        }    
        console.log(blockedDates.sort(), "blockdates array")
      }
    



     var SlotDoYou = [];
    const dateChanged = (index, inde2) => {
        //=================today 7-26-2023 sakthi
        debugger;
        console.log(Var_SlotDetails_Ref.current[index].date_slots[inde2].isActive)
        if (Var_SlotDetails_Ref.current[index].date_slots[inde2].isActive == false) {
          if(Var_SlotDetails_Ref.current[index].date_slots[inde2].is_booked == 1){
            setVar_SlotName('CancelSlot');
          }else {
          setVar_SlotName('block booking');
          }
          Var_SlotDetails_Ref.current[index].date_slots[inde2].isActive = true;
        }
        else if (Var_SlotDetails_Ref.current[index].date_slots[inde2].isActive == true) {
          console.log('canecl booking');
          setVar_SlotName('cancel booking');
          Var_SlotDetails_Ref.current[index].date_slots[inde2].isActive = false;
        //   setTimeout(() => {
        //   }, 100);
        }
    
        if (Var_SlotDetails_Ref.current[index].date_slots[inde2].isBooked == false) {
          setTimeout(() => {
            //  Var_SlotDetails_Ref.current[index].date_slots[inde2].bookactive = 1;
        }, 100);
            Var_SlotDetails_Ref.current[index].date_slots[inde2].Bookslotactivate = 0;
            Var_SlotDetails_Ref.current[index].date_slots[inde2].isBooked = true;
        }
        else if (Var_SlotDetails_Ref.current[index].date_slots[inde2].isBooked == true) {
          setTimeout(() => {
              setVar_ShowBlock_Slot(false);
            }, 100);
            Var_SlotDetails_Ref.current[index].date_slots[inde2].isBooked = false;
            Var_SlotDetails_Ref.current[index].date_slots[inde2].Bookslotactivate = 1;
            Var_SlotDetails_Ref.current[index].date_slots[inde2].bookactive = false;
        }
    
        if (Var_SlotDetails_Ref.current[index].date_slots[inde2].isBooked === true) {
          if (Var_SlotDetails_Ref.current[index].date_slots[inde2].isActive === true || Var_SlotDetails_Ref.current[index].date_slots[inde2].isBooked === false) {
            console.log("testValue");
          } else if (Var_SlotDetails_Ref.current[index].date_slots[inde2].is_booked === 0) {
            debugger;
                  Var_SlotDetails_Ref.current[index].date_slots[inde2].isBooked = false;
                  Var_SlotDetails_Ref.current[index].date_slots[inde2].Bookslotactivate = 1;
                  Var_SlotDetails_Ref.current[index].date_slots[inde2].bookactive = false;
            // this.modalController.create({
            //   component: ConfirmPage,
            //   cssClass: 'confirmation-alert1',
            //   componentProps: {
            //     // messageContent: "There are bookings against the selected slots, Do you want to <b>block?</b>"
            //     // messageContent: this.SlotDoYou.length != 1 ? "Do you want to cancel this slots?" : "Do you want to cancel this slot?"
            //     messageContent: this.SlotDoYou.length != 1 ? this.addCall.title440 : this.addCall.title436
            //   }
            // }).then((modal) => {
            //   modal.present().then(() => {
            //     modal.onDidDismiss()
            //       .then((data) => {
            //         if (data.role === 'confirm') {
            //           this.SlotDoYou = [];
            //           setTimeout(() => {
            //             this.showblock_slot = false;
            //             Var_SlotDetails_Ref.current[index].date_slots[inde2].isBooked = false;
            //             Var_SlotDetails_Ref.current[index].date_slots[inde2].Bookslotactivate = 1;
            //             Var_SlotDetails_Ref.current[index].date_slots[inde2].bookactive = false;
            //           }, 100);
            //           this.insertBlockSlots();
            //         } else {
            //           setTimeout(() => {
            //             Var_SlotDetails_Ref.current[index].date_slots[inde2].isActive = false;
            //           }, 100);
            //         }
            //       });
            //   });
            // });
          }
        }
    
    
        setTimeout(() => {
          console.log(Var_SlotDetails_Ref.current[index].date_slots[inde2].isBooked)
        }, 150);
    
        //===================== add timeout for not update the status instantly
    
        setTimeout(() => {
            SlotDoYou.push((Var_SlotDetails_Ref.current, Var_SlotDetails_Ref.current[index].date_slots.filter(x => x.isActive === true).length));
          console.log((Var_SlotDetails_Ref.current, Var_SlotDetails_Ref.current[index].date_slots.filter(x => x.isActive === true).length), '=', (Var_SlotDetails_Ref.current[index].date_slots.length),SlotDoYou.length)
          if (Var_SlotDetails_Ref.current[index].date_slots.filter(x => x.isActive === true).length >= (Var_SlotDetails_Ref.current[index].date_slots.length)) {
            allOption[index] = true;
            Var_SlotDetails_Ref.current[index].dateChecked = true
            if (!blockedDates.includes(Var_SlotDetails_Ref.current[index].block_date)) {
              blockedDates.push(Var_SlotDetails_Ref.current[index].block_date)
            }
            setVar_SlotDetails((preRows) => [...preRows]);
          } else {
            allOption[index] = false;
            Var_SlotDetails_Ref.current[index].dateChecked = false
            let index1 = blockedDates.map(i => i).indexOf(Var_SlotDetails_Ref.current[index].block_date)
            blockedDates.splice(index1, 1)
            setVar_SlotDetails((preRows) => [...preRows]);
    
          }
        }, 100);
    
        if (Var_SlotDetails_Ref.current[index].date_slots[inde2].bookactive == true) {
          Var_SlotDetails_Ref.current[index].date_slots[inde2].Bookslotactivate = 0
        }    
      }



    // availability slots upload method start
    const uploadSlotsModels = () => {
        setVar_IsShowConfirmAlert(!Var_IsShowConfirmAlert);
        setLoading(true);
        console.log(Var_SlotDetails_Ref.current,"slotList");
        setVar_CancelDetails([]);
        setVar_BlockDetails([]);
        var BlockDetails = [];
        var CancelDetails = [];
        console.log(Var_DuplicateSlots_Ref.current, 'duplicateslotList');

        Var_SlotDetails_Ref.current.forEach((slots, i) => {
            let difference = slots.date_slots.filter(x => !Var_DuplicateSlots_Ref.current[i].date_slots.includes(JSON.stringify(x), console.log(x)));
            console.log(difference, "difference");
            for (let item of difference) {
              if (item.isActive == true) {
                BlockDetails.push({
                  'from_time': item.from_time,
                  'to_time': item.to_time,
                  'block_date': item.date,
                  'bookstatus': item.Bookslotactivate,
    
                });
              } else {
                CancelDetails.push({
                  'from_time': item.from_time,
                  'to_time': item.to_time,
                  'block_date': item.date
                });
              }
            }
    
          })

          

        var slotsData = {
            "doctor_id": localStorage.getItem("Doctor_id"),
            "blocked_details": BlockDetails.length > 0 ? BlockDetails : [],
            "cancel_details": CancelDetails.length > 0 ? CancelDetails : [],
            "created_by": localStorage.getItem("Doctor_id"),
            "ipaddress": "192.168.1.54",
            "blockDate_From": blockedDates.sort()[blockedDates.length - 1],
            "blockDate": blockedDates.sort()[0],
        }
        Axios({
            method: "POST",
            url: 'doctor/insertDoctorBlockSlots',
            data: slotsData
        }).then((response) => {
            debugger;
            // setVar_CancelText((response.data.msg == 'Appointment blocked successfully!') ? 'Slot blocked successfully!' : (response.data.msg == 'Appointment block successfully!')
            //     ? 'Slots blocked successfully!' : (response.data.msg == 'Appointment cancelled successfully!') ? 'Slot unblocked successfully!' : (response.data.msg == 'Appointment cancel successfully!')
            //         ? 'Slots unblocked successfully!' : (response.data.msg == 'Member has already arrived, so cannot block these slots') ? 'Member has already arrived, so cannot cancel these slots.' : (response.data.msg == 'Appointment cancelblocked successfully!' ? 'Appointment canceled successfully!' : 'Appointments canceled successfully!'));
            setVar_CancelText((response.data.msg == 'Appointment blocked successfully!') ? 'Slot blocked successfully!' : (response.data.msg == 'Appointment block successfully!')
                ? 'Slots blocked successfully!' : (response.data.msg == 'Appointment cancelled successfully!') ? 'Slot unblocked successfully!' : (response.data.msg == 'Appointment cancel successfully!')
                    ? 'Slots unblocked successfully!' : (response.data.msg == 'Appointment cancelblocked successfully!') ? 'Appointment canceled successfully!'
                        : (response.data.msg == 'Member has already arrived, so cannot block these slots') ? 'Member has already arrived, so cannot cancel these slots!' : 'Appointments canceled successfully!');
            setLoading(false);
            console.log("Var_SlotNumbers_Ref", response.data);
            console.log("Var_SlotNumbers_Ref", Var_SlotDetails_Ref.current);
            setVar_PopupImg(successModelTickImg);
            setVar_Popup_content("success!");
            if (response.data.status == 0) {
                setVar_PopupImg(alertModelTickImg);
                setVar_Popup_content("failed!");
            }
            setVar_IsShowSuccess(!Var_IsShowSuccess);
        })
    }
    // availability slots upload method end
    const currentDateInUTC = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()));

    console.log(currentDateInUTC.toUTCString());

    // console.log("jgfhgchgcgh",formattedDate);

    //     const utcTimestamp = "2023-09-14T12:00:00.000Z"; // Replace with the timestamp from your server
    // const options = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
    // const localTime = new Date(utcTimestamp).toLocaleTimeString('en-US', options);
    // console.log("Local Time (24-hr format without date):", localTime);



    // const utcTimestamp = "2023-09-14T12:00:00.000Z"; // Replace with the timestamp from your server
    // const offsetMinutes = new Date().getTimezoneOffset();
    // const localTime = new Date(new Date(utcTimestamp).getTime() - (offsetMinutes * 60000)).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    // console.log("praveen:", localTime);



    // const currentTime = new Date();
    // const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    // const formattedTime = currentTime.toLocaleTimeString('en-US', options);

    // console.log("Current time (24-hr format):", formattedTime);

    // const currentTimeUTC = new Date();
    // const options = { timeZone: 'UTC', hour12: false };
    // const formattedTimeUTC = currentTimeUTC.toLocaleTimeString('en-US', options);

    // console.log("check:", formattedTimeUTC);



    const currentTimeUTC = new Date().toUTCString();
    console.log("Current UTC time:", currentTimeUTC);

    var now = new Date();
    var hours = now.getHours().toString().padStart(2, '0');
    var minutes = now.getMinutes().toString().padStart(2, '0');
    var seconds = now.getSeconds().toString().padStart(2, '0');

    var formattedTimeString = hours + ':' + minutes + ':' + seconds;
    console.log('time =', formattedTimeString);




    return (
        <div>

            {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            }
            {/* ================= Availability Slots sub header start ================= */}
            <div className="sub_header">
                <div className="sub_header_body">
                    <div className="back_navigation" onClick={() => handleOnClick("/availability")} >
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                        <span>CANCEL / BLOCK SLOTS</span>
                    </div>
                    <div className="flex_grow"></div>
                    <div className="header_right_content"></div>
                </div>
            </div>
            {/* ================= Availability Slots sub header end ================= */}

            {/* ================= Availability Slots list View  start ================= */}
            <div className="content_view">
                {/* ======================== Slots details start======================================= */}
                <div>
                    {Var_SlotDetails.length > 0 && Var_SlotDetails?.map((data, index) => {
                        return (

                            <>
                                {data.date_slots.length > 0 && <Card className="slot_pane_content" key={index}>
                                    <div className="pane_row_two">
                                        <div className="inner_pane_content">
                                            <div>
                                                {data.Booked_count == 0 && <Checkbox checked={data.dateChecked ? true : false} className="slot_checkbox_main" onChange={(e) => checkAll(index,  e.target.checked)} />}
                                            </div>
                                            <p className="cancel_slots_date">{dateformat(data.block_date, "dd-mmm-yy")}</p>
                                        </div>
                                        <Button className={`${Var_DisplayText && Var_SlotNumber === index + 1 ? "btn-background_1" : "btn-background_2"}`} onClick={() => hideShow(index + 1)}>
                                            {Var_DisplayText && Var_SlotNumber === index + 1 ? "Hide Slots" : "Show Slots"}
                                        </Button>
                                    </div>
                                    {Var_SlotNumbers.length > 0 && Var_SlotNumbers?.map((slotsTime, slotindex) => {
                                        return (slotsTime === index + 1 &&
                                            <Grid container className="hidden_slots_pane slot-width" key={slotindex}>
                                                <Grid item xs={12} md={2} ></Grid>
                                                <Grid item xs={12} md={8} className="hidslot_check">
                                                    {data.date_slots && data.date_slots.length > 0 && data.date_slots?.map((datas, checkindex) => {
                                                        return (

                                                            <>
                                                                {moment().format('YYYY-MM-DD') ==datas.block_date &&
                                                                    <>
                                                                        {datas.from_time > formattedTimeString &&
                                                                            <>
                                                                                {
                                                                                   datas.isActive == false && datas.isBooked == false ?
                                                                                        (<Checkbox checked={false} onChange={() => dateChanged(index,checkindex)} />) :
                                                                                        <>
                                                                                            {
                                                                                                datas.isActive != true && datas.is_booked == 1 &&
                                                                                                <img src={isBookedImg} className="Txtbox_alin"  onClick={() => dateChanged(index,checkindex)} ></img>
                                                                                            }
                                                                                            {
                                                                                                datas.isActive == true &&  (datas.Bookslotactivate == "null" || datas.Bookslotactivate == null || datas.Bookslotactivate == 0) &&
                                                                                                <img src={isBlockedImg} className="Txtbox_alin"  onClick={() => dateChanged(index,checkindex)}></img>
                                                                                            }
                                                                                            {
                                                                                                datas.isActive == true && (datas.is_booked == 1 || datas.bookactive == true) &&
                                                                                                <img src={isCancelImg} className="Txtbox_alin"  onClick={() => dateChanged(index,checkindex)}></img>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                                <div className="slot_cancel"> {formatTimeShow(datas.from_time)} </div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                }



                                                                {moment().format('YYYY-MM-DD') !== datas.block_date &&
                                                                    <>

                                                                        {
                                                                           datas.isActive == false && datas.isBooked == false ?
                                                                                (<Checkbox checked={false} onChange={() => dateChanged(index,checkindex)} />) :
                                                                                <>
                                                                                    {
                                                                                        datas.isActive != true && datas.is_booked == 1 &&
                                                                                        <img src={isBookedImg} className="Txtbox_alin"  onClick={() => dateChanged(index,checkindex)} ></img>
                                                                                    }
                                                                                    {
                                                                                        datas.isActive == true &&  (datas.Bookslotactivate == "null" || datas.Bookslotactivate == null || datas.Bookslotactivate == 0) &&
                                                                                        <img src={isBlockedImg} className="Txtbox_alin"  onClick={() => dateChanged(index,checkindex)}></img>
                                                                                    }
                                                                                    {
                                                                                        datas.isActive == true && (datas.is_booked == 1 || datas.bookactive == true) &&
                                                                                        <img src={isCancelImg} className="Txtbox_alin"  onClick={() => dateChanged(index,checkindex)}></img>
                                                                                    }
                                                                                </>
                                                                        }
                                                                        <div className="slot_cancel"> {formatTimeShow(datas.from_time)} </div>

                                                                    </>
                                                                }
                                                            </>
                                                        )

                                                    })}

                                                </Grid>
                                                <Grid item xs={12} md={2}></Grid>
                                            </Grid>
                                        )
                                    })}
                                </Card>
                                }
                                {Var_SlotNumbers.length > 0 && Var_SlotNumbers?.map((n, slotindex) => {
                                    return (
                                        n === index + 1 &&

                                        <div className="row ">
                                            <Grid item xs={12} md={4} ></Grid>
                                            <Grid item xs={12} md={6} className="slots_name">
                                                <div> <img src={isCancelImg} className="Txtbox_alin"  />Cancel</div>
                                                <div> <img src={isBookedImg} className="Txtbox_alin"  />Booked Slot</div>
                                                <div className="thirdDIv"> <img src={isBlockedImg} className="Txtbox_alin"  />Blocked Slots</div>
                                            </Grid>
                                            <Grid item xs={12} md={4} ></Grid>

                                        </div>
                                    )
                                })}

                            </>
                        )
                    })}
                </div>
                {Var_SlotDetails_Ref.current.length > 0  && <div className="cancel_slots_button">
                    <Button className="cancel_slots_cancel_button" onClick={() => navigateToAvailablityCalender()}>Cancel</Button>
                    <Button className="cancel_slots_confirm_button" onClick={() => yesConfirmSlots()}>Confirm</Button>
                </div>}


                {/* ======================== Slots details end======================================= */}


                {/* ======================== Slots Confirm Model start======================================= */}
                <Dialog open={Var_IsShowConfirmAlert} onClose={setVar_IsShowConfirmAlert}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%", maxWidth: "300px", borderRadius: "20px"
                            },
                        },
                    }}>
                    <DialogContent>
                        <div className="success_model_body">
                            <div className="icon">
                                <img className="image_body_sizes" src={alertModelTickImg} />
                            </div>
                            <div className="failedtxt">alert!</div>
                            <div className="content">{Var_alertPopuptext_Ref.current}</div>
                            <div className="alert_btn_center">
                                <button className="alert_no_btn" onClick={() => noConfirmSlots()}>No</button>
                                <button className="alert_yes_btn" onClick={() => uploadSlotsModels()}>Yes</button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                {/* ======================== Slots Confirm Model end ======================================= */}


                {/* ======================== Add Slots Success Model======================================= */}
                <Dialog
                    fullWidth={fullWidth}
                    open={Var_IsShowSuccess}
                    onClose={setVar_IsShowSuccess}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "300px",
                                borderRadius: "20px",
                            },
                        },
                    }}
                >
                    <DialogContent>
                        <div className="success_model_body">
                            <div className="icon">
                                <img src={setVar_PopupImg_Ref.current} />
                            </div>
                            {Var_Popup_content_Ref.current != 'failed!' && <div className="title">{Var_Popup_content_Ref.current}</div>}
                            {Var_Popup_content_Ref.current == 'failed!' && <div className="failedtxt">{Var_Popup_content_Ref.current}</div>}
                            <div className="content">{Var_CancelText_Ref.current}</div>
                            {Var_Popup_content_Ref.current != 'failed!' && <div div className="segment_center">
                                <button className="botton_Cart_Create" onClick={() => isCloseAvailabilitySuccessModel()}>OK</button>
                            </div>}
                            {Var_Popup_content_Ref.current == 'failed!' && <div div className="segment_center">
                                <button className="botton_Cart_Create_Manage" onClick={() => isCloseAvailabilitySuccessModel()}>OK</button>
                            </div>}
                        </div>
                    </DialogContent>
                </Dialog>
                {/* ======================== Add Slots Success end======================================= */}
            </div>
            {/* ================= Availability Slots list View  end ================= */}

            {/* ================= Availability Slots footer start ================= */}
            <div className="footer">
                <FooterMaster />
            </div>
            {/* ================= Availability Slots footer end ================= */}
        </div >
    )
}
export default AvailabilitySlotsComponent;



