// AUTHOR : PRAVEEN S
// CR-DATE: 
// MOD-DATE: 05-08-2023
// DESCRIPTION: doctor select droupdown doctor select doctor id issue done
import React, { Component, useEffect, useState } from "react";
import useStateRef from "react-usestateref";
import { Card, Row, Col, Container, Dropdown, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
// dashboard css atsrt
import './Dashboard.css'
// dashboard css end
// mui css import start
import { MenuItem, DialogContent, Dialog } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
// mui css import end
// images start
import Logo from '../../assets/Doctor_Images/Easidoc.jpeg'
// import Notification from '../../assets/Doctor_web_Icon/Group 19086.svg'
import support from '../../assets/Doctor_web_Icon/male-telemarketer.svg'
import Notification from '../../assets/Doctor_Images/notification (1).png'
import emptyNotification from '../../assets/Doctor_Images/notification (1).png'
import videoIcon from '../../assets/Doctor_web_Icon/Group21005.svg'


// images end
// component imnporting start
import Service from '../../helpers/Service'
import DashboardMasterComponent from "../Dashboard/DashboardMaster";
import FooterMaster from '../Dashboard/FooterMaster'
// component imnporting end
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import Postimg from '../../assets/clinicIcon/homePageIcon/post.svg';
import Manageserviceimg from '../../assets/clinicIcon/homePageIcon/manageService.svg';
import Supportimg from '../../assets/Doctor_web_Icon/Group 21105.png';
import Workinghoursimg from '../../assets/clinicIcon/homePageIcon/workhours.svg';
import CollateralPlayIcon from '../../assets/Doctor_web_Icon/Group21005.svg';
import closeicon from '../../assets/icon/Icon ionic-ios-close.png';
import handIcon from '../../assets/Doctor_web_Icon/Path 38456.svg';
import homeIcon from '../../assets/Doctor_Images/Group 9.png';
import RejectIcon from "../../images/rejectIcon.svg"
import HeaderMasterComponent from "../Header/HeaderMaster";
function DashboardComponent() {
    // Navigation 
    let Var_History = useHistory();
    // Navigation 
    // Route Navigation start
    const routeNavigation = (path) => { Var_History.push("/dashboard" + path) };
    const routeNavigationByVerification = (path) => {
        Var_History.push({
            pathname: "/dashboard" + path, state: {  // location state
                verification: true,
            }
        })
    };
    // Route Navigation end
    // variable declaration start
    const [Var_Profiledata, setVar_Profiledata] = React.useState([]);
    const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor, Var_getClinicWiseDoctor_Ref] = useStateRef([]);
    const [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect, Var_doctorSpecialitySelect_Ref] = useStateRef(null);
    const [Var_PermissionObject, setVar_PermissionObject, Var_PermissionObject_Ref] = useStateRef([]);
    const [loading, setLoading] = useState(true);
    const [verificationPage, setVerificationPage] = useState(false);
    const [Var_NotificationData, setVar_NotificationData] = React.useState([]);
    const [Varnotificationcount, setVarnotificationcount, Varnotificationcount_Ref] = useStateRef([]);
    var [activeStep, setActiveStep] = useState(0);
    var [stepList, setStepList] = useState([
        { "label": "Reviewed & Approved", "dateTime": "23-Jun-23 10:00AM" },
        { "label": "Reviewed & Approved", "dateTime": "24-Jun-23 11:00AM" },
        { "label": "Reviewed & Approved", "dateTime": "25-Jun-23 12:00PM" },
        { "label": "You are ready", "dateTime": "to go live" }]);
    var [isOpenVideoPopup, setisOpenVideoPopup] = useState(false);
    var [videoPopupTitle, setVideoPopupTitle] = useState("");
    var [videoListbyMenu, setVideoListbyMenu] = useState([]);
    var [menuVideo, setMenuVideo] = useState([]);
    var [currentVideo, setCurrentVideo] = useState("");
    var [currentSubmenuid, setCurrentSubmenuid] = useState("");
    var [Var_DoctorId, setVar_DoctorId] = useState("");
    var [approved, setApproved] = useState("P");
    var [approvedLive, setApprovedLive] = useState("");
    var [approvedStatus, setApprovedStatus] = useState(0);
    var [declineStatus, setDeclinestatus] = useState("");
    var [updateStatus, setupdateStatus] = useState(false);
    var [payloadstatus, setpayloadstatus] = useState(0);

    // variable declaration end
    // Initial load method start
    useEffect(() => {
        // getClinicWiseDoctor();
        getDoctorProfile();
        getMenulist();
        localStorage.setItem("Collaterals_Page", false);
        // getNotifications();
        // clinicTabPermissionById();
    }, []);
    // Initial load method end
    // get doctor profile api call start
    function getDoctorProfile() {
        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("clinic_id")
        };
        axios
            .post("clinic/getClinicProfile", data)
            .then((response) => {
                setVar_Profiledata(response.data.data);
                getClinicWiseDoctor();
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    // get doctor profile api call end
    // get clinic wise doctor api call start
    function getClinicWiseDoctor() {
        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("clinic_id")
        };
        axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {
                debugger;
                if (response.data.data.length != 0) {
                    if (localStorage.getItem("clinic_doctor_name") == 'Clinic_Doctor') {
                        setVar_getClinicWiseDoctor(response.data.data);
                        localStorage.setItem("doctor_id", response.data.data[0].doctor_id);
                        localStorage.setItem("Doctor_id", response.data.data[0].doctor_id);
                        localStorage.setItem("doctor_Name", response.data.data[0].doctorName);
                        localStorage.setItem("contract_status", response.data.data[0].contract_status);
                        setVar_doctorSpecialitySelect(response.data.data[0]);
                        localStorage.setItem("speciality_Id", response.data.data[0].specialityid);
                        Service.doctorDetail = (response.data.data[0])
                        clinicTabPermissionById()
                        checkThreeStepVerification(response.data.data[0].doctor_id)
                        setLoading(false);
                    } else {
                        if (localStorage.getItem("clinic_doctor_name") == 'Clinic_Doctor_Change') {
                            setVar_getClinicWiseDoctor(response.data.data);
                            for (let i = 0; i < Var_getClinicWiseDoctor_Ref.current.length; i++) {
                                if (localStorage.getItem("changes_doctor") == response.data.data[i].doctor_id) {
                                    // localStorage.setItem("doctor_id", response.data.data[i].doctor_id);
                                    // localStorage.setItem("doctor_Name", response.data.data[i].doctorName);
                                    // localStorage.setItem("contract_status", response.data.data[i].contract_status);
                                    setVar_doctorSpecialitySelect(response.data.data[i]);
                                    // localStorage.setItem("speciality_Id", response.data.data[i].specialityid);
                                    Service.doctorDetail = (response.data.data[i])
                                    clinicTabPermissionById()
                                    checkThreeStepVerification(localStorage.getItem("doctor_id"))
                                    setLoading(false);
                                    console.log(localStorage.getItem("doctor_id"), "Doctor_Changes_1");
                                }
                            }

                        }
                        console.log(localStorage.getItem("doctor_id"), "Doctor_Changes");
                        if (localStorage.getItem("clinic_doctor_name") == null) {
                            Var_History.push('/')
                        }
                        setLoading(false);
                    }
                } else {
                    setApproved('P');
                    setVerificationPage(true);
                    setActiveStep(0);
                    localStorage.setItem("contract_status", "null");
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    // get clinic wise doctor api call end
    const doctorSpecialityId = (e) => {
        debugger;
        localStorage.setItem("clinic_doctor_name", "Clinic_Doctor_Change");
        localStorage.setItem("speciality_Id", e.target.value.specialityid);
        localStorage.setItem("doctor_id", e.target.value.doctor_id);
        localStorage.setItem("Doctor_id", e.target.value.doctor_id);
        localStorage.setItem("changes_doctor", e.target.value.doctor_id);
        localStorage.setItem("doctor_Name", e.target.value.doctorName);
        localStorage.setItem("contract_status", e.target.value.contract_status);
        setVar_doctorSpecialitySelect(e.target.value);
        clinicTabPermissionById();
        checkThreeStepVerification(e.target.value.doctor_id)
        sendToken();
        // Var_History.push("/dashboard/");
    }
    const clinicTabPermissionById = () => {
        //setLoading(true);
        debugger;
        var data = {
            doctorId: localStorage.getItem("doctor_id"),
            clinicId: localStorage.getItem("clinic_id")
        };
        axios
            .post("doctor/clinicTabPermissionbyId", data)
            .then((response) => {
                setVar_PermissionObject(response.data.data[0]);
                localStorage.setItem('menupermissions', JSON.stringify(response.data.data[0]));
                console.log(Var_PermissionObject_Ref.current, "Var_PermissionObject_Ref");
            })
            .catch((error) => {
                // setLoading(false);
                console.log(error);
            });
    }
    const checkThreeStepVerification = (doctor_id,) => {
        getNotifications()
        var data = {
            "doctor_id": doctor_id,
            "clinic_id": localStorage.getItem("clinic_id")
        }
        axios
            .post("doctor/verifyThreeStepVerification", data)
            .then((response) => {
                debugger;
                if (response.status == 200) {
                    let findex = response.data.data.findIndex((obj) => obj.id == 0);
                    var filteredData = response.data.data.filter(x => x.id == "A" || x.id == "R");
                    var filteredData1 = response.data.data.filter(x => x.menu == "Go Live");
                    var rejectres = response.data.data.filter((x) => x.menu == "Reject Reason by Salesadmin");
                    setDeclinestatus(rejectres[0]?.id ? rejectres[0].id : '')


                    if (filteredData.length != 0) {
                        setApproved(filteredData[0].id);
                        if (filteredData[0].id == 'A') {
                            setApproved('P');
                            setApprovedLive('A');
                            setVerificationPage(true)
                            setActiveStep(findex)
                            setVar_DoctorId(doctor_id)
                            // setVerificationPage(false)
                            // setActiveStep(0)
                        }
                        else if (filteredData[0].id == 'R') {
                            setApproved('R');
                            setApprovedLive('P');
                            localStorage.setItem("FooterStatus", "P");
                            setVerificationPage(true)
                            setActiveStep(findex)
                            console.log(findex, "findex========");
                            return
                        }
                        if (filteredData1[0].id != '0' && filteredData1[0].id != '' && filteredData1[0].id != null) {
                            // setApprovedStatus(filteredData1[0].id);
                            setApproved('A');
                            localStorage.setItem("FooterStatus", "A");
                            setVerificationPage(false);
                            setActiveStep(0);
                        } else {
                            setApprovedStatus(filteredData1[0].id);
                        }
                    } else {
                        setApproved('P');
                        setApprovedLive('P');
                        localStorage.setItem("FooterStatus", "P");
                        setVerificationPage(true)
                        setActiveStep(findex)
                        // if(findex == 1){
                        //     setActiveStep(findex);
                        // }else if(findex == 2){
                        //     setActiveStep(findex- 1);
                        //     setActiveStep(findex);
                        // }
                        console.log(findex, "findex========");

                    }
                    console.log(approved, "approved=====");
                }
            })
            .catch((error) => {
                // setLoading(false);
                console.log(error);
            });
    }
    const updategoLiveStatus = () => {
        debugger;
        var data = {
            // "vendor_id": localStorage.getItem("clinic_id") ? localStorage.getItem("clinic_id") : ""
            "vendor_id": Var_DoctorId ? Var_DoctorId : ""
        }
        axios
            .post("admin/updateGoLiveStatus", data)
            .then((response) => {
                debugger;

                if (response.data.status == 1) {
                    setApproved('A');
                    setVerificationPage(false);
                    setActiveStep(0);
                    // setApprovedStatus(1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const getNotifications = () => {
        setLoading(true);
        var data = {
            doctor_id: localStorage.getItem("doctor_id"),
            clinic_id: localStorage.getItem("clinic_id"),
            speciality_id: localStorage.getItem("speciality_Id")
        };
        axios
            .post("doctor/doctorwebnotification", data)
            .then((response) => {
                setVar_NotificationData(response.data.data);
                setVarnotificationcount(response.data.data.filter(x => x.to_date == formattedDate && (x.speciality_id == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id")) || x.to_date == formattedDate && (x.ref_speciality_id == localStorage.getItem("speciality_Id") && x.ref_doctor_id == localStorage.getItem("doctor_id"))))
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const currentDate = new Date();
    // Get the year, month, and day
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because January is 0
    const day = ('0' + currentDate.getDate()).slice(-2);
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    const getMenulist = () => {
        axios({
            method: "POST",
            url: "admin/getCollateralVideosByVendor",
            data: {
                "vendorId": 3
            },
        })
            .then((response) => {
                if (response.data.status == 200) {
                    setVideoListbyMenu(response.data.data)
                }
                else {
                    setVideoListbyMenu([])
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleMenuCardOnClick = (data) => {
        if (videoListbyMenu.length != 0 && data != '') {
            var video = videoListbyMenu.filter((obj) => { return obj.menu_name.toLowerCase() == data.toLowerCase() })
            if (video.length > 0) {
                setMenuVideo(video)
                setisOpenVideoPopup(true)
                setCurrentVideo(video[0].video_path ? video[0].video_path : '')
                setCurrentSubmenuid(video[0].sub_menu_id)
                setVideoPopupTitle(video[0].menu_name)
            }
            else {
                setMenuVideo([])
            }
        }
        else {
            setMenuVideo([])
        }
    }
    const navClick = (data) => {
        setCurrentVideo(data.video_path ? data.video_path : '')
        setCurrentSubmenuid(data.sub_menu_id)
        setVideoPopupTitle(data.menu_name)
    }
    const sendToken = () => {

        var data = {
            "vendor_id": localStorage.getItem("doctor_id"),
            "token": localStorage.getItem("TokenFCM")
        };
        axios
            .post("admin/insertvendorwebtokenclinic", data)
            .then((response) => {
                console.log("update token response", response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function rejectIcon() {
        return (
            <div className="rejectimage">
                <img src={RejectIcon} />
            </div>
        );
    }
    const storeData = (payload, data) => {
        debugger
        console.log(payload, data);
        if ('Data' == data) {
            setupdateStatus(true);
            setpayloadstatus(payload);
        } else {
            setupdateStatus(false);
            setpayloadstatus(payload);
        }

    }

    return (
        <div className="layout_Total_body">
            <div>
                {loading &&
                    <div className="loader_body">
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress />
                        </Box>
                    </div>
                }
                {/* ====================Dashboard Header Start=================== */}
                <div>
                    <div className="dashboard_header">
                        {/* <div className="dashboard_header_row">
                            <div className="dashboard_header_content">
                                <div className="dashboard_header_column">
                                    <img src={Logo} alt="logo" className="dashboard_header_logo" />
                                </div>
                                <div className='dashboard_header_dropdown'>
                                    <div className='dashboard_header_dropdown_button'>
                                        <FormControl fullWidth>
                                            <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 1, minWidth: 100 }}
                                                id="demo-simple-select"
                                                value={Var_doctorSpecialitySelect_Ref.current}
                                                onChange={(e) => {
                                                    doctorSpecialityId(e)
                                                }}  >
                                                {Var_getClinicWiseDoctor.map((item) => (
                                                    <MenuItem
                                                        key={item.specialityname}
                                                        value={item}>
                                                        {item.doctorName}, {item.specialityname}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div style={{ flexGrow: "1" }}>
                            </div>
                            <div className="dashboard_header_profile_part">
                                <div className="dashboard_header_icons">
                                    <div className="dashboard_header_home_icon">
                                        <img src={homeIcon} alt="log" className="home_image"
                                            onClick={() => routeNavigation("/")}
                                        />
                                    </div>
                                    {Varnotificationcount_Ref.current.length > 0 &&
                                    <div className="notification_image_header">
                                        <img src={Notification} alt="log" className="notification_image"
                                            onClick={() => routeNavigation("/notification")} />
                                    </div>
                                    }
                                    {Varnotificationcount_Ref.current.length == 0 &&
                                    <div className="notification_image_header">
                                        <img className="notification_icon_image" src={emptyNotification} alt="log"
                                            onClick={() => routeNavigation("/notification")} />
                                    </div>
                                    }
                                </div>
                                <div>
                                    {Var_Profiledata?.map((item, i) => (
                                        <img src={item.profileImage == null ? Logo : item.profileImage == "" ? Logo : item.profileImage == " " ? Logo : item.profileImage} className="profile_image"
                                            onClick={() => routeNavigation("/profile")}
                                        />
                                    ))}
                                </div>
                                <div>
                                    {Var_Profiledata?.map((item, i) => (
                                        <div className="profile_text_header">
                                            <p className="profile_text_name_header">{item.vendor_name}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div> */}
                        <HeaderMasterComponent onEdit={(payload, data) => storeData(payload, data)} />

                    </div>
                    {!updateStatus ? <div>
                        <DashboardMasterComponent menupermission={Var_PermissionObject_Ref.current} speciality_id={payloadstatus} />
                    </div> :
                        <div>
                            <DashboardMasterComponent menupermission={Var_PermissionObject_Ref.current} speciality_id={payloadstatus} />
                        </div>}

                </div>
                {/* ====================Dashboard Header End=================== */}









                {/* <div> */}
                {/* ===========================================Page Header Start================================================== */}
                {/* {!verificationPage && approved == 'A' && localStorage.getItem('contract_status') == 'A' &&
                        <div className="layout_header">
                            <div className="home_header">                        
                                <div className="header_row">
                                    <div className="row_content_aprove">
                                        <div style={{ display: "flex" }}>
                                            <div className="header_column1">
                                                <img src={Logo} alt="log" className="logo_images_aprove" />
                                            </div>
                                            <div className='fix_dropdown_aprove'>
                                                <div className='drop dropdown_button'>
                                                    <FormControl fullWidth>
                                                        <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 1, minWidth: 100 }}
                                                            id="demo-simple-select"
                                                            value={Var_doctorSpecialitySelect_Ref.current}
                                                            onChange={(e) => {
                                                                doctorSpecialityId(e)
                                                            }}  >
                                                            {Var_getClinicWiseDoctor.map((item) => (
                                                                <MenuItem
                                                                    key={item.specialityname}
                                                                    value={item}>
                                                                    {item.doctorName}, {item.specialityname}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ flexGrow: "1" }}>
                                        </div>
                                        <div className="header_right_part">
                                            <div className="header_icons">
                                                {Varnotificationcount_Ref.current.length > 0 &&
                                                    <img src={Notification} alt="log" className="notification_images"
                                                        onClick={() => routeNavigation("/notification")}
                                                    />
                                                }
                                                {Varnotificationcount_Ref.current.length == 0 &&
                                                    <img className="notification_not_icon" src={emptyNotification} alt="log"
                                                        onClick={() => routeNavigation("/notification")}
                                                    />
                                                }
                                                <div className="support-container">
                                                    <img src={support} alt="log" className=" support_images"
                                                        onClick={() => routeNavigation("/support")}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                {Var_Profiledata?.map((item, i) => (
                                                    <div className="profile_text_aprove">
                                                        <p className="profile_name_header">Welcome</p>
                                                        <p className="profile_name_header username">{item.vendor_name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="support-container">
                                                {Var_Profiledata?.map((item, i) => (
                                                    <img src={item.profileImage == null ? Logo : item.profileImage == "" ? Logo : item.profileImage == " " ? Logo : item.profileImage} className="profile_img_aprove"
                                                        onClick={() => routeNavigation("/profile")}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}
                {/* ===========================================Three Step Page Header Start================================================== */}
                {/* {verificationPage && (approved == 'P' || approved == 'R') && localStorage.getItem('contract_status') == 'A' &&
                        <div className="layout_header">
                            <div className="home_header">
                            <div className="header_row">
                                    <div className="row_content_aprove">
                                        <div style={{ display: "flex" }}>
                                            <div className="header_column1">
                                                <img src={Logo} alt="log" className="logo_images_aprove" />
                                            </div>
                                            <div className='fix_dropdown_aprove'>
                                                <div className='drop dropdown_button'>
                                                    <FormControl fullWidth>
                                                        <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 1, minWidth: 100 }}
                                                            id="demo-simple-select"
                                                            value={Var_doctorSpecialitySelect_Ref.current}
                                                            onChange={(e) => {
                                                                doctorSpecialityId(e)
                                                            }}  >
                                                            {Var_getClinicWiseDoctor.map((item) => (
                                                                <MenuItem
                                                                    key={item.specialityname}
                                                                    value={item}>
                                                                    {item.doctorName}, {item.specialityname}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ flexGrow: "1" }}></div>
                                        <div className="header_right_part">
                                            <div className="header_icons">
                                                <div className="support-container">
                                                    <img src={support} alt="log" className=" support_images"
                                                        onClick={() => routeNavigation("/support")}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                {Var_Profiledata?.map((item, i) => (
                                                    <div className="profile_text_aprove">
                                                        <p className="profile_name_header">Welcome</p>
                                                        <p className="profile_name_header username">{item.vendor_name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="support-container">
                                                {Var_Profiledata?.map((item, i) => (
                                                    <img src={item.profileImage == null ? Logo : item.profileImage == "" ? Logo : item.profileImage == " " ? Logo : item.profileImage} className="profile_img_aprove"/>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}
                {/* {verificationPage && (approved == 'P' || approved == 'p') && (localStorage.getItem('contract_status') == 'P' || localStorage.getItem('contract_status') == '' || localStorage.getItem('contract_status') == 'null') &&
                        <div className="layout_header">
                            <div className="home_header">
                            <div className="header_row">
                                    <div className="row_content_aprove">
                                        <div style={{ display: "flex" }}>
                                            <div className="header_column1">
                                                <img src={Logo} alt="log" className="logo_images_aprove" />
                                            </div>
                                            <div className='fix_dropdown_aprove'>
                                                <div className='drop dropdown_button'>
                                                    <FormControl fullWidth>
                                                        <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 1, minWidth: 100 }}
                                                            id="demo-simple-select"
                                                            value={Var_doctorSpecialitySelect_Ref.current}
                                                            onChange={(e) => {
                                                                doctorSpecialityId(e)
                                                            }}  >
                                                            {Var_getClinicWiseDoctor.map((item) => (
                                                                <MenuItem
                                                                    key={item.specialityname}
                                                                    value={item}>
                                                                    {item.doctorName}, {item.specialityname}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ flexGrow: "1" }}>
                                        </div>
                                        <div className="header_right_part">
                                            <div className="header_icons">
                                                <div className="support-container">
                                                    <img src={support} alt="log" className=" support_images"/>
                                                </div>
                                            </div>
                                            <div>
                                                {Var_Profiledata?.map((item, i) => (
                                                    <div className="profile_text_aprove">
                                                        <p className="profile_name_header">Welcome</p>
                                                        <p className="profile_name_header username">{item.vendor_name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="support-container">
                                                {Var_Profiledata?.map((item, i) => (
                                                    <img src={item.profileImage == null ? Logo : item.profileImage == "" ? Logo : item.profileImage == " " ? Logo : item.profileImage} className="profile_img_aprove"/>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>                    
                            </div>
                        </div>
                    } */}
                {/* ===========================================Page Header End================================================== */}
                {/* ===========================================Page Body Start================================================== */}
                {/* {!verificationPage && approved == 'A' && localStorage.getItem('contract_status') == 'A' && <div className="layout_body">
                        <DashboardMasterComponent menupermission={Var_PermissionObject_Ref.current} />
                    </div>}
                    {verificationPage && (approved == 'P' || approved == 'R') && localStorage.getItem('contract_status') == 'A' && <div className="verification_main_div">
                        <div className="three_step_head"><h1 className="mb-8 three_step">3 Step Registration to go Live</h1></div>
                        <div>
                            <Grid container>
                                <Grid sm={12} md={12} className="ml-5 mr-5">
                                    <Grid container>
                                        <Grid sm={3} md={3} className={activeStep >= 0 ? 'active_card' : 'disable_card'}>
                                            <div style={{ position: "relative" }}>
                                                <img className='step_head_img' src={Manageserviceimg} alt="Manageserviceimg" onClick={() => { if (activeStep >= 0) routeNavigationByVerification("/manageservice") }} />
                                                <img src={CollateralPlayIcon} className="play_img" onClick={() => { if (activeStep >= 0) handleMenuCardOnClick('Manage Service') }} />
                                            </div>
                                            <div className="mt-2"> Manage Services</div>
                                        </Grid>
                                        <Grid sm={3} md={3} className={(activeStep >= 1 ? 'active_card' : 'disable_card')}>
                                            <div style={{ position: "relative" }}>
                                                <img className='step_head_img' src={Workinghoursimg} alt="Workinghoursimg" onClick={() => { if (activeStep >= 1) routeNavigationByVerification("/workinghours") }} />
                                                <img src={CollateralPlayIcon} className="play_img" onClick={() => { if (activeStep >= 1) handleMenuCardOnClick('Working Hours') }} />
                                            </div>
                                            <div className="mt-2"> Working Hours</div>
                                        </Grid>
                                        <Grid sm={3} md={3} className={(activeStep >= 2 ? 'active_card' : 'disable_card')}>
                                            <div style={{ position: "relative" }}>
                                                <img className='step_head_img' src={Postimg} alt="Postimg" onClick={() => { if (activeStep >= 2) routeNavigationByVerification("/post") }} />
                                                <img src={CollateralPlayIcon} className="play_img" onClick={() => { if (activeStep >= 2) handleMenuCardOnClick('post') }} />
                                            </div>
                                            <div className="mt-2"> Post</div>
                                        </Grid>
                                        <Grid sm={3} md={3} className={(activeStep >= 3 ? 'active_card' : 'disable_card')}>
                                            {activeStep == 4 && <div>
                                                {approvedStatus == 0 && approvedLive != 'A' ?
                                                    approved == 'R' ? (<div className="d-flex justify-content-center"><div className="decline_msg_box" >{declineStatus}</div></div>)
                                                        : (<div><img className='step_head_img' src={Supportimg} alt="Supportimg" /></div>)

                                                    :
                                                    <div>
                                                        <img className='step_head_img' src={Supportimg} alt="Supportimg" onClick={() => { if (activeStep >= 3) updategoLiveStatus() }} />
                                                    </div>
                                                }
                                            </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <div className="stepper_line_show w-100">
                                    <Grid sm={12} md={12} className="ml-5 mr-5 mt-5">
                                        <Stepper activeStep={activeStep} orientation="horizontal" alternativeLabel={true}>
                                            {stepList.map((label, i) => (
                                                <Step key={label}>
                                                    <StepLabel StepIconComponent={(i == 3 && approved == 'R') && rejectIcon}></StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Grid>
                                </div>
                                <div className="w-100">
                                    <div className="w-100"></div>
                                    {activeStep == 4 && <div>
                                        {approvedStatus == 0 && approvedLive != 'A' ?
                                            approved == 'R' ?
                                                (<div className="mt-2 waiting_class1 " style={{ fontWeight: '600', color: '#ff0068' }}>Declined</div>) :
                                                (<div className="mt-2 waiting_class " style={{ fontWeight: '600' }}>Waiting for <br /><span> verification</span></div>) :
                                            <div className="mt-2 congrats_text" style={{ fontWeight: '600' }}>
                                                Congratulations. <br /> <span style={{ marginRight: '15px' }}>You are Live!</span>
                                                <div className="click_div">
                                                    <button
                                                        className="start_button"
                                                        onClick={() => { if (activeStep >= 3) updategoLiveStatus() }}
                                                    >Start</button>
                                                </div>
                                            </div>

                                        }
                                    </div>
                                    }
                                </div>
                            </Grid>
                        </div>
                    </div>}
                    {verificationPage && (approved == 'P' || approved == 'p') && (localStorage.getItem('contract_status') == 'P' || localStorage.getItem('contract_status') == '' || localStorage.getItem('contract_status') == 'null') && <div className="verification_main_waiting">
                        <h2 className="pt-5 mb-3">Waiting for Doctor Registration</h2>
                    </div>} */}
                {/* ===========================================Page Body End================================================== */}
                {/* </div> */}
                {/* {!verificationPage && approved == 'A' && localStorage.getItem('contract_status') == 'A' && <div className="footer">
                    <FooterMaster menupermission={Var_PermissionObject_Ref.current} />
                </div>} */}
                <Dialog
                    fullWidth={true}
                    alignment="center"
                    size="md"
                    open={isOpenVideoPopup}
                    onClose={setisOpenVideoPopup}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%", minWidth: "960px", maxWidth: "960px", borderRadius: "20px"
                            },
                        },
                    }}
                >
                    <div className="dialog_header">
                        <div className="dialog_title">{videoPopupTitle}</div>
                        <div className="flex_grow"></div>
                        <div onClick={() => setisOpenVideoPopup(false)}>
                            <img src={closeicon} />
                        </div>
                    </div>
                    <DialogContent>
                        <div>
                            <div style={{ textAlign: 'center' }}>
                                <video src={currentVideo} style={{ width: '100%' }} controls></video>
                                <div>
                                    <ul className="collaterals_video_ul">
                                        {
                                            menuVideo.map((obj) => (
                                                <li className={currentSubmenuid == obj['sub_menu_id'] ? "active" : ""} onClick={() => { navClick(obj) }}> {obj['sub_menu_name']} </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
}
export default DashboardComponent;
