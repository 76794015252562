import { useHistory } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import useStateRef from "react-usestateref";
import { notification, Spin } from "antd";
import moment from "moment";

// Mui meterial component import
import {
    InputLabel,
    FormControlLabel,
    Select,
    FormControl,
    MenuItem,
    Container,
    Card,
    Grid,
    TextField,
    Button,
    DialogContent,
    Dialog,
    Radio,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "./DashboardMaster.css";
import MonthlyChart from "../Dashboard/DashboardChart";

import Appoinmentsimg from "../../assets/clinicIcon/homePageIcon/appointment.svg";
import Queueimg from "../../assets/clinicIcon/homePageIcon/queue.svg";
import Availabilityimg from "../../assets/clinicIcon/homePageIcon/manageSchedule.svg";
import Workinghoursimg from "../../assets/clinicIcon/homePageIcon/workhours.svg";
import Walkinimg from "../../assets/clinicIcon/homePageIcon/referral.svg";
import Cancelledimg from "../../assets/clinicIcon/homePageIcon/cancelled.svg";
import Postimg from "../../assets/clinicIcon/homePageIcon/post.svg";
import Manageserviceimg from "../../assets/clinicIcon/homePageIcon/manageService.svg";
import videoIcon from "../../assets/Doctor_web_Icon/Group21005.svg";

import DashboardImage from "../../assets/Doctor_Images/Group 17328.png";
import DashboardDoctorImage from "../../assets/Doctor_Images/file.png";
import DashboardAddAppointmentImage from "../../assets/Doctor_Images/add_plus.png";
import DashboardAppointmentsImage from "../../assets/Doctor_Images/calendar (2).png";
import DashboardServicesImage from "../../assets/Doctor_Images/time.png";
import DashboardHoursImage from "../../assets/Doctor_Images/settings.png";
import DashboardRevenueImage from "../../assets/Doctor_Images/Layer_2.png";
import MaleIcon from "../../assets/Doctor_Images/male_user.png";
import FemaleIcon from "../../assets/Doctor_Images/female_user.png";
import VectorIcon from "../../assets/Doctor_Images/user.png";
import closeicon from "../../assets/Web_Doctor_Icon_new_theme/close.svg";

function DashboardMasterComponent({ speciality_id }) {
    console.log(speciality_id, "speciality_id_changes");
    let Var_History = useHistory();
    let interval;
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [Var_UpcomingAppointment, setVar_UpcomingAppointment, Var_UpcomingAppointment_Ref] = useStateRef([]);
    const [Var_CheckinStatus, setVar_CheckinStatus, Var_CheckinStatus_Ref] =
        useStateRef("0");
    const [Var_CheckOutStatus, setVar_CheckOutStatus, Var_CheckOutStatus_Ref] =
        useStateRef("0");
    var [Var_TotalCount, setVar_TotalCount, Var_TotalCount_Ref] = useStateRef(0);
    var [Var_DateApi, setVar_DateApi, dateRefapi] = useStateRef("");
    const [WalkInAppointmentModel, setWalkInAppointmentModel] = useState(false);
    const [walname, setwalname] = useState("");
    const [walgender, setwalgender] = useState("");
    const [walmobile, setwalmobile] = useState("");
    const [walage, setwalage] = useState("");
    const [walservice, setwalservice] = useState("");
    const [walsymptoms, setwalsymptoms] = useState("");
    const [Var_ProfileName, setVar_ProfileName, Var_ProfileName_Ref] =
        useStateRef("");
    const [Var_ProfilePhone, setVar_ProfilePhone, Var_ProfilePhone_Ref] =
        useStateRef("");
    const [Var_ProfileAge, setVar_ProfileAge, Var_ProfileAge_Ref] =
        useStateRef("");
    const [Var_SelectTimeSlots, setVar_SelectTimeSlots, Var_SelectTimeSlots_Ref] =
        useStateRef("");
    const [Var_SlotsLength, setVar_SlotsLength, Var_SlotsLength_Ref] =
        useStateRef("");
    const [Var_gendervalue, setVar_gendervalue, Var_gendervalue_Ref] =
        useStateRef("Select");
    const [Var_gender, setVar_gender, Var_gender_Ref] = useStateRef("Select");
    const [Var_AppointmentId, setVar_AppointmentId, Var_AppointmentId_Ref] =
        useStateRef("");
    const [Var_PatientIdId, setVar_PatientId, Var_PatientId_Ref] =
        useStateRef("");
    const [Var_GetServiceList, setVar_GetServiceList, Var_GetServiceList_Ref] =
        useStateRef([]);
    const [
        Var_GetServiceListSelect,
        setVar_GetServiceListSelect,
        Var_GetServiceListSelect_Ref,
    ] = useStateRef("");
    const [Var_GetSymptoms, setVar_GetSymptoms, Var_GetSymptoms_Ref] =
        useStateRef("");
    const [bookingBtnState, setBookingBtnState, bookingBtnStateRef] =
        useStateRef(true);
    const [walBookingBtnState, setWalBookingBtnState, walBookingBtnStateRef] =
        useStateRef(true);
    const [
        Var_SelectToTimeSlots,
        setVar_SelectToTimeSlots,
        Var_SelectToTimeSlots_Ref,
    ] = useStateRef("");
    var [Var_Date, setVar_Date, Var_DateRef] = useStateRef("");
    const [Var_SuccessModelText, setVar_SuccessModelText] = useState("");
    const [fullWidth, setFullWidth] = React.useState(true);
    const [isOpenWalkInModel, setisOpenWalkInModel] = useState(false);
    const [isOpenWalkInQrModel, setisOpenWalkInQrModel] = useState(false);
    var [isOpenSuccessModel, setIsOpenSuccessModel] = useState(false);
    const [Var_WalkInGetSlots, setVar_WalkInGetSlots, Var_WalkInGetSlots_Ref] =
        useStateRef([]);
    const [Var_WalingNoSlots, setVar_WalingNoSlots, Var_WalingNoSlots_Ref] =
        useStateRef("0");
    var [isOpenQrCodeModel, setisOpenQrCodeModel] = useState(false);
    const [Var_SlotsValue, setSlotsValue] = useStateRef("");
    const [Var_SlotsValue_Minus, setSlotsValue_Minus] = useStateRef("");
    const [, setSlotsValueData, Var_SlotsValueData_Ref] = useStateRef("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [
        Var_NextPatientDetailsData,
        setVar_NextPatientDetailsData,
        Var_NextPatientDetailsData_Ref,
    ] = useStateRef([]);
    const [
        Var_AddAppointmentMsg,
        setVar_AddAppointmentMsg,
        Var_AddAppointmentMsg_Ref,
    ] = useStateRef("");
    const [isshowsuccess, setishiddensuccess] = useState(false);
    const [Var_Date_Today, setVar_Date_Today] = useState(new Date());
    const [Var_Profiledata, setVar_Profiledata] = React.useState([]);
    const [ProfileDetailsModel, setProfileDetailsModel] = useState(false);
    const [
        Var_ManageServicesTotalCount,
        setVar_ManageServicesTotalCount,
        Var_ManageServicesTotalCount_Ref,
    ] = useStateRef([]);
    const [
        Var_WorkingHoursTotalCount,
        setVar_WorkingHoursTotalCount,
        Var_WorkingHoursTotalCount_Ref,
    ] = useStateRef([]);

    const [Var_ClinicID, setVar_ClinicID, Var_ClinicID_Ref] = useStateRef(null);
    const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor] = useState([]);
    var [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect] =
        useState("");
    const [
        Var_TabPermissionById,
        setVar_TabPermissionById,
        Var_TabPermissionById_Ref,
    ] = useStateRef(null);
    const [
        Var_Availability_access,
        setVar_Availability_access,
        Var_Availability_access_Ref,
    ] = useStateRef(null);
    const [
        Var_Workinghrs_access,
        setVar_Workinghrs_access,
        Var_Workinghrs_access_Ref,
    ] = useStateRef(null);
    const [Var_Post_acccess, setVar_Post_acccess, Var_Post_acccess_Ref] =
        useStateRef(null);
    const [Var_Service_access, setVar_Service_access, Var_Service_access_Ref] =
        useStateRef(null);
    const [loading, setLoading] = useState(false);
    const [menupermission, setmenupermission, menupermission_ref] = useStateRef(
        "props.menupermission"
    );
    const [revenueIncome, setRevenueIncome] = useState([]);
    const [getTotalRevenue, setTotalRevenue] = useState(null);
    const [Var_year, setVar_year, Var_year_Ref] = useStateRef(2024);
    const [VarUseEffect, setVarUseEffect, VarUseEffect_Ref] =
        useStateRef(speciality_id);
    const [Var_DashboardContent, setVar_DashboardContent, Var_DashboardContent_Ref] = useStateRef("Welcome");
    const [Var_PermissionObject, setVar_PermissionObject, Var_PermissionObject_Ref] = useStateRef([]);

    useEffect(() => {
        debugger;
        console.log(speciality_id, "speciality_id_changes_use_effect");
        const currentDate = new Date();
        const currentHour = String(currentDate.getHours()).padStart(2, "0");
        const currentMinute = String(currentDate.getMinutes()).padStart(2, "0");
        const currentSecond = String(currentDate.getSeconds()).padStart(2, "0");
        const formattedTime = `${currentHour}:${currentMinute}:${currentSecond}`;
        const hour = currentDate.getHours();



        const Var_CurrentDate = new Date()
            .toISOString()
            .replace(/T/, " ")
            .replace(/\..+/, "");

        const currentDateInUTC = new Date(
            Date.UTC(
                new Date().getUTCFullYear(),
                new Date().getUTCMonth(),
                new Date().getUTCDate(),
                new Date().getUTCHours(),
                new Date().getUTCMinutes(),
                new Date().getUTCSeconds()
            )
        );

        console.log(currentDateInUTC.toUTCString());
        console.log(formattedTime, Var_CurrentDate, currentDateInUTC, "Date");
        setVar_Date(moment(new Date().toLocaleDateString("fr-CA")).format("DD-MMM-YY"));
        setVar_DateApi(new Date().toLocaleDateString("fr-CA"));
        getClinicWiseDoctor();
        getClinicProfile();
        changeLanguage();
        if (hour >= 5 && hour < 12) {
            return setVar_DashboardContent("Good Morning");
        } else if (hour >= 12 && hour < 17) {
            return setVar_DashboardContent("Good Afternoon");
        } else if (hour >= 17 && hour < 21) {
            return setVar_DashboardContent("Good Evening");
        } else {
            return setVar_DashboardContent("Good Night");
        }

    }, [speciality_id]);
    // navigation to back start

    const getRevenueIncome = () => {
        var data = {
            doctorId: localStorage.getItem("doctor_id"),
            clinicId: localStorage.getItem("clinic_id"),
            year: Var_year_Ref.current,
        };
        axios.post("doctor/getRevenueForDoctor", data).then((response) => {
            let totalData = [];
            let income = 0;
            response.data.data.forEach((item, index) => {
                totalData.push({
                    month: new Date(2024, index, 1),
                    value: item.booking_count,
                });
                income = item.booking_count + income;
            });
            setRevenueIncome(totalData);
            setTotalRevenue(income);
        });
    };

    const handleOnClick = (path) => {
        if (path == "appointments" && Var_PermissionObject_Ref.current.appointment_access == 0) {
            notification.error({ message: "No permission" })
            return
        } else if (path == "manageservice" && Var_PermissionObject_Ref.current.service_access == 0) {
            notification.error({ message: "No permission" })
            return
        } else if (path == "workinghours/viewworkinghours" && Var_PermissionObject_Ref.current.workinghrs_access == 0) {
            notification.error({ message: "No permission" })
            return
        } else if (path == "revenue" && Var_PermissionObject_Ref.current.revenue_access == 0) {
            notification.error({ message: "No permission" })
            return
        }
        Var_History.push("/dashboard/" + path);
    };
    // navigation to back end

    const getClinicWiseDoctor = () => {
        debugger;
        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("clinic_id")
        };
        axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {
                if (localStorage.getItem("clinic_doctor_name") == 'Clinic_Doctor') {
                    setVar_getClinicWiseDoctor(response.data.data);
                    setVar_doctorSpecialitySelect(response.data.data[0].specialityid);
                    localStorage.setItem("doctor_id", response.data.data[0].doctor_id)
                    localStorage.setItem("speciality_Id", response.data.data[0].specialityid);
                }
                setLoading(false);
                sendToken();
                clinicTabPermissionById();
                getAllAppointment();
                getManageServicesList();
                getworkingDetails();
                getRevenueIncome();
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    const noPermission = (type) => {
        if (type == "appointments") {
            notification.error({ message: "No permission" });
        } else if (type == "queue") {
            notification.error({ message: "No permission" });
        } else if (type == "availability") {
            notification.error({ message: "No permission" });
        } else if (type == "workinghours") {
            notification.error({ message: "No permission" });
        } else if (type == "walkinbooking") {
            notification.error({ message: "No permission" });
        } else if (type == "cancelledappointments") {
            notification.error({ message: "No permission" });
        } else if (type == "post") {
            notification.error({ message: "No permission" });
        } else if (type == "manageservice") {
            notification.error({ message: "No permission" });
        }
    };
    const sendToken = () => {
        var data = {
            vendor_id: localStorage.getItem("doctor_id"),
            token: localStorage.getItem("TokenFCM"),
        };
        axios
            .post("admin/insertvendorwebtokenclinic", data)
            .then((response) => {
                console.log("update token response", response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const changeLanguage = () => {
        var data = {
            vendor_id: localStorage.getItem("doctor_id"),
            language: "en",
        };
        axios
            .post("admin/insertvendorweblanguageclinic", data)
            .then((response) => {
                console.log("update token response", response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getAllAppointment = () => {
        debugger;
        const patient_details = [];
        setVar_NextPatientDetailsData([]);
        setVar_UpcomingAppointment([]);
        setVar_TotalCount(0);
        setVar_CheckOutStatus(0);
        var date = dateRefapi.current;
        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("clinic_id"),
            doctor_id: localStorage.getItem("doctor_id"),
            patient_type: "",
            period: "day",
            search_date: date.toString(),
            search_date_to: date.toString(),
        };
        axios
            .post("doctor/getAppointmentList", data)
            .then((response) => {
                debugger;
                if (response.data.status == 1) {
                    if (response.data.data[0].result.length != 0) {

                        console.log("Appoinment", response.data.data[0].result);
                        var TotalAppointmentCount = response.data.data[0].result.filter(
                            (x) => x.appointment_type_id != 2
                        );
                        setVar_TotalCount(TotalAppointmentCount.length);
                        var checkOutStatusAppointment = response.data.data[0].result.filter(
                            (x) => x.check_out_status == 0
                        );
                        setVar_UpcomingAppointment(checkOutStatusAppointment);
                        patient_details.push(checkOutStatusAppointment);
                        if (patient_details.length != 0) {
                            setVar_NextPatientDetailsData(patient_details[0][0]);
                        } else {
                            setVar_NextPatientDetailsData([]);
                        }
                        console.log(Var_NextPatientDetailsData_Ref.current, "datedfdsd");
                        var checkInStatus = response.data.data[0].result.filter(
                            (x) => x.check_in_status == 1
                        );
                        var checkOutStatus = response.data.data[0].result.filter(
                            (x) => x.check_out_status == 1
                        );
                        setVar_CheckinStatus(checkInStatus.length);
                        console.log("checkin", checkInStatus.length);
                        setVar_CheckOutStatus(checkOutStatus.length);
                        console.log("checkout", checkOutStatus.length);
                        setLoading(false);
                        // changeCheckinStatus(response.data.data[0].result)
                        clearInterval(interval);
                        interval = setInterval(() => {
                            getAllAppointment();
                        }, 60000); // 1000ms interval
                    } else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    // // Calculate the number of pages
    // const totalPages = Math.ceil(Var_UpcomingAppointment.length / itemsPerPage);

    // const currentData = Var_UpcomingAppointment.slice(
    //     (currentPage - 1) * itemsPerPage,
    //     currentPage * itemsPerPage
    // );
    // console.log(currentData, "currentData");

    // // Change the page
    // const goToPage = (page) => {
    //     if (page >= 1 && page <= totalPages) {
    //         setCurrentPage(page);
    //     }
    // };

    // validation for not booked before enter all inputs start
    const validation = () => {
        setwalname("");
        setwalmobile("");
        setwalage("");
        setwalgender("");
        setwalsymptoms("");
        setwalservice("");
        if (
            Var_SelectTimeSlots_Ref.current == "" &&
            Var_ProfileName_Ref.current == "" &&
            Var_ProfilePhone_Ref.current == "" &&
            Var_ProfileAge_Ref.current == "" &&
            (Var_gender_Ref.current == "" || Var_gender_Ref.current == "Select") &&
            (Var_GetServiceListSelect_Ref.current.id == 0 ||
                Var_GetServiceListSelect_Ref.current.id == "") &&
            Var_SlotsLength_Ref.current == "" &&
            Var_GetSymptoms_Ref.current == ""
        ) {
            setwalname(true);
            setwalmobile(true);
            setwalage(true);
            setwalgender(true);
            setwalsymptoms(true);
            setwalservice(true);
            return false;
        }
        if (Var_ProfileName_Ref.current == "") {
            setwalname(true);
            return false;
        }
        if (Var_gender_Ref.current == "" || Var_gender_Ref.current == "Select") {
            setwalgender(true);
            return false;
        }
        if (Var_ProfileAge_Ref.current == "") {
            setwalage(true);
            return false;
        }
        if (Var_ProfilePhone_Ref.current == "") {
            setwalmobile(true);
            return false;
        }
        if (Var_GetSymptoms_Ref.current == "") {
            setwalsymptoms(true);
            return false;
        }
        if (
            Var_GetServiceListSelect_Ref.current.id == "" ||
            Var_GetServiceListSelect_Ref.current.id == 0
        ) {
            setwalservice(true);
            return false;
        }
        if (Var_SlotsLength_Ref.current == "") {
            return false;
        }
        if (Var_SelectTimeSlots_Ref.current == "") {
            return false;
        }
        if (walservice || walmobile || walgender || walname || walsymptoms) {
            return false;
        } else {
            return true;
        }
    };
    //validation for not booked before enter all inputs end

    const bookedSuccessfully = () => {
        let Var_Validate = validation();
        if (Var_Validate) {
            const currentTimeUTC = new Date().toUTCString();
            console.log("Current UTC time:", currentTimeUTC);

            var now = new Date();
            var hours = now.getHours().toString().padStart(2, "0");
            var minutes = now.getMinutes().toString().padStart(2, "0");
            var seconds = now.getSeconds().toString().padStart(2, "0");

            var formattedTimeStrings = hours + ":" + minutes + ":" + seconds;
            console.log("time =", formattedTimeStrings);
            setWalBookingBtnState(false);
            var date = dateRefapi.current;
            setLoading(true);
            var data = {
                mobile_no: Var_ProfilePhone_Ref.current,
                name: Var_ProfileName_Ref.current,
                age: Var_ProfileAge_Ref.current,
                gender: Var_gendervalue_Ref.current,
                book_date: date.toString(),
                from_time: Var_SelectTimeSlots_Ref.current,
                to_time: Var_SelectToTimeSlots_Ref.current,
                service_type_id: Var_GetServiceListSelect_Ref.current.id,
                book_amount: 0,
                total_slots: 0,
                payment_status: 0,
                clinic_id: localStorage.getItem("clinic_id"),
                doctor_id: localStorage.getItem("doctor_id"),
                created_by: localStorage.getItem("doctor_id"),
                arrived_status:
                    Var_DateRef.current == moment(new Date()).format("DD-MMM-YY")
                        ? "1"
                        : "0",
                arrived_order_list:
                    Var_DateRef.current == moment(new Date()).format("DD-MMM-YY")
                        ? new Date().getHours() +
                        ":" +
                        new Date().getMinutes() +
                        ":" +
                        new Date().getSeconds()
                        : "NULL",
                speciality_id: localStorage.getItem("speciality_Id"),
                symptoms: Var_GetSymptoms_Ref.current,
            };
            axios
                .post("clinic/insert_patient_walkin", data)
                .then((response) => {
                    {
                        if (response.data.status == 1) {
                            setVar_AddAppointmentMsg(
                                "Congratulations! Appointment Added Successfully."
                            );
                            setVar_SuccessModelText("Walk-in Booking added successfully!");
                            setWalkInAppointmentModel(!WalkInAppointmentModel);
                            setIsOpenSuccessModel(!isOpenSuccessModel);
                            setWalBookingBtnState(true);
                            setLoading(false);
                            setVar_ProfileAge("");
                            setVar_ProfilePhone("");
                            setVar_ProfileName("");
                            setVar_GetServiceListSelect({
                                cost: "",
                                description: "",
                                doctor_id: "",
                                file_name: "",
                                file_path: "",
                                id: 0,
                                is_active: "",
                                service: "Select Service",
                                slot_duration: "",
                                spec_id: "",
                            });
                            setVar_GetSymptoms("");
                            setishiddensuccess(true);
                            setTimeout(() => {
                                setishiddensuccess(false);
                                getAllAppointment();
                            }, 3000);
                        } else {
                            setVar_AddAppointmentMsg(response.data.msg + "!");
                            setishiddensuccess(true);
                            setTimeout(() => {
                                setishiddensuccess(false);
                                getAllAppointment();
                            }, 3000);
                        }
                        // setVar_WalkInbooked(response.data.data)
                    }
                })
                .catch((error) => {
                    setWalBookingBtnState(true);
                    setLoading(false);
                    console.log(error);
                });
        }
    };
    // walk in booking API end

    const isAppointmentCloseModel = () => {
        setVar_ProfileAge("");
        setVar_ProfilePhone("");
        setVar_ProfileName("");
        setVar_gender("Select");
        setVar_WalingNoSlots("0");
        setVar_WalkInGetSlots([]);
        setVar_GetSymptoms("");
        setVar_SlotsLength("");
        setVar_GetServiceListSelect({
            cost: "",
            description: "",
            doctor_id: "",
            file_name: "",
            file_path: "",
            id: 0,
            is_active: "",
            service: "Select Service",
            slot_duration: "",
            spec_id: "",
        });
        setWalkInAppointmentModel(false);
    };

    const isCloseModel = (modelname) => {
        if (modelname == "WalkInModel") {
            setVar_ProfileAge("");
            setVar_ProfilePhone("");
            setVar_ProfileName("");
            setVar_gender("Select");
            setVar_WalingNoSlots("0");
            setVar_WalkInGetSlots([]);
            setVar_GetSymptoms("");
            setVar_SlotsLength("");
            setVar_GetServiceListSelect({
                cost: "",
                description: "",
                doctor_id: "",
                file_name: "",
                file_path: "",
                id: 0,
                is_active: "",
                service: "Select Service",
                slot_duration: "",
                spec_id: "",
            });
        } else if (modelname == "WalkInQrModel") {
            setisOpenWalkInQrModel(false);
            setVar_SuccessModelText("Walk-in Booking added successfully!");
            setIsOpenSuccessModel(!isOpenSuccessModel);
        } else if (modelname == "QrCodeModel") {
            setisOpenQrCodeModel(false);
        }
    };

    const getGender = (e) => {
        setVar_gendervalue(e.target.value);
        setwalgender("");
        if (e.target.value == "M") {
            setVar_gender("Male");
        } else {
            setVar_gender("Female");
        }
    };

    // =============== Get All SlotsService Start ==========================================
    const getSlotsService = () => {
        if (
            Var_GetServiceListSelect_Ref.current.id != "" ||
            Var_GetServiceListSelect_Ref.current.id != 0
        ) {
            setwalservice(false);
        }
        setLoading(true);
        var date = dateRefapi.current;
        var data = {
            search_date: date.toString(),
            clinic_id: localStorage.getItem("clinic_id"),
            doctor_id: localStorage.getItem("doctor_id"),
            service_id: Var_GetServiceListSelect_Ref.current.id,
            type: "",
            nxtdate: "true",
        };
        axios
            .post("doctor/getslotsbyservice", data)
            .then((response) => {
                var check = new Date().toLocaleTimeString("en-US", {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false
                });
                console.log("timingcheck", check)
                {
                    console.log("Sloats", response.data.data[0].date_slots);
                    var filteredData = response.data.data[0].date_slots.filter(
                        (x) => x.appointtype_id == "1" && check < x.from_time
                    );
                    setVar_WalkInGetSlots(filteredData);
                    // setVar_WalkInGetSlots(response.data.data[0].date_slots);
                    setVar_SlotsLength(response.data.data[0].date_slots.length);
                    setLoading(false);
                    if (response.data.data[0].date_slots.length != 0) {
                        setVar_WalingNoSlots("1");
                        setLoading(false);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };
    // =============== Get All Appointment End ==========================================

    const walkinMobileNumbetValidation = (event, type) => {
        debugger;
        console.log(event.target.value, "number");
        const inputText = event.target.value;
        const numericOnly = inputText.replace(/\D/g, "");
        if (type == "PhoneNumber") {
            if (
                numericOnly.toString().length >= 0 &&
                numericOnly.toString().length <= 10
            ) {
                setVar_ProfilePhone(numericOnly);
                setwalmobile("");
            } else {
                setwalmobile(true);
            }
            console.log(walmobile, "walmobile", Var_ProfilePhone_Ref.current);
        } else if (type == "Age") {
            if (
                numericOnly.toString().length >= 0 &&
                numericOnly.toString().length <= 3
            ) {
                setVar_ProfileAge(numericOnly);
                setwalage("");
            } else {
                setwalage(true);
            }
            console.log(walage, "walage", Var_ProfileAge_Ref.current);
        }
    };

    // select slots from time function start
    const selectSlots = (value) => {
        Var_WalkInGetSlots_Ref.current.forEach((element) => {
            element.is_clicked = 0;
        });
        setVar_SelectTimeSlots(value.from_time);
        value.is_clicked = 1;
        setVar_SelectToTimeSlots(value.to_time);
    };
    // select slots from time function end

    // Time conversion start

    const convertTo12HrFormat = (time24) => {
        var timeArr = time24.split(":");
        var hours = parseInt(timeArr[0]);
        var minutes = parseInt(timeArr[1]);
        var suffix = hours >= 12 ? "PM" : "AM";

        hours = hours % 12 || 12;
        var time12 =
            hours + ":" + (minutes < 10 ? "0" + minutes : minutes) + " " + suffix;

        return time12;
    };
    const timeFormat = (time) => {
        let datetime = new Date("1970-01-01T" + time + "Z");
        return datetime.toLocaleTimeString("en-US", {
            timeZone: "UTC",
            hour12: true,
            hour: "numeric",
            minute: "numeric",
        });
    };

    // Time conversion End

    const addPatientModel = () => {
        setVar_WalingNoSlots("0");
        setVar_WalkInGetSlots([]);
        setVar_GetServiceListSelect({
            cost: "",
            description: "",
            doctor_id: "",
            file_name: "",
            file_path: "",
            id: 0,
            is_active: "",
            service: "Select Service",
            slot_duration: "",
            spec_id: "",
        });
        setVar_GetSymptoms("");
        getservice();
        setVar_gender("Select");
        setVar_SelectTimeSlots("");
        setVar_SlotsLength("");
        setwalname("");
        setwalmobile("");
        setwalage("");
        setwalgender("");
        setwalservice("");
        setwalsymptoms("");
        setWalkInAppointmentModel(!WalkInAppointmentModel);
    };

    //get service list API start
    const getservice = () => {
        setLoading(true);
        var data = {
            doctor_id: localStorage.getItem("doctor_id"),
            speciality_id: localStorage.getItem("speciality_Id"),
        };
        axios
            .post("doctor/getDoctorServiceBySpecilaity", data)
            .then((response) => {
                {
                    console.log("service", response.data.data);
                    setVar_GetServiceList(response.data.data);
                    setVar_GetServiceListSelect({
                        cost: "",
                        description: "",
                        doctor_id: "",
                        file_name: "",
                        file_path: "",
                        id: 0,
                        is_active: "",
                        service: "Select Service",
                        slot_duration: "",
                        spec_id: "",
                    });
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };
    //get service list API end
    // ==========================TimePopOver ==================================
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverClick = (value) => {
        setSlotsValueData(value);
        setSlotsValue("00:" + value);
        setSlotsValue_Minus(value);
        handleClose();
    };
    const values = Array.from({ length: 50 }, (_, index) => index + 10);

    const patientDetails = (data) => {
        debugger;
        setVar_NextPatientDetailsData([]);
        setVar_NextPatientDetailsData(data);
        console.log(Var_NextPatientDetailsData_Ref.current, "87654323456");
    };

    // Get Clinic profile api call start
    function getClinicProfile() {
        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("clinic_id"),
        };
        axios
            .post("clinic/getClinicProfile", data)
            .then((response) => {
                setVar_Profiledata(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    // Get Clinic profile api call end

    // ========================================== Get Manage Services Start =========================================
    const getManageServicesList = () => {
        setLoading(true);
        var Var_RequestData = {
            doctor_id: localStorage.getItem("doctor_id"),
        };

        axios({
            method: "POST",
            url: "doctor/getDoctorService",
            data: Var_RequestData,
        })
            .then((response) => {
                if (response.data.status == 1) {
                    setVar_ManageServicesTotalCount(response.data.data.length);
                } else {
                    setVar_ManageServicesTotalCount(0);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };
    // =========================================== Get Manage Services End =================================================

    // ============================ Get Working Hours Api Start==================================
    const getworkingDetails = () => {
        setLoading(true);
        var DoctorServiceBySpecilaity = {
            doctorId: localStorage.getItem("doctor_id"),
            clinicId: localStorage.getItem("clinic_id"),
            limit: 100,
            pageno: 1,
        };
        axios({
            method: "POST",
            url: "doctor/getdocAppointmentSettings",
            data: DoctorServiceBySpecilaity,
        })
            .then((response) => {
                if (response.data.status == 1) {
                    if (response.data.data[0].details.length > 0) {
                        setVar_WorkingHoursTotalCount(response.data.data[0].totalCount);
                    } else {
                        setVar_WorkingHoursTotalCount(0);
                    }
                } else {
                    setVar_WorkingHoursTotalCount(0);
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };
    const clinicTabPermissionById = () => {
        //setLoading(true);
        debugger;
        var data = {
            doctorId: localStorage.getItem("doctor_id"),
            clinicId: localStorage.getItem("clinic_id")
        };
        axios
            .post("doctor/clinicTabPermissionbyId", data)
            .then((response) => {
                setVar_PermissionObject(response.data.data[0]);
                console.log(Var_PermissionObject_Ref.current, "Var_PermissionObject_Ref");
            })
            .catch((error) => {
                // setLoading(false);
                console.log(error);
            });
    }
    // ============================ Get Working Hours Api End==================================

    const [datasss, setDatasss] = useState([
        { month: new Date(2024, 0, 1), value: 10 }, // January
        { month: new Date(2024, 1, 1), value: 20 }, // February
        { month: new Date(2024, 2, 1), value: 40 }, // March
        { month: new Date(2024, 3, 1), value: 55 }, // April
        { month: new Date(2024, 4, 1), value: 70 }, // May
        { month: new Date(2024, 5, 1), value: 67 }, // June
        { month: new Date(2024, 6, 1), value: 70 }, // July
        { month: new Date(2024, 7, 1), value: 53 }, // August
        { month: new Date(2024, 8, 1), value: 10 }, // September
        { month: new Date(2024, 9, 1), value: 20 }, // October
        { month: new Date(2024, 10, 1), value: 0 }, // November
        { month: new Date(2024, 11, 1), value: 0 }, // December
    ]);

    const getYear = (e) => {
        setVar_year(e.target.value);
        getRevenueIncome();
    };

    return (
        <div>
            {loading && (
                <div className="loader_body">
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                </div>
            )}

            {/* ====================Dashboard Module Start=================== */}

            <div className="dashboard_content">
                <div className="dashboard_content_full_row">
                    <p className="dashboard_content_full_text"><b>DATE:</b> {moment(Var_Date_Today).format("DD-MMM-YY")}</p>
                    {Var_Profiledata?.map((item, i) => (
                        <div>
                            <p className="dashboard_content_doctor_name_text">{Var_DashboardContent_Ref.current}, {item.vendor_name}</p>
                            <p className="dashboard_content_name_text">Have a nice day at work it seems like you're <br /> referring to {item.vendor_name}.</p>
                        </div>
                    ))}
                    <img className='Dashboard_Image' src={DashboardImage} alt="Dashboard_Image" />
                    <img className='Dashboard_Doctor_Image' src={DashboardDoctorImage} alt="Dashboard_Doctor_Image" />
                </div>
                <div className="dashboard_content_button_container">
                    <button className="dashboard_content_button" onClick={() => { addPatientModel(); }}>
                        <img className='Dashboard_Add_Appointment_Image' src={DashboardAddAppointmentImage} alt="Dashboard_Add_Appointment_Image" />
                        <span className="dashboard_content_button_text">Add Appointment</span>
                    </button>
                </div>
                <div className="row dashboard_content_menus">
                    <div className="col-3 col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 dashboard_content_menus_card_one">
                        <div className="row dashboard_content_card_one" onClick={() => handleOnClick("appointments")}>
                            <p className="dashboard_content_card_text">APPOINTMENTS</p>
                            <div className="dashboard_content_card_value">
                                <p className="dashboard_content_card_text_appointments">{Var_CheckOutStatus_Ref?.current}/<span className="dashboard_content_card_text_appointments_span">{Var_TotalCount_Ref.current}</span></p>
                                <img className='Dashboard_Appointments_Image' src={DashboardAppointmentsImage} alt="Dashboard_Appointments_Image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-3 col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 dashboard_content_menus_card_two">
                        <div className="row dashboard_content_card_two" onClick={() => handleOnClick("manageservice")}>
                            <p className="dashboard_content_card_text">SERVICES</p>
                            <div className="dashboard_content_card_value">
                                <p className="dashboard_content_card_text_services">{Var_ManageServicesTotalCount_Ref?.current}</p>
                                <img className='Dashboard_Hours_Image' src={DashboardHoursImage} alt="Dashboard_Hours_Image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-3 col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 dashboard_content_menus_card_three">
                        <div className="row dashboard_content_card_three" onClick={() => handleOnClick("workinghours/viewworkinghours")}>
                            <p className="dashboard_content_card_text">WORKING HOURS</p>
                            <div className="dashboard_content_card_value">
                                <p className="dashboard_content_card_text_workinghours">{Var_WorkingHoursTotalCount_Ref?.current}</p>
                                <img className='Dashboard_Services_Image' src={DashboardServicesImage} alt="Dashboard_Services_Image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-3 col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 dashboard_content_menus_card_four">
                        <div className="row dashboard_content_card_four">
                            {/* onClick={() => handleOnClick("revenue")} */}
                            <p className="dashboard_content_card_text">REVENUE</p>
                            <div className="dashboard_content_card_value">
                                <p className="dashboard_content_card_text_revenue">{getTotalRevenue}</p>
                                <img className='Dashboard_Revenue_Image' src={DashboardRevenueImage} alt="Dashboard_Revenue_Image" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row dashboard_content_appointments">
                    <div className="col-6 col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="dashboard_table_card">
                            <div className="dashboard_table_heading_name">
                                <p className="dashboard_table_card_text">UPCOMING APPOINTMENTS</p>
                                <hr className="dashboard_table_card_line" />
                            </div>
                            <div className="row dashboard_table_card_row">
                                <div className="col-2 dashboard_table_card_header">
                                    <h6 className="dashboard_table_card_header_text" style={{ paddingLeft: '10px' }}><span>S.No</span></h6>
                                </div>
                                <div className="col-4 dashboard_table_card_header">
                                    <h6 className="dashboard_table_card_header_text"><span>Name</span></h6>
                                </div>
                                <div className="col-3 dashboard_table_card_header">
                                    <h6 className="dashboard_table_card_header_text"><span>Gender</span></h6>
                                </div>
                                <div className="col-3 dashboard_table_card_header">
                                    <h6 className="dashboard_table_card_header_text"><span>Phone No</span></h6>
                                </div>
                            </div>
                            {Var_UpcomingAppointment_Ref.current?.length != 0 ?
                                <div className="dashboard_upcoming_appointment_data">
                                    {Var_UpcomingAppointment_Ref.current.map((responseData, i) => (
                                        <div className="dashboard_card_content_new" key={i} onClick={() => patientDetails(responseData)}>
                                            <div className={`dashboard_card_content_new ${i % 2 === 0 ? "row" : "row dashboard_content_row_data"}`}>
                                                <div className="col-2">
                                                    <h6 className="dashboard_table_card_header_text" style={{ paddingLeft: '13px' }}>{i + 1}</h6>
                                                </div>
                                                <div className="col-4">
                                                    <h6 className="dashboard_table_card_header_text">{responseData.name}</h6>
                                                </div>
                                                <div className="col-3">
                                                    <h6 className="dashboard_table_card_header_text">{responseData.gender}</h6>
                                                </div>
                                                <div className="col-3">
                                                    <h6 className="dashboard_table_card_header_text">{responseData.mobile_no}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div> :
                                <div className="row">
                                    <p className="text-center no_data_found">No Data Found</p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-6 col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="dashboard_next_patient_card">
                            <div className="dashboard_table_heading_name">
                                <p className="dashboard_table_card_text">NEXT PATIENT DETAILS</p>
                                <hr className="dashboard_table_card_line" />
                            </div>
                            <div className="row dashboard_next_patient_details_row1">
                                <div className="col-3 text-center dashboard_next_patient_details_head_row2">
                                    {(Var_NextPatientDetailsData_Ref.current?.gender == 'Male' || Var_NextPatientDetailsData_Ref.current?.gender == 'male') ? <img className='dashboard_next_patient_image' src={MaleIcon} alt="dashboard_next_patient_image" /> :
                                        (Var_NextPatientDetailsData_Ref.current?.gender == 'Female' || Var_NextPatientDetailsData_Ref.current?.gender == 'female') ? <img className='dashboard_next_patient_image' src={FemaleIcon} alt="dashboard_next_patient_image" /> : <img className='dashboard_next_patient_image' src={VectorIcon} alt="dashboard_next_patient_image" />}
                                </div>
                                <div className="col-3 dashboard_next_patient_details_head_row2">
                                    <p className="dashboard_next_patient_header">Name</p>
                                    <p className="dashboard_next_patient_content">{Var_NextPatientDetailsData_Ref.current?.name}</p>
                                </div>
                                <div className="col-3 dashboard_next_patient_details_head_row2">
                                    <p className="dashboard_next_patient_header">Gender</p>
                                    <p className="dashboard_next_patient_content">{Var_NextPatientDetailsData_Ref.current?.gender}</p>
                                </div>
                                <div className="col-3 dashboard_next_patient_details_head_row2"></div>
                            </div>
                            <div className="row dashboard_next_patient_details_row2">
                                <div className="col-3 dashboard_next_patient_details_head_row3"></div>
                                <div className="col-3 dashboard_next_patient_details_head_row3">
                                    <p className="dashboard_next_patient_header">Patient ID</p>
                                    {(Var_NextPatientDetailsData_Ref.current?.length != 0 && Var_NextPatientDetailsData_Ref.current?.length != undefined && Var_NextPatientDetailsData_Ref.current?.length != null ) ? <p className="dashboard_next_patient_content">ID000{Var_NextPatientDetailsData_Ref.current?.patient_id}</p> : ''}
                                </div>
                                <div className="col-3 dashboard_next_patient_details_head_row3">
                                    <p className="dashboard_next_patient_header">Phone No</p>
                                    <p className="dashboard_next_patient_content">{Var_NextPatientDetailsData_Ref.current?.mobile_no}</p>
                                </div>
                                <div className="col-3 dashboard_next_patient_details_head_row3">
                                    <p className="dashboard_next_patient_header">Age</p>
                                    <p className="dashboard_next_patient_content">{Var_NextPatientDetailsData_Ref.current?.age}</p>
                                    {/* <p className="dashboard_next_patient_content">{Var_NextPatientDetailsData_Ref.current.dob}</p> */}
                                </div>
                            </div>
                            {Var_NextPatientDetailsData_Ref.current?.symptoms != null ? <div className="dashboard_next_patient_details_row3">
                                <div className="dashboard_next_patient_details_head_row4">
                                    <p className="dashboard_next_patient_content_symptoms">{Var_NextPatientDetailsData_Ref.current?.symptoms}</p>
                                </div>
                            </div> : ''}
                        </div>
                    </div>
                </div>
                <div className="row dashboard_chart" style={{ paddingTop: '40px' }}>
                    <div style={{ marginBottom: '63px' }}>
                        <div className="dashboard_chart_data row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-10">
                                        <div style={{ marginLeft: '3%' }}>
                                            <p className="dashboard_chart_header">INCOME</p>
                                            <hr className="dashboard_table_card_line2" />
                                            <p className="dashboard_chart_content_value">₹{getTotalRevenue}</p>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div style={{ marginTop: '10%' }}>
                                            <FormControl sx={{ m: "8px 0px" }} size="small">
                                                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={Var_year}
                                                    renderValue={(p) => p} onChange={(e) => getYear(e)}>
                                                    <MenuItem value={2021}>2021</MenuItem>
                                                    <MenuItem value={2022}>2022</MenuItem>
                                                    <MenuItem value={2023}>2023</MenuItem>
                                                    <MenuItem value={2024}>2024</MenuItem>
                                                    <MenuItem value={2025}>2025</MenuItem>
                                                    <MenuItem value={2026}>2026</MenuItem>
                                                    <MenuItem value={2027}>2027</MenuItem>
                                                    <MenuItem value={2028}>2028</MenuItem>
                                                    <MenuItem value={2029}>2029</MenuItem>
                                                    <MenuItem value={2030}>2030</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <MonthlyChart data={revenueIncome} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ====================Dashboard Module End=================== */}

            {/* =============================== Add Appointment Model Start ========================= */}
            <Dialog
                fullWidth={fullWidth}
                alignment="center"
                size="md"
                open={WalkInAppointmentModel}
                onClose={setWalkInAppointmentModel}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "850px",
                            borderRadius: "8px !important",
                            padding: "10px 0px 30px",
                        },
                    },
                }}
            >
                <div className="dashboard_dialog_header">
                    {/* <div className="dialog_title"> WALK-IN BOOKING</div> */}
                    <div className="flex_grow"></div>
                    <div onClick={() => isAppointmentCloseModel()}>
                        <img style={{ cursor: "pointer" }} src={closeicon} />
                    </div>
                </div>
                <DialogContent>
                    <Container>
                        <div className="row">
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_left_part">
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Patient Name
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_ProfileName}
                                        type="text"
                                        placeholder="Enter full name"
                                        onChange={(event) => setVar_ProfileName(event.target.value)}
                                    />
                                </div>
                                {Var_ProfileName_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walname == true && (
                                            <div item xs={12} className="field_required pb-1 pt-0">
                                                Field Required
                                            </div>
                                        )}
                                    </>
                                )}
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Age<span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_ProfileAge}
                                        type="number"
                                        placeholder="Enter age"
                                        maxLength="3"
                                        onChange={(e) => walkinMobileNumbetValidation(e, "Age")}
                                    />
                                </div>
                                {Var_ProfileAge_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walage == true && (
                                            <div className="field_required">Field Required</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_right_part">
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Gender
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <FormControl
                                        sx={{ m: "8px 0px", minWidth: 353 }}
                                        size="small"
                                    >
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={Var_gender}
                                            renderValue={(p) => p}
                                            onChange={(e) => getGender(e)}
                                        >
                                            <MenuItem value={"M"}>Male</MenuItem>
                                            <MenuItem value={"F"}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                {walgender == true && (
                                    <div className="field_required">Field Required</div>
                                )}
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Phone Number
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_ProfilePhone}
                                        type="number"
                                        placeholder="Enter number"
                                        maxLength="10"
                                        onChange={(e) =>
                                            walkinMobileNumbetValidation(e, "PhoneNumber")
                                        }
                                    />
                                </div>
                                {Var_ProfilePhone_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walmobile == true && (
                                            <div className="field_required">Field Required</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 walk_model_service_part">
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Symptoms
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_GetSymptoms}
                                        type="text"
                                        placeholder="Enter symptoms"
                                        onChange={(event) => setVar_GetSymptoms(event.target.value)}
                                    />
                                </div>
                                {Var_GetSymptoms_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walsymptoms == true && (
                                            <div item xs={12} className="field_required pb-1 pt-0">
                                                Field Required
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 walk_model_service_part">
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Select Service
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>

                                <div className="dropdown_control">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                sx={{
                                                    padding: "8.5px 14px",
                                                    height: "40px",
                                                    minHeight: 15,
                                                    minWidth: "-webkit-fill-available",
                                                }}
                                                value={Var_GetServiceListSelect}
                                                onChange={(e) => {
                                                    setVar_GetServiceListSelect(e.target.value);
                                                    getSlotsService();
                                                    setVar_SelectTimeSlots("");
                                                }}
                                                renderValue={(Var_GetServiceListSelect) => {
                                                    return Var_GetServiceListSelect.service;
                                                }}
                                            >
                                                <MenuItem
                                                    key={0}
                                                    value={{
                                                        cost: "",
                                                        description: "",
                                                        doctor_id: "",
                                                        file_name: "",
                                                        file_path: "",
                                                        id: 0,
                                                        is_active: "",
                                                        service: "Select Service",
                                                        slot_duration: "",
                                                        spec_id: "",
                                                    }}
                                                ></MenuItem>

                                                {Var_GetServiceList.map((item) => (
                                                    <MenuItem key={item.service} value={item}>
                                                        {item.is_active == 1 && (
                                                            <Container>
                                                                <Card className="dropdown_card">
                                                                    <Grid container>
                                                                        <Grid item xs={1}></Grid>
                                                                        <Grid item xs={1} align="start">
                                                                            <img
                                                                                src={
                                                                                    item.file_path == " "
                                                                                        ? VectorIcon
                                                                                        : item.file_path ==
                                                                                            "[object Object]"
                                                                                            ? VectorIcon
                                                                                            : item.file_path == ""
                                                                                                ? VectorIcon
                                                                                                : item.file_path
                                                                                }
                                                                                alt="a"
                                                                                className="dropdownimg"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={1}></Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={9}
                                                                            className="ref_lab"
                                                                            align="start"
                                                                        >
                                                                            <p className="dropdowntext">
                                                                                {item.service}
                                                                            </p>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Card>
                                                            </Container>
                                                        )}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    {walservice == true && (
                                        <Grid item xs={12} className="field_required">
                                            Field Required
                                        </Grid>
                                    )}
                                </div>
                                {Var_WalingNoSlots_Ref.current == "1" &&
                                    Var_WalkInGetSlots.length == 0 && (
                                        <Grid container className="modal_slots_time">
                                            <Grid item>
                                                <p className="dashboard_warning_helper_txt">
                                                    Slots Not Available
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )}
                                {Var_WalkInGetSlots.length != 0 && (
                                    <>
                                        <Grid container className="modal_slots_time">
                                            <Grid item>
                                                <p className="dashboard_helper_txt">Slots</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ justifyContent: "space-evenly" }}
                                            >
                                                <Grid container className="dashboard_total_slots_top">
                                                    {Var_WalkInGetSlots?.map((value) => (
                                                        <Grid item xs={2}>
                                                            {(value.date >=
                                                                moment(new Date()).format("YYYY-MM-DD") ==
                                                                true ||
                                                                value.from_time >=
                                                                new Date().getHours() +
                                                                ":" +
                                                                new Date().getMinutes() +
                                                                ":" +
                                                                new Date().getSeconds()) && (
                                                                    <>
                                                                        <div className="dashboard_time_sloat">
                                                                            <p
                                                                                className={`dashboard_time_show ${value.is_booked != 0
                                                                                    ? "dashboard_time_disabled"
                                                                                    : ""
                                                                                    }`}
                                                                                onClick={() => {
                                                                                    if (value.is_booked == 0) {
                                                                                        selectSlots(value);
                                                                                    }
                                                                                }}
                                                                                disabled={
                                                                                    value.is_booked == 1 ? true : false
                                                                                }
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        value.is_booked == 0
                                                                                            ? value.is_clicked == 1
                                                                                                ? "var(--app-theme)"
                                                                                                : "#2A7A6F40"
                                                                                            : "#FBEAE5",
                                                                                    color:
                                                                                        value.is_booked == 0
                                                                                            ? value.is_clicked == 1
                                                                                                ? "white"
                                                                                                : "var(--app-theme) "
                                                                                            : "#E12F2F",
                                                                                    border:
                                                                                        value.is_booked == 0
                                                                                            ? "1px solid var(--app-theme) "
                                                                                            : "1px solid #E12F2F",
                                                                                }}
                                                                            >
                                                                                {timeFormat(value.from_time)}
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                )}
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                <div>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="dashboard_select_finder">
                                                <div className="dashboard_select_icon">
                                                    <div className="dashboard_check_box_booked" />
                                                    <p className="booked">Booked</p>
                                                </div>
                                                <div className="dashboard_select_icon">
                                                    <div className="dashboard_check_box_available" />
                                                    <p className="available">Available</p>
                                                </div>
                                                <div className="dashboard_select_icon">
                                                    <div className="dashboard_check_box_selected" />
                                                    <p className="selected">Selected</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                        <div className="walk_appointment_booking_button">
                            <button
                                className="walk_appointment_cancel_button"
                                onClick={() => isCloseModel("WalkInModel")}
                            >
                                Clear
                            </button>
                            <button
                                className="walk_appointment_book_button"
                                onClick={
                                    walBookingBtnStateRef.current
                                        ? () => bookedSuccessfully()
                                        : undefined
                                }
                            >
                                Add Appointment
                            </button>
                        </div>
                    </Container>
                </DialogContent>
            </Dialog>
            {/* =============================== Add Appointment Model End ========================= */}

            {/* ================= Add Appointment Success Model Start =============== */}
            {isshowsuccess && (
                <div className="popup-main">
                    <div className="pushNotification_body">
                        <div className="pushNotification_content">
                            <p className="notification_text_head">
                                <b>{Var_AddAppointmentMsg_Ref.current}</b>
                            </p>
                        </div>
                        <div className="progress-prt">
                            <div className="progress-bar"></div>
                        </div>
                    </div>
                </div>
            )}
            {/* =================  Add Appointment Success Model End  ================= */}

            {/* ====================Dashboard Module Image Start=================== */}
            {/* <div className='main_card_image'>
                <Grid container className='first_row_card_image'>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem'>
                            <div className="d-flex justify-content-center">
                                <div className="image_div" onClick={() => handleOnClick("appointments")}>
                                    <img className='card_image ' src={Appoinmentsimg} alt="Appoinmentsimg" />
                                </div>
                            </div>
                            <p className='home_card_title'>Appointments</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem'>
                            <div className="d-flex justify-content-center">
                                <div className="image_div" onClick={() => handleOnClick("queue")}>
                                    <img className='card_image' src={Queueimg} alt="Queueimg" />
                                </div>
                            </div>
                            <p className='home_card_title'>Queue</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem'>

                            <div className="d-flex justify-content-center">
                                <div className="image_div" onClick={() => handleOnClick("availability")}>
                                    <img className='card_image' src={Availabilityimg} alt="Availabilityimg" />
                                </div>
                            </div>
                            <p className='home_card_title'>Manage Schedule</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem'>
                            <div className="d-flex justify-content-center">
                                <div className="image_div" onClick={() => handleOnClick("workinghours")}>
                                    <img className='card_image' src={Workinghoursimg} alt="Workinghoursimg" />
                                </div>
                            </div>
                            <p className='home_card_title'>Working Hours</p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className='first_row_div_image'>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem'>
                            <div className="d-flex justify-content-center">
                                <div className="image_div">
                                    <img className='card_image card_image_Walkin' src={Walkinimg} alt="Walkinimg" onClick={() => handleOnClick("referral")} />
                                </div>
                            </div>
                            <p className='home_card_title'>Referral</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem' >
                            <div className="d-flex justify-content-center">
                                <div className="image_div" onClick={() => handleOnClick("cancelledappointments")}>
                                    <img className='card_image' src={Cancelledimg} alt="Cancelledimg" />
                                </div>
                            </div>
                            <p className='home_card_title'>Canceled</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem'>
                            <div className="d-flex justify-content-center">
                                <div className="image_div" onClick={() => handleOnClick("post")}>
                                    <img className='card_image' src={Postimg} alt="Postimg" />
                                </div>
                            </div>
                            <p className='home_card_title'>Post</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem'>
                            <div className="d-flex justify-content-center">
                                <div className="image_div" onClick={() => handleOnClick("manageservice")}>
                                    <img className='card_image' src={Manageserviceimg} alt="Manageserviceimg" />
                                </div>
                            </div>
                            <p className='home_card_title'>Manage Services</p>
                        </div>
                    </Grid>
                </Grid>
            </div> */}
            {/* ====================Dashboard Module Image End=================== */}
        </div>
    );
}

export default DashboardMasterComponent;
