import Axios from "axios";
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import React, { useState, useEffect } from "react";

// Mui material component import
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Datedata from "../Revenue_settle/mothpicker/Datedata";

import "./ManageServiceMaster.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderMasterComponent from "../Header/HeaderMaster";
import FooterMaster from "../Dashboard/FooterMaster";

import Success from "../../assets/Doctor_web_Icon/success.svg";
import deleteIcon from "../../assets/clinicIcon/deleteiconh.svg";
import editIcon from "../../assets/clinicIcon/editiconh.svg";
import Alert from "../../assets/Doctor_Images/Delete white Icon.svg";
import vector from "../../assets/Doctor_web_Icon/Vector.png";
import closeicon from "../../assets/icon/Icon ionic-ios-close.png";
import BackIcon from "../../assets/Doctor_Images/Group 31.svg";
import alertimg from "../../assets/Doctor_Images/Delete white Icon.svg";

function ManageServiceComponent() {
  let Var_History = useHistory();

  const [Var_ManageServicesdata, setVar_ManageServicesdata] = useStateRef([]);
  const [, setVar_PopupImg, setVar_PopupImg_Ref] = useStateRef("");
  const [, setVar_Popup_content, Var_Popup_content_Ref] = useStateRef("");
  const [, setVar_Popup_Message, Var_Popup_Message_Ref] = useStateRef("");
  const [loading, setLoading] = useState(true);
  const [, setVar_ManageServiceDeleteId, Var_ManageServiceDeleteId_Ref] =
    useStateRef("");
  const [, setVarimagepopup, Varimagepopup_Ref] = useStateRef("");
  const [, setVar_Checked] = useState(false);

  const [
    Var_IsShowDeleteManageServiceModel,
    setVar_IsShowDeleteManageServiceModel,
  ] = useStateRef(false);
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = useState(false);
  const [Var_IsShowSuccessnew, setVar_IsShowSuccessnew] = useState(false);
  const [, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] =
    useStateRef(null);
  const [
    Var_DoctorSpeciality,
    setVar_DoctorSpeciality,
    Var_DoctorSpeciality_Ref,
  ] = useStateRef(null);
  const [isOpenimageModel, setIsOpenimageModel] = React.useState(false);
  const [Var_content_head, setVar_content_head, Var_content_head_Ref] =
    useStateRef("");
  const [verification, setVerification] = useState(
    Var_History.location.state
      ? Var_History.location.state.verification
        ? Var_History.location.state.verification
        : false
      : false
  );
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [Var_msgContent, setVar_msgContent, Var_msgContent_Ref] =
    useStateRef("");
  const [isshowalert, setisshowalert] = useState(false);
  const [isshowsuccess, setishiddensuccess] = useState(false);

  // Calculate the number of pages
  const totalPages = Math.ceil(Var_ManageServicesdata.length / itemsPerPage);

  const currentData = Var_ManageServicesdata.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Change the page
  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  // initial function call start
  useEffect(() => {
    getClinicWiseDoctor();

    getManageServicesList();
  }, []);
  // initial function call end

  // navigattion to back start
  const navigateToBack = (path) => {
    debugger;
    console.log(verification);
    Var_History.push("/dashboard" + path);
  };
  // navigattion to back end

  // ========================================== Get Manage Services List Start =========================================
  const getManageServicesList = () => {
    setLoading(true);
    var Var_RequestData = {
      doctor_id: localStorage.getItem("doctor_id"),
    };

    Axios({
      method: "POST",
      url: "doctor/getDoctorService",
      data: Var_RequestData,
    })
      .then((response) => {
        setVar_ManageServicesdata(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // =========================================== Get Manage Services List End =================================================

  const isOpenAddDeal = (value) => {
    setVarimagepopup(value);
    setIsOpenimageModel(true);
  };

  // ====================================== Add ManageService Start  ====================================
  const addService = () => {
    navigateToBack("/manageservice/addservice");
  };
  // ====================================== Add ManageService End ====================================

  // ====================================== EditManageServiceStart ====================================
  const editManageService = (data, name, bname) => {
    navigateToBack("/manageservice/editservice/" + data.id);
  };
  // ====================================== EditManageServiceEnd ====================================

  // const isCloseimageModel = () => {
  //   setPopupVisible(false);
  // }

  const isCloseimageModel = () => {
    setIsOpenimageModel(false);
  };

  //  =============================== Delete Manage Service  start ======================================
  const deleteManageServiceList = () => {
    setLoading(true);
    setisshowalert(!isshowalert);

    // setVar_IsShowDeleteManageServiceModel(!Var_IsShowDeleteManageServiceModel);

    var Var_RequestData = {
      id: Var_ManageServiceDeleteId_Ref.current,
    };

    Axios({
      method: "DELETE",
      url: "doctor/delete_mas_doctor_service_type",
      data: Var_RequestData,
    })
      .then((response) => {
        debugger;
        if (response.data.status == 1) {
          // setVar_content_head("success_body_size");
          // setVar_PopupImg(Success);
          // setVar_Popup_content("SUCCESS!");
          // setVar_Popup_Message("Manage service deleted successfully!");
          // setishiddensuccess(true);
          //       setVar_msgContent("Manage service deleted successfully!");

          setVar_msgContent("Manage service deleted successfully!");
          setVar_IsShowSuccessnew(true);
          setTimeout(() => {
            setVar_IsShowSuccessnew(false);
          }, 1500);
          deleteServiceModels();
          getManageServicesList();
          setLoading(false);
        } else {
          // notification.error({ message: response.data.msg });
          // setVar_PopupImg(Alert);
          // setVar_content_head("success_body_size_alert");
          // setVar_Popup_content("FAILED!");
          // setVar_Popup_Message(response.data.msg + "!");
          // setishiddensuccess(true);
          //       setVar_msgContent({message : response.data.msg });

          setVar_msgContent(response.data.msg);
          setVar_IsShowSuccessnew(true);
          setTimeout(() => {
            setVar_IsShowSuccessnew(false);
          }, 1500);
          deleteServiceModels();
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // ================================== Delete Manage Service End ===============================

  const deleteManageService = (data) => {
    // setVar_IsShowDeleteManageServiceModel(!Var_IsShowDeleteManageServiceModel);
    setisshowalert(!isshowalert);

    setVar_ManageServiceDeleteId(data);
  };
  const deleteServiceModels = () => {
    setVar_IsShowSuccessnew(!Var_IsShowSuccess);
    getManageServicesList();
  };
  const oKModalClose = () => {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
    Var_History.push("/dashboard/manageservice");
  };

  const getClinicWiseDoctor = () => {
    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("clinic_id"),
    };
    Axios.post("clinic/getClinicWiseDoctor", data)
      .then((response) => {
        var filteredData = response.data.data.filter(
          (x) =>
            x.specialityid == localStorage.getItem("speciality_Id") &&
            x.doctor_id == localStorage.getItem("doctor_id")
        );
        setVar_DoctorDetailShow(
          filteredData[0].doctorName +
            "," +
            " " +
            filteredData[0].specialityname
        );
        setVar_DoctorSpeciality(filteredData[0].specialityname);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div>
      {loading && (
        <div className="loader_body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      )}

      <div>
        <HeaderMasterComponent />
      </div>
      {/* ======================= SubHeader Start ======================================= */}
      <div className="Navication-header">
        <div>
          <img src={BackIcon} onClick={() => navigateToBack("/")} />
          <label onClick={() => navigateToBack("/")}>Manage Services</label>
        </div>
      </div>
      {/* <div className="sub_header">
        <div className="sub_header_body">
          <div className="back_navigation" onClick={() => navigateToBack("/")}>
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span>MANAGE SERVICES</span>
          </div>
          <div className="flex_grow d-flex justify-content-center">
          </div>
          <div className="header_right_content">
            <div className="page_head_doctor_container">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  SelectProps={{
                    renderValue: (p) => p
                  }}
                  value={Var_DoctorDetailShow_Ref.current}
                  readOnly={true}
                  renderValue={(p) => p}

                >
                </Select>
              </FormControl>
            </div>
            <div className="page_head_date_container">
              <button className="add_btn" onClick={() => addService()}>
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                <span>Add Service</span>
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* ======================= SubHeader End ======================================= */}

      {/* ======================= ManageService body  Content  Start ======================================= */}
      <div className="content_view_manage_service">
        <div className="add-main">
          <div
            className="add-hours-prt"
            onClick={() => navigateToBack("/manageservice/addservice")}
          >
            <div className="add-hours-cld">
              <label className="add-icon-label">+</label>
            </div>
          </div>
        </div>
        {/* <Grid container className="content_row_manage">
          <Grid sm={12} md={3} lg={2} align="start" className="manage_service_data">
            <h6 className="profile_head_service" ><b>Service Title</b></h6>
          </Grid>
          <Grid sm={12} md={2} lg={3} className="manage_service_data" >
            <h6 className="profile_head_service"><b>Service Description</b></h6>
          </Grid>
          <Grid sm={12} md={2} lg={1.5} className="manage_service_data" >
            <h6 className="profile_head_service "><b>Duration <span className="fontSize10">(mins)</span></b></h6>
          </Grid>
          <Grid sm={12} md={2} lg={1.5} className="manage_service_data">
            <h6 className="profile_head_service " ><b>Fee <span className="fontSize10">(KWD)</span></b></h6>
          </Grid>
          <Grid sm={12} md={1} lg={1} className="manage_service_data" align="center">
            <h6 className="profile_head_service Durationslights" ><b>Thumbnail</b></h6>
          </Grid>
          <Grid sm={12} md={2} lg={2} align="center">
            <h6 className="profile_head_service">
              <b>Action</b>
            </h6>
          </Grid>
          <Grid sm={12} md={2} lg={1} className="manage_service_data" align="start" style={{ paddingLeft: '10px' }}>
            <h6 className="profile_head_service">
              <b>Status</b>
            </h6>
          </Grid>
        </Grid>

        <div style={{ marginBottom: "50px" }}>
          {Var_ManageServicesdata.map((responseData, i) => (

            <div className="card_content" key={i}>
              <Grid container className="content_row_manage">
                <Grid sm={12} md={3} lg={2} align="start" className="manage_service_data">
                  <h6 className="profile_head_service" >{responseData.service}</h6>
                </Grid>
                <Grid sm={12} md={2} lg={3} className="manage_service_data" >
                  <h6 className="profile_head_service" style={{ marginRight: "30px" }}>{responseData.description}</h6>
                </Grid>
                <Grid sm={12} md={2} lg={1.5} className="manage_service_data" >
                  <h6 className="profile_head_service Durationslight">{responseData.slot_duration}</h6>
                </Grid>
                <Grid sm={12} md={2} lg={1.5} className="manage_service_data">
                  <h6 className="profile_head_service Durationslight">{Datedata.formatMoney(responseData.cost)}</h6>
                </Grid>
                <Grid sm={12} md={1} lg={1} align="center">
                  <img src={responseData.file_path != "" ? responseData.file_path : vector} className="card_row_img_manage" onClick={() => isOpenAddDeal(responseData.file_path)} alt="" />
                </Grid>
                <Grid sm={12} md={2} lg={2} className="manage_service_active">
                  <div>
                    <img src={editIcon} className="edit_icon_service" onClick={() => editManageService(responseData, "Edit", "Update")} alt=""></img>
                    <img src={deleteIcon} className="edit_icon_service" onClick={() => deleteManageService(responseData.id)}alt="" />
                  </div>
                </Grid>
                <Grid sm={12} md={2} lg={1} className="manage_service_data">
                  <span className="manage_active">{responseData.is_active == 1 ? "Active" : "In-Active"}</span>
                </Grid>
              </Grid>
            </div>
          ))}
        </div> */}

        <Grid container className="content_row_manage_new">
          <Grid
            sm={12}
            md={3}
            lg={2}
            align="start"
            className="manage_service_data"
          >
            <h6 className="profile_head_service">
              <b>Service Title</b>
            </h6>
          </Grid>
          <Grid sm={12} md={2} lg={3} className="manage_service_data">
            <h6 className="profile_head_service">
              <b>Service Description</b>
            </h6>
          </Grid>
          <Grid sm={12} md={2} lg={2} className="manage_service_data">
            <h6 className="profile_head_service ">
              <b>
                Duration <span className="fontSize10">(mins)</span>
              </b>
            </h6>
          </Grid>
          <Grid sm={12} md={2} lg={2} className="manage_service_data" style={{paddingLeft:"2%"}}>
            <h6 className="profile_head_service ">
              <b>
                Fee <span className="fontSize10"></span>
              </b>
            </h6>
          </Grid>
          <Grid sm={12} md={2} lg={2} align="center">
            <h6 className="profile_head_service">
              <b>Action</b>
            </h6>
          </Grid>
          <Grid
            sm={12}
            md={2}
            lg={1}
            className="manage_service_data"
            align="start"
            style={{ paddingLeft: "10px" }}
          >
            <h6 className="profile_head_service">
              <b>Status</b>
            </h6>
          </Grid>
        </Grid>

        <div className="card_details_service">
          {currentData.map((responseData, i) => (
            <div className="card_content_new" key={i}>
              <Grid
                container
                className={`card_content_new ${
                  i % 2 === 0 ? "content_row_manage" : "content_row_manage_data"
                }`}
              >
                <Grid
                  sm={12}
                  md={3}
                  lg={2}
                  align="start"
                  className="manage_service_data"
                >
                  <h6 className="profile_head_service">
                    {responseData.service}
                  </h6>
                </Grid>
                <Grid sm={12} md={2} lg={3} className="manage_service_data">
                  <h6
                    className="profile_head_service"
                    style={{ marginRight: "30px" }}
                  >
                    {responseData.description}
                  </h6>
                </Grid>
                <Grid sm={12} md={2} lg={2} className="manage_service_data">
                  <h6 className="profile_head_service Durationslight">
                    {responseData.slot_duration}
                  </h6>
                </Grid>
                <Grid sm={12} md={2} lg={2} className="manage_service_data">
                  <h6 className="profile_head_service Durationslight">
                    {Datedata.formatMoney(responseData.cost)}
                  </h6>
                </Grid>
                <Grid sm={12} md={2} lg={2} className="manage_service_active">
                  <div>
                    <img
                      src={editIcon}
                      className="edit_icon_service"
                      onClick={() =>
                        editManageService(responseData, "Edit", "Update")
                      }
                      alt=""
                    />
                    <img
                      src={deleteIcon}
                      className="edit_icon_service"
                      onClick={() => deleteManageService(responseData.id)}
                      alt=""
                    />
                  </div>
                </Grid>
                <Grid sm={12} md={2} lg={1} className="manage_service_data">
                  <span className="manage_active">
                    {responseData.is_active == 1 ? "Active" : "In-Active"}
                  </span>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
        {/* Pagination Controls */}
        <Grid>
          <nav aria-label="Page navigation" className="pagination_list_show">
            <ul className="pagination page_list_pagination">
              <li
                style={{ cursor: "pointer" }}
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link row_page_details"
                  onClick={() => goToPage(currentPage - 1)}
                >
                  {"<"}
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  style={{ cursor: "pointer" }}
                  key={index}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link row_page_details"
                    onClick={() => goToPage(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                style={{ cursor: "pointer" }}
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link row_page_details"
                  onClick={() => goToPage(currentPage + 1)}
                >
                  {">"}
                </button>
              </li>
            </ul>
          </nav>
        </Grid>
      </div>

      {/* =======================ManageService body  Content  End======================================= */}
      <div>
        {/* ======================Delete Post Confirm Model Start======================================= */}

        <Dialog
          // fullWidth={fullWidth}
          open={isshowalert}
          onClose={setisshowalert}
          className="confirm_model"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="confirmation_model_body">
              <div className="image_division">
                <img className="image_icon" src={alertimg} />
              </div>
              <div className="confirm_header">Are you sure?</div>
              <div className="confirm_content">
                You want to delete this working hours.
              </div>
              <div className="confirm_btn">
                <button
                  className="confirm_no_btn "
                  onClick={() => deleteManageService()}
                >
                  No, Cancel{" "}
                </button>
                <button
                  className="confirm_yes_btn "
                  onClick={() => deleteManageServiceList()}
                >
                  Yes, Delete
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          className="delete_model"
          open={Var_IsShowDeleteManageServiceModel}
          onClose={setVar_IsShowDeleteManageServiceModel}
        >
          <DialogContent sx={{ width: "300px" }}>
            <div>
              <Grid container>
                <Grid item xs={12} align="center">
                  <img alt="" className="image_body_sizes" src={Alert} />
                </Grid>
                <Grid item xs={12} align="center">
                  <p className="success_body_size_alert"> ALERT!</p>
                </Grid>
                <Grid item xs={12} align="center">
                  <p className="success_msg">
                    {" "}
                    Do you want to delete this service?
                  </p>
                </Grid>
                <Grid item xs={12} align="center">
                  <Button
                    className="alert_no_btn"
                    onClick={() => deleteManageService()}
                  >
                    No
                  </Button>
                  <Button
                    className="alert_yes_btn"
                    onClick={() => deleteManageServiceList()}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
        {/* ====================== Delete Post Confirm Model End======================================= */}

        {/* ======================DeleteModealSuccess start ==================================== */}
        <Dialog
          className="delete_model"
          open={Var_IsShowSuccess}
          onClose={setVar_IsShowSuccess}
        >
          <DialogContent sx={{ width: "300px" }}>
            <div>
              <Grid container>
                <Grid item xs={12} align="center" className="success_card">
                  <img
                    className="image_body_sizes"
                    src={setVar_PopupImg_Ref.current}
                    alt=""
                  />
                </Grid>
                <Grid item xs={12} align="center" className="success_card">
                  <p className={Var_content_head_Ref.current}>
                    {Var_Popup_content_Ref.current}
                  </p>
                </Grid>
                <Grid item xs={12} align="center" className="success_card">
                  <p className="success_msg">{Var_Popup_Message_Ref.current}</p>
                </Grid>
                <Grid item xs={12} align="center" className="success_card">
                  <Button
                    className="success_ok_btn"
                    onClick={() => oKModalClose()}
                  >
                    Ok
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
        {/* ======================DeleteModealSuccess end ==================================== */}

        {/* =====================hgfdsa========================= */}

        <Dialog
          className="delete_model"
          open={isOpenimageModel}
          onClose={isCloseimageModel}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "340px",
                borderRadius: "20px",
                height: "51%",
              },
            },
          }}
        >
          <div className="dialog_header">
            <div className="dialog_title">VIEW DOCUMENT</div>
            <div className="flex_grow"></div>
            <div onClick={() => isCloseimageModel()}>
              <img src={closeicon} alt="" />
            </div>
          </div>
          <DialogContent className="view_post_cls">
            <img
              className="image_content_manage_servive"
              src={
                Varimagepopup_Ref.current !== ""
                  ? Varimagepopup_Ref.current
                  : vector
              }
              alt=""
            ></img>
          </DialogContent>
        </Dialog>

        {/* ================================jhgfds=========================== */}
      </div>
      {Var_IsShowSuccessnew && (
        <div className="popup-main">
          <div className="pushNotification_body">
            <div className="pushNotification_content">
              <p className="notification_text_head">
                <b>{Var_msgContent}</b>
              </p>
            </div>
            <div className="progress-prt">
              <div className="progress-bar"></div>
            </div>
          </div>
        </div>
      )}
      {/* <Modalpage
        open={modalT}
        setModal={() => setModal(false)}
        data={modeldata}
        type={modalType}
      /> */}
    </div>
  );
}
export default ManageServiceComponent;
