import React, { useEffect } from "react";
import { create } from "@amcharts/amcharts4/core"; // Import core
import * as am4charts from "@amcharts/amcharts4/charts"; // Import everything from am4charts (for LineSeries, etc.)
import * as am4core from "@amcharts/amcharts4/core"; // You also need to import am4core for other utilities like color, etc.

const MonthlyChart = ({ data }) => {
  useEffect(() => {
    const chart = create("chartdiv", am4charts.XYChart);
    chart.data = data;
    chart.logo.disabled = true;
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dataFields.date = "month"; // This refers to the `month` field in the data
    dateAxis.renderer.minGridDistance = 50;

    // Disable grid lines
    dateAxis.renderer.grid.template.disabled = true;

    // Format the date labels to only show month names
    dateAxis.dateFormats.setKey("month", "MMM"); // Use "MMM" to display abbreviated month (e.g., "JAN", "FEB")
    dateAxis.periodChangeDateFormats.setKey("month", "MMM");

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Value";
    valueAxis.min = 0;
    valueAxis.max = 70000; // Adjust as needed
    valueAxis.strictMinMax = true;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "month";
    series.name = "Value";
    series.strokeWidth = 3;
    series.stroke = am4core.color("#2F887C");
    series.tooltipText = "{dateX.formatDate('MM')}:\n[bold]{valueY}[/]";
    series.tooltip.pointerOrientation = "vertical";

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.fill = am4core.color("#2F887C");
    bullet.circle.strokeWidth = 2;
    bullet.circle.stroke = am4core.color("#FFFFFF");

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id="chartdiv" style={{ width: "100%", height: "336px" }}></div>;
};

export default MonthlyChart;