import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import { notification } from "antd";

// Mui material imports

import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import "./AddService.css";
import "bootstrap/dist/css/bootstrap.min.css";
import IOSSwitch from "../../helpers/Switch/Switch";
import FooterMaster from "../Dashboard/FooterMaster";
import LinearProgress from "@mui/material/LinearProgress";
import HeaderMasterComponent from "../Header/HeaderMaster";

import uploadimage from "../../assets/icon/uploading-archive-1.svg";
import successModelTickImg from "../../assets/Doctor_web_Icon/success.svg";
import alterImage from "../../../src/images/Vector.png";
import BackIcon from "../../assets/Doctor_Images/Group 31.svg";

function ManageAddServiceComponent() {
  let Var_History = useHistory();

  const [Var_SpecialityValueId, setVar_SpecialityValueId] = useStateRef("");

  const [Var_ServiceValue, setVar_ServiceValue, Var_ServiceValue_Ref] =
    useStateRef("");
  const [Var_FeeKWDValue, setFeeKWDValue, Var_FeeKWDValue_Ref] =
    useStateRef("");
  const [Var_SlotsValue, setSlotsValue] = useStateRef("");
  const [Var_SlotsValue_Minus, setSlotsValue_Minus] = useStateRef("");
  const [, setSlotsValueData, Var_SlotsValueData_Ref] = useStateRef("");
  const [Var_DescriptionValue, setDescriptionValue, Var_DescriptionValue_Ref] =
    useStateRef("");
  const [, setServiceImage, Var_ServiceImage_Ref] = useStateRef("");
  const [Var_ServiceImageName, setServiceImageName, Var_ServiceImageName_Ref] =
    useStateRef("Upload Image");
  const [, setServiceActiveSwitchValue, Var_ServiceActiveSwitchValue_Ref] =
    useStateRef(true);
  const [, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] =
    useStateRef(null);
  const [, setVar_DoctorSpeciality, Var_DoctorSpeciality_Ref] =
    useStateRef(null);
  const [loading, setLoading] = useState(true);
  const [Var_fileprocess, setVar_fileprocess] = React.useState(false);
  const [Var_progress, setVar_Progress] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [isOpenServiceSuccessModel, setisOpenServiceSuccessModel] =
    useState(false);
  const [isDisable, setisisDisable] = useState(false);
  // Check for 3 step verification
  const [verification, setVerification] = useState(
    Var_History.location.state
      ? Var_History.location.state.verification
        ? Var_History.location.state.verification
        : false
      : false
  );
  const [Var_IsShowSuccessnew, setVar_IsShowSuccessnew] = useState(false);
  const [Var_msgContent, setVar_msgContent, Var_msgContent_Ref] =
    useStateRef("");

  // ======================== Edit Service Values start==========================

  // initial function call start
  useEffect(() => {
    getSpeciality();
    getClinicWiseDoctor();
    setServiceActiveSwitchValue(true);
    setVar_fileprocess(false);
    setisisDisable(false);
  }, []);
  // initial function call End

  // navigattion to back start
  const navigateToBack = (path) => {
    debugger;
    console.log(verification);
    Var_History.push({
      pathname: "/dashboard" + path,
      state: {
        // location state
        verification: verification,
      },
    });
  };
  // navigattion to back end

  // navigattion to back start
  const handleOnClick = (path) => {
    Var_History.push("/dashboard" + path);
  };
  // navigattion to back End
  // get speciality api call start
  const getSpeciality = () => {
    setLoading(true);
    let data = {
      doctor_id: localStorage.getItem("doctor_id"),
    };
    axios
      .post("doctor/getSpecialitybyDoctor", data)
      .then((response) => {
        setVar_SpecialityValueId(response.data.data[0].id);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // get speciality api call end

  // form change get values
  const getServiceValue = (event) => {
    setVar_ServiceValue(event.target.value);
  };
  const getFeeKWDValue = (event) => {
    let numericonly = event.target.value.replace(/[^0-9.]/g, "");
    setFeeKWDValue(numericonly);
  };
  const getDescriptionValue = (event) => {
    setDescriptionValue(event.target.value);
  };
  const getServiceImage = (event) => {
    addServiceFileUpload(event.target.files[0]);
    setServiceImageName(event.target.files[0].name);
  };
  const getServiceActiveSwitchValue = (event) => {
    debugger;
    setServiceActiveSwitchValue(event.target.checked);
  };

  // add service s3 file upload start
  const addServiceFileUpload = (file) => {
    setisisDisable(true);
    setVar_fileprocess(true);
    setVar_Progress(0);
    const formData = new FormData();
    formData.append("module_id", "3");
    formData.append("file", file);
    formData.append("pathLocation", "MANAGE-SERVICE/");

    axios({
      method: "POST",
      url: "admin/awsS3FileUpload",
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setVar_Progress(percentage);
      },
    })
      .then((response) => {
        setisisDisable(false);
        setServiceImage(response.data.filepath.Location);
      })
      .catch((error) => {
        console.log(error);
        setVar_fileprocess(false);
        setisisDisable(false);
      });
  };
  // add service s3 file upload end

  // add manage service final submit start
  const addManaeServiceFinalSubmit = () => {
    if (
      Var_ServiceValue != "" &&
      Var_SpecialityValueId != "" &&
      Var_FeeKWDValue != "" &&
      Var_DescriptionValue != ""
    ) {
      if (
        Var_SlotsValueData_Ref.current > 9 &&
        Var_SlotsValueData_Ref.current < 61
      ) {
        if (Var_FeeKWDValue > 1) {
          setVar_SpecialityValueId();
          setLoading(true);
          setisisDisable(true);
          let data = {
            id: 0,
            doctor_id: localStorage.getItem("doctor_id"),
            service: Var_ServiceValue,
            specialityId: localStorage.getItem("speciality_Id"),
            cost: Var_FeeKWDValue,
            slot_duration: Var_SlotsValue,
            description: Var_DescriptionValue,
            is_active: Var_ServiceActiveSwitchValue_Ref.current == true ? 1 : 0,
            file_name: Var_ServiceImageName,
            file_path: Var_ServiceImage_Ref.current.split("?")[0],
          };

          axios
            .post("doctor/addmanageservice", data)
            .then((response) => {
              setisisDisable(false);
              debugger;
              if (response.data.data[0].ErrorCode == "9999") {
                setVar_msgContent("Manage service added successfully!");
                setVar_IsShowSuccessnew(true);
                setTimeout(() => {
                  setVar_IsShowSuccessnew(false);
                  handleOnClick("/manageservice");
                }, 1500);
                // setisOpenServiceSuccessModel(!isOpenServiceSuccessModel);
                setLoading(false);
              } else if (response.data.data[0].ErrorCode == "9998") {
                notification.error({ message: response.data.data[0].msg });
                setLoading(false);
              }
            })
            .catch((error) => {
              setisisDisable(false);
              notification.error({ message: error });
              setLoading(false);
            });
        } else {
          notification.error({ message: "The fee should be above 1 KWD." });
        }
      } else {
        notification.error({ message: "Slot Duration 10 Minu to 60 Minu" });
      }
    } else {
      notification.error({ message: "Please select all fields" });
    }
  };
  // add manage service final submit end

  // modelcontroles start

  const isCloseServiceSuccessModel = () => {
    setisOpenServiceSuccessModel(!isOpenServiceSuccessModel);
    Var_History.push("/dashboard/manageservice");
  };
  // modelcontroles end

  const getClinicWiseDoctor = () => {
    setLoading(true);
    let data = {
      clinic_id: localStorage.getItem("clinic_id"),
    };
    axios
      .post("clinic/getClinicWiseDoctor", data)
      .then((response) => {
        let filteredData = response.data.data.filter(
          (x) =>
            x.specialityid == localStorage.getItem("speciality_Id") &&
            x.doctor_id == localStorage.getItem("doctor_id")
        );
        setVar_DoctorDetailShow(
          filteredData[0].doctorName +
            "," +
            " " +
            filteredData[0].specialityname
        );
        setVar_DoctorSpeciality(filteredData[0].specialityname);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // ==========================TimePopOver ==================================
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClick = (value) => {
    setSlotsValueData(value);
    setSlotsValue("00:" + value);
    setSlotsValue_Minus(value);
    handleClose();
  };
  const values = Array.from({ length: 50 }, (_, index) => index + 10);

  return (
    <div>
      {/* =============================Sub Header Start============================== */}
      {loading && (
        <div className="loader_body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      )}
      <div>
        <HeaderMasterComponent />
      </div>
      <div className="Navication-header">
        <div>
          <img src={BackIcon} onClick={() => handleOnClick("/manageservice")} />
          <label onClick={() => handleOnClick("/manageservice")}>
            Manage Services
          </label>{" "}
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <label>Add Service</label>
        </div>
      </div>
      {/* <div className="sub_header">
        <div className="sub_header_body">
          <div className="back_navigation" onClick={() => { navigateToBack("/manageservice") }}>
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span>ADD SERVICE</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            <div>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  SelectProps={{
                    renderValue: (p) => p
                  }}
                  value={Var_DoctorDetailShow_Ref.current}
                  readOnly={true}
                  renderValue={(p) => p}

                >
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div> */}
      {/* =============================Sub Header End ============================== */}

      {/* ===============================Add Service Content Start=========================== */}
      <div className="content_view_manage_service container-fluid">
        <div className="manage_service_adding_details">
          <div className="add_service_details">
            <Grid item xs={6} className="speciality_ame_aded">
              <InputLabel
                className="demo_special_name"
                id="demo-select-small-label"
              >
                Specialty
              </InputLabel>
              <TextField
                className="Text_Add_field"
                id="outlined-basic"
                placeholder={Var_DoctorSpeciality_Ref.current}
                size="small"
                sx={{ minWidth: "-webkit-fill-available" }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={6} className="speciality_ame_aded">
              <InputLabel
                className="demo_special_name"
                id="demo-select-small-label"
              >
                Service Title
              </InputLabel>
              <TextField
                className="Text_Add_field"
                type="text"
                placeholder="Enter service description"
                size="small"
                sx={{ minWidth: "-webkit-fill-available" }}
                onChange={(e) => getServiceValue(e)}
              />
            </Grid>

            <Grid item xs={6} className="speciality_ame_aded">
              <InputLabel
                className="demo_special_name"
                id="demo-select-small-label"
              >
                Service Description
              </InputLabel>

              <TextField
                type="text"
                placeholder="Description"
                className="Text_Add_field"
                size="small"
                sx={{ minWidth: "-webkit-fill-available" }}
                onChange={(e) => getDescriptionValue(e)}
              />
            </Grid>

            <div className="fee_slot_duration">
              <div>
                <Grid
                  container
                  item
                  xs={12}
                  lg={12}
                  className="speciality_ame_aded_fee"
                >
                  <InputLabel
                    className="demo_special_name"
                    id="demo-select-small-label"
                  >
                    Fee <span className="fontSize10"></span>
                  </InputLabel>
                  <TextField
                    className="Text_Add_field"
                    type="text"
                    placeholder="Enter Fee"
                    size="small"
                    inputProps={{ maxLength: 7 }}
                    sx={{ minWidth: "-webkit-fill-available" }}
                    value={Var_FeeKWDValue_Ref.current}
                    onChange={(e) => getFeeKWDValue(e)}
                  />
                </Grid>
              </div>

              <div>
                <Grid
                  container
                  item
                  xs={12}
                  lg={12}
                  className="speciality_ame_aded_fee"
                >
                  <InputLabel
                    className="demo_special_name"
                    id="demo-select-small-label"
                  >
                    Slot Duration <span className="fontSize10">(mins)</span>
                  </InputLabel>

                  <TextField
                    type="text"
                    // placeholder="00:00"
                    className="Text_Add_field"
                    placeholder="00"
                    size="small"
                    inputProps={{ maxLength: 5 }}
                    sx={{ minWidth: "-webkit-fill-available" }}
                    // onChange={(e) => getSlotsValue(e)}
                    value={Var_SlotsValue_Minus}
                    onClick={(event) => handleClick(event)}
                  />
                  <div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <div>
                        {values.map((value, index) => (
                          <Typography
                            key={index}
                            sx={{ p: 2, cursor: "pointer" }}
                            onClick={() => handlePopoverClick(value)}
                          >
                            {index + 10}
                          </Typography>
                        ))}
                      </div>
                    </Popover>
                  </div>
                </Grid>
              </div>
            </div>

            <Grid item xs={6} className="speciality_ame_aded_active">
              <InputLabel
                id="demo-select-small-label"
                className="demo_special_name"
              >
                Active
              </InputLabel>
              <FormControlLabel
                className="every_days_service"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    className="toggle"
                    checked={
                      Var_ServiceActiveSwitchValue_Ref.current == true
                        ? true
                        : false
                    }
                    onClick={(e) => getServiceActiveSwitchValue(e)}
                  />
                }
              />
            </Grid>

            <Grid container className="save_details_new">
              <Grid ClassName="save_profile_details">
                <div
                  className="save_button_details"
                  onClick={() => addManaeServiceFinalSubmit()}
                >
                  ADD
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {/* ===============================Add Service Content End=========================== */}
      {/* ======================== AddService Success Model======================================= */}

      {/* ================= Add deal Success Model Start ================= */}
      <Dialog
        open={isOpenServiceSuccessModel}
        onClose={setisOpenServiceSuccessModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogContent>
          <div className="success_model_body">
            <div className="icons">
              <img src={successModelTickImg} alt="" />
            </div>
            <div className="title">success!</div>
            <div className="content">Manage service added successfully!</div>
            <div className="segment_center">
              <button
                className="botton_Cart_Create"
                onClick={() => isCloseServiceSuccessModel()}
              >
                OK
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* ================= Add deal Success Model End ================= */}

      {/* ==========================================================TimeSlotPicker =========================================== */}
      {/* 
      <Dialog
        // fullWidth={fullWidth}
        open={isOpenTimePickerModel}
        onClose={setisOpenTimePickerModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "100px",
              borderRadius: "20px",
              maxHeight: "100px"
            },
          },
        }}
      >
        <DialogContent>
          {Timearray.map((i) => (
          <label>{i}</label>))}
        </DialogContent>
      </Dialog> */}
      {Var_IsShowSuccessnew && (
        <div className="popup-main">
          <div className="pushNotification_body">
            <div className="pushNotification_content">
              <p className="notification_text_head">
                <b>{Var_msgContent}</b>
              </p>
            </div>
            <div className="progress-prt">
              <div className="progress-bar"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default ManageAddServiceComponent;
