import React, { useState,useRef,useEffect } from "react";
import logo from "../../assets/icon/bannerone.png"
import tomimg from "../../assets/icon/tomImagewhite.png";
import moment from "moment";






const Printdata  = React.forwardRef((props, ref) => {
console.log(props);
debugger
var arraywidth = [];
  var propsHeader = props.heading;
  var widthAdjustDt = "";
  var sm_table_align=0
  var Tom_share=0
  var table_width=0
  var subtitle_change=0
  var title_change=0;
  if (window.location.href.includes("paid%20advance")) {
    arraywidth = [5, 10, 40,15,15,15];
  }else if(window.location.href.includes("Fast%20Track")){
    arraywidth = [5, 10, 25, 15, 15, 15,15];
  }else if(window.location.href.includes("Walk%20In")){
    arraywidth = [5, 10, 35, 15, 15, 15,15];
  }else if(window.location.href.includes("Paid%20at%20Clinic%20(No%20Insurance)")){
    arraywidth = [5, 10, 30, 15, 10, 10,10,10];
  }else if(window.location.href.includes("Paid%20through%20Insurance%20(full%20Coverage)")){
    arraywidth = [5, 10, 29, 8, 8, 8,8,8,8,8];
    widthAdjustDt = 'A';
    Tom_share = 1;
  }else if(window.location.href.includes("Paid%20through%20Insurance%20(Partial%20Coverage)")){
    arraywidth = [5, 10, 21, 8, 8, 8,8,8,8,8,8];
    widthAdjustDt = 'A';
    Tom_share = 1;
  }else if(window.location.href.includes("Referral%20Incentive")){
    arraywidth = [5,15, 30, 20, 20];
    table_width=90
    sm_table_align=60
    subtitle_change=230
  }else if(window.location.href.includes("settlement")){
    arraywidth = [5, 30,10 ,15];
    table_width=60
    sm_table_align=60
    subtitle_change=230
    title_change=1
  }
 const  formatMoney=(number)=> {
    number = parseFloat(number);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };

    var formattedNumber = number.toLocaleString('en-US', options);
    const parts = formattedNumber.split('.');
    if(formattedNumber == 'NaN'){
      formattedNumber = "0";
    }
    if (parts.length === 1) {
      return `${formattedNumber}.00`;
    } else {
      const decimalPart = parts[1].padEnd(2, '0');
      return `${parts[0]}.${decimalPart}`;
    }
  }

    return(
       <>
       {props.type === 'print' &&
        <div ref={ref}  style={{ width: '297mm', height: '180mm', margin: '0 auto' }}>
          {props.rowdata.map((rowData, rowIndex) => {
            const startIdx = rowIndex * 30;
            const endIdx = startIdx + 30;
            const totalPages = Math.ceil(props.rowdata.length / 30);
            const isLastPage = rowIndex === totalPages - 1;
            if (startIdx <= endIdx && startIdx < props.rowdata.length) {
              const pageHeight = isLastPage ? '96vh' : '97vh';
              return (

                <div style={{ margin: "50px", height: pageHeight }}>
                  <div style={{width: "100%" }}>
                    <img src={logo} className="logo" style={{ width: "120px", height: "70px",  margin: "0px 0px 10px 0px",}}></img>
                  </div>
                  <p style={{ textAlign: "center", fontSize: "22px", margin: "0px auto 10px auto" }}>{`${ title_change==0?props.title?.props?.children[0]:props.title?.props?.children}`}</p>
                  <p style={{ textAlign: "right", fontSize: "15px", margin: `0 ${subtitle_change == 0 ? 0 : subtitle_change}px 0 auto` }}>{`All values in KWD`}</p>
                  <table style={{ width: `${table_width==0?100:55}%`, border: "1px solid black", margin: '0 auto' }}>
                    <thead>
                      <tr>
                        {props.heading.map((item, index) => {
                          if (item.label.includes('Tom')) {
                            const parts = item.label.split('Tom');
                            return (
                              <>
                                {widthAdjustDt == 'A' ?
                                  <th key={item.id} style={{ width: `${arraywidth[index]}%`,justifyContent: 'center', border: "1px solid black", backgroundColor: "#510F30", color: "#ffffff", textAlign: 'center' }}>
                                    <div style={{paddingTop:Tom_share==0?'0px':'5px'}}>
                                      <img src={tomimg} style={{ height: '8px', width: '12px' }}></img>
                                    </div>
                                    {parts[0]?.replace('Tom', '')}
                                    <span style={{position:'relative', fontSize: '10px',top:Tom_share==0?'0px':'-7px' }}>{parts.length == 2 && parts[1]}</span>
                                  </th>
                                  :
                                  <th key={item.id} style={{ width: `${arraywidth[index]}%`, border: "1px solid black", backgroundColor: "#510F30", color: "#ffffff", textAlign: 'center' }}>
                                    <img src={tomimg} style={{ height: '12px', width: '12px' }}></img>
                                    {parts[0]?.replace('Tom', '')}
                                    <span style={{ fontSize: '10px'}}>{parts.length == 2 && parts[1]}</span>
                                  </th>}
                              </>
                            )
                          }
                          else if (item.label.includes('(KWD)')) {
                            const parts = item.label.split('(');
                            return (
                              <th key={item.id} style={{ border: "1px solid black", backgroundColor: "#510F30", color: "#ffffff", textAlign: 'center' }}>
                                {parts[0]}
                                <span style={{ fontSize: '7px' }}>{' (' + parts[1]}</span>
                              </th>
                            )
                          } else {
                            const parts = item.label.split(' ');
                            const part_one=parts.length>=2 && parts[1].includes("by")?true:false
                            return (
                              <>
                                {!item.label.includes('Name') && widthAdjustDt == 'A' ?
                                  <th key={item.id} style={{ width: `${arraywidth[index]}%`, border: "1px solid black", backgroundColor: "#510F30", color: "#ffffff", textAlign: 'center' }}>
                                    {/* {item.label} */}
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                                    {part_one ?<><span style={{ fontSize: '10px' }}>{parts[0]}</span>
                                    <span style={{ fontSize: '10px' }}>{parts.length >= 2 && parts[1]}{parts.length == 3 && " "+parts[2]}</span>
                                       </>:<>
                                        <span style={{ fontSize: '10px' }}>{parts[0]}</span>
                                    <span style={{ fontSize: '10px' }}>{parts.length >= 2 && parts[1]}</span>
                                        <span style={{ fontSize: '10px' }}>{parts.length == 3 && parts[2]}</span>
                                        </>}
                                    </div>
                                  </th>
                                  :
                                  <th key={item.id} style={{ width: `${arraywidth[index]}%`, fontSize: '10px', border: "1px solid black", backgroundColor: "#510F30", color: "#ffffff", textAlign: 'center' }}>
                                    {item.label}
                                  </th>}
                              </>
                            )
                          }

                        })}
                      </tr>
                    </thead>

                    <tbody>
                      {/************** total calculateD value *****************/}
                      <tr>
                        {props?.totalcal && rowIndex + 1 == 1 && props.totalcal.map((item, ind) => {
                          return (

                            <th style={{ width: `${arraywidth[ind]}%`, backgroundColor: item.income == '' ? '' : '#f8e0d8', border: "1px solid black", padding: "2px 5px", textAlign: item.income == 'Total' ? 'center' : 'end', color: '#510F30',fontWeight:'normal' }}>
                              {item.income == 'Total' || item.income == '' ? item.income : formatMoney(item.income)}
                            </th>
                          )
                        })}
                      </tr>
                      {props.rowdata.slice([startIdx], [endIdx]).map((item, index) => (
                        <tr key={index}>
                          {props.heading.map((header, ind) => {
                            //               
                            if (props.heading[ind].typeDt === 'float' || props.heading[ind].typeDt === 'amt') {
                              //                         const checkData=["Income","income","DoctorName","vendor_contact","payableAmount","tomShare","totalRevenue",
                              // "doctorRevenue","insuranceDeduction","referralIncentive","doctorIncome","book_amount","DoctorCommissionPer",
                              // "pharmacyRevenue","sumTotal","rate","amount","tomCommission","tomTotalShare"];

                              return (
                                <td key={header.id} style={{ border: "1px solid black", padding: "2px 5px", textAlign: 'end' }}>
                                  {/* {ind==0?index+1 :item[header.id]} */}
                                  {item[header.id] !== undefined ? item[header.id] == null ? '-' : item[header.id] == '0.000' ? '-' : formatMoney(item[header.id]) : item[header.id]}
                                </td>)
                            } else if (props.heading[ind].typeDt === 'date' || props.heading[ind].type === 'date') {
                              return (
                                <td key={header.id} style={{ border: "1px solid black", padding: "2px 5px", textAlign: 'center' }}>
                                  {/* {ind==0?index+1 :item[header.id]} */}
                                  {moment(item[header.id]).format('DD-MMM-YY')}
                                </td>)
                            }
                            else if (props.heading[ind].typeDt === 'str') {
                              return (
                                <td key={header.id} style={{ border: "1px solid black", padding: "2px 5px", textAlign: 'start' }}>
                                  {ind == 0 ? index + 1 : item[header.id]}

                                </td>)
                            }
                            else {
                              return (
                                <td key={header.id} style={{ border: "1px solid black", padding: "2px 5px", textAlign: 'center' }}>
                                  {props.heading[ind].typeDt === 'sno' ? index + startIdx + 1 : item[header.id] == '-' ? '' : item[header.id]}
                                </td>)
                            }
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div style={{ width: '100%', marginTop: '3rem' }}>
                    <p style={{ textAlign: "right", fontSize: "15px", margin: "0 auto 10px auto" }}>{`Page ${rowIndex + 1} of ${Math.ceil(props.rowdata.length / 30) == 0 ? 1 : Math.ceil(props.rowdata.length / 30)}`}</p>
                  </div>

                </div>

              )
            }

            else {
              return null;
            }
          })}

        </div>

      }
      </>
    )

})
export default Printdata;










