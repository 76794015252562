import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useEffect, useState } from "react";
import useStateRef from "react-usestateref";
import { Card, Row, Col, Container, Dropdown, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./HeaderMaster.css";
// dashboard css atsrt
// dashboard css end
// mui css import start
import { MenuItem, DialogContent, Dialog } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
// mui css import end
// images start
import Logo from '../../assets/Images/logo (1).png'
// import Notification from '../../assets/Doctor_web_Icon/Group 19086.svg'
import support from '../../assets/Doctor_web_Icon/male-telemarketer.svg'
import Notification from '../../assets/Doctor_Images/notification (1).png'
import emptyNotification from '../../assets/Doctor_Images/notification (1).png'
import videoIcon from '../../assets/Doctor_web_Icon/Group21005.svg'


// images end
// component imnporting start
import Service from '../../helpers/Service'
import DashboardMasterComponent from "../Dashboard/DashboardMaster";
import FooterMaster from '../Dashboard/FooterMaster'
// component imnporting end
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import Postimg from '../../assets/clinicIcon/homePageIcon/post.svg';
import Manageserviceimg from '../../assets/clinicIcon/homePageIcon/manageService.svg';
import Supportimg from '../../assets/Doctor_web_Icon/Group 21105.png';
import Workinghoursimg from '../../assets/clinicIcon/homePageIcon/workhours.svg';
import CollateralPlayIcon from '../../assets/Doctor_web_Icon/Group21005.svg';
import closeicon from '../../assets/icon/Icon ionic-ios-close.png';
import handIcon from '../../assets/Doctor_web_Icon/Path 38456.svg';
import homeIcon from '../../assets/Doctor_Images/Group 9.png';
import RejectIcon from "../../images/rejectIcon.svg"
import editIcon from "../../assets/Doctor_Images/edit.svg"
import logoutIcon from "../../assets/icon/Group 23875.svg";


const HeaderMasterComponent = ({ onEdit }) => {
  // Navigation 
  let Var_History = useHistory();
  // Navigation 
  // Route Navigation start
  const routeNavigation = (path) => { Var_History.push("/dashboard" + path) };
  const routeNavigationByVerification = (path) => {
    Var_History.push({
      pathname: "/dashboard" + path, state: {  // location state
        verification: true,
      }
    })
  };
  // Route Navigation end
  // variable declaration start
  const [Var_Profiledata, setVar_Profiledata, Var_Profiledata_ref] = useStateRef([]);
  const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor, Var_getClinicWiseDoctor_Ref] = useStateRef([]);
  const [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect, Var_doctorSpecialitySelect_Ref] = useStateRef(null);
  const [Var_PermissionObject, setVar_PermissionObject, Var_PermissionObject_Ref] = useStateRef([]);
  const [loading, setLoading] = useState(true);
  const [verificationPage, setVerificationPage] = useState(false);
  const [Var_NotificationData, setVar_NotificationData] = React.useState([]);
  const [Varnotificationcount, setVarnotificationcount, Varnotificationcount_Ref] = useStateRef([]);
  var [activeStep, setActiveStep] = useState(0);
  var [stepList, setStepList] = useState([
    { "label": "Reviewed & Approved", "dateTime": "23-Jun-23 10:00AM" },
    { "label": "Reviewed & Approved", "dateTime": "24-Jun-23 11:00AM" },
    { "label": "Reviewed & Approved", "dateTime": "25-Jun-23 12:00PM" },
    { "label": "You are ready", "dateTime": "to go live" }]);
  var [isOpenVideoPopup, setisOpenVideoPopup] = useState(false);
  var [videoPopupTitle, setVideoPopupTitle] = useState("");
  var [videoListbyMenu, setVideoListbyMenu] = useState([]);
  var [menuVideo, setMenuVideo] = useState([]);
  var [currentVideo, setCurrentVideo] = useState("");
  var [currentSubmenuid, setCurrentSubmenuid] = useState("");
  var [Var_DoctorId, setVar_DoctorId] = useState("");
  var [approved, setApproved] = useState("P");
  var [approvedLive, setApprovedLive] = useState("");
  var [approvedStatus, setApprovedStatus] = useState(0);
  var [declineStatus, setDeclinestatus] = useState("");
  const [ProfilePopupOpen, setProfilePopupOpen, ProfilePopupOpen_Ref] = useStateRef(false);
  var [headerDropdownStatus, setheaderDropdownStatus, headerDropdownStatus_Ref] = useStateRef(false);
  const [Var_ProfileSuccessfully, setVar_ProfileSuccessfully] = useStateRef("");
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);

  // variable declaration end
  // Initial load method start
  useEffect(() => {
    // getClinicWiseDoctor();
    var location = window.location.href.slice(-10);
    console.log(location, "123457890");
    if (location == '/dashboard' || location == 'dashboard/') {
      setheaderDropdownStatus(true);
    } else {
      setheaderDropdownStatus(false);
    }
    getDoctorProfile();
    getMenulist();
    localStorage.setItem("Collaterals_Page", false);
    // getNotifications();
    // clinicTabPermissionById();
  }, []);
  // Initial load method end
  // get doctor profile api call start
  function getDoctorProfile() {
    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("clinic_id")
    };
    axios
      .post("clinic/getClinicProfile", data)
      .then((response) => {
        setVar_Profiledata(response.data.data);
        getClinicWiseDoctor();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  // get doctor profile api call end
  // get clinic wise doctor api call start
  function getClinicWiseDoctor() {
    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("clinic_id")
    };
    axios
      .post("clinic/getClinicWiseDoctor", data)
      .then((response) => {
        debugger;
        if (response.data.data.length != 0) {
          if (localStorage.getItem("clinic_doctor_name") == 'Clinic_Doctor') {
            setVar_getClinicWiseDoctor(response.data.data);
            localStorage.setItem("doctor_id", response.data.data[0].doctor_id);
            localStorage.setItem("Doctor_id", response.data.data[0].doctor_id);
            localStorage.setItem("doctor_Name", response.data.data[0].doctorName);
            localStorage.setItem("contract_status", response.data.data[0].contract_status);
            setVar_doctorSpecialitySelect(response.data.data[0]);
            localStorage.setItem("speciality_Id", response.data.data[0].specialityid);
            Service.doctorDetail = (response.data.data[0])
            clinicTabPermissionById()
            checkThreeStepVerification(response.data.data[0].doctor_id)
            setLoading(false);
          } else {
            if (localStorage.getItem("clinic_doctor_name") == 'Clinic_Doctor_Change') {
              setVar_getClinicWiseDoctor(response.data.data);
              debugger;
              for (let i = 0; i < Var_getClinicWiseDoctor_Ref.current.length; i++) {
                if (localStorage.getItem("changes_doctor") == response.data.data[i].doctor_id && localStorage.getItem("speciality_Id") == response.data.data[i].specialityid) {
                  // localStorage.setItem("doctor_id", response.data.data[i].doctor_id);
                  // localStorage.setItem("doctor_Name", response.data.data[i].doctorName);
                  // localStorage.setItem("contract_status", response.data.data[i].contract_status);
                  setVar_doctorSpecialitySelect(response.data.data[i]);
                  // localStorage.setItem("speciality_Id", response.data.data[i].specialityid);
                  Service.doctorDetail = (response.data.data[i])
                  clinicTabPermissionById()
                  checkThreeStepVerification(localStorage.getItem("doctor_id"))
                  setLoading(false);
                  console.log(localStorage.getItem("doctor_id"), "Doctor_Changes_1");
                }
              }

            }
            console.log(localStorage.getItem("doctor_id"), "Doctor_Changes");
            if (localStorage.getItem("clinic_doctor_name") == null) {
              Var_History.push('/')
            }
            setLoading(false);
          }
        } else {
          setApproved('P');
          setVerificationPage(true);
          setActiveStep(0);
          localStorage.setItem("contract_status", "null");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  // get clinic wise doctor api call end
  const doctorSpecialityId = (e) => {
    debugger;
    localStorage.setItem("clinic_doctor_name", "Clinic_Doctor_Change");
    localStorage.setItem("speciality_Id", e.target.value.specialityid);
    localStorage.setItem("doctor_id", e.target.value.doctor_id);
    localStorage.setItem("Doctor_id", e.target.value.doctor_id);
    localStorage.setItem("changes_doctor", e.target.value.doctor_id);
    localStorage.setItem("doctor_Name", e.target.value.doctorName);
    localStorage.setItem("contract_status", e.target.value.contract_status);
    setVar_doctorSpecialitySelect(e.target.value);
    clinicTabPermissionById();
    checkThreeStepVerification(e.target.value.doctor_id)
    sendToken();
    onEdit(localStorage.getItem("speciality_Id"), 'Data')
    // Var_History.push("/dashboard/");
  }
  const clinicTabPermissionById = () => {
    //setLoading(true);
    debugger;
    var data = {
      doctorId: localStorage.getItem("doctor_id"),
      clinicId: localStorage.getItem("clinic_id")
    };
    axios
      .post("doctor/clinicTabPermissionbyId", data)
      .then((response) => {
        setVar_PermissionObject(response.data.data[0]);
        localStorage.setItem('menupermissions', JSON.stringify(response.data.data[0]));
        console.log(Var_PermissionObject_Ref.current, "Var_PermissionObject_Ref");
      })
      .catch((error) => {
        // setLoading(false);
        console.log(error);
      });
  }
  const checkThreeStepVerification = (doctor_id,) => {
    getNotifications()
    var data = {
      "doctor_id": doctor_id,
      "clinic_id": localStorage.getItem("clinic_id")
    }
    axios
      .post("doctor/verifyThreeStepVerification", data)
      .then((response) => {
        debugger;
        if (response.status == 200) {
          let findex = response.data.data.findIndex((obj) => obj.id == 0);
          var filteredData = response.data.data.filter(x => x.id == "A" || x.id == "R");
          var filteredData1 = response.data.data.filter(x => x.menu == "Go Live");
          var rejectres = response.data.data.filter((x) => x.menu == "Reject Reason by Salesadmin");
          setDeclinestatus(rejectres[0]?.id ? rejectres[0].id : '')


          if (filteredData.length != 0) {
            setApproved(filteredData[0].id);
            if (filteredData[0].id == 'A') {
              setApproved('P');
              setApprovedLive('A');
              setVerificationPage(true)
              setActiveStep(findex)
              setVar_DoctorId(doctor_id)
              // setVerificationPage(false)
              // setActiveStep(0)
            }
            else if (filteredData[0].id == 'R') {
              setApproved('R');
              setApprovedLive('P');
              localStorage.setItem("FooterStatus", "P");
              setVerificationPage(true)
              setActiveStep(findex)
              console.log(findex, "findex========");
              return
            }
            if (filteredData1[0].id != '0' && filteredData1[0].id != '' && filteredData1[0].id != null) {
              // setApprovedStatus(filteredData1[0].id);
              setApproved('A');
              localStorage.setItem("FooterStatus", "A");
              setVerificationPage(false);
              setActiveStep(0);
            } else {
              setApprovedStatus(filteredData1[0].id);
            }
          } else {
            setApproved('P');
            setApprovedLive('P');
            localStorage.setItem("FooterStatus", "P");
            setVerificationPage(true)
            setActiveStep(findex)
            // if(findex == 1){
            //     setActiveStep(findex);
            // }else if(findex == 2){
            //     setActiveStep(findex- 1);
            //     setActiveStep(findex);
            // }
            console.log(findex, "findex========");

          }
          console.log(approved, "approved=====");
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.log(error);
      });
  }
  const updategoLiveStatus = () => {
    debugger;
    var data = {
      // "vendor_id": localStorage.getItem("clinic_id") ? localStorage.getItem("clinic_id") : ""
      "vendor_id": Var_DoctorId ? Var_DoctorId : ""
    }
    axios
      .post("admin/updateGoLiveStatus", data)
      .then((response) => {
        debugger;

        if (response.data.status == 1) {
          setApproved('A');
          setVerificationPage(false);
          setActiveStep(0);
          // setApprovedStatus(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getNotifications = () => {
    setLoading(true);
    var data = {
      doctor_id: localStorage.getItem("doctor_id"),
      clinic_id: localStorage.getItem("clinic_id"),
      speciality_id: localStorage.getItem("speciality_Id")
    };
    axios
      .post("doctor/doctorwebnotification", data)
      .then((response) => {
        setVar_NotificationData(response.data.data);
        setVarnotificationcount(response.data.data.filter(x => x.to_date == formattedDate && (x.speciality_id == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id")) || x.to_date == formattedDate && (x.ref_speciality_id == localStorage.getItem("speciality_Id") && x.ref_doctor_id == localStorage.getItem("doctor_id"))))
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const currentDate = new Date();
  // Get the year, month, and day
  const year = currentDate.getFullYear();
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because January is 0
  const day = ('0' + currentDate.getDate()).slice(-2);
  // Format the date as "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;
  const getMenulist = () => {
    axios({
      method: "POST",
      url: "admin/getCollateralVideosByVendor",
      data: {
        "vendorId": 3
      },
    })
      .then((response) => {
        if (response.data.status == 200) {
          setVideoListbyMenu(response.data.data)
        }
        else {
          setVideoListbyMenu([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleMenuCardOnClick = (data) => {
    if (videoListbyMenu.length != 0 && data != '') {
      var video = videoListbyMenu.filter((obj) => { return obj.menu_name.toLowerCase() == data.toLowerCase() })
      if (video.length > 0) {
        setMenuVideo(video)
        setisOpenVideoPopup(true)
        setCurrentVideo(video[0].video_path ? video[0].video_path : '')
        setCurrentSubmenuid(video[0].sub_menu_id)
        setVideoPopupTitle(video[0].menu_name)
      }
      else {
        setMenuVideo([])
      }
    }
    else {
      setMenuVideo([])
    }
  }
  const navClick = (data) => {
    setCurrentVideo(data.video_path ? data.video_path : '')
    setCurrentSubmenuid(data.sub_menu_id)
    setVideoPopupTitle(data.menu_name)
  }
  const sendToken = () => {

    var data = {
      "vendor_id": localStorage.getItem("doctor_id"),
      "token": localStorage.getItem("TokenFCM")
    };
    axios
      .post("admin/insertvendorwebtokenclinic", data)
      .then((response) => {
        console.log("update token response", response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function rejectIcon() {
    return (
      <div className="rejectimage">
        <img src={RejectIcon} />
      </div>
    );
  }
  const openProfilePopup = () => {
    getCliniProfile();
    setTimeout(() => {
      setProfilePopupOpen(true);
    }, 300)
  }
  const closeProfilePopup = () => {
    setProfilePopupOpen(false);
  }
  function getCliniProfile() {
    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("clinic_id")
    };
    axios
      .post("clinic/getClinicProfile", data)
      .then((response) => {
        setVar_Profiledata(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  const logoutsuccess = (path) => {
    setVar_ProfileSuccessfully("Logout successful.");
    setVar_IsShowSuccess(true);
    setTimeout(() => {
      setVar_IsShowSuccess(false);
      localDataClear();
      logout(path);
    }, 3000);
  };

  const logout = (path) => {
    Var_History.push(path);
  };

  const localDataClear = () => {
    localStorage.clear("LoginData");
    localStorage.clear("clinic_name");
    localStorage.clear("clinic_id");
    localStorage.clear("clinic_vendor_type_id");
    localStorage.clear("doctor_id");
    localStorage.clear("speciality_Id");
  };

  return (
    <div>
      {/* ==================== Header Start=================== */}
      <div className="dashboard_header">
        <div className="dashboard_header_row">
          <div className="dashboard_header_content">
            <div className="dashboard_header_column">
              <img src={Logo} alt="logo" className="dashboard_header_logo" />
            </div>
            <div className='dashboard_header_dropdown'>
              <div className='dashboard_header_dropdown_button'>
                {!headerDropdownStatus_Ref.current ? <FormControl fullWidth>
                  <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 1, minWidth: 100 }}
                    id="demo-simple-select"
                    value={Var_doctorSpecialitySelect_Ref.current}
                    readOnly={true}
                    onChange={(e) => {
                      doctorSpecialityId(e)
                    }}  >
                    {Var_getClinicWiseDoctor.map((item) => (
                      <MenuItem
                        key={item.specialityname}
                        value={item}>
                        {item.doctorName}, {item.specialityname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> :
                  <FormControl fullWidth>
                    <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 1, minWidth: 100 }}
                      id="demo-simple-select"
                      value={Var_doctorSpecialitySelect_Ref.current}
                      onChange={(e) => {
                        doctorSpecialityId(e)
                      }}  >
                      {Var_getClinicWiseDoctor.map((item) => (
                        <MenuItem
                          key={item.specialityname}
                          value={item}>
                          {item.doctorName}, {item.specialityname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>}
              </div>
            </div>
          </div>
          <div style={{ flexGrow: "1" }}>
          </div>
          <div className="dashboard_header_profile_part">
            <div className="dashboard_header_icons">
              <div className="dashboard_header_home_icon">
                <img src={homeIcon} alt="log" className="header_home_image"
                  onClick={() => routeNavigation("/")}
                />
              </div>
              {/* {Varnotificationcount_Ref.current.length > 0 &&
                <div className="notification_image_header">
                  <img src={Notification} alt="log" className="header_notification_image"
                    onClick={() => routeNavigation("/notification")}
                     />
                </div>
              }
              {Varnotificationcount_Ref.current.length == 0 &&
                <div className="notification_image_header">
                  <img className="notification_icon_image" src={emptyNotification} alt="log"
                    onClick={() => routeNavigation("/notification")} />
                </div>
              } */}
            </div>
            <div>
              {Var_Profiledata?.map((item, i) => (
                <img src={item.profileImage == null ? Logo : item.profileImage == "" ? Logo : item.profileImage == " " ? Logo : item.profileImage} className="header_profile_image"
                  onClick={() => openProfilePopup()}
                />
              ))}
            </div>
            <div>
              {Var_Profiledata?.map((item, i) => (
                <div className="profile_text_header">
                  <p className="profile_text_name_header">{item.vendor_name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Header End=================== */}

      {/* =======================Profile Popup start=========================================== */}
      <div>
        <Dialog className="profile_model"
          open={ProfilePopupOpen} onClose={closeProfilePopup}>
          <DialogContent sx={{
            width: "100%",
            minWidth: "350px",
            borderRadius: "20px"
          }}>
            {Var_Profiledata_ref.current?.map((item, i) => (
              <div style={{ textAlign: "center" }}>
                <div className="profile-image-container" >
                  <img src={item.profileImage}></img>
                </div>
                <p className="profile-name">{item.vendor_name}</p>
                {/* <p>(Hospital Administrator)</p> */}
                <Grid container className="prfile_container">
                  <Grid item xs={5} className="profileimg-subheader">
                    {/* <p>Age</p>
                  <p>Gender</p>*/}
                    <p>Mobile</p>
                    <p>Location</p>
                  </Grid>
                  <Grid item xs={1} >
                    <div className="profile-line"> </div>
                  </Grid>
                  <Grid item xs={5} className="profileimg-value">
                    {/* <p>Age</p>
                  <p>Gender</p>*/}
                    <p>{item.mobile}</p>
                    <p>Location</p>
                  </Grid>
                </Grid>
                <div className="edit-parent-dvsn">
                  <div className="logout-child-dvsn" onClick={() => logoutsuccess("/")}>
                    <Button className="profile-edit-button">
                      Log out
                    </Button>
                    <img src={logoutIcon} className="profile-editicon" ></img>
                  </div>
                  <div className="edit-child-dvsn" onClick={() => routeNavigation("/profile")}>
                    <Button className="profile-edit-button">
                      Edit
                    </Button>
                    <img src={editIcon} className="profile-editicon" ></img>
                  </div>
                </div>
                <label className="terms-policy">Terms & Privacy poilicy</label>
              </div>
            ))}
          </DialogContent>
        </Dialog>
      </div>
      {/* =======================Profile Popup start=========================================== */}




      {/* <div className="home_header">
        <Row className="home_header_row">
          <Col sm="12" md="3" lg="3" className="d-flex">
            <Button className="header_back" onClick={() => handleOnClick(" ")}>
            <i className="fa fa-angle-left header_back_button " aria-hidden="true"></i>
            </Button>
            <h5 className="header_name">Wallet</h5>
          </Col>
          <Col sm="12" md="5" lg="5"></Col>
          <Col sm="12" md="2" lg="2">
            <TextField
              className="header_input"
              placeholder="Saud,Density"
              variant="outlined"
            />
          </Col>
          <Col sm="12" md="2" lg="2"></Col>
        </Row>
      </div>
      <div className="credit_point_row_one">
        <div className="credit_point">
          <Card className="credit_point_card_one">
            <p className="credit_point_name">Dr. Saud</p>
            <p className="credit_point_member">Gold Member</p>
            <p className="credit_point_wallet">Wallet Balance - Nil</p>
          </Card>
        </div>
        <div></div>
        <div className="credit_point">
          <Card className="credit_point_card_two">
            <img src={TOMLogo} alt="a" className="credit_point_img" />
          </Card>
        </div>
      </div>
      <div className="credit_point_row_two">
        <div className="credit_point">
          <Card className="credit_point_card_three">
            <img src={Prices} alt="a" className="credit_point_imgs" />
            <p className="credit_point_kwd">
              <span>110</span> <span className="fontSize10">(KWD)</span>
            </p>
            <p className="credit_point_earned">Earned So Far</p>
          </Card>
        </div>
        <div></div>
        <div className="credit_point">
          <Card className="credit_point_card_four">
            <img src={Prices} alt="a" className="credit_point_imgs" />
            <p className="credit_point_kwd">
              <span>10</span> <span className="fontSize10">(KWD)</span>
            </p>
            <p className="credit_point_earned">Earned This Week</p>
          </Card>
        </div>
      </div>
      <div className="credit_point_row_three">
        <Card className="credit_point_card_five">
          <Row>
            <Col sm="12" md="1" lg="1">
              <img src={TOMLogo} alt="a" className="credit_point_images" />
            </Col>
            <Col sm="12" md="5" lg="5">
              <div>
                <h5 className="credit_point_card_name">
                  Norah's booking of Lina's & Dina's,Diet
                </h5>
                <p className="credit_point_date">03-Apr-23</p>
              </div>
            </Col>
            <Col sm="12" md="5" lg="5"></Col>
            <Col sm="12" md="1" lg="1" className="credit_point_mobile">
              <i className="fa fa-mobile" aria-hidden="true"></i>
            </Col>
          </Row>
        </Card>
      </div> */}
    </div>
  );
};
export default HeaderMasterComponent;
