import React, { useState, useEffect } from 'react';

import offlineimage from "./assets/Images/offline_image.jpg"
import offlineimg from "./assets/Images/no_network.svg"
import offlineimagedata from "./assets/Doctor_Images/globalization.png"
import logoimage from "./assets/Doctor_Images/Easidoc.jpeg"

const OnlineStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [pingTime, setPingTime] = useState(null);
    const [internetLimitAlert, setInternetLimitAlert] = useState(false);


    useEffect(() => {
        const handleOnline = () => {
            if (navigator.connection) {
                if (navigator.connection.downlink < 3) {
                    setPingTime(navigator.connection.downlink);
                    setInternetLimitAlert(true);
                }
                // console.log(`Effective network type: ${navigator.connection.effectiveType}`);
                // console.log(`Downlink Speed: ${navigator.connection.downlink}Mb/s`);
                // console.log(`Round Trip Time: ${navigator.connection.rtt}ms`);
            } else {
                console.log('Navigator Connection API not supported');
            }
            setIsOnline(true);
        };

        const handleOffline = () => {
            setIsOnline(false);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const setwifiSpeed = (speed) => {
        alert(speed);
    }

    const pagereload = () =>{
        window.location.reload()
    }

    return (
        <div>
            {internetLimitAlert &&
                <div className='offlinemsg_body'>
                    <div className='offlinemsg_body_imgs'>
                        <img src={logoimage} style={{ width: '100px' }} />
                    </div>
                    <div className='offline-content'>
                        <img src={offlineimagedata} style={{ width: '100px' }} />
                        <h2>Your network connection is unstable!</h2>
                        <h3 className='try-again' onClick={pagereload} style={{color:'#E1446A'}}>Try Again</h3>
                        {/* <h2>You are offline. Please check your internet connection.</h2> */}
                    </div>
                    {/* <h2>Bad Internet connection Please check your internet.</h2> */}
                </div>
            }
            {!isOnline &&
                <div className='offlinemsg_body'>
                    <div className='offlinemsg_content'>
                        <div className='offlinemsg_body_imgs'>
                            <img src={logoimage} style={{ width: '100px' }} />
                        </div>
                        <div className='offline-content'>
                            <img src={offlineimagedata} style={{ width: '100px' }} />
                            <h3>oops ! no connection!</h3>
                            <h2>Looks like you are facing a network interruption.</h2>
                            <h2>Check your Network Connection.</h2>
                            <h3 className='try-again' onClick={pagereload} style={{color:'#E1446A'}}>Try Again</h3>
                            {/* <h2>You are offline. Please check your internet connection.</h2> */}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default OnlineStatus;
