import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import jsonData from "../local.json";
import "./RevenueModal.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../../assets/Web_Doctor_Icon_new_theme/TOM_logo.svg";
import Download from "../../../assets/Web_Doctor_Icon_new_theme/Download_icon.svg";
import { useState } from "react";
import success from "../../../assets/Web_Doctor_Icon_new_theme/tickmark.svg";
import { TextField, DialogContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';

import FastTrack from '../../../assets/Web_Doctor_Icon_new_theme/fasttack_Appointment.svg';
import InClinicOnline from '../../../assets/Web_Doctor_Icon_new_theme/incliniconline_Appointment.svg';
import InClinic from '../../../assets/Web_Doctor_Icon_new_theme/member_Appointment.svg';
import Online from '../../../assets/Web_Doctor_Icon_new_theme/online_Appointment.svg';
import referral from '../../../assets/Web_Doctor_Icon_new_theme/referral_Appointment.svg';
import WalkIn from '../../../assets/Web_Doctor_Icon_new_theme/walkIn_Appointment.svg';
import MaleIcon from "../../../assets/Web_Doctor_Icon_new_theme/MaleJpg.jpg";
import FemaleIcon from "../../../assets/Web_Doctor_Icon_new_theme/FeMaleJpg.jpg";

const ViewAppointMent = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 550,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "25px",
  outline: "none",
};
const viewadditionalservice = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // height: 550,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "25px",
  outline: "none",
};
const RefferModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 165,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "25px",
  outline: "none",
};
const ViewReciept = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: 410,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "25px",
  outline: "none",
};
const SuccessCard = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  height: 300,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "25px",
  outline: "none",
};
const UploadModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 450,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "25px",
  outline: "none",
};
const formatDate = (datessss) => {
  debugger;
  let date = new Date(datessss);
  const day = date.getDate();
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);
  const hours = date.getHours();


  return `${day}-${month}-${year} `;
}

const apointmentType = (val, type) => {
  let imageSrc = '';
  switch (type) {
    case -1:
      imageSrc = WalkIn;
      break;
    case 1:
      imageSrc = InClinic;
      break;
    case 2:
      imageSrc = Online;
      break;
    case 3:
      imageSrc = FastTrack;
      break;
    default:
      imageSrc = null; // If type is not matched, set imageSrc to null
      break;
  }
  return (
    <div>
      <div className="p-1">Appointment Type</div>
      <div className="Modal_font p-1">{val} {imageSrc && <img src={imageSrc} alt='Appointment Type Image' />} </div>
    </div>
  );
}

const Modalpage = (props) => {
  debugger
  const { open, setModal, data, type } = props;
  console.log(data, "111");
  const [activeSegment, setActiveSegment] = useState(null);

  const handleSegmentClick = (segment) => {
    setActiveSegment(segment);
  };
  return (
    <>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          backdrop='static'
        >
          {type === 0 ? (
            <Box sx={ViewAppointMent} className="overflow-height">
              <div className="Model_crd d-flex justify-content-around">
                <div className="model_title">
                  View Appointment
                  <IoMdClose
                    className="close_icon"
                    onClick={setModal}
                  ></IoMdClose>
                </div>
              </div>
              <div className="px-3">
                <div className="d-flex justify-content-center p-3">
                  <div className="d-flex align-items-center" > <img className="Image_container"  src={ !data.patientImage ? (data.gender === "Male" ? MaleIcon : FemaleIcon) : data.patientImage} /></div>
                </div>
                <div className="d-flex justify-content-center">
                  <span style={{ color: "#510f30", fontSize: "15px " }}>
                    {data.patientName ? data.patientName : "-"}
                  </span>

                </div>
                <div className="dashed_line"></div>
                <Row className="container Font_size m-0 pt-3 over-height">
                  <Col className="container" md={6} sm={12}>
                    <div className="p-1">Name</div>
                    <div className="Modal_font p-1">{data.patientName ? data.patientName : "-"}</div>
                  </Col>
                  <Col className="container" md={6} sm={12}>
                    {apointmentType(data.AppointmentTypeN, data.AppointmentTypeNId)}
                  </Col>
                  <Col className="container" md={6} sm={12}>
                    <div className="p-1">Specialty</div>
                    <div className="Modal_font p-1">{data.speciality_name ? data.speciality_name : "-"}</div>
                  </Col>
                  <Col className="container" md={6} sm={12}>
                    <div className="p-1">Service</div>
                    <div className="Modal_font p-1">{data.serviceName ? data.serviceName : "-"}</div>
                  </Col>
                  <Col className="container" md={6} sm={12}>
                    <div className="p-1">Date</div>
                    <div className="Modal_font p-1">{formatDate(data.appointmentDate) ? formatDate(data.appointmentDate) : "-"}</div>
                  </Col>
                  <Col className="container" md={6} sm={12}>
                    <div className="p-1"> Time</div>
                    <div className="Modal_font p-1" style={{ textTransform: 'lowercase' }}>{data.appointmentTime ? data.appointmentTime : "-"}</div>
                  </Col>
                  <Col className="container" md={6} sm={12}>
                    <div className="p-1">Clinic</div>
                    <div className="Modal_font p-1">{data.clinicName ? data.clinicName : "-"}</div>
                  </Col>
                  <Col className="container" md={6} sm={12}>
                    <div className="p-1">Location</div>
                    <div className="Modal_font p-1">{data.clinicAddress ? data.clinicAddress : "-"}</div>
                  </Col>
                  <Col className="container" md={6} sm={12}>
                    <div className="p-1">Booking ID </div>
                    <div className="Modal_font p-1">{data.id ? data.id : "-"}</div>
                  </Col>
                  <Col className="container" md={6} sm={12}>
                    <div className="p-1">Transaction ID </div>
                    <div className="Modal_font p-1">{data.transactionId && data.transactionId !== 'undefined' ? data.transactionId : "-"}</div>
                  </Col>
                  <Row className="container Font_size m-0">
                    <Col md={6} className="d-flex p-0">
                      <Col md={6} sm={12} className="">
                        <div className="p-1">
                          Fee <span className="KWD_font">(KWD)</span>
                        </div>
                        <div className="Modal_font p-1">{data.totalFee ? data.totalFee.toFixed(3) : "-"}</div>
                      </Col>
                      <Col md={6} sm={12} className="">
                        <div className="p-1">
                          Deal
                        </div>
                        <div className="Modal_font p-1">{data.dealAmount ? data.dealAmount : "-"}</div>
                      </Col>
                    </Col>
                    <Col md={6} sm={12} className="container">
                      <div className="p-1">
                        Billed Amount <span className="KWD_font">(KWD)</span>
                      </div>
                      <div className="Modal_font p-1">{data.book_amount ? data.book_amount.toFixed(3) : "-"}</div>
                    </Col>
                  </Row>
                </Row>
              </div>
            </Box>



          ) : type === 1 ? (
            <Box sx={RefferModal}>
              <div className="px-0">
                <div className="Model_crd d-flex justify-content-around">
                  <div className="model_title">
                    {data.referredTo}
                    <IoMdClose
                      className="close_icon"
                      onClick={setModal}
                    ></IoMdClose>
                  </div>
                </div>
                <div className="d-flex justify-content-start p-3">
                  <div className="d-flex align-items-center"><img className="Image_container" src={data.vendorProfile} /></div>
                  <div style={{ padding: "20px", width: '100%' }}>
                    <div>{data.vendorName}</div> {/* DOCTOR NAME */}
                    {data.referredTo == 'Doctor' &&
                      <div>{data.speciality_name ? data.speciality_name : ""}</div>
                    }
                  </div>
                </div>

                {/* <Row className="container  m-0 font_size_2">
                <Col sm={12} md={6} className="p-1">
                  <div>Date</div>
                </Col>
                <Col sm={12} md={6} className="p-1 Modal_font">
                  <div>{formatDate(data.book_date)}</div>
                </Col>
                <Col sm={12} md={6} className="p-1">
                  <div>Name</div>
                </Col>
                <Col sm={12} md={6} className="p-1 Modal_font">
                  <div>{data.patientName}</div>
                </Col>
                <Col sm={12} md={6} className="p-1">
                  <div>Payment Method</div>
                </Col>
                <Col sm={12} md={6} className="p-1 Modal_font">
                  <div>
                    {data.paymentMode}                   
                  </div>
                </Col>
                <Col sm={12} md={6} className="p-1">
                  <div>Fee</div>
                </Col>
                <Col sm={12} md={6} className="p-1 Modal_font">
                  <div></div>
                </Col>
                <Col sm={12} md={6} className="p-1">
                  <div>
                    Dr.Sara <span>Referral Incentive</span>
                  </div>
                </Col>
                <Col sm={12} md={6} className="p-1 Modal_font">
                  <div>{data.doctorAmount}</div>
                </Col>
                <Col sm={12} md={6} className="p-1">
                  <div>
                    <img style={{ height: "18px" }} src={logo}></img>
                    <span>Share</span>
                  </div>
                </Col>
                <Col sm={12} md={6} className="p-1 Modal_font">
                  <div>{data.tomIncome}</div>
                </Col>
              </Row> */}

              </div>
            </Box>
          ) : type === 2 ? (
            <div>
              <Box sx={ViewReciept}>
                <div className="Model_crd d-flex justify-content-around">
                  <div className="model_title">
                    View Receipt
                    <IoMdClose
                      className="close_icon"
                      onClick={setModal}
                    ></IoMdClose>
                  </div>
                </div>
                <div className="d-flex justify-content-end container p-4 ">
                  <div>
                    <img className="Downloadimg" src={Download}></img>
                  </div>
                </div>
                <div className="container p-2 d-flex justify-content-center">
                  <div className="recpit_crd"></div>
                </div>
                <div className="container d-flex justify-content-center p-1">
                  <div
                    className={`segment_line ${activeSegment === "KFH" ? "active" : ""
                      }`}
                    onClick={() => handleSegmentClick("KFH")}
                  >
                    <div className="w-100 d-flex justify-content-center">
                      KFH SOA
                    </div>
                  </div>
                  <div
                    className={`segment_line ${activeSegment === "tom" ? "active" : ""
                      }`}
                    onClick={() => handleSegmentClick("tom")}
                  >
                    <div className="w-100 d-flex justify-content-center">
                      tom SOA
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          ) : type === 3 ? (

            <div>
              <Box sx={SuccessCard}>
                <div className="container">
                  <div>
                    <div className="d-flex justify-content-center" style={{ padding: '30px' }}>
                      <img src={success} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="sucs_text">SUCCESS!</div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="succes_msg">
                        Amount paid successfully!
                      </div>
                    </div>
                    <div className="d-flex justify-content-center"><div className="ok_button" onClick={setModal}>OK</div></div>
                  </div>
                </div>
              </Box>
            </div>
          ) : type === 4 ? (
            <div>
              <Box sx={UploadModal}>
                <div className="Model_crd d-flex justify-content-around">
                  <div className="model_title">
                    UPLOAD RECEIPT
                    <IoMdClose
                      className="close_icon"
                      onClick={setModal}
                    ></IoMdClose>
                  </div>
                </div>
                <div className="container p-4 d-flex justify-content-center">
                  <div className="upload_crd"></div>
                </div>
                <div className="d-flex justify-content-center p-3">
                  <div className="upload_butm">
                    Upload
                  </div>
                </div>
              </Box>
            </div>
          ) : type === 5 ? (
            <div>
              <Box sx={UploadModal}>
                <div className="container">
                  <div>
                    <div className="d-flex justify-content-center" style={{ padding: '30px' }}>
                      <img src={success} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="sucs_text">FAILED!</div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="succes_msg">
                        Amount paid failed!
                      </div>
                    </div>
                    <div className="d-flex justify-content-center"><div className="ok_button" onClick={setModal}>OK</div></div>
                  </div>
                </div>
              </Box>
            </div>
          ) : type === 6 ? (
            <Box sx={viewadditionalservice}>
              <div className="Model_crd d-flex justify-content-around">
                <div className="model_title">
                  payment method
                  <IoMdClose
                    className="close_icon"
                    onClick={setModal}
                  ></IoMdClose>
                </div>
              </div>
              <DialogContent>
                <div className="apponment_payment_cost">
                  <h6 className="payment_type_title">
                    Booked Amount
                  </h6>
                  {/* {Var_AppointmentDetail.map((value) => ( */}
                  <TextField
                    sx={{ Width: "300px" }}
                    id="standard-basic"
                    variant="standard"
                    inputProps={{ readOnly: true }}
                    value={data.book_amount}
                  />
                  <div className="additional_payment_source">
                    <div>
                      <h6 className="payment_type_title_checkbox">
                        Additional Service
                      </h6>
                    </div>

                    {/* <div>
                      <Checkbox
                        style={{ fontSize: "29px" }} checked={true} disabled={true}>
                      </Checkbox>
                    </div> */}
                  </div>
                  <div>

                    <Grid item xs={10} md={10} style={{ textAlign: 'left', paddingRight: '12px' }}>
                      {JSON.parse(data.additionalservicelist).map((item, index) => (
                        // <div  className="add-browse-input">
                        <div key={index} className="add-browse-input">
                          <div className="add-input-browser-li" style={{ margin: '5px 5px 5px 5px' }}>
                            <div>
                              {`${item.service}`}{"   "}{`${item.cost}`}<span className="_f10"> KWD</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Grid>
                    <div>
                      <div className="payment_type_title_text">
                        Total Additional Service Cost
                      </div>
                      <TextField
                        style={{ Width: "100px" }}
                        id="standard-basic"
                        inputProps={{ readOnly: true }}
                        variant="standard"
                        value={data.additionalfees}
                      />
                    </div>
                    <div>
                      <div className="payment_type_title_text">
                        Full Amount
                      </div>
                      <TextField
                        style={{ Width: "100px" }}
                        id="standard-basic"
                        inputProps={{ readOnly: true }}
                        variant="standard"
                        value={data.additionalservicelisttotal}
                      />
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Box>
          ) : type === 7 ? (
            <Box sx={ViewAppointMent}>
              <div className="Model_crd d-flex justify-content-around">
                <div className="model_title">
                  View Appointment
                  <IoMdClose
                    className="close_icon"
                    onClick={setModal}
                  ></IoMdClose>
                </div>
              </div>
              <div className="d-flex justify-content-center p-3">
                <div className="d-flex align-items-center" > <img className="Image_container" src={data.file_name} /></div>
              </div>
              <div className="d-flex justify-content-center">
                <span style={{ color: "#510f30", fontSize: "15px " }}>
                  {data.name ? data.name : "-"}
                </span>
              </div>
              <div className="dashed_line"></div>
              <Row className="container Font_size m-0 pt-3">
                <Col className="container" md={6} sm={12}>
                  <div className="p-1">Name</div>
                  <div className="Modal_font p-1">{data.name ? data.name : "-"}</div>
                </Col>
                <Col className="container" md={6} sm={12}>
                  {apointmentType(data.AppointmentTypeN, data.AppointmentTypeNId)}
                </Col>
                <Col className="container" md={6} sm={12}>
                  <div className="p-1">Specialty</div>
                  <div className="Modal_font p-1">{data.speciality_name ? data.speciality_name : "-"}</div>
                </Col>
                <Col className="container" md={6} sm={12}>
                  <div className="p-1">Service</div>
                  <div className="Modal_font p-1">{data.service_type ? data.service_type : "-"}</div>
                </Col>
                <Col className="container" md={6} sm={12}>
                  <div className="p-1">Date</div>
                  <div className="Modal_font p-1">{formatDate(data.appointment_date) ? formatDate(data.appointment_date) : "-"}</div>
                </Col>
                <Col className="container" md={6} sm={12}>
                  <div className="p-1"> Time</div>
                  <div className="Modal_font p-1" style={{ textTransform: 'lowercase' }}>{data.appointment_time ? data.appointment_time : "-"}</div>
                </Col>
                <Col className="container" md={6} sm={12}>
                  <div className="p-1">Clinic</div>
                  <div className="Modal_font p-1">{data.clinicName ? data.clinicName : "-"}</div>
                </Col>
                <Col className="container" md={6} sm={12}>
                  <div className="p-1">Location</div>
                  <div className="Modal_font p-1">{data.clinicAddress ? data.clinicAddress : "-"}</div>
                </Col>
                <Col className="container" md={6} sm={12}>
                  <div className="p-1">Booking ID </div>
                  <div className="Modal_font p-1">{data.booking_id ? data.booking_id : "-"}</div>
                </Col>
                <Col className="container" md={6} sm={12}>
                  <div className="p-1">Transaction ID </div>
                  <div className="Modal_font p-1">{data.transactionId && data.transactionId !== 'undefined' ? data.transactionId : "-"}</div>
                </Col>
                <Row className="container Font_size m-0">
                  <Col md={6} className="d-flex p-0">
                    <Col md={6} sm={12} className="">
                      <div className="p-1">
                        Fee <span className="KWD_font">(KWD)</span>
                      </div>
                      <div className="Modal_font p-1">{data.totalFee ? data.totalFee?.toFixed(3) : "-"}</div>
                    </Col>
                    <Col md={6} sm={12} className="">
                      <div className="p-1">
                        Deal
                      </div>
                      <div className="Modal_font p-1">{data?.dealAmount ? data?.dealAmount : "-"}</div>
                    </Col>
                  </Col>
                  <Col md={6} sm={12} className="container">
                    <div className="p-1">
                      Billed Amount <span className="KWD_font">(KWD)</span>
                    </div>
                    <div className="Modal_font p-1">{data.book_amount ? data.book_amount?.toFixed(3) : "-"}</div>
                  </Col>
                </Row>
              </Row>
            </Box>



          ) : type === 8 ? (
            <Box sx={viewadditionalservice}>
              <div className="Model_crd d-flex justify-content-around">
                <div className="model_title">
                  payment method
                  <IoMdClose
                    className="close_icon"
                    onClick={setModal}
                  ></IoMdClose>
                </div>
              </div>
              <DialogContent>
                <div className="apponment_payment_cost">
                  <h6 className="payment_type_title">
                    Paid through Insurance {" "}
                    <span style={{ fontSize: "12px" }}>(Partial Coverage) </span>
                    <span className="currency_value">(KWD)</span>
                  </h6>
                  {/* {Var_AppointmentDetail.map((value) => ( */}
                  <TextField
                    sx={{ Width: "300px" }}
                    id="standard-basic"
                    label="Enter amount"
                    variant="standard"
                    value={data.totalFee}
                  />
                  <div className="additional_payment_source">
                    <div>
                      <h6 className="payment_type_title_checkbox">
                        Additional Service
                      </h6>
                    </div>

                    <div>
                      <Checkbox
                        style={{ fontSize: "29px" }} checked={true} disabled={true}>
                      </Checkbox>
                    </div>
                  </div>
                  <div>

                    <Grid item xs={10} md={10} style={{ textAlign: 'left', paddingRight: '12px' }}>
                      {JSON.parse(data.additionalservicelist).map((item, index) => (
                        // <div  className="add-browse-input">
                        <div key={index} className="add-browse-input">
                          <div className="add-input-browser-li" style={{ margin: '5px 5px 5px 5px' }}>
                            <div>
                              {`${item.service}`}{"   "}{`${item.cost} KWD`}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Grid>
                    <div>
                      <div className="payment_type_title_text">
                        Total Additional Service Cost
                      </div>
                      <TextField
                        style={{ Width: "100px" }}
                        id="standard-basic"
                        label="Enter amount"
                        variant="standard"
                        value={data.totaladitionalamount}
                      />
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Box>
          ) : type === 9 ? (
            <Box sx={viewadditionalservice}>
              <div className="Model_crd d-flex justify-content-around">
                <div className="model_title">
                  payment method
                  <IoMdClose
                    className="close_icon"
                    onClick={setModal}
                  ></IoMdClose>
                </div>
              </div>
              <DialogContent>
                <div className="apponment_payment_cost">
                  <h6 className="payment_type_title">
                    Paid through Insurance{" "}
                    <span style={{ fontSize: "12px" }}>(Full Coverage) </span>
                    <span className="currency_value">(KWD)</span>
                  </h6>
                  {/* {Var_AppointmentDetail.map((value) => ( */}
                  <TextField
                    sx={{ Width: "300px" }}
                    id="standard-basic"
                    label="Enter amount"
                    variant="standard"
                    value={data.book_amount}
                  />
                  <div className="additional_payment_source">
                    <div>
                      <h6 className="payment_type_title_checkbox">
                        Additional Service
                      </h6>
                    </div>

                    <div>
                      <Checkbox
                        style={{ fontSize: "29px" }} checked={true} disabled={true}>
                      </Checkbox>
                    </div>
                  </div>
                  <div>

                    <Grid item xs={10} md={10} style={{ textAlign: 'left', paddingRight: '12px' }}>
                      {JSON.parse(data.additionalservicelist).map((item, index) => (
                        // <div  className="add-browse-input">
                        <div key={index} className="add-browse-input">
                          <div className="add-input-browser-li" style={{ margin: '5px 5px 5px 5px' }}>
                            <div>
                              {`${item.service}`}{"   "}{`${item.cost} KWD`}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Grid>
                    <div>
                      <div className="payment_type_title_text">
                        Total Additional Service Cost
                      </div>
                      <TextField
                        style={{ Width: "100px" }}
                        id="standard-basic"
                        label="Enter amount"
                        variant="standard"
                        value={data.totaladitionalamount}
                      />
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Box>
          ) : (
            ""
          )
          }
        </Modal>


      </div>
    </>
  );
};
export default Modalpage;
