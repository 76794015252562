import useStateRef from "react-usestateref";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { QRCode } from "antd";
import moment from "moment";
import { notification, Spin } from "antd";
import ReactDOM from 'react-dom';
import Coverflow from 'react-coverflow';
import { StyleRoot } from 'radium';
import Service from '../../helpers/Service'
// Mui meterial component import
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField'
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Axios from "axios";
import './QueueMaster.css'
import profile from '../../assets/Images/TOMLogo.jpg'
import price from '../../assets/Images/Price.png'
import success from '../../assets/Images/Success.png'
import documents from '../../assets/Images/documents.png'
import excel from '../../assets/Images/excel.png'
import alert from '../../assets/Images/alert.svg'
import referral from "../../assets/icon/referral.svg"
import Report from '../../assets/icon/Group 19092.svg'
import FooterMaster from '../Dashboard/FooterMaster'
import Member from '../../assets/Doctor_web_Icon/Visit.svg'
import Fasttrack from '../../assets/Doctor_web_Icon/Fasttrack.svg'
import Online from '../../assets/Doctor_web_Icon/Online.svg'
import Walkin from '../../assets/Doctor_web_Icon/Walkin.svg'
import R from '../../assets/Doctor_web_Icon/ref.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { Pagination } from 'swiper/modules';
// import User from '../../assets/Doctor_web_Icon/user.png'
import User from '../../assets/Doctor_web_Icon/Group 23213.svg'
import MaleIcon from '../../assets/clinicIcon/MaleJpg.jpg'
import FemaleIcon from '../../assets/clinicIcon/FeMaleJpg.jpg'
import { FeaturedPlayListOutlined } from "@material-ui/icons";
const QueueComponent = () => {
    let Var_History = useHistory();
    const swiperRef = useRef(null);
    let interval;
    var [Var_Date, setVar_Date, Var_DateRef] = useStateRef("")
    var [Var_DateApi, setVar_DateApi, dateRefapi] = useStateRef("")
    const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
    const [Vartotalappointmentcount, setVartotalappointmentcount, Vartotalappointmentcount_Ref] = useStateRef("");
    const [Vartotalcheckincount, setVartotalcheckincount, Vartotalcheckincount_Ref] = useStateRef("");
    const [checkInResult, setcheckInResult, checkInResult_Ref] = useStateRef([]);
    const [Var_QueueCheckin, setVar_QueueCheckin, Var_QueueCheckin_Ref] = useStateRef(false);
    const [Var_Profiledata, setVar_Profiledata] = React.useState([]);
    const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor] = React.useState([]);
    var [coverFlowList, setCoverFlowList, coverFlowList_Ref] = useStateRef([]);
    var [coverFlowListArrived, setCoverFlowListArrived, coverFlowListArrived_Ref] = useStateRef([]);
    var [activeSlide, setactiveSlide] = useState()
    var [activeSlideData, setactiveSlideData] = useState({})
    var [checkIn, setcheckIn] = useState({ inhouse: 0, member: 0, online: 0, vip: 0, walkin: 0 })
    var [countInfo, setcountInfo] = useState({ inhouse: 0, member: 0, online: 0, vip: 0, walkin: 0 })
    var [totalSlideData, setTotalSlideData] = useState([])
    var [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect] = useState("");
    var [firstActiveSlide, setFirstActiveSlide] = useState("");
    var [Var_QueueData, setVar_QueueData] = useState(false);

    // initial function call start
    useEffect(() => {
        setVar_doctorSpecialitySelect(Service.doctorDetail)
        getClinicWiseDoctor();
        setVar_Date(moment(new Date().toLocaleDateString('fr-CA')).format("DD-MMM-YY"));
        setVar_DateApi(new Date().toLocaleDateString('fr-CA'));
        getQueueList()
        return () => {
            clearInterval(interval); // Cleanup the interval on component unmount
        };




    }, []);


    // initial function call End

    // navigattion to back start
    const navigateToBack = (path) => {
        Var_History.push("/dashboard" + path)
    };
    // navigattion to back end


    // =============== Get Next Date start ==========================================
    const nextdate = () => {
        let date = new Date(Var_DateRef.current);
        date.setDate(date.getDate() + 1);
        let options = {
            day: '2-digit',
            month: 'short',
            year: '2-digit'
        };

        let formattedDate = date.toLocaleDateString('en-US', options);
        var monthsegment = moment(formattedDate).format("DD-MMM-YY")
        setVar_Date(monthsegment);
        setVar_DateApi(date.toLocaleDateString('fr-CA'));
        getQueueList()
    }
    // =============== Get Next Date End ==========================================

    // =============== Get Pervious Date start ==========================================
    const perviousdate = () => {
        let date = new Date(Var_DateRef.current);
        date.setDate(date.getDate() - 1);
        let options = {
            day: '2-digit',
            month: 'short',
            year: '2-digit'
        };

        let formattedDate = date.toLocaleDateString('en-US', options);
        var monthsegment = moment(formattedDate).format("DD-MMM-YY")
        setVar_Date(monthsegment);
        setVar_DateApi(date.toLocaleDateString('fr-CA'));
        getQueueList()
    }
    // =============== Get Pervious Date End ==========================================

    // const getClinicWiseDoctor = () => {
    //     var data = {
    //         clinic_id: localStorage.getItem("clinic_id")
    //     };
    //     Axios
    //         .post("clinic/getClinicWiseDoctor", data)
    //         .then((response) => {
    //             var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id"));
    //             setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }


    const convertTo12HrFormat = (time24) => {
        var timeArr = time24.split(':');
        var hours = parseInt(timeArr[0]);
        var minutes = parseInt(timeArr[1]);
        var suffix = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var time12 = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + suffix;
        return time12;
    }



    const getClinicWiseDoctor = () => {
        debugger;
        // setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("clinic_id")
        };
        Axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {
                debugger;
                var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
                setVar_DoctorDetailShow(filteredData[0].doctorName + ',' + " " + filteredData[0].specialityname);
                // setLoading(false);
            })
            .catch((error) => {
                // setLoading(false);
                console.log(error);
            });
    }


    const getQueueList = () => {
        var data = {
            clinic_id: localStorage.getItem("clinic_id"),
            doctor_id: localStorage.doctor_id,
            book_date: moment(new Date(Var_DateRef.current)).format("YYYY-MM-DD")
        };
        Axios({
            method: "POST",
            url: "doctor/getQueueList",
            data
        }).then((response) => {
            if (response.data.status) {

                setcheckIn(response.data.data[0].checkIn)
                setcountInfo(response.data.data[0].countInfo)
                setcheckInResult(response.data.data[0].result)
                setVartotalappointmentcount(response.data.data[0].result.filter(x => x.appointment_type_id >= 0))
                setVartotalcheckincount(response.data.data[0].result.filter(x => x.check_in_status == 1))
                // setVarnotificationcount(response.data.data.filter(x => x.to_date == formattedDate && (x.speciality_id == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id")) || (x.ref_speciality_id == localStorage.getItem("speciality_Id") && x.ref_doctor_id == localStorage.getItem("doctor_id"))))

                //   setCoverFlowList(response.data.data[0].result)
                // setCoverFlowList(response.data.data[0].result.filter(x=>x.arrived_status=="1"))
                setactiveSlideData({});
                var array1 = [];
                var array2 = [];
                let active_slide = '';
                if (response.data.data[0].result.length > 0) {

                    response.data.data[0].result.map((obj, index) => {

                        if (obj['token_no'] == 1 && active_slide == '') {
                            if (index == 0 && obj['token_no'] == 1) {
                                let appointment_date_time = moment(obj.appointment_date.split(" ")[0] + " " + obj.appointment_time, "YYYY-MM-DD hh:mm:ss")
                                let differnt = appointment_date_time.diff(moment(), 'minutes')
                                if (differnt > 0) {
                                    active_slide = 0
                                    setactiveSlideData({})
                                    // active_slide = obj['queueno']
                                    // setactiveSlideData(obj)
                                }
                                else {
                                    active_slide = obj['queueno']
                                    setactiveSlideData(obj)
                                }

                            }
                            else {
                                active_slide = obj['queueno']
                                setactiveSlideData(obj)
                            }

                        }


                        // if (obj.checked_in != null) {

                        //     active_slide = obj['queueno']
                        //     // setactiveSlideData(obj)
                        // }
                        if (obj.checked_in !== null) {
                            array1.push(obj);
                            setVar_QueueCheckin(true);
                            setVar_QueueData(true);
                            console.log(Var_QueueCheckin, "Var_QueueCheckin");
                        }
                        else {
                            array2.push(obj);
                            setVar_QueueData(false);
                        }
                        console.log(Var_QueueCheckin, "Var_QueueCheckin");
                    })

                    array1 = array1.concat(array2);
                    setCoverFlowList(array1.filter(x => x.arrived_status == "1"))
                    setCoverFlowListArrived(array1.filter(x => x.arrived_status == "1"))

                    // active_slide = active_slide === '' ? array1.filter(x => x.checked_in !== null).length: active_slide
                    // setactiveSlide(active_slide);
                    array1.forEach((element, index) => {
                        if (element.checked_in != null && element.arrived_status == "1") {
                            active_slide = index + 1
                            setactiveSlide(active_slide);
                            setactiveSlideData(element);

                        }
                    });
                    setFirstActiveSlide(active_slide)



                    setTotalSlideData(response.data.data[0].result)

                    // setTimeout(() => {
                    //     if (swiperRef.current.swiper) {
                    //         swiperRef.current.swiper.update();
                    //         const activeSlide = swiperRef.current.swiper.slides[active_slide];
                    //         activeSlide.click();
                    //     }

                    // }, 100);

                    if (coverFlowList_Ref.current.length > 0) {
                        setTimeout(() => {
                            debugger;
                            if (swiperRef.current && swiperRef.current.swiper) {
                                swiperRef.current.swiper.update();
                                const activeSlide = swiperRef.current.swiper.slides[active_slide];
                                if (activeSlide != undefined && activeSlide != null) {
                                    activeSlide?.click();
                                    const swiperWidth = swiperRef.current.swiper.width;
                                    const slideWidth = activeSlide.clientWidth;
                                    const centerOffset = (swiperWidth - slideWidth) / 2;
                                    if (activeSlide.offsetLeft < 1000) {
                                        swiperRef.current.swiper.setTranslate(-activeSlide.offsetLeft + centerOffset);
                                        console.log(activeSlide.offsetLeft, "one-1");
                                    } else {
                                        if (activeSlide.swiperSlideIndex >= 15 && activeSlide.swiperSlideIndex <= 20) {
                                            swiperRef.current.swiper.setTranslate(-1500 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-1");
                                        } else if (activeSlide.swiperSlideIndex >= 21 && activeSlide.swiperSlideIndex <= 25) {
                                            swiperRef.current.swiper.setTranslate(-1800 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-2");
                                        } else if (activeSlide.swiperSlideIndex >= 26 && activeSlide.swiperSlideIndex <= 30) {
                                            swiperRef.current.swiper.setTranslate(-2300 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-3");
                                        } else if (activeSlide.swiperSlideIndex >= 31 && activeSlide.swiperSlideIndex <= 35) {
                                            swiperRef.current.swiper.setTranslate(-2800 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-4");
                                        } else if (activeSlide.swiperSlideIndex >= 36 && activeSlide.swiperSlideIndex <= 40) {
                                            swiperRef.current.swiper.setTranslate(-3300 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-5");
                                        } else if (activeSlide.swiperSlideIndex >= 41 && activeSlide.swiperSlideIndex <= 45) {
                                            swiperRef.current.swiper.setTranslate(-3800 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-6");
                                        } else if (activeSlide.swiperSlideIndex >= 46 && activeSlide.swiperSlideIndex <= 50) {
                                            swiperRef.current.swiper.setTranslate(-4300 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-7");
                                        } else if (activeSlide.swiperSlideIndex >= 51 && activeSlide.swiperSlideIndex <= 55) {
                                            swiperRef.current.swiper.setTranslate(-4700 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-8");
                                        } else if (activeSlide.swiperSlideIndex >= 56 && activeSlide.swiperSlideIndex <= 60) {
                                            swiperRef.current.swiper.setTranslate(-5200 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-9");
                                        } else if (activeSlide.swiperSlideIndex >= 61 && activeSlide.swiperSlideIndex <= 65) {
                                            swiperRef.current.swiper.setTranslate(-5700 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-10");
                                        } else if (activeSlide.swiperSlideIndex >= 66 && activeSlide.swiperSlideIndex <= 70) {
                                            swiperRef.current.swiper.setTranslate(-6150 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-11");
                                        } else if (activeSlide.swiperSlideIndex >= 71 && activeSlide.swiperSlideIndex <= 75) {
                                            swiperRef.current.swiper.setTranslate(-6700 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-12");
                                        } else if (activeSlide.swiperSlideIndex >= 76 && activeSlide.swiperSlideIndex <= 80) {
                                            swiperRef.current.swiper.setTranslate(-7100 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-13");
                                        } else if (activeSlide.swiperSlideIndex >= 81 && activeSlide.swiperSlideIndex <= 85) {
                                            swiperRef.current.swiper.setTranslate(-7700 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-14");
                                        } else if (activeSlide.swiperSlideIndex >= 86 && activeSlide.swiperSlideIndex <= 90) {
                                            swiperRef.current.swiper.setTranslate(-8100 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-15");
                                        } else if (activeSlide.swiperSlideIndex >= 91 && activeSlide.swiperSlideIndex <= 95) {
                                            swiperRef.current.swiper.setTranslate(-8600 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-16");
                                        } else if (activeSlide.swiperSlideIndex >= 96 && activeSlide.swiperSlideIndex <= 100) {
                                            swiperRef.current.swiper.setTranslate(-9100 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-17");
                                        } else if (activeSlide.swiperSlideIndex >= 101 && activeSlide.swiperSlideIndex <= 105) {
                                            swiperRef.current.swiper.setTranslate(-9600 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-18");
                                        } else if (activeSlide.swiperSlideIndex >= 106 && activeSlide.swiperSlideIndex <= 110) {
                                            swiperRef.current.swiper.setTranslate(-10100 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-19");
                                        } else {
                                            swiperRef.current.swiper.setTranslate(-1000 + centerOffset);
                                            console.log(activeSlide.offsetLeft, "two-2-20");
                                        }
                                    }
                                }
                            }

                        }, 100);
                    }
                    else {
                        if (coverFlowListArrived_Ref.current.length > 0 && Var_QueueData != true) {
                            setTimeout(() => {
                                debugger;
                                if (swiperRef.current && swiperRef.current.swiper) {
                                    swiperRef.current.swiper.update();
                                    const activeSlide = swiperRef.current.swiper.slides[active_slide];
                                    activeSlide.click();
                                    const swiperWidth = swiperRef.current.swiper.width;
                                    const slideWidth = activeSlide.clientWidth;
                                    const centerOffset = (swiperWidth - slideWidth) / 2;
                                    if (activeSlide.offsetLeft < 1000) {
                                        swiperRef.current.swiper.setTranslate(-activeSlide.offsetLeft + centerOffset);
                                        console.log(activeSlide.offsetLeft, "one");
                                    } else {
                                        swiperRef.current.swiper.setTranslate(-1000 + centerOffset);
                                        console.log(activeSlide.offsetLeft, "two");
                                    }
                                }

                            }, 100);
                        }
                    }


                }


            }
            clearInterval(interval);
            interval = setInterval(() => {
                getQueueList()
            }, 60000); // 1000ms interval


        }).catch((error) => {
            console.log(error);
        });
    }

    const onChangeSlide = (index, data) => {
        setVar_QueueData(true);
        setactiveSlide(index)
        setactiveSlideData(data)
        changeSlidePositionCenter(index)

    }

    const changeSlidePositionCenter = (index) => {
        if (swiperRef.current.swiper) {
            swiperRef.current.swiper.update();
            const activeSlide = swiperRef.current.swiper.slides[index];
            const swiperWidth = swiperRef.current.swiper.width;
            const slideWidth = activeSlide.clientWidth;
            const centerOffset = (swiperWidth - slideWidth) / 2;
            // swiperRef.current.swiper.setTranslate(-activeSlide.offsetLeft + centerOffset);
            if (activeSlide.offsetLeft < 1000) {
                swiperRef.current.swiper.setTranslate(-activeSlide.offsetLeft + centerOffset);
                console.log(activeSlide.offsetLeft, "one-3");
            } else {
                if (index >= 15 && index <= 20) {
                    swiperRef.current.swiper.setTranslate(-1500 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-1");
                } else if (index >= 21 && index <= 25) {
                    swiperRef.current.swiper.setTranslate(-1800 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-2");
                } else if (index >= 26 && index <= 30) {
                    swiperRef.current.swiper.setTranslate(-2300 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-3");
                } else if (index >= 31 && index <= 35) {
                    swiperRef.current.swiper.setTranslate(-2800 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-4");
                } else if (index >= 36 && index <= 40) {
                    swiperRef.current.swiper.setTranslate(-3300 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-5");
                } else if (index >= 41 && index <= 45) {
                    swiperRef.current.swiper.setTranslate(-3800 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-6");
                } else if (index >= 46 && index <= 50) {
                    swiperRef.current.swiper.setTranslate(-4300 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-7");
                } else if (index >= 51 && index <= 55) {
                    swiperRef.current.swiper.setTranslate(-4700 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-8");
                } else if (index >= 56 && index <= 60) {
                    swiperRef.current.swiper.setTranslate(-5150 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-9");
                } else if (index >= 61 && index <= 65) {
                    swiperRef.current.swiper.setTranslate(-5650 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-10");
                } else if (index >= 66 && index <= 70) {
                    swiperRef.current.swiper.setTranslate(-6150 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-11");
                } else if (index >= 71 && index <= 75) {
                    swiperRef.current.swiper.setTranslate(-6650 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-12");
                } else if (index >= 76 && index <= 80) {
                    swiperRef.current.swiper.setTranslate(-7150 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-13");
                } else if (index >= 81 && index <= 85) {
                    swiperRef.current.swiper.setTranslate(-7650 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-14");
                } else if (index >= 86 && index <= 90) {
                    swiperRef.current.swiper.setTranslate(-8150 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-15");
                } else if (index >= 91 && index <= 95) {
                    swiperRef.current.swiper.setTranslate(-8650 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-16");
                } else if (index >= 96 && index <= 100) {
                    swiperRef.current.swiper.setTranslate(-9150 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-17");
                } else if (index >= 101 && index <= 105) {
                    swiperRef.current.swiper.setTranslate(-9650 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-18");
                } else if (index >= 106 && index <= 110) {
                    swiperRef.current.swiper.setTranslate(-10100 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-19");
                } else {
                    swiperRef.current.swiper.setTranslate(-1000 + centerOffset);
                    console.log(activeSlide.offsetLeft, "two-3-20");
                }
            }
        }

    }

    const getAppointmentImageBytype = (data) => {
        switch (data) {
            case "Fast Track":
                return Fasttrack;
                break;
            case "In-Clinic":
                return Member
                break
            case "Online":
                return Online
                break
            case "Walk-in":
                return Walkin
                break

        }
    }
    return (

        <div>
            <div>
                {/* =================== Header Starts ============================== */}
                <div className="head_of_the_page">
                    <div className="sub_header">
                        <div className="sub_header_body">
                            <div className="back_navigation"
                                onClick={() => navigateToBack("")}
                            >
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                                <span>QUEUE</span>
                            </div>
                            <div className="flex_grow"></div>
                            <div className="header_right_content">
                                <div>
                                    <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            SelectProps={{
                                                renderValue: (p) => p
                                            }}
                                            value={Var_DoctorDetailShow_Ref.current}
                                            readOnly={true}
                                            renderValue={(p) => p}

                                        >
                                        </Select>
                                        {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                    </FormControl>
                                </div>
                                <div>
                                    <div className='page_head_date_container'>
                                        <Button className='date_left_button'
                                            onClick={() => {
                                                perviousdate();
                                            }}
                                        >
                                            <i className="fa fa-chevron-left date_left_button_icon" aria-hidden="true" />
                                        </Button>
                                        <Button className='date_button'>{Var_Date}</Button>
                                        <Button className='date_right_button'
                                            onClick={() => {
                                                nextdate();
                                            }}
                                        >
                                            <i className="fa fa-chevron-right date_right_button_icon" aria-hidden="true" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* =================== Header End ============================== */}

                {/* =================== Content Starts ============================== */}
                <div>
                    <div className='content_view'>

                        {/* =================== Profile Types  Starts ============================== */}
                        <Card className='Counts'>
                            <Container>
                                <Grid container className="queue_page">
                                    <Grid item xs='6' className='data_column border_line'>

                                        <div className="total_appointment_count">
                                            <div>
                                                <p>Total Appointments :</p>
                                            </div>
                                            <div>
                                                <p className="total_count_value">{Vartotalappointmentcount_Ref.current.length}</p>
                                            </div>
                                        </div>

                                        <div>
                                            <Grid container className='data_row'>
                                                <Grid item xs='4' className='imgpadding'>
                                                    <Grid container>
                                                        <Grid item xs='8' style={{ display: "flex" }}>
                                                            <img src={Fasttrack} alt='' className='queueicon' align="start" />
                                                            <p>Fast Track </p>
                                                        </Grid>
                                                        <Grid item xs='4'>
                                                            <div className='single_appointment_count'>
                                                                <p>: {countInfo.vip || 0}</p>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs='4' className='imgpadding'>
                                                    <Grid container>
                                                        <Grid item xs='8' style={{ display: "flex" }}>
                                                            <img src={Walkin} alt='' className='queueicon' align="start" />
                                                            <p>Walk-in</p></Grid>
                                                        <Grid item xs='4'> <div className='single_appointment_count'>
                                                            <p>: {countInfo.walkin || 0}</p>
                                                        </div></Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs='4' className='imgpadding'>
                                                    <Grid container>
                                                        <Grid item xs='8' style={{ display: "flex" }}>
                                                            <img src={Member} alt='' className='queueicon' align="start" />
                                                            <p>In-Clinic</p>
                                                        </Grid>
                                                        <Grid item xs='4'>
                                                            <div className='single_appointment_count'>
                                                                <p>: {countInfo.member || 0}</p>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs='4' className='imgpadding'>
                                                    <Grid container>
                                                        <Grid item xs='8' style={{ display: "flex" }}>
                                                            <img src={Online} alt='' className='queueicon' align="start" />
                                                            <p>Online</p></Grid>
                                                        <Grid item xs='4'>
                                                            <div className='single_appointment_count'>
                                                                <p>: {countInfo.online || 0}</p>
                                                            </div></Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs='4' className='imgpadding'>
                                                    <Grid container>
                                                        <Grid item xs="8" style={{ display: "flex" }}>
                                                            <img src={R} alt='' className='queueicon' align="start" />
                                                            <p>Referral</p></Grid>
                                                        <Grid item xs="4">
                                                            <div className='single_appointment_count'>
                                                                <p>: {countInfo.online || 0}</p>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs='4' className='imgpadding'></Grid>
                                            </Grid>
                                        </div>
                                        <div>
                                        </div>
                                    </Grid>
                                    <Grid item xs='6' className='data_column' style={{ paddingLeft: '43px' }}>

                                        <div className="total_appointment_count">
                                            <div>
                                                <p>Checked-in / Started session :</p>
                                            </div>
                                            <div>
                                                <p className="total_count_value">{Vartotalcheckincount_Ref.current.length}</p>
                                            </div>
                                        </div>
                                        <Grid container className='data_row'>
                                            <Grid item xs='4' className='imgpadding'>
                                                <Grid container>
                                                    <Grid item xs="8" style={{ display: "flex" }}>
                                                        <img src={Fasttrack} alt='' className='queueicon' align="start" />
                                                        <p>Fast Track</p>
                                                    </Grid>
                                                    <Grid item xs="4">
                                                        <div className='single_appointment_count'>
                                                            <p>: {checkIn.vip || 0}</p>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs='4' className='imgpadding'>
                                                <Grid container >
                                                    <Grid item xs="8" style={{ display: "flex" }}>
                                                        <img src={Walkin} alt='' className='queueicon' align="start" />
                                                        <p>Walk-in</p>
                                                    </Grid>
                                                    <Grid item xs="4">
                                                        <div className='single_appointment_count'>
                                                            <p>: {checkIn.walkin || 0}</p>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs='4' className='imgpadding'>
                                                <Grid container >
                                                    <Grid item xs="8" style={{ display: "flex" }}>
                                                        <img src={Member} alt='' className='queueicon' align="start" />
                                                        <p>In-Clinic</p>
                                                    </Grid>
                                                    <Grid item xs="4">
                                                        <div className='single_appointment_count'>
                                                            <p>: {checkIn.member || 0}</p>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs='4' className='imgpadding'>
                                                <Grid container >
                                                    <Grid item xs="8" style={{ display: "flex" }}>
                                                        <img src={Online} alt='' className='queueicon' align="start" />
                                                        <p>Online</p>
                                                    </Grid>
                                                    <Grid item xs="4">
                                                        <div className='single_appointment_count'>
                                                            <p>: {checkIn.online || 0}</p>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs='4' className='imgpadding'>
                                                <Grid container >
                                                    <Grid item xs="8" style={{ display: "flex" }}>
                                                        <img src={R} alt='' className='queueicon' align="start" />
                                                        <p>Referral</p>
                                                    </Grid>
                                                    <Grid item xs="4">
                                                        <div className='single_appointment_count'>
                                                            <p>: {checkIn.online || 0}</p>
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                            <Grid item xs='4' className='imgpadding'></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Card>
                        {/* =================== Profile Types  End ============================== */}

                        {/* =================== Queues Detials  Starts ============================== */}

                        <div>
                            <Card className="clinic_Queue">
                                <Grid container className="">
                                    <Grid sm="12" className="slider_common_div">
                                        {checkInResult.length != 0 && coverFlowList != 0 && <Swiper
                                            modules={[Pagination]}
                                            slidesPerView={9}
                                            pagination={{ clickable: true }}
                                            initialSlide={activeSlide}
                                            slideToClickedSlide={true}
                                            centeredSlides={true}
                                            centeredSlidesBounds={true}
                                            ref={swiperRef}
                                            className="queue_swiper"
                                        >
                                            <SwiperSlide></SwiperSlide>
                                            {Var_QueueCheckin == true && coverFlowList.map((obj, index) => {

                                                return (
                                                    <SwiperSlide onClick={(e) => { onChangeSlide(index + 1, obj) }} style={{ transform: 'translate3d(0px ,0px,0px)!important' }}>
                                                        <div className={`${activeSlide == index + 1 ? (activeSlide == index ? 'completed_active' : 'active') : activeSlide < index + 1 ? (obj['check_in_status'] == "0" ? 'next_slide' : '') : ''} slide_div`}
                                                            style={{
                                                                zIndex: activeSlide >= index + 1 ? 100 + index : 100 - index, position: 'relative'
                                                            }}>
                                                            <img src={getAppointmentImageBytype(obj['appointment_type'])} className="slide_appopintment_type" />
                                                            <img src={obj['appointment_type_id'] == 0 && obj['gender'] == "Male" ? MaleIcon : obj['appointment_type_id'] == 0 && obj['gender'] == "Female" ? FemaleIcon : obj['file_name'] == '' && obj['gender'] == "Male" ? MaleIcon : obj['file_name'] == '' && obj['gender'] == "Female" ? FemaleIcon : obj['file_name'] == 'null' && obj['gender'] == "Male" ? MaleIcon : obj['file_name'] == 'null' && obj['gender'] == "Female" ? FemaleIcon : obj['file_name'] == 'undefined' && obj['gender'] == "Male" ? MaleIcon : obj['file_name'] == 'undefined' && obj['gender'] == "Female" ? FemaleIcon : obj['file_name']} />
                                                            <div className="coverflow_label"> {index + 1}
                                                            </div>
                                                        </div> </SwiperSlide>)
                                            })}
                                            {Var_QueueCheckin == false && coverFlowList.map((obj, index) => {

                                                return (
                                                    <SwiperSlide onClick={(e) => { onChangeSlide(index + 1, obj) }} >
                                                        <div className={`${activeSlide == index + 1 ? (activeSlide == index ? 'completed_active' : 'active') : activeSlide < index + 1 ? (obj['check_in_status'] == "0" ? 'next_slide' : '') : ''} slide_divs`}
                                                            style={{
                                                                zIndex: activeSlide >= index + 1 ? 100 + index : 100 - index, position: 'relative'
                                                            }}>
                                                            <img src={getAppointmentImageBytype(obj['appointment_type'])} className="slide_appopintment_type" />
                                                            <img src={obj['appointment_type_id'] == 0 && obj['gender'] == "Male" ? MaleIcon : obj['appointment_type_id'] == 0 && obj['gender'] == "Female" ? FemaleIcon : obj['file_name'] == '' && obj['gender'] == "Male" ? MaleIcon : obj['file_name'] == '' && obj['gender'] == "Female" ? FemaleIcon : obj['file_name'] == 'null' && obj['gender'] == "Male" ? MaleIcon : obj['file_name'] == 'null' && obj['gender'] == "Female" ? FemaleIcon : obj['file_name'] == 'undefined' && obj['gender'] == "Male" ? MaleIcon : obj['file_name'] == 'undefined' && obj['gender'] == "Female" ? FemaleIcon : obj['file_name']} />
                                                            <div className="coverflow_label"> {index + 1}
                                                            </div>
                                                        </div> </SwiperSlide>)
                                            })}
                                            <SwiperSlide></SwiperSlide>
                                        </Swiper>}
                                    </Grid>

                                </Grid>
                            </Card>
                        </div>
                        {/* =================== Queues Detials  Starts ============================== */}

                        {/* =================== Profile Detials  Starts ============================== */}

                        <div>
                            <div>
                                {Var_QueueData == true && <div className='profile_details_prescription queue_profile_details_prescription'>
                                    <div className='profile_detail_container'>
                                        {activeSlideData?.file_name &&
                                        <img src={(activeSlideData.file_name != '' && activeSlideData.file_name != 'null' && activeSlideData.file_name != 'undefined' &&activeSlideData?.file_name) ? activeSlideData.file_name : (activeSlideData.gender == "Male" || activeSlideData.gender == "male") ? MaleIcon : (activeSlideData.gender == "Female" || activeSlideData.gender == "female") ? FemaleIcon : ""} className='profile_Details' />}
                                        {/* <img src={activeSlideData.file_name == '' && activeSlideData.age == "male" ? MaleIcon : activeSlideData.file_name == '' && activeSlideData.age == "Male" ? MaleIcon :
                                            activeSlideData.file_name == '' && activeSlideData.age == "female" ? FemaleIcon : activeSlideData.file_name == '' && activeSlideData.age == "Female" ? FemaleIcon :
                                                activeSlideData.file_name == 'null' && activeSlideData.age == "male" ? MaleIcon : activeSlideData.file_name == 'null' && activeSlideData.age == "Male" ? MaleIcon :
                                                    activeSlideData.file_name == 'null' && activeSlideData.age == "female" ? FemaleIcon : activeSlideData.file_name == 'null' && activeSlideData.age == "Female" ? FemaleIcon :
                                                        activeSlideData.file_name == 'undefined' && activeSlideData.age == "male" ? MaleIcon : activeSlideData.file_name == 'undefined' && activeSlideData.age == "Male" ? MaleIcon :
                                                            activeSlideData.file_name == 'undefined' && activeSlideData.age == "female" ? FemaleIcon : activeSlideData.file_name == 'undefined' && activeSlideData.age == "Female" ? FemaleIcon :
                                                                activeSlideData.file_name} className='profile_Details' /> */}
                                        <div className='detailsprofile'>
                                            <p className='profile_name'>{activeSlideData.name || ''}</p>
                                            <div style={{ display: "flex" }}>
                                                {activeSlideData.age != '0' && < p className='profile_age'>{(activeSlideData.age != "" && activeSlideData.age != 'null' && activeSlideData.age != 'undefined' &&activeSlideData?.age) ? (activeSlideData.age + " " + "yrs,") : ""} </p>}
                                                <p className='profile_age'><span style={{ textTransform: "capitalize", paddingLeft: '3px' }}> {activeSlideData.gender || ''}</span></p>
                                            </div>
                                            <p className='profile_number'>{(activeSlideData.mobile_no != "" && activeSlideData.mobile_no != 'null' && activeSlideData.mobile_no != 'undefined') ? activeSlideData.mobile_no : ''}</p>
                                            {/* <p className='profile_age'> {activeSlideData.referred_by != '' && activeSlideData.referred_by ? `Referred by ${activeSlideData.referred_by}` : ''}</p> */}
                                        </div>
                                    </div>
                                    <div className="flex_grow"></div>
                                    <div className='profile_detail_type'>
                                        {/* <img src={Member} alt='pro' className='Profoile_Type' /> */}
                                        <p className='report_date'>{activeSlideData.appointment_date ? moment(activeSlideData.appointment_date.split(" ")[0], "YYYY-MM-DD").format("DD-MMM-YY") : ''}</p>
                                        {/* <p className='profile_time'>{activeSlideData.formatted_appointment_time || ''}</p> */}
                                        <p className='profile_time'>{activeSlideData.formatted_appointment_time == null ? '' : convertTo12HrFormat(activeSlideData.formatted_appointment_time)}</p>

                                    </div>
                                </div>}
                            </div>
                        </div>
                        {/* =================== Profile Detials  End ============================== */}

                    </div>
                </div>
                {/* =================== Content End ============================== */}
                {/* =================== Footer Starts ============================== */}
                <div className="layout_footer footer">
                    <FooterMaster />
                </div>
                {/* =================== Footer End ============================== */}

            </div>
        </div >
    )

}

export default QueueComponent;