// AUTHOR : Dinesh
// CR-DATE: 20-July-2023
// MOD-DATE: 21-July-2023
// DESCRIPTION: Add Support Update work

import { useHistory } from "react-router-dom";
import Axios from "axios";
import useStateRef from "react-usestateref";
import axios from "axios";
import React, { useState, useRef, useEffect } from 'react';
import { notification} from "antd";
import MicRecorder from 'mic-recorder-to-mp3';

import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";

// Mui meterial component import
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';

import './AddSupport.css'
import FooterMaster from '../Dashboard/FooterMaster'
import startAudio from "../../assets/Doctor_web_Icon/mic.png";
import stopAudio from "../../assets/Doctor_web_Icon/stoprecord.png";
import uploadDocument from "../../assets/Doctor_web_Icon/submit.png";
import successModelTickImg from '../../assets/Doctor_web_Icon/success.svg'
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';
import Eye from '../../assets/Doctor_web_Icon/Eye.png'
import closeicon from '../../assets/icon/Icon ionic-ios-close.png';
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
function AddSupportComponent() {
    let Var_History = useHistory();
    const [Var_Remarks, setVar_Remarks, Var_Remarks_Ref] = useStateRef("");
    const [, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
    const [, setVar_FileNameDocument, Var_FileNameDocument_Ref] = useStateRef("");
    const [, setVar_FileDocument, Var_FileDocument_Ref] = useStateRef("");
    const [, setVar_FileAudio, Var_FileAudio_Ref] = useStateRef("");
    const [Var_audiopath, setVar_audiopath, Var_audiopath_Ref] = useStateRef("");
    const [Var_filename, setVar_filename, ] = useStateRef("");
    const [, setSuportTicketNo, Var_SuportTicketNo_Ref] = useStateRef(true);

    const [Var_SelectDocumentFile, setVar_SelectDocumentFile, Var_SelectDocumentFile_Ref] = useStateRef("");
    const [Var_AudioImage, setVar_AudioImage, Var_AudioImage_Ref] = useStateRef(startAudio);
    const [Var_AudioAnime, setVar_AudioAnime, Var_AudioAnime_Ref] = useStateRef(false);
    const [isshowsuccess, setishiddensuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [Var_progress, setVar_Progress] = useState(0);
    const [, setVar_buttoncolor] = React.useState("#510F30");
    const [Var_fileprocess, setVar_fileprocess, ] = React.useState(false);
    const [Var_Audioprogress, setVar_Audioprogress] = useState(0);
    const [Var_Audioprocess, setVar_Audioprocess, ] = React.useState(false);
    const [Var_isDisable, setVar_isDisable, ] = React.useState(false);
    const [openRecordModal, setopenRecordModal,openRecordModal_Ref ] = useStateRef(false);
    const [audioDetails, setaudioDetails,audioDetails_Ref ] = useStateRef({
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0,
        },
      });
    // Voice Recording Controlls Start

    const [isPlaying, setIsPlaying] = React.useState(false);
    const [state, setState] = React.useState({
        isRecording: false,
        blobURL: '',
        isBlocked: false,
        trackProgress: 0,

    });
    let musicRef = React.useRef(null);
    let intervalRef = React.useRef(null);
    const [audioUL, setaudioUL] = React.useState(false);

    // Voice Recording Controlls End


    useEffect(() => {
        getClinicWiseDoctor();
        getMedia();
        // );
        setSuportTicketNo("");
        async function getMedia(constraints) {
            let stream = null;
            try {
                stream = await navigator.mediaDevices.getUserMedia(constraints, { audio: true });
                /* use the stream */
            } catch (err) {
                /* handle the error */
            }
        }

        // audio controlls end
        setVar_isDisable(false);
    }, []);

    React.useEffect(() => {
        if (isPlaying) {
            musicRef?.current?.play?.();
            startTimer();
        } else {
            musicRef?.current?.pause?.();
            clearInterval(intervalRef.current);
        }
    }, [isPlaying]);

    // navigation to back start
    const handleOnClick = (path) => {
        Var_History.push("/dashboard" + path)
    };
    // navigation to back end
    const selectDocumentFile = () => {
        // Trigger the file select dialog when the div is clicked
        const input = document.createElement("input");
        input.type = "file";
        // input.accept = "image/*"; // You can specify the accepted file types here if needed
        input.onchange = (event) => {
            // Handle the selected file(s) here
            documentuploadAdd(event);
        };
        input.click();

    }
    // Audio Upload AddSupport start
    const audiouploadAdd = async (e) => {
        setVar_isDisable(true);
        setVar_Audioprocess(true)
        setVar_Audioprogress(0)
        setVar_buttoncolor("#D4C3CC")
        const formData = new FormData();
        formData.append('file', e);
        formData.append('module_id', "3");
        formData.append('pathLocation', "SUPPORT/");
        axios({
            method: "POST",
            url: "admin/awsS3FileUpload",
            data: formData,
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log("progress", percentage)
                setVar_Audioprogress(percentage)
            },
        }).then((response) => {

            console.log("File", response.data)
            setVar_FileAudio(response.data.filepath.Location);
            setVar_audiopath(getFileNameFromUrl(response.data.filepath.Location));
            setVar_isDisable(false);
        })
            .catch((error) => {
                console.log("Add Support : " + " " + error)
                setVar_Audioprocess(false);
                setVar_isDisable(false);
            });
    };
    // Audio Upload AddSupport End

    // Document Upload AddSupport start
    const documentuploadAdd = async (e) => {
        setVar_fileprocess(true)
        setVar_Progress(0)
        setVar_buttoncolor("#D4C3CC")
        setVar_FileNameDocument(e.target.files[0].name);
        setVar_SelectDocumentFile(e.target.files[0]);
        setVar_isDisable(true);
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('module_id', "3");
        formData.append('pathLocation', "SUPPORT/");
        axios({
            method: "POST",
            url: "admin/awsS3FileUpload",
            data: formData,
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log("progress", percentage)
                setVar_Progress(percentage);
            },
        }).then((response) => {

            console.log("File", response.data)
            setVar_FileDocument(response.data.filepath.Location);
            setVar_filename(getFileNameFromUrl(response.data.filepath.Location));
            setVar_isDisable(false);
        })
            .catch((error) => {
                setVar_fileprocess(false);
                setVar_isDisable(false);
            });
    };
    // Document Upload AddSupport End


    // =======================================================
    const playPauseButton = document.getElementById('playPauseButton');

    // Document Upload AddSupport start
    const submitSupport = () => {
        if (Var_Remarks_Ref.current != "") {
            if (Var_FileDocument_Ref.current != "" || Var_FileAudio_Ref.current != "") {
                if (Var_AudioAnime_Ref.current == false) {
                    setLoading(true);
                    setVar_isDisable(true);
                    const formData = new FormData();
                    formData.append('vendor_id', localStorage.getItem("clinic_id"));
                    formData.append('screen_name', "");
                    formData.append('remarks', Var_Remarks_Ref.current)
                    formData.append('image_files', Var_FileDocument_Ref.current.split('?')[0]);
                    formData.append('audio_files', Var_FileAudio_Ref.current.split('?')[0])
                    formData.append("module_name", "Clinic")

                    axios.post("admin/save_vendor_ticket", formData).then((response) => {

                        if (response.data.status == 1) {
                            console.log("File", response.data)
                            setSuportTicketNo(response.data.data.ticket_no);
                            setishiddensuccess(true);
                            setLoading(false);
                            setVar_isDisable(false);
                        }
                    })
                        .catch((error) => {
                            setLoading(false);
                        });
                } else {
                    notification.error({ message: "Please stop the audio recording." });
                }
            } else {
                notification.error({ message: "Upload Audio or Document" });
            }
        } else {
            notification.error({ message: "Enter Remarks" });
        }
    }
    // Document Upload AddSupport End



    const okClose = () => {
        setishiddensuccess(false);
        handleOnClick("/support")
    }

    const getClinicWiseDoctor = () => {
        setLoading(true);

        let data = {
            clinic_id: localStorage.getItem("clinic_id")
        };
        axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {

                let filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
                setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(() => {
                console.log('Microphone permission already granted.');
                start();
            })
            .catch((error) => {
                console.log('Microphone permission not granted. Requesting permission...');
                notification.error({ message: "Microphone permission not granted. Requesting permission..." });
                navigator.mediaDevices.getUserMedia({ audio: true })
                    .then(() => {
                        console.log('Microphone permission granted.');
                        start();
                    })
                    .catch((error) => {
                        console.error('Error accessing microphone:', error);
                    });
            });
    };

  // audio controls start
    const start = () => {
        if (state.isBlocked) {
            console.log('Permission Denied');
        } else {
            Mp3Recorder.start()
                .then(() => {
                    setState({ isRecording: true });
                })
                .catch((e) => console.error(e));
        }
        setVar_AudioAnime(true);
    };

    const stop = () =>
        Mp3Recorder.stop()
            .getMp3()
            .then(([buffer, blob]) => {

                blob["name"] = "TOM-Suport-" + new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear() + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + new Date().getSeconds() + ".mp3";
                blob["webkitRelativePath"] = "";
                blob["lastModifiedDate"] = new Date();
                var filename = "TOM-Suport-" + new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear() + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + new Date().getSeconds() + ".mp3";;
                var audioFile = new File([blob], filename);

                setVar_AudioAnime(false);
                audiouploadAdd(audioFile);
            })
            .catch((e) => console.log(e));

    const startTimer = () => {
        intervalRef.current = setInterval(() => {
            if (musicRef?.current?.duration === musicRef?.current?.currentTime) {
                musicRef?.current?.pause?.();
                clearInterval(intervalRef?.current);
            }
            setState({
                ...state,
                trackProgress: musicRef?.current?.currentTime,
            });
        }, 100);
    };

    const onChangeTrackProgress = (e) => {
        setState({
            ...state,
            trackProgress: e.target.value,
        });
        musicRef.current.currentTime = e.target.value;
    };
    // audio controls end
    const fileUploadCalcel = (type) => {
        if ("audio" == type) {
            setVar_audiopath('')
            setVar_FileAudio('')
        } else if ("document" == type) {
            setVar_filename('')
            setVar_FileDocument('')

        }

    }
    const getFileNameFromUrl = (url) => {
        const urlObject = new URL(url);
        const pathname = urlObject.pathname;
        return pathname.substring(pathname.lastIndexOf('/') + 1);
      };
       const handleReset = () =>{
        const reset = {
          url: null,
          blob: null,
          chunks: null,
          duration: {
            h: 0,
            m: 0,
            s: 0,
          },
        };
        setaudioDetails(reset);
      }

     const  handleCountDown = (data) => {
        console.log(data);
      }
      const handleAudioUpload = (file) => {
        console.log(file);
        file["name"] = "TOM-Suport-" + new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear() + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + new Date().getSeconds() + ".mp3";
        file["webkitRelativePath"] = "";
        file["lastModifiedDate"] = new Date();
        var filename = "TOM-Suport-" + new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear() + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + new Date().getSeconds() + ".mp3";;
        var audioFile = new File([file], filename);
        audiouploadAdd(audioFile); 
        handleCloseAudioRecord();
      }    

       const handleAudioStop = (data) => {
        console.log(data);
        setaudioDetails(data);
      }
       const handleOpenAudioRecord = () => {
        const reset = {
          url: null,
          blob: null,
          chunks: null,
          duration: {
            h: 0,
            m: 0,
            s: 0,
          },
        };
        setopenRecordModal(true);
        setaudioDetails(reset);
      };
       const handleCloseAudioRecord = () => {
        const reset = {
          url: null,
          blob: null,
          chunks: null,
          duration: {
            h: 0,
            m: 0,
            s: 0,
          },
        };
        setopenRecordModal(false);
        setaudioDetails(reset);
      };

    return (
        <div>
            {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            }
            {/* ================= Add Support sub header start ================= */}
            <div className="sub_header">
                <div className="sub_header_body">
                    <div className="back_navigation" onClick={() => handleOnClick("/support")}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                        <span>ADD SUPPORT</span>
                    </div>
                    <div className="flex_grow"></div>
                    <div className="header_right_content">
                        <div>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    SelectProps={{
                                        renderValue: (p) => p
                                    }}
                                    value={Var_DoctorDetailShow_Ref.current}
                                    readOnly={true}
                                    renderValue={(p) => p}

                                >
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
            {/* ================= Add Support sub header end ================= */}


            {/* ================= Add Support list View  start ================= */}
            <div className='content_viewss'>
                <div className="remarks_content">
                    <span className="input_span">Remarks</span>
                    <TextField
                        className="remarks_field"
                        value={Var_Remarks}
                        onChange={(e) => setVar_Remarks(e.target.value)}
                        variant="outlined"
                        placeholder="Enter remarks ..."
                        sx={{ minWidth: '-webkit-fill-available' }}
                    />
                </div>

                <div className="remarks_content_img">
                    <div style={{width:"22%"}}>
                        {Var_AudioAnime == false &&
                            <div className="remarks_images" onClick={handleOpenAudioRecord}>
                                <img src={Var_AudioImage} className="remarks_img" alt="" ></img>
                                <p>Upload Audio</p>
                            </div>
                        }
         <Dialog
          open={openRecordModal}
          onClose={handleCloseAudioRecord}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "45%",
                borderRadius: "20px",
              },
            },
          }}
        >
 <DialogTitle>
            <div className="upload_instruction_full">
              <h4 className="uploadins_head">VOICE RECORD</h4>
              <img className="upload_ins_close" alt=""  onClick={() => handleCloseAudioRecord()} src={closeicon}></img>
            </div>
          </DialogTitle>
            <DialogContent>
            <Recorder
              record={true}
              hideHeader={true}
              audioURL={audioDetails.url}
              showUIAudio
              handleAudioStop={(data) => handleAudioStop(data)}
              handleAudioUpload={(data) => handleAudioUpload(data)}
              handleCountDown={(data) => handleCountDown(data)}
              handleReset={() => handleReset()}
              mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
            />
            </DialogContent>
        </Dialog>

                        {/* {Var_AudioAnime == true &&
                            <div className="remarks_images" onClick={stop} disabled={!state.isRecording}>
                                <img src={stopAudio} className="remarks_img" alt=""></img>
                                <p>Upload Audio</p>

                                {Var_AudioAnime == true &&
                                    <div style={{ display: "block" }}>
                                        <div class="boxContainer" >
                                            <div class="box box1"></div>
                                            <div class="box box2"></div>
                                            <div class="box box3"></div>
                                            <div class="box box4"></div>
                                            <div class="box box5"></div>
                                            <div class="box box6"></div>
                                            <div class="box box7"></div>
                                            <div class="box box8"></div>
                                            <div class="box box9"></div>
                                            <div class="box box10"></div>
                                            <div class="box box11"></div>
                                            <div class="box box12"></div>
                                            <div class="box box13"></div>
                                            <div class="box box13"></div>
                                            <div class="box box14"></div>
                                            <div class="box box15"></div>
                                            <div class="box box16"></div>
                                            <div class="box box17"></div>
                                            <div class="box box18"></div>
                                            <div class="box box19"></div>
                                            <div class="box box20"></div>
                                        </div>
                                    </div>
                                }

                            </div>

                        } */}
                        {Var_audiopath_Ref.current !== '' &&
                            <div className='button_container' style={{ textAlign: "center" }}>
                                <div className="segment_center">
                                    <div className='report_cancel_button'  style={{display:"flex"}}>
                                        <div className='button_texts'>
                                            <p>{Var_audiopath}</p>
                                        </div>
                                        <div className="close_icons"  onClick={() => fileUploadCalcel("audio")}>X</div>
                                    </div>
                                </div>
                                {Var_Audioprocess &&
                                    <div>
                                        <div className="upload-container" >
                                            {/* Your other UI elements */}
                                            <LinearProgress
                                                variant="determinate"
                                                value={Var_Audioprogress}
                                                color="secondary"
                                                className="progressBar"
                                                sx={{ zIndex: '1', width: '100%', margin: '0px 3px' }}
                                            />
                                            {/* Display progress percentage */}
                                            <div className="progress-text">{`${Var_Audioprogress}%`}</div>
                                        </div>
                                    </div>
                                }
                            </div>}

                    </div>
                    <div style={{width:"22%"}}>
                        <div className="remarks_images" onClick={() => selectDocumentFile()}>
                            <img src={uploadDocument} className="remarks_img" alt=""></img>
                            <p>Upload Document</p>
                        </div>

                        {Var_FileDocument_Ref.current != "" &&
                            <div className='button_container' style={{ textAlign: "center" }}>

                                <div className="segment_center">
                                    <div className='report_cancel_button' style={{display:"flex"}} >
                                        <div className='button_texts'>
                                            <p>{Var_filename}</p>
                                        </div>
                                        <div className="close_icons"  onClick={() => fileUploadCalcel("document")}>X</div>
                                        <div>
                                        <a href={Var_FileDocument_Ref.current} target="_blank">
                                        <img src={Eye} className="eye" style={{ margin: "0px 0px 10px 10px" }}
                                        alt=""></img></a>
                                        </div>
                                    </div>
                                </div>
                                {Var_fileprocess &&
                                    <div>
                                        <div className="upload-container" >
                                            {/* Your other UI elements */}
                                            <LinearProgress
                                                variant="determinate"
                                                value={Var_progress}
                                                color="secondary"
                                                className="progressBar"
                                                sx={{ zIndex: '1', width: '100%', margin: '0px 3px' }}
                                            />
                                            {/* Display progress percentage */}
                                            <div className="progress-text">{`${Var_progress}%`}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="remarks_submit">
                    <Button variant="contained" color="success" className="success_post_add_btn"
                        onClick={() => submitSupport()}
                        disabled={Var_isDisable}  >
                        Submit
                    </Button>
                </div>
            </div>
            {/* ================= Add Support list View  end ================= */}


            {/* ================= Add Support footer start ================= */}
            {localStorage.getItem("FooterStatus") == 'A' &&
                <div className="layout_footer footer">
                    <FooterMaster />
                </div>
            }
            {/* ================= Add Support footer end ================= */}

            {/* ================= Working hours Update Success Model Start =============== */}
            <Dialog
                fullWidth={true}
                open={isshowsuccess}
                onClose={setishiddensuccess}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "300px",
                            borderRadius: "20px",
                        },
                    },
                }}
            >
                <DialogContent>
                    <div className="success_model_body">
                        <div className="icons">
                            <img src={successModelTickImg}alt="" />
                        </div>
                        <div className="title">SUCCESS!</div>
                        <div className="contents">Ticket added successfully!</div>
                        <div className="content">Your Ticket ID is  {Var_SuportTicketNo_Ref.current}.</div>
                        <div className="segment_center">
                            <button className="botton_Cart_Create" onClick={() => okClose()}>OK</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            {/* ================= Working hours Update Success Model End  ================= */}
        </div>
    )

}
export default AddSupportComponent;







