import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import useStateRef from "react-usestateref";
import { notification } from "antd";
import { MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// Mui meterial component import
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import "./Profile.css";
import HeaderMasterComponent from "../Header/HeaderMaster";
import FooterMaster from "../Dashboard/FooterMaster";

import alertModelTickImg from "../../assets/Images/alert.svg";
import successModelTickImg from "../../assets/Doctor_web_Icon/success.svg";
import vector from "../../assets/Doctor_web_Icon/Vector.png";
import add_icon from "../../assets/clinicIcon/Add.svg";
import EmailIcon from "../../assets/icon/EmailIcon.svg";
import WebSiteIcom from "../../assets/icon/websiteIcon.svg";
import PhoneIcon from "../../assets/icon/PhoneIcon.svg";
import LoactionIcon from "../../assets/icon/LoactionIcon.svg";
import logoutIcon from "../../assets/icon/Group 23875.svg";
import BackIcon from "../../assets/Doctor_Images/Group 31.svg";

function ProfileComponent() {
  let Var_History = useHistory();

  const [Var_ShowImage, setVar_ShowImage] = React.useState(false);
  const [Var_Profiledata, setVar_Profiledata, Var_Profiledata_Ref] =
    useStateRef([]);
  const [, setVar_ProfileImg, Var_ProfileImg_Ref] = useStateRef("");
  const [, setVar_SinceData, Var_SinceData_Ref] = useStateRef("");
  const [, setVar_EmailData, Var_EmailData_Ref] = useStateRef("");
  const [, setVar_WebsiteData, Var_WebsiteData_Ref] = useStateRef("");
  const [, setVar_MobileData, Var_MobileData_Ref] = useStateRef("");
  const [, setVar_AddressData, Var_AddressData_Ref] = useStateRef("");
  const [loading, setLoading] = useState(true);

  const [Var_ProfileSuccessfully, setVar_ProfileSuccessfully] = useStateRef("");
  const [Var_ShowResults, setVar_ShowResults] = React.useState(true);
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);
  const [Var_IsShowSuccessmodal, setVar_IsShowSuccessmodal] =
    React.useState(false);
  const [Var_IsShowDeletePostModel, setVar_IsShowDeletePostModel] =
    React.useState(false);
  const [Var_PharmacyDetials, setVar_PharmacyDetials, Var_PharmacyDetials_Ref] =
    useStateRef([]);
  const [Var_pharmdataSelect, setVar_pharmdataSelect, Var_pharmdataSelect_ref] =
    useStateRef("");
  const [Var_pharmacyId, setVar_pharmacyId, Var_pharmacyId_Ref] = useStateRef(
    []
  );

  // initial function call start
  useEffect(() => {
    getDoctorProfile();
  }, []);
  // initial function call end

  const logoutModal = () => {
    setVar_IsShowDeletePostModel(!Var_IsShowDeletePostModel);
  };

  const logout = (path) => {
    Var_History.push(path);
  };

  const localDataClear = () => {
    localStorage.clear("LoginData");
    localStorage.clear("clinic_name");
    localStorage.clear("clinic_id");
    localStorage.clear("clinic_vendor_type_id");
    localStorage.clear("doctor_id");
    localStorage.clear("speciality_Id");
  };

  // navigation to back start
  const handleOnClick = (path) => {
    Var_History.push("/dashboard" + path);
  };
  // navigation to back end

  // profile model controls start
  const isCloseProfileSuccessModel = () => {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
  };
  // profile model controls end

  const successClose = () => {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
  };

  const noDeletePostModel = (data) => {
    setVar_IsShowDeletePostModel(!Var_IsShowDeletePostModel);
  };

  const yesDeletePostModel = (path) => {
    localDataClear();
    setVar_IsShowDeletePostModel(!Var_IsShowDeletePostModel);
    setVar_IsShowSuccessmodal(!Var_IsShowSuccessmodal);
  };
  const logoutsuccess = (path) => {
    setVar_ProfileSuccessfully("Logout successful.");
    setVar_IsShowSuccess(true);
    setTimeout(() => {
      setVar_IsShowSuccess(false);
      localDataClear();
      logout(path);
    }, 3000);
  };

  //File upload methods start
  const uploadImage = () => {
    setVar_ShowImage(!Var_ShowImage);
    setVar_ShowResults(!Var_ShowResults);
  };
  const getVar_MobileData = (value) => {
    const numericOnly = value.replace(/\D/g, "");
    setVar_MobileData(numericOnly);
  };
  const uploadDocument = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("module_id", "3");
    formData.append("file", e.target.files[0]);
    formData.append("pathLocation", "PROFILE/");
    Axios({
      method: "POST",
      url: "admin/awsS3FileUpload",
      data: formData,
    })
      .then((response) => {
        setVar_ProfileImg(response.data.filepath.Location);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  //File upload methods end

  const defaultPharmacy = {
    vendor_name: "Select Pharmacy",
    pharmacyId: "",
  };


  const walkinMobileNumbetValidation = (event) => {
    let numericonly = event.target.value.replace(/[^0-9.]/g, '');
    if (/^\d{0,10}$/.test(event.target.value)) {

      setVar_MobileData(numericonly);
    }
  };


  //Get Profile Details List start
  const getDoctorProfile = () => {
    setLoading(true);
    let profileGetData = {
      clinic_id: localStorage.getItem("clinic_id"),
    };
    Axios({
      method: "POST",
      url: "clinic/getClinicProfile",
      data: profileGetData,
    })
      .then((response) => {
        debugger;
        setVar_Profiledata(response.data.data);
        console.log("profile detail;s", Var_Profiledata);
        setVar_ProfileImg(response.data.data[0].profileImage);
        setVar_SinceData(response.data.data[0].vendor_since);
        setVar_EmailData(response.data.data[0].email);
        setVar_WebsiteData(response.data.data[0].website);
        setVar_MobileData(response.data.data[0].mobile);
        setVar_AddressData(response.data.data[0].address);
        const pharmaListData = response.data.data[0].pharma_list
          ? JSON.parse(response.data.data[0].pharma_list)
          : [];
        setVar_pharmdataSelect(
          pharmaListData.length > 0 ? pharmaListData[0] : defaultPharmacy
        );
        setLoading(false);
        if (pharmaListData.length > 0) {
          localStorage.setItem("pharmacy_id", pharmaListData[0].pharmacyId);
        } else {
          localStorage.setItem("pharmacy_id", "");
          getPharmacyDetials();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  //Get Profile Details List end

  //Edit Profile Details List start
  const editDoctorProfile = () => {
    if (Var_AddressData_Ref.current == "") {
      notification.error({ message: "Enter Address" });
    } else if (Var_MobileData_Ref.current == "") {
      notification.error({ message: "Enter Mobile Number" });
    } else {
      setVar_ShowResults(!Var_ShowResults);
      setLoading(true);
      let profileEditData = {
        clinic_id: localStorage.getItem("clinic_id"),
        mobile: Var_MobileData_Ref.current,
        email: Var_EmailData_Ref.current,
        address: Var_AddressData_Ref.current,
        website: Var_WebsiteData_Ref.current,
        vendor_since: Var_SinceData_Ref.current,
        file_name: "testing",
        file_path: Var_ProfileImg_Ref.current.split("?")[0],
        pharmacy_id: Var_pharmacyId.toString(),
      };
      Axios({
        method: "PUT",
        url: "clinic/editClinicProfile",
        data: profileEditData,
      })
        .then((response) => {
          setVar_ShowImage(!Var_ShowImage);
          setVar_ProfileSuccessfully("Profile updated successfully.");
          setVar_IsShowSuccess(true);
          setTimeout(() => {
            setVar_IsShowSuccess(false);
            getDoctorProfile();
            handleOnClick("");
          }, 3000);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //Edit Profile Details List end

  const getPharmacyDetials = () => {
    const payloadData = {
      Doctorid: localStorage.getItem("clinic_id"),
    };
    Axios({
      method: "POST",
      url: "doctor/getPharmadetails",
      data: payloadData,
    })
      .then((response) => {
        setVar_PharmacyDetials(response.data.data[0]);
        setVar_pharmdataSelect({
          vendor_name: "Select Pharmacy",
          pharmacyId: "",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPharmacyValue = (event) => {
    setVar_pharmdataSelect(event.target.value);
    setVar_pharmacyId(event.target.value.pharmacy_id);
  };
  return (
    <div>
      {loading && (
        <div className="loader_body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      )}

      <div>
        <HeaderMasterComponent />
      </div>
      {/* ================= Profile sub header start ================= */}
      {/* <div className="sub_header">
        <div className="sub_header_body">
          <div className="back_navigation" onClick={() => handleOnClick("/")}>
            <i className="fa fa-angle-left" aria-hidden="true"></i>
            <span>PROFILE</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            <h5>
              {Var_ShowResults ? (
                <p className="profile_edit" onClick={() => uploadImage()}>
                  Edit
                </p>
              ) : (
                <p className="profile_edit" onClick={() => editDoctorProfile()}>
                  Save
                </p>
              )}
            </h5>
          </div>
        </div>
      </div> */}
      <div>
        <div className="Navication-header">
          <div>
            <img src={BackIcon} onClick={() => handleOnClick("/")} />
            <label onClick={() => handleOnClick("/")}>Profile</label>
          </div>
        </div>
        {/* ================= Profile sub header end ================= */}

        {/* ================= Profile details View  start ================= */}
        <div className="workinghours_content">
          {/* ================= Profile details start ================= */}

          <div className="d-flex justify-content-center">
            {Var_Profiledata?.map((responseData, i) => (
              <Grid container key={i} className="profile_border_details">
                {/* <Grid item xs={3} className="logout_details">
                  <div
                    className="logout_button_details"
                    onClick={() => logoutsuccess("/")}
                  >
                    Logout
                    <img
                      style={{ paddingLeft: "5px", height: "17px" }}
                      src={logoutIcon}
                      alt=""
                    ></img>
                  </div>
                </Grid> */}

                <Grid container>
                  <Grid item xs={12} className="img_contain">
                    <div className="profile_upload_added">
                    <div>
                    <img
                      src={
                        Var_ProfileImg_Ref.current != ""
                          ? Var_ProfileImg_Ref.current
                          : vector
                      }
                      className="profile_pic_new"
                      alt=""
                    />
                    </div>
                    <div>
                     <span style={{marginLeft: "-11px"}}><img src={add_icon} onChange={(e) => uploadDocument(e)}className="profile_upload_select" aria-hidden="true" />
                      <input type="file" className="profile_upload" 
                  sx={{ minWidth: '-webkit-fill-available' }} />
                  </span>
                  </div>
                  </div>
                    <Grid item className="vendor_name_details">
                      <span className="profile_doctor_name_new">
                        {responseData.vendor_name}
                      </span>
                      {/* <div>(Hospital Administrator)</div> */}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className="speciality_email_details">
                  <Grid xs={4} className="coloumn_2details">
                    <div className="speciality_name_details">
                      Email Address<span className="star_details">*</span>
                    </div>
                    <TextField
                      className="textfield_details"
                      id="outlined-basic"
                      placeholder="Email Address"
                      variant="outlined"
                      value={Var_EmailData_Ref.current}
                      // onChange={(e) => setVar_EmailData(e.target.value)}
                      readonly={true}
                      sx={{ m: 1, minHeight: 8, minWidth: 250 }}
                    />
                  </Grid>

                  {Var_Profiledata_Ref.current[0].pharma_list == null && (
                    <Grid item xs={4}>
                      <InputLabel
                        id="demo-select-small-label"
                        style={{ color: "black", fontWeight: "500" }}
                      >
                        Pharmacy
                      </InputLabel>
                      <FormControl fullWidth>
                        <Select
                          value={Var_pharmdataSelect}
                          size="small"
                          sx={{
                            height: "50px",
                            minWidth: "-webkit-fill-available",
                            borderRadius: "5px",
                          }}
                          onChange={(e) => getPharmacyValue(e)}
                          placeholder="Select Pharmacy"
                          renderValue={(selected) => (
                            <p className="selectPval">{selected.vendor_name}</p>
                          )}
                        >
                          <MenuItem
                            key={0}
                            value={{
                              vendor_type_id: "",
                              vendor_name: "Select Pharmacy",
                              vendor_profile_path: "",
                              pharmacy_id: "",
                            }}
                          ></MenuItem>
                          {Var_PharmacyDetials_Ref.current.map((item) => {
                            return (
                              <MenuItem key={item.pharmacy_id} value={item}>
                                {item.vendor_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {Var_Profiledata_Ref.current[0].pharma_list != null && (
                    <Grid item xs={4}>
                      <InputLabel
                        className="demo_special_name"
                        id="demo-select-small-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Pharmacy
                      </InputLabel>
                      <TextField
                        className="Pharmacy_select_profile"
                        id="outlined-basic"
                        placeholder={
                          Var_pharmdataSelect_ref.current.vendor_name
                        }
                        size="small"
                        sx={{ minWidth: "-webkit-fill-available" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid container style={{ justifyContent: "space-around" }}>
                  <Grid xs={4} className="coloumn_2details">
                    <div className="speciality_name_details">
                      Contact Number<span className="star_details">*</span>
                    </div>
                    <TextField
                      className="textfield_details"
                      id="outlined-basic"
                      placeholder="Contact Number"
                      variant="outlined"
                      value={Var_MobileData_Ref.current}
                      
                      onChange={(e) => walkinMobileNumbetValidation(e)}
                      sx={{ m: 1, minHeight: 8, minWidth: 250 }}
                    />
                  </Grid>

                  <Grid xs={4} className="coloumn_2details">
                    <div className="speciality_name_details">
                      Location<span className="star_details">*</span>
                    </div>
                    <TextField
                      className="textfield_details"
                      id="outlined-basic"
                      placeholder="Location"
                      variant="outlined"
                      value={Var_AddressData_Ref.current}
                      onChange={(e) => setVar_AddressData(e.target.value)}
                      sx={{ m: 1, minHeight: 8, minWidth: 250 }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container>
                  
                  <Grid xs={4} className="coloumn_1details"></Grid>
                </Grid> */}
                <Grid container className="save_details_new">
                  <Grid ClassName="save_profile_details">
                    <div
                      className="save_button_details"
                      onClick={() => editDoctorProfile()}
                    >
                      Save
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {/* </div>

        <div> */}
          </div>
          {/* ================= Profile details end ================= */}

          {Var_IsShowSuccess && (
            <div className="popup-main">
              <div className="pushNotification_body">
                <div className="pushNotification_content">
                  <p className="notification_text_head">
                    <b>{Var_ProfileSuccessfully}</b>
                  </p>
                </div>
                <div className="progress-prt">
                  <div className="progress-bar"></div>
                </div>
              </div>
            </div>
          )}

          {/* ======================== Profile Success Model start======================================= */}
          {/* <Dialog
          fullWidth={true}
          open={Var_IsShowSuccess}
          onClose={setVar_IsShowSuccess}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={successModelTickImg} alt="" />
              </div>
              <div className="title">success!</div>
              <div className="content">{Var_ProfileSuccessfully}</div>
              <div className="segment_center">
                <button
                  className="botton_Cart_Create"
                  onClick={() => isCloseProfileSuccessModel()}
                >
                  OK
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog> */}
          {/* ================================= */}
          {/* <Dialog
          fullWidth={true}
          open={Var_IsShowSuccess}
          onClose={setVar_IsShowSuccess}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={successModelTickImg} alt="" />
              </div>
              <div className="title">success!</div>
              <div className="content">{Var_ProfileSuccessfully}</div>
              <div className="segment_center">
                <button
                  className="botton_Cart_Create"
                  onClick={() => successClose("/")}
                >
                  OK
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog> */}
          {/* ======================== Profile Success Model end======================================= */}

          {/* ===========================================sdfgdhtrfg======================== */}
          {/* <Dialog
          open={Var_IsShowDeletePostModel}
          onClose={setVar_IsShowDeletePostModel}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={alertModelTickImg} alt="" />
              </div>
              <div className="title">alert!</div>
              <div className="content">Do you want to logout?</div>
              <div className="alert_btn_center">
                <button
                  className="alert_no_btn"
                  onClick={() => noDeletePostModel()}
                >
                  No
                </button>
                <button
                  className="alert_yes_btn"
                  onClick={() => yesDeletePostModel("/")}
                >
                  Yes
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog> */}

          {/* <Dialog
          fullWidth={true}
          open={Var_IsShowSuccessmodal}
          onClose={setVar_IsShowSuccessmodal}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={successModelTickImg} alt="" />
              </div>
              <div className="title">success!</div>
              <div className="content">Logout successful!</div>
              <div className="segment_center">
                <button
                  className="botton_Cart_Create"
                  onClick={() => logoutsuccess("/")}
                >
                  OK
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog> */}

          {/* =============================================jyhgfdsr========================== */}
        </div>
      </div>
      {/* ================= Profile details View  end ================= */}

      {/* ================= Profile footer start ================= */}
      {/* <div className="footer">
        <FooterMaster />
      </div> */}
      {/* ================= Profile footer end ================= */}
    </div>
  );
}
export default ProfileComponent;
