// AUTHOR : Dinesh
// CR-DATE: 27-June-2023
// MOD-DATE: 19-July-2023
// DESCRIPTION: Forgot Update work

import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import Axios from "axios";
import { notification, Spin } from "antd";

// Mui meterial component import
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import "./Forget.css";

import Logo from "../../images/Logo.svg";
import DoctorWeb from "../../assets/Images/login_image.png";
import EMail_Box from "../../assets/Doctor_Images/Email_Icon.jpeg";
import HomeImage from "../../assets/Images/log_im.svg";
import tombutton from "../../assets/Images/tom_journey.svg";

function Forgot() {
  let Var_History = useHistory();

  const [Var_Email, setVar_Email] = useState("");
  const [Var_EmailErr, setVar_EmailErr] = useState("");
  const [Var_IsLoading, setVar_IsLoading] = useState("");
  const [loading, setLoading] = useState(false);


  // navigation method start
  const handleOnClick = (path) => {
    Var_History.push(path)
  };
  // navigation method end


  //forgot method start
  const submitMail = (e) => {
    e.preventDefault();
    try {
      if (Var_Email) {
        setLoading(true);
        setVar_IsLoading(true);
        const host = window.location.host;
        const pathName = window.location.pathname.split("/")[1];

        var obj_data = {
          email: Var_Email,
          host,
          pathname: "HealthCareModule",
          module: "Clinic",
        };
        Axios({
          method: "POST",
          url: "admin/forgot_password_check_mail",
          data: obj_data,
        })
          .then((response) => {
            if (response.data.status === 1) {
              notification.success({
                message: response.data.msg,
              });
              handleOnClick("/");
              setLoading(false);
            } else {
              notification.error({
                message: response.data.msg,
              });
              setLoading(false);
            }
            setVar_IsLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setVar_IsLoading(false);
            console.log(error);
          });
      } else {
        setVar_EmailErr(true);
      }
    } catch (e) {
      setLoading(false);
      setVar_IsLoading(false);
    }
  };
  //forgot method end

  return (
    <div>
      {/* {loading &&
        <div className="loader_body">
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        </div>
      } */}

      {/* ================= Forgot page start ================= */}
      <div className="BG_Card">
      <div className="login_BG">
        <div className="login_input_card">
          <div>
            <p className="welcome_name_forget">Request New Password</p>
            <p className="doctor_name_forget">Enter the current email address associated with your
               account, <br/> then click submit. We'll email you a link to
               a page <br/> where you can easily create a new password.</p>
            {/* <p className="login_name">Login To See Your Schedules</p> */}
            <div className="email_container">
            <label className="email_input_label">E-Mail ID</label><br />
            <input className="email_input" type="email"
              placeholder="Enter user name"
              name="email" autoComplete="true" value={Var_Email}
              onChange={(e) => setVar_Email(e.target.value)}></input>
            <img className="email_box_icon" alt="EMail_Box" src={EMail_Box} />
            <div style={{ display: Var_EmailErr ? "block" : "none", color: "#ff0068", textAlign: "center", marginTop: "5px" }}>
              Please Enter Valid Email
            </div>
          </div>
          <div className="login_button_container">
            <button className="login_button" onClick={(e) => submitMail(e)}>
              Submit
            </button>
          </div>
          <div className="login_button_container">
            <button className="cancel_button" onClick={() => handleOnClick("/")}>
              Cancel
            </button>
          </div>
          </div>
        </div>
      </div>
      </div>

      {/* ================= Forgot page end ================= */}

    </div>
  );
};

export default Forgot;
