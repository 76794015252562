// AUTHOR : Dinesh
// CR-DATE: 17-July-2023
// MOD-DATE: 01-August-2023
// DESCRIPTION: Notification Update work

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import useStateRef from "react-usestateref";
import axios from "axios";
import moment from "moment";


// Mui meterial component import
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";


import './Notification.css'
import HeaderMasterComponent from "../Header/HeaderMaster";
import FooterMaster from '../Dashboard/FooterMaster'
import NotFound from "../../assets/Web_Doctor_Icon_new_theme/NotFound.svg"

function NotificationComponent() {
    let Var_History = useHistory();
    const [Var_NotificationData, setVar_NotificationData] = React.useState([]);
    const [Var_NoData, setVar_NoData, Var_NoData_Ref] = useStateRef([]);
    const [loading, setLoading] = useState(true);
    const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);

    // var Var_Dates = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    var Var_Dates = new Date().toLocaleDateString('fr-CA');
    const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor] = React.useState([]);
    var [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect] = useState("");
    const [Var_specialityid, setVar_specialityid, Var_specialityid_Ref] = useStateRef(null);


    // initial function call start
    useEffect(() => {
        getClinicWiseDoctor();
        getNotifications();
    }, []);
    // initial function call end


    // navigation to back start
    const handleOnClick = (path) => {
        Var_History.push("/dashboard" + path)
    };
    // navigation to back end


    //Get Notifications Details List start
    // const getNotifications = () => {
    //     setLoading(true);
    //     var notificationGetData = {
    //         // vendor_type_id: localStorage.getItem("doctor_id"),
    //         doctor_id: localStorage.getItem("doctor_id")
    //         // date_value: Var_Dates,
    //     };
    //     Axios({
    //         method: "get",
    //         url: "doctor/doctorwebnotification",
    //         data: notificationGetData,
    //     })
    //         .then((response) => {

    //             setVar_NotificationData([]);
    //             setVar_NoData(response.data.msg);
    //             setLoading(false);
    //             if (response.data.data.length > 0) {
    //                 setVar_NotificationData(response.data.data);
    //                 setLoading(false);
    //             }
    //         })
    //         .catch((error) => {
    //             setLoading(false);
    //             console.log(error);
    //         });
    // }


    const convertTo12HrFormat = (time24) => {
        var timeArr = time24.split(':');
        var hours = parseInt(timeArr[0]);
        var minutes = parseInt(timeArr[1]);
        var suffix = hours >= 12 ? 'pm' : 'am';

        hours = hours % 12 || 12;
        var time12 = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + suffix;

        return time12;
    }




    //Get Notifications Details List end


    // const getClinicWiseDoctor = () => {
    //     setLoading(true);
    //     debugger
    //     var data = {
    //         clinic_id: localStorage.getItem("clinic_id")
    //     };
    //     axios
    //         .post("clinic/getClinicWiseDoctor", data)
    //         .then((response) => {
    //             console.log('test', response.data.data)
    //             setVar_getClinicWiseDoctor(response.data.data);
    //             setVar_doctorSpecialitySelect(response.data.data[0].specialityid);
    //             var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
    //             console.log(filteredData);
    //             setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);
    //             setVar_specialityid(filteredData[0].specialityid);
    //             console.log("praveen123456789",Var_specialityid)
    //             localStorage.setItem("")
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }



    // const getClinicWiseDoctor = () => {
    //     setLoading(true);
    //     debugger
    //     var data = {
    //       clinic_id: localStorage.getItem("clinic_id")
    //     };
    //     axios
    //       .post("clinic/getClinicWiseDoctor", data)
    //       .then((response) => {
    //         console.log('test', response.data.data)
    //         setVar_getClinicWiseDoctor(response.data.data);
    //         setVar_doctorSpecialitySelect(response.data.data[0].specialityid);
    //         var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
    //         console.log(filteredData);
    //         setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);

    //         console.log("poiuytrdcvhgfcvygfvc",filteredData[0].specialityid)
    //         localStorage.setItem("")
    //         setLoading(false);
    //         setVar_specialityid(filteredData[0].specialityid);

    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }

    const getClinicWiseDoctor = () => {
        debugger;
        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("clinic_id")
        };
        axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {
                debugger;
                var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
                setVar_DoctorDetailShow(filteredData[0].doctorName + ',' + " " + filteredData[0].specialityname);
                setVar_specialityid(filteredData[0].specialityid);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }



    const getNotifications = () => {
        setLoading(true);
        debugger
        var data = {
            doctor_id: localStorage.getItem("doctor_id"),
            clinic_id: localStorage.getItem("clinic_id"),
            speciality_id: localStorage.getItem("speciality_Id")
        };
        axios
            .post("doctor/doctorwebnotification", data)
            .then((response) => {
                // setVar_NotificationData(response.data.data);
                // setVarnotificationcount(response.data.data.filter(x => x.to_date == formattedDate && (x.speciality_id == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id")) || (x.ref_speciality_id == localStorage.getItem("speciality_Id") && x.ref_doctor_id == localStorage.getItem("doctor_id"))))
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }




    const currentDate = new Date();

    // Get the year, month, and day
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because January is 0
    const day = ('0' + currentDate.getDate()).slice(-2);

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    console.log("praveen", formattedDate)


    return (
        <div>
            {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            }

            <div>
              <HeaderMasterComponent />
            </div>

            {/* ================= Notification sub header start ================= */}
            <div className="sub_header">
                <div className="sub_header_body">
                    <div className="back_navigation" onClick={() => handleOnClick("/")}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                        <span>NOTIFICATION</span>
                    </div>
                    <div className="flex_grow"></div>
                    <div className="header_right_content"></div>
                    <div className='dropdown_button'>
                        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                SelectProps={{
                                    renderValue: (p) => p
                                }}
                                value={Var_DoctorDetailShow_Ref.current}
                                readOnly={true}
                                renderValue={(p) => p}
                            >
                            </Select>
                        </FormControl>
                    </div>
                </div>

            </div>
            {/* ================= Notification sub header end ================= */}


            {/* ================= Notification list View  start ================= */}
            <div className='content_view'>
                {/* {(Var_NotificationData.todate ==formattedDate)  &&  */}

                {Var_NotificationData.length > 0 &&
                    Var_NotificationData?.map((item, i) => (
                        <>


                            {item.to_date == formattedDate && item.speciality_id == localStorage.getItem("speciality_Id") && item.doctor_id == localStorage.getItem("doctor_id") &&
                                <>

                                    <Card className='notification_content_card' key={i}>

                                        <div className="notification_content">
                                            <div>
                                                <p className='clinic_doctor_name'>{item.vendor_name}</p>
                                                <p className='clinic_name'>{item.vendor_contact}</p>
                                            </div>
                                            <p className='clinic_time'>{item.booked_time == null ? 0 : convertTo12HrFormat(item.booked_time)}</p>
                                            {/* <p className="clinic_time">{item.booked_time}</p> */}
                                        </div>
                                        <hr className='underline_status'></hr>
                                        <p className='email_id'>{item.notification_content}</p>

                                    </Card>
                                </>
                            }


                            {item.to_date == formattedDate && item.ref_speciality_id == localStorage.getItem("speciality_Id") && item.ref_doctor_id == localStorage.getItem("doctor_id") &&
                                <>

                                    <Card className='notification_content_card' key={i}>

                                        <div className="notification_content">
                                            <div>
                                                <p className='clinic_doctor_name'>{item.vendor_name}</p>
                                                <p className='clinic_name'>{item.vendor_contact}</p>
                                            </div>
                                            <p className='clinic_time'>{item.booked_time == null ? 0 : convertTo12HrFormat(item.booked_time)}</p>
                                            {/* <p className="clinic_time">{item.booked_time}</p> */}
                                        </div>
                                        <hr className='underline_status'></hr>
                                        <p className='email_id'>{item.notification_content}</p>

                                    </Card>
                                </>
                            }



















                        </>
                    ))
                }


                {
                    // Var_NotificationData.length == 0 && <div>  <p>{Var_NoData_Ref.current}</p> </div>
                    Var_NotificationData == 0 &&

                    <div style={{ display: Var_NoData, height: "70vh" }}>
                        <p className="cancel_no_data"> <img src={NotFound} className="cancel_no_data_image" />No Data Found</p>
                    </div>
                }

            </div>
            {/* ================= Notification list View  end ================= */}


            {/* ================= Notification footer start ================= */}
            {/* <div className="footer">
                <FooterMaster />
            </div> */}
            {/* ================= Notification footer end ================= */}
        </div>
    )
}
export default NotificationComponent;
