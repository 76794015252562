// AUTHOR : Dinesh
// CR-DATE: 26-June-2023
// MOD-DATE: 19-July-2023
// DESCRIPTION: Login Update work

import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { notification, Spin } from "antd";

// Mui meterial component import
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import "./Login.css";

import EMail_Box from "../../assets/Doctor_Images/Email_Icon.jpeg";
import Eye from "../../images/eye.svg";
import Logo from "../../images/Logo.svg";
import HomeImage from "../../assets/Images/log_im.svg";
import tombutton from "../../assets/Images/tom_journey.svg";

function Login() {
  let Var_History = useHistory();

  const [Var_Email, setVar_Email] = useState("");
  const [Var_Password, setVar_Password] = useState("");
  const [Var_LoginError, setVar_LoginError] = useState("");
  const [Var_InvalidEmail, setVar_InvalidEmail] = useState("");
  const [Var_EmailError, setVar_EmailError] = useState(false);
  const [Var_PasswordError, setVar_PasswordError] = useState(false);
  const [Var_Hidden, setVar_Hidden] = useState(true);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Var_Remainder, setVar_Remainder] = useState(false);

  // initial function call start
  useEffect(() => {
    localDataClear();
  }, []);
  // initial function call end

  // navigation method start
  const handleOnClick = (path) => {
    Var_History.push(path);
  };
  // navigation method end
  const iconStyle = {
    fontSize: "26px", // Change the size
    color: "#2F887C", // Change the color
    position: "absolute",
    top: "68px",
    right: "13px",
    cursor: "pointer",
  };

  //login validation method start
  const toggleshow = () => {
    setVar_Hidden(!Var_Hidden);
  };
  const emailValidation = (value) => {
    setVar_Email(value);
    setVar_LoginError("");
    // setVar_InvalidEmail(validateEmail(value));
    let re = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
    // /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (value.match(re)) {
      setVar_InvalidEmail(false);
    } else {
      setVar_InvalidEmail(true);
      setVar_EmailError(false);
    }
    if (value === "") {
      setVar_InvalidEmail(false);
    }
  };
  const validation = () => {
    setVar_EmailError(false);
    setVar_PasswordError(false);
    setVar_LoginError("");
    setVar_InvalidEmail(false);
    if (Var_Email === "" && Var_Password === "") {
      setVar_EmailError(true);
      setVar_PasswordError(true);
      return false;
    } else if (Var_Email === "") {
      setVar_EmailError(true);
      return false;
    } else if (Var_Password === "") {
      setVar_PasswordError(true);
      return false;
    }
    return true;
  };

  const getRemainderData = (e) => {
    setVar_Remainder(e);
  };

  const getPasswordData = (e) => {
    setVar_Password(e);
    setVar_LoginError("");
    setVar_PasswordError(false);
  };
  const validateEmail = (data) => {
    let re = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
    // /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (data.match(re)) {
      setVar_InvalidEmail(false);
    } else {
      setVar_InvalidEmail(true);
    }
  };
  const localDataClear = () => {
    localStorage.clear("LoginData");
    localStorage.clear("clinic_name");
    localStorage.clear("clinic_id");
    localStorage.clear("clinic_vendor_type_id");
    localStorage.clear("doctor_id");
    localStorage.clear("speciality_Id");
  };
  //login validation method end

  //login post data start
  const postLogin = async () => {
    const isVaild = await validation();
    if (isVaild) {
      var logindata = {
        email: Var_Email,
        password: Var_Password,
        module: "clinic",
      };
      setLoading(true);
      Axios({
        method: "POST",
        // url: "clinic/login",
        url: "admin/LoginWeb",
        data: logindata,
      })
        .then((response) => {
          debugger;
          // if (response.data.status == 1 && response.data.data[0].vendor_type_id == 3) {
          //   if (response.data.data.length > 0) {
          //     localStorage.setItem("LoginData", JSON.stringify(response.data.data[0]));
          //     localStorage.setItem("clinic_name", response.data.data[0]["user_name"]);
          //     localStorage.setItem("clinic_id", response.data.data[0]["vendor_id"]);
          //     localStorage.setItem("clinic_vendor_type_id", response.data.data[0]["vendor_type_id"]);
          //     setLoading(false);
          //     Var_History.push("/dashboard");
          //     setLoading(false);
          //   }
          //   setLoading(false);
          // } else {
          //   setVar_LoginError("Email Or Password wrong");
          //   setLoading(false);
          // }
          if (
            response.data.status == 1 &&
            response.data.data[0].user_type_id == 3
          ) {
            if (response.data.data.length > 0) {
              localStorage.setItem(
                "LoginData",
                JSON.stringify(response.data.data[0])
              );
              localStorage.setItem(
                "clinic_name",
                response.data.data[0]["user_name"]
              );
              localStorage.setItem(
                "clinic_id",
                response.data.data[0]["vendor_id"]
              );
              localStorage.setItem(
                "clinic_vendor_type_id",
                response.data.data[0]["user_type_id"]
              );
              setLoading(false);
              Var_History.push("/dashboard");
              setLoading(false);
              getClinicWiseDoctor();
            }
            setLoading(false);
          } else {
            setVar_LoginError("Email Or Password wrong");
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          notification.error({ message: "Invalid username or password" });
        });
    }
  };
  //login post data end
  function getClinicWiseDoctor() {
    var data = {
      clinic_id: localStorage.getItem("clinic_id"),
    };
    Axios.post("clinic/getClinicWiseDoctor", data)
      .then((response) => {
        debugger;
        if (response.data.data.length != 0) {
          localStorage.setItem("clinic_doctor_name", "Clinic_Doctor");
          localStorage.setItem("changes_doctor", " ");
        } else {
          console.log("Data");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <div className="login_page">
      {/* {loading &&
        <div className="loader_body">
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        </div>
      } */}

      {/*                                                                                                     ================= Login page start ================= */}
      <div className="BG_Card">
        <div className="login_BG">
          <div className="login_input_card">
            <div>
              <p className="welcome_name">Welcome Back</p>
              <p className="doctor_name">Mr. Doctor</p>
              <p className="login_name">Login To See Your Schedules</p>
              <div>
                <div className="email_container">
                  <label className="email_input_label">E-Mail ID</label>
                  <br />
                  <input
                    className="email_input"
                    type="email"
                    placeholder="Enter your email address"
                    name="email"
                    autoComplete="true"
                    value={Var_Email}
                    onChange={(e)                                                                             => emailValidation(e.target.value)}
                  ></input>
                  <img
                    className="email_box_icon"
                    alt="EMail_Box"
                    src={EMail_Box}
                  />
                  <div className="error">
                    {Var_EmailError && "Email is Required"}
                  </div>
                  <div className="error">
                    {Var_InvalidEmail && "Invalid Email"}
                  </div>
                </div>
                <div className="password_container">
                  <label className="password_input_label">Password</label>
                  <br />
                  <input
                    className="password_input"
                    type={Var_Hidden ? "password" : "text"}
                    placeholder="Enter your password"
                    name="password"
                    autoComplete="true"
                    value={Var_Password}
                    onChange={(e)                                                                             => getPasswordData(e.target.value)}
                  ></input>
                  {Var_Hidden ? (
                    <VisibilityOff onClick={toggleshow} style={iconStyle} />
                  ) : (
                    <Visibility onClick={toggleshow} style={iconStyle} />
                  )}
                  <div className="error">
                    {Var_PasswordError && "Password is Required"}
                  </div>
                </div>
                <div className="error">
                  {Var_LoginError && "Email Or Password wrong"}
                </div>
                <div className="login_forgot">
                  {/* <div className="remainder_button">
                  <input type="radio" className="remainder" id="remainder" value={Var_Remainder} onClick={(e) => getRemainderData(e.target.checked)}></input>
                  <p className="login_remainder_button" for="remainder">Remainder me</p>
                </div> */}
                  <p
                    className="login_forget_button"
                    onClick={()                                                                               => handleOnClick("forgot")}
                  >
                    Forgot Password?
                  </p>
                </div>
                <div className="login_button_container">
                  <button
                    className="login_button"
                    onClick={()                                                                               => {
                      postLogin();
                    }}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================= Login page end ================= */}
    </div>
  );
}

export default Login;
